import { combineReducers } from "redux";
import appReducer from "./reducer/app";
import markPageReducer from "./reducer/markPageReducer";
import reportCardPageReducer from "./reducer/reportCardPageReducer";
import schoolReducer from "./reducer/school";
import schoolYearReducer from "./reducer/schoolYear";
import settingReducer from "./reducer/setting";
import settingPageClassListReducer from "./reducer/settingPageClassListReducer";
import settingPageEvaluateStructureListReducer from "./reducer/settingPageEvaluateStructureListReducer";
import staffListPageReducer from "./reducer/staffListPageReducer";
import studentListPageReducer from "./reducer/studentListPageReducer";
import summaryPageReducer from "./reducer/summaryPageReducer";
import transcriptPageReducer from "./reducer/transcriptPageReducer";

const reducers = combineReducers({
  app: appReducer,
  school: schoolReducer,
  schoolYear: schoolYearReducer,
  setting: settingReducer,
  markPage: markPageReducer,
  studentListPage: studentListPageReducer,
  reportCardPage: reportCardPageReducer,
  transcriptPage: transcriptPageReducer,
  summaryPage: summaryPageReducer,
  staffListPage: staffListPageReducer,
  settingPageClassList: settingPageClassListReducer,
  settingPageEvaluateStructureList: settingPageEvaluateStructureListReducer,
});

export default reducers;
