import { Button, Form, Modal, Radio } from "antd";
import {
  anualLeaveActive,
  presentActive,
  lateActive,
  noAuthLeaveActive,
  daySchoolActive,
} from "asset/img/Images";
// import { isPermitted } from "authen/authenHelper";
import CustomBreadcrumb from "common/breadcrumb/CustomBreadcrumb";
import InfoFieldDatePicker from "common/CusInfoField/infoFieldDatePicker/InfoFieldDatePicker";
import InfoFieldSelect from "common/CusInfoField/infoFieldSelect/InfoFieldSelect";
import RectangleTab from "common/RectangleTabs/RectangleTab";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
// import { getSetting } from "util/APIHelper";
import IsMobile from "hook/isMobile";
import MobileHeader from "mobile/layout/header/MobileHeader";
import { HEADER_TYPE } from "mobile/util/mobileConstant";
import {
  exportAttendance,
  checkExistsAttendance,
  getAttendanceByDay,
  getAttendanceEvent,
  updateAttendanceEvent,
  handleAttendanceRawData,
  handleBoardingAttendance,
  // filterClass,
  // filterGrade,
  // filterStudentAttendance,
  // getAttendanceList,
  getAttendanceListByClass,
  getAttendanceRoomId,
  getClassListByGrade,
  getAttendanceByClassDate,
  getGradeList,
  getLessonListBySession,
  getNumberOfAttendancesByCard,
  getSessionList,
  getStudentListByClass,
  getRoomAttendance,
  updateMultiAttendance,
} from "util/APIs";
import {
  ATTENDANCE_STATUS,
  is1920OrMore,
  // PERMISSION_OBJECT,
  // PERMISSION_TYPE,
  // SETTING_GROUP_KEYS,
  TABLE_PAGE_SIZES,
} from "util/constant";
import { routes, STUDENT_MANAGEMENT } from "util/routes";
import DateAttendance from "./component/DateAttendance";
import LunchAttendance from "./component/LunchAttendance";
import MonthAttendance from "./component/MonthAttendance";
import WeekAttendance from "./component/WeekAttendance";
import DaySchoolAttendence from "./component/DaySchoolAttendence";
import styles from "./styles.module.css";
import { Notification } from "common/Notification";


const boardingOptions = [
  {
    title: "Có",
    value: true,
  },
  {
    title: "Không",
    value: false,
  },
];

export default function AttendancePage() {
  const isUsingMobile = IsMobile();
  const userInfo = useSelector((state) => state?.app?.userInfo);
  const accountId = userInfo?.account?.id;
  // #region    VARIABLES //////////////////////////
  //////////////////////////////////////////////////
  const isAllowToUpdate = true;
  // const isAllowToUpdate = isPermitted(
  //   PERMISSION_OBJECT.attendance,
  //   PERMISSION_TYPE.update
  // );

  const responsive = {
    setResponsiveCol: () => {
      if (!is1920OrMore) {
        // columns[1].width = 120;
        // columns[2].width = 120;
        // columns[3].width = 120;
      }
    },
    nodeWrapperWidth: () => {
      if (is1920OrMore) return "560px";
      if (!is1920OrMore) return "560px";
    },
    tableScroll: () => {
      if (is1920OrMore) return {};
      else
        return {
          x: 1000,
          y: 490,
        };
    },
  };
  const TABS = ["Theo ngày", "Theo tuần", "Theo tháng", "Ăn trưa", "Bán trú"];
  const [form] = Form.useForm();

  const schoolYear = useSelector(
    // (state) => state.schoolYear.selectedSchoolYear
    (state) => state
  );

  const [grade, setGrade] = useState(null);
  const [gradeExport, setGradeExport] = useState(null);

  const [section, setSection] = useState(null);
  const [classId, setClassId] = useState(null);
  const [className, setClassName] = useState(null);
  const [pageSize, setPageSize] = useState(TABLE_PAGE_SIZES[0]);
  const [gradeList, setGradeList] = useState([]);
  const [classList, setClassList] = useState([]);
  const [room, setRoomList] = useState(null);
  const [roomId, setRoomId] = useState(null);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isRefresh, setIsRefresh] = useState(false);
  const [sectionList, setSectionList] = useState(null);
  const [sectionListFull, setSectionListFull] = useState(null);
  const [currentTab, setCurrentTab] = useState(TABS[0]);
  const [tableLoading, setTableLoading] = useState(false);
  const [attendanceData, setAttendanceData] = useState([]);
  const [attendanceRoom, setAttendanceRoom] = useState([]);
  const [attendanceClassDateData, setAttendanceClassDateData] = useState([]);
  const [daySchoolId, setDaySchoolId] = useState(null);
  const [isSubmittedAll, setIsSubmittedAll] = useState(true);
  // const [listAttendanceClass, setListAttendanceClass] = useState([]);
  const [selectedDate, setSelectedDate] = useState(moment().format());

  const [sectionExport, setSectionExport] = useState(null);
  const [selectedDateExport, setSelectedDateExport] = useState(null);
  const [selectedDateFromExport, setSelectedDateFromExport] = useState(null);
  const [selectedDateToExport, setSelectedDateToExport] = useState(null);
  const [dateType, setDateType] = useState('day');

  // const [selectedDay, setSelectedDay] = useState(new Date().getDate());
  const [totalStudents, setTotalStudents] = useState(0);
  const [attendanceStudents, setAttendancesStudents] = useState(0);
  const [lessonList, setLessonList] = useState([]);
  const [currentLesson, setCurrentLesson] = useState(null);
  const [absentStudent, setAbsentStudent] = useState(0);
  const [boarding, setBoarding] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tempSection, settempSection] = useState(null);


  responsive.setResponsiveCol();
  const getSectionList = useCallback(async () => {
    // const res = await getSetting(SETTING_GROUP_KEYS.section, setSectionList);
    // if (res?.data?.data?.[0]) {
    //   setSection(res?.data?.data?.[0]?.key);
    // }
    const params = {
      // orderBy: "title",
    };
    const res = await getSessionList(accountId, params);
    if (res?.status === 200) {
      const data = res?.data?.data;
      setSectionListFull([...data]);
      data.pop()
      setSectionList(res?.data?.data);
      if (data?.length > 0) {
        setSection(data[0]?.id);
        setSectionExport(data[0]?.id);
      }
    }
  }, [accountId]);

  const checkIsAttendanceAll = useCallback(async () => {
    try {
      const params = {
        class_id: classId,
        date: selectedDate,
        section,
      };
      const res = await checkExistsAttendance(params);
      if (res?.data?.code === "success") {
        setIsSubmittedAll(res?.data?.data?.exist);
      }
    } catch (error) { }
  }, [classId, section, selectedDate]);

  const getGrade = useCallback(async () => {
    const params = {
      orderBy: "name",
    };
    const res = await getGradeList(accountId, params);
    if (res?.status === 200) {
      const data = res?.data?.data;
      data.pop()
      setGradeList(data);
      if (data?.length > 0) {
        setGrade(data[0]?.id);
        setGradeExport(data[0]?.id);
      }
    }
    // const params = {
    //   sort: "created_at",
    //   page: 1,
    //   size: 9999,
    //   school_year_id: schoolYear.id,
    //   page_name: PERMISSION_OBJECT.attendance,
    // };
    // filterGrade(params).then((res) => {
    //   if (res?.data?.code === "success") {
    //     const data = res?.data?.data;

    //     setGradeList(data);

    //     if (data?.length > 0) {
    //       setGrade(data[0].id);
    //     }
    //   }
    // });
  }, [accountId]);

  const getClass = useCallback(
    async (gradeId) => {
      const params = {
        orderBy: "name",
      };
      const res = await getClassListByGrade(accountId, gradeId, params);
      if (res?.status === 200) {
        const data = res?.data?.data;
        setClassList(data);
        if (data?.length > 0) {
          setClassId(data[0]?.id);
        }
      }
    },
    [accountId]
  );

  const getRoom = useCallback(async () => {
    const res = await getRoomAttendance(accountId, userInfo?.user?.id);
    if (res?.status === 200) {
      const data = res?.data?.data;
      data.sort((a, b) => a.id - b.id);
      setRoomList(data);
      if (data?.length > 0) {
        setRoomId(data[0]?.id);
      }
    }
  }, [accountId]);

  const getDataByDay = useCallback(async () => {
    const params = {
      "filters[date][$eq]": moment(selectedDate).format("YYYY-MM-DD"),
      "filters[sessionId]": section,
      "filters[lessonId]": currentLesson,
    };
    if (classId) {
      const res = await getAttendanceByDay(accountId, classId, params);
      if (res?.status === 200) {
        setAttendanceData(res?.data.data);
      }
    }
  }, [accountId, classId, currentLesson, section, selectedDate]);
  const [attendanceEventId, setAttendanceEventId] = useState("");
  const [attendanceEventState, setAttendanceEventState] = useState("");
  const getData = useCallback(
    async (page = null) => {
      try {
        if (page === null) page = currentPage;
        setTableLoading(true);
        const original = {
          "pagination[pageSize]": pageSize.value,
          "pagination[page]": page,
        };
        if (currentTab === TABS[0]) {
          if (tempSection !== null) {
            setSection(tempSection)
          }
          settempSection(null)
          // Theo ngày
          const date = {
            "filters[sessionId]": section,
            "filters[date][$eq]": moment(selectedDate).format("YYYY-MM-DD"),
          };
          const attendance_event = await getAttendanceEvent(accountId, date);
          if (attendance_event.data?.data[0]?.attendance === false) {
            setAttendanceEventState(attendance_event.data?.data[0]?.attendance);
            setAttendanceEventId(attendance_event.data?.data[0]?.id);
            setAttendanceData([]);
            const params = {
              ...original,
              "filters[date][$eq]": moment(selectedDate).format("YYYY-MM-DD"),
              "filters[sessionId]": section,
              "filters[lessonId]": currentLesson,
              "orderBy": "stt"
            };
            if (classId && currentLesson) {
              const res = await getAttendanceByDay(accountId, classId, params);
              if (res?.status === 200) {
                setAttendanceData(res?.data.data);
                const present_lst = []
                res.data.data.filter(function (obj) {
                  if (obj.workflow_state === "present") {
                    present_lst.push(obj.id)
                  }
                })
                setAttendancesStudents(present_lst.length)
              }
            }
          } else {
            setAttendanceEventState(attendance_event.data?.data[0]?.attendance);
            setAttendanceData([]);
            const params = {
              ...original,
              "filters[date][$eq]": moment(selectedDate).format("YYYY-MM-DD"),
              "filters[sessions.id][$in]": section,
              "filters[lessons.id][$in]": currentLesson,
            };
            if (classId && currentLesson) {
              const res = await getAttendanceListByClass(
                accountId,
                classId,
                params
              );
              if (res?.status === 200) {
                res?.data.data.sort(
                  (a, b) => a.students[0].stt - b.students[0].stt
                );
                if (res?.status === 200) {
                  setAttendanceData(res?.data.data);
                  const present_lst = []
                  res.data.data.filter(function (obj) {
                    if (obj.workflow_state === "present") {
                      present_lst.push(obj.id)
                    }
                  })
                  setAttendancesStudents(present_lst.length)
                }
              }
            }
          }
        }
        else if (currentTab === TABS[4]) {
          if (section !== 5) {
            settempSection(section)
          }
          setSection(5)
          const date = {
            "filters[sessionId]": 5,
            "filters[date][$eq]": moment(selectedDate).format("YYYY-MM-DD"),
          };
          const attendance_event = await getAttendanceEvent(accountId, date);
          if (attendance_event.data?.data[0]?.attendance === false) {
            setAttendanceEventState(attendance_event.data?.data[0]?.attendance);
            setAttendanceEventId(attendance_event.data?.data[0]?.id);
          }
          const attendanceRoomData = await getAttendanceRoomId(
            accountId,
            roomId
          );
          if (attendanceRoomData?.status === 200) {
            attendanceRoomData?.data?.data.forEach((element) => {
              element["boarding_attendance"] = true;
            });
          }

          if (roomId) {
            const params = {
              "filters[date]": moment(selectedDate).format("YYYY-MM-DD"),
            };
            const res = await getAttendanceByClassDate(
              accountId,
              roomId,
              params
            );

            // console.log("buoitaco", res, res?.data?.data);
            // console.log(
            //   "attendanceRoomData?.data?.data",
            //   attendanceRoomData?.data?.data
            // );
            res?.data?.data.forEach((element) => {
              // console.log(element.students[0]);
              const check = attendanceRoomData?.data?.data.filter((e) => {
                return (
                  e.id === element.students[0]?.id ||
                  e.id === element.students[0]
                );
              });
              // console.log("check", check);
              if (check.length) {
                check[0]["boarding_attendance"] = element.boarding_attendance;
                check[0]["boarding_attendance_id"] = element.id;
              }
            });
            // console.log(roomId);

            setAttendanceRoom(attendanceRoomData?.data?.data);
            // setAttendanceClassDateData(res?.data?.data[0].boarding_attendance);
            setDaySchoolId(res?.data?.data[0].id);
          }
        }
        else {
          let params = {
            // ...original,
          };
          if (currentTab === TABS[1]) {
            const startOfWeek = moment(selectedDate)
              .startOf("week")
              .format("YYYY-MM-DD");
            const endOfWeek = moment(selectedDate)
              .endOf("week")
              .format("YYYY-MM-DD");
            params["filters[attendances.date][$gte]"] = startOfWeek;
            params["filters[attendances.date][$lte]"] = endOfWeek;
            params["sessionId"] = section;
            params["lessonId"] = currentLesson;
            params["populate[]"] = "attendances";
          } else if (currentTab === TABS[2]) {
            // Theo tháng
            const startOfMonth = moment(selectedDate)
              .startOf("month")
              .format("YYYY-MM-DD");
            const endOfMonth = moment(selectedDate)
              .endOf("month")
              .format("YYYY-MM-DD");
            params["filters[attendances.date][$gte]"] = startOfMonth;
            params["filters[attendances.date][$lte]"] = endOfMonth;
            params["sessionId"] = section;
            params["lessonId"] = currentLesson;
            params["populate[]"] = "attendances";
          } else if (currentTab === TABS[3]) {
            // Ăn trưa
            params["filters[ban_tru]"] = boarding;
            params["filters[attendances.date]"] =
              moment(selectedDate).format("YYYY-MM-DD");
          }
          if (classId) {
            const res = await getStudentListByClass(accountId, classId, params);
            if (res?.status === 200) {
              const { data, pagination } = res?.data;
              setAttendanceData(data);
              setTotalItems(pagination?.total);
              setCurrentPage(pagination?.page);
            }
          }
        }
        // const res = await filterStudentAttendance({
        //   sort: "created_at",
        //   page: 1,
        //   size: 9999,
        //   class_id: classId,
        //   month_year: selectedDate,
        //   school_year_id: schoolYear?.id,
        //   section,
        // });
        // if (res?.data?.code === "success") {
        //   setAttendanceData(res?.data?.data);
        //   setTotalItems(parseInt(res?.headers?.["x-total-items"]));
        //   setCurrentPage(parseInt(res?.headers?.["x-page"]));
        //   setTableLoading(false);
        // }
      } catch (error) {
      } finally {
        setTableLoading(false);
      }
    },
    [
      currentPage,
      pageSize.value,
      currentTab,
      selectedDate,
      section,
      tempSection,
      currentLesson,
      classId,
      accountId,
      boarding,
      roomId,
      setSection,
      settempSection,
    ]
  );

  // const getClassData = useCallback(async () => {
  //   if(currentTab === 'Theo ngày') {
  //     const params = {
  //       "filters[type][$eq]": "card",
  //       "filters[date][$eq]": moment(selectedDate).format("YYYY-MM-DD"),
  //       "filters[sessions.id][$eq]": section,
  //       "filters[workflow_state][$eq]": 'present',
  //     }
  //     if(classId) {
  //       const res = await getStudentListByClass(accountId, classId);
  //       if (res?.status === 200) {
  //         setTotalStudents(res?.data?.data?.length);
  //       }
  //       const res_attendances = await getAttendanceListByClass(accountId, classId, params);
  //       if(res_attendances?.status === 200) {
  //         setAttendancesStudents(res_attendances?.data?.data?.length);
  //       }
  //     }
  //   }
  // }, [accountId, classId, currentTab, section, selectedDate]);

  const getNumberOfStuData = useCallback(async () => {
    const params = {
      // "filters[type][$eq]": "class",
      "filters[sessions.id][$eq]": section,
      "filters[date][$eq]": moment(selectedDate).format("YYYY-MM-DD"),
      "filters[lessons.id][$in]": currentLesson,
    };
    if (classId) {
      const res = await getNumberOfAttendancesByCard(
        accountId,
        classId,
        params
      );
      if (res?.status === 200) {
        setTotalStudents(res.data.data.totalStudents);
        // setAttendancesStudents(res.data.data.totalAttendances);
        // setAbsentStudent(res.data.data?.absentStudents || 0);

      }
    }
  }, [section, selectedDate, currentLesson, classId, accountId, classList]);

  const getLessonList = useCallback(async () => {
    const params = {
      "filters[sessions.id][$eq]": section,
      orderBy: "name",
    };
    const res = await getLessonListBySession(accountId, params);
    if (res?.status === 200) {
      const data = res?.data.data;
      setLessonList(data);

      if (data[0]?.id) {
        setCurrentLesson(data[0].id);
      }
    }
  }, [accountId, section]);

  const handleSetSelectedDate = (value) => {
    const temp = moment(value).format();
    setSelectedDate(temp);
  };

  const handleSetSelectedDateExport = async (value) => {
    const temp = moment(value).format();
    const isBoarding = sectionListFull.filter(e => e.id === sectionExport)[0];
    if (isBoarding?.session_code === "boarding") {
      setSelectedDateExport(temp);
      return
    }
    const date = {
      "filters[sessionId]": sectionExport,
      "filters[date][$eq]": moment(temp).format("YYYY-MM-DD"),
    };
    const attendance_event = await getAttendanceEvent(accountId, date);
    if (attendance_event?.status === 200) {
      if (attendance_event.data?.data[0]?.attendance === true) {
        setSelectedDateExport(temp);
      }
      else {
        Notification.sendWarning(`Danh sách điểm danh ngày ${moment(temp).format("DD-MM-YYYY")} chưa được tổng hợp`);
      }
    }

  };

  const handleSelectDateFromExport = async (value) => {
    const temp = moment(value).format();
    const isBoarding = sectionListFull.filter(e => e.id === sectionExport)[0];
    if (isBoarding?.session_code === "boarding") {
      setSelectedDateFromExport(temp);
      return
    }
    const date = {
      "filters[sessionId]": sectionExport,
      "filters[date][$eq]": moment(temp).format("YYYY-MM-DD"),
    };
    const attendance_event = await getAttendanceEvent(accountId, date);
    if (attendance_event?.status === 200) {
      if (attendance_event.data?.data[0]?.attendance === true) {
        setSelectedDateFromExport(temp);
      }
      else {
        Notification.sendWarning(`Danh sách điểm danh ngày ${moment(temp).format("DD-MM-YYYY")} chưa được tổng hợp`);
      }
    }
  };
  const handleSelectDateToExport = async (value) => {
    const temp = moment(value).format();
    const isBoarding = sectionListFull.filter(e => e.id === sectionExport)[0];
    if (isBoarding?.session_code === "boarding") {
      setSelectedDateToExport(temp);
      return
    }
    const date = {
      "filters[sessionId]": sectionExport,
      "filters[date][$eq]": moment(temp).format("YYYY-MM-DD"),
    };
    const attendance_event = await getAttendanceEvent(accountId, date);
    if (attendance_event?.status === 200) {
      if (attendance_event.data?.data[0]?.attendance === true) {
        setSelectedDateToExport(temp);
      }
      else {
        Notification.sendWarning(`Danh sách điểm danh ngày ${moment(temp).format("DD-MM-YYYY")} chưa được tổng hợp`);
      }
    }
  };

  const handleSectionExport = (value) => {
    setSectionExport(value);
    setSelectedDateFromExport(null);
    setSelectedDateToExport(null);
    setSelectedDateExport(null);
  };

  const handleAttendanceClass = async () => {
    const event_data = {
      data: {
        attendance: true,
      },
    };
    const update_attendance_event = await updateAttendanceEvent(
      accountId,
      attendanceEventId,
      event_data
    );
    if (update_attendance_event?.status === 200) {
      const date = {
        "filters[sessionId]": section,
        "filters[date][$eq]": moment(selectedDate).format("YYYY-MM-DD"),
      };
      const fetchData = async () => {
        const attendance_event = await getAttendanceEvent(accountId, date);
        if (attendance_event?.status === 200) {
          setAttendanceEventState(attendance_event.data?.data[0]?.attendance);
        }
      };
      fetchData();
    }
    if (section !== 5) {
      const params = {
        "data": {
          "date": moment(selectedDate).format("YYYY-MM-DD"),
          "sessionId": section,
        }
      };
      setTableLoading(true)
      const res = await handleAttendanceRawData(accountId, params);
    }
    else {
      const params = {
        "data": {
          "date": moment(selectedDate).format("YYYY-MM-DD"),
          "classes": [roomId],
        }
      };
      setTableLoading(true)
      const res = await handleBoardingAttendance(accountId, params);
    }
    if (update_attendance_event?.status === 200) {
      getData();
      setTableLoading(false);
    }
  };

  //////////////////////////////////////////////////
  // #endregion FUNCTIONS //////////////////////////

  // #region    VIEWS //////////////////////////////
  //////////////////////////////////////////////////
  const renderTab = (tab, index) => {
    const isSelected = currentTab === tab;
    return (
      <RectangleTab
        isSelected={isSelected}
        onClick={() => setCurrentTab(tab)}
        key={`${tab}_${index}`}
        text={tab}
        index={index}
        totalCount={TABS.length}
      />
    );
  };

  useEffect(() => { }, []);
  useEffect(() => {
    if (schoolYear && schoolYear !== null) getGrade();
  }, [schoolYear, getGrade]);

  useEffect(() => {
    if (grade && grade !== null) {
      getClass(grade);
    }
  }, [grade, getClass]);

  useEffect(() => {
    if (classId && section && currentLesson) {
      getData();
    }
  }, [classId, currentLesson, getData, section, roomId]);

  useEffect(() => {
    if (isRefresh) {
      getData();
      setIsRefresh(false);
    }
  }, [getData, isRefresh, roomId]);

  useEffect(() => {
    getSectionList();
  }, [getSectionList]);

  useEffect(() => {
    if (section) {
      getLessonList();
    }
  }, [getLessonList, section]);

  useEffect(() => {
    if (classId && section && currentLesson) {
      getNumberOfStuData();
    }
  }, [classId, currentLesson, getNumberOfStuData, section]);

  useEffect(() => {
    getRoom();
  }, [getRoom]);

  useEffect(() => {
    setCurrentPage(1);
  }, [currentTab]);

  const showAddModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = async () => {
    if (dateType === "day") {
      const params = {
        "filters[grades.id]": gradeExport,
        "filters[sessions.id]": sectionExport,
        "filters[date][$eq]": moment(selectedDateExport).format("YYYY-MM-DD"),
      }
      const res = await exportAttendance(accountId, params)
      if (res.status === 200) {
        window.open(res.data.data, '_blank');
      }
    }
    else if (dateType === "period") {
      const params = {
        "filters[grades.id]": gradeExport,
        "filters[sessions.id]": sectionExport,
        "filters[date][$gt]": moment(selectedDateFromExport).format("YYYY-MM-DD"),
        "filters[date][$lt]": moment(selectedDateToExport).format("YYYY-MM-DD"),
      }
      const res = await exportAttendance(accountId, params);
      if (res.status === 200) {
        window.open(res.data.data);
      }
    }

    setIsModalOpen(false);
  }
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleDateTypeChange = (e) => {
    setDateType(e.target.value);
  };
  return (
    <div className={`flex flex-col h-full`}>
      {isUsingMobile ? (
        <MobileHeader
          type={HEADER_TYPE.middleTitle}
          title="Điểm danh"
          haveNotiButton={false}
        />
      ) : (
        <CustomBreadcrumb
          crumbs={[STUDENT_MANAGEMENT, routes.ATTENDANCE]}
          style={styles.breadcrumb}
        />
      )}
      {/* <CustomBreadcrumb crumbs={["/home", "/attendance"]} /> */}
      <div className={`py-4 section-shadow bg-white`}>
        <div className="px-8 mb-5 flex justify-between items-center">
          <span className={`${styles.titleDiemDanh}`}>Điểm danh</span>
          <div className="px-8 mb-5 flex items-center gap-2">
            <Button
              onClick={showAddModal}
              className={`flex justify-center items-center ${styles.searchBtn}`}
            >
              <span className={`${styles.searchBtnText}`}>Xuất báo cáo</span>
            </Button>
            <Modal style={{ marginTop: "-70px" }} width={1000} title="Xuất báo cáo" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
              <InfoFieldSelect
                label="Chọn khối"
                data={gradeList}
                value={gradeExport}
                setValue={setGradeExport}
                direction="vertical"
              />
              <br />
              <InfoFieldSelect
                label="Chọn buổi"
                data={sectionListFull}
                value={sectionExport}
                setValue={handleSectionExport}
                direction="vertical"
                // onChange={handleFilterChange}
                // valueFieldName="session_code"
                valueTextName="title"
                allowClear={false}
              // className={`flex-1 mr-4 max-[912px]:w-1/2`}
              />
              <br />
              <Radio.Group onChange={handleDateTypeChange} value={dateType} className="mb-5">
                <Radio value="day">Chọn ngày</Radio>
                <Radio value="period">Chọn khoảng thời gian</Radio>
              </Radio.Group>
              {dateType === 'day' && (
                <>
                  <InfoFieldDatePicker
                    label={'Chọn ngày'}
                    value={selectedDateExport}
                    setValue={handleSetSelectedDateExport}
                    placeholder="Chọn thời gian"
                    direction="vertical"
                    className="flex-1"
                    picker={'date'}
                  />
                </>
              )}

              {dateType === 'period' && (
                <div style={{ display: "flex" }}>
                  <InfoFieldDatePicker
                    label={'Từ Ngày'}
                    value={selectedDateFromExport}
                    setValue={handleSelectDateFromExport}
                    placeholder="Chọn thời gian"
                    direction="vertical"
                    className="flex-1"
                    picker={'date'}
                  />
                  <InfoFieldDatePicker
                    label={'Tới ngày'}
                    value={selectedDateToExport}
                    setValue={handleSelectDateToExport}
                    placeholder="Chọn thời gian"
                    direction="vertical"
                    className="flex-1"
                    picker={'date'}
                  />
                </div>
              )}

            </Modal>
            <Button
              className={`flex justify-center items-center ${styles.searchBtn}`}
              href="https://sharing.cohota.com/thuthiem/donxinphepnghihoc.pdf"
              target="blank"
            >
              <span className={`${styles.searchBtnText}`}>
                Biểu mẫu xin phép
              </span>
            </Button>
            <Button
              className={`flex justify-center ${styles.searchBtn} 
            ${attendanceEventState ? styles.disableBtn : ""}`}
              disabled={attendanceEventState ? true : false}
              onClick={handleAttendanceClass}
            >
              <span className={`${styles.searchBtnText}`}>Lưu tất cả</span>
            </Button>
          </div>
        </div>
        <div className={`flex px-8 overflow-x-scroll`}>
          {TABS.map(renderTab)}
        </div>
        <div className={`pt-5`}>
          <div
            className={`ml-1 font-semibold justify-between ${styles.wrapperHeader}`}
          >
            <div
              className={`${currentTab === "Theo ngày"
                ? styles.wrapperFilterDay
                : styles.wrapperFilter
                }  gap-2`}
            >
              {currentTab === TABS[4] && (
                <>
                  <InfoFieldSelect
                    label="Phòng"
                    data={room}
                    value={roomId}
                    setValue={setRoomId}
                    direction="vertical"
                  // className={`flex-1 mr-4 max-[912px]:w-1/2`}
                  />
                </>
              )}
              {currentTab !== TABS[4] && (
                <>
                  <InfoFieldSelect
                    label="Khối"
                    data={gradeList}
                    value={grade}
                    setValue={setGrade}
                    direction="vertical"
                  // className={`flex-1 mr-4 max-[912px]:w-1/2`}
                  />

                  <InfoFieldSelect
                    label="Lớp"
                    data={classList}
                    value={classId}
                    setValue={setClassId}
                    direction="vertical"
                  // className={`flex-1 mr-4 max-[912px]:w-1/2`}
                  />
                </>
              )}

              <InfoFieldDatePicker
                label={
                  currentTab === TABS[0] ||
                    currentTab === TABS[3] ||
                    currentTab === TABS[4]
                    ? "Ngày"
                    : currentTab === TABS[1]
                      ? "Tuần"
                      : "Tháng"
                }
                format={
                  currentTab === TABS[0] ||
                    currentTab === TABS[3] ||
                    currentTab === TABS[4]
                    ? "DD/MM/YYYY"
                    : currentTab === TABS[1]
                      ? "wo"
                      : "MM/YYYY"
                }
                value={selectedDate}
                setValue={handleSetSelectedDate}
                placeholder="Chọn thời gian"
                direction="vertical"
                // className={`flex-1 mr-4 max-[912px]:w-1/2`}
                picker={
                  currentTab === TABS[0] ||
                    currentTab === TABS[3] ||
                    currentTab === TABS[4]
                    ? "date"
                    : currentTab === TABS[1]
                      ? "week"
                      : "month"
                }
              />
              {currentTab !== TABS[3] && currentTab !== TABS[4] && (
                <>
                  <InfoFieldSelect
                    label="Buổi"
                    data={sectionList}
                    value={section}
                    setValue={setSection}
                    direction="vertical"
                    // onChange={handleFilterChange}
                    // valueFieldName="session_code"
                    valueTextName="title"
                    allowClear={false}
                  // className={`flex-1 mr-4 max-[912px]:w-1/2`}
                  />
                  <InfoFieldSelect
                    label="Tiết"
                    data={lessonList}
                    value={currentLesson}
                    setValue={setCurrentLesson}
                    direction="vertical"
                    allowClear={false}
                  />
                </>
              )}
              {currentTab === TABS[3] && (
                <InfoFieldSelect
                  label="Bán trú"
                  data={boardingOptions}
                  value={boarding}
                  setValue={setBoarding}
                  direction="vertical"
                  valueFieldName="value"
                  valueTextName="title"
                  allowClear={true}
                />
              )}
            </div>

            {currentTab !== TABS[3] && currentTab !== TABS[4] && (
              <>
                <div
                  className={`min-[1454px]:w-1/2 min-[1454px]:justify-end min-[1454px]:pr-6 flex items-end justify-evenly px-6 ${styles.wrapperStatus}`}
                >
                  <div className={`flex items-center justify-end mr-6`}>
                    <img
                      src={presentActive}
                      alt="Late"
                      className={`${styles.statusImage}`}
                    />
                    {/* <IconPresent /> */}
                    <span className={`ml-1 font-semibold ${styles.statusOff}`}>
                      Có mặt
                    </span>
                  </div>
                  <div className={`flex items-center justify-end mr-6`}>
                    <img
                      src={lateActive}
                      alt="Late"
                      className={`${styles.statusImage}`}
                    />
                    <span className={`ml-1 font-semibold ${styles.statusOff}`}>
                      Trễ
                    </span>
                  </div>
                  <div className="flex items-center justify-end mr-6">
                    <img
                      src={anualLeaveActive}
                      alt="Anual Leave"
                      className={`${styles.statusImage}`}
                    />
                    <span className={`ml-1 font-semibold ${styles.statusOff}`}>
                      Nghỉ có phép
                    </span>
                  </div>
                  <div className="flex items-center justify-end mr-6">
                    <img
                      src={noAuthLeaveActive}
                      alt="No Auth Leave"
                      className={`${styles.statusImage}`}
                    />
                    <span className={`ml-1 font-semibold ${styles.statusOff}`}>
                      Nghỉ không phép
                    </span>
                  </div>
                </div>
              </>
            )}
          </div>
          {currentTab === TABS[4] && (
            <>
              <div
                className={`min-[1454px]:w-1/2 min-[1454px]:justify-end min-[1454px]:pr-6 flex items-end justify-evenly px-6 ${styles.wrapperStatus}`}
              >
                <div className={`flex items-center justify-end mr-6`}>
                  <img
                    src={daySchoolActive}
                    alt="Late"
                    className={`${styles.statusImage}`}
                  />
                  {/* <IconPresent /> */}
                  <span className={`ml-1 font-semibold ${styles.statusOff}`}>
                    Ngủ
                  </span>
                </div>
                <div className="flex items-center justify-end mr-6">
                  <img
                    src={noAuthLeaveActive}
                    alt="No Auth Leave"
                    className={`${styles.statusImage}`}
                  />
                  <span className={`ml-1 font-semibold ${styles.statusOff}`}>
                    Không ngủ
                  </span>
                </div>
              </div>
            </>
          )}
          {currentTab !== TABS[3] && (
            <>
              <div className="mt-5 px-6">
                <span className="text-[16px] font-medium">
                  Sỉ số:{" "}
                  {`${attendanceStudents} / ${totalStudents}`}
                </span>
              </div>

              {/* <div className="mt-2 px-6">
                <span className="text-[16px] font-medium">
                  Thực tế:{" "}
                  {`${totalStudents - absentStudent}  / ${totalStudents}`}
                </span>
              </div> */}
            </>
          )}

          <div className={`mx-6 mt-5`}>
            {/* {currentTab === TABS[0] ? (
              <Form form={form} component={false}>
                <CusTable
                  rowKey="id"
                  data={attendanceData}
                  columns={mergedColumns}
                  loading={tableLoading}
                  havePagination={false}
                  rowClassName="editable-row"
                  scroll={responsive.tableScroll()}
                />
              </Form>
            ) : undefined} */}
            {currentTab === TABS[0] && (
              <DateAttendance
                classId={classId}
                selectedDate={selectedDate}
                section={section}
                currentLesson={currentLesson}
                data={attendanceData}
                tableLoading={tableLoading}
                currentPage={currentPage}
                pageSize={pageSize}
                totalItems={totalItems}
                setCurrentPage={setCurrentPage}
                setPageSize={setPageSize}
                setIsRefresh={setIsRefresh}
                scroll={responsive.tableScroll()}
              />
            )}
            {currentTab === TABS[1] && (
              <WeekAttendance
                classId={classId}
                selectedDate={selectedDate}
                section={section}
                currentLesson={currentLesson}
                data={attendanceData}
                tableLoading={tableLoading}
                currentPage={currentPage}
                pageSize={pageSize}
                totalItems={totalItems}
                setCurrentPage={setCurrentPage}
                setPageSize={setPageSize}
                setIsRefresh={setIsRefresh}
              />
            )}
            {currentTab === TABS[2] && (
              <MonthAttendance
                classId={classId}
                selectedDate={selectedDate}
                section={section}
                currentLesson={currentLesson}
                data={attendanceData}
                tableLoading={tableLoading}
                currentPage={currentPage}
                pageSize={pageSize}
                totalItems={totalItems}
                setCurrentPage={setCurrentPage}
                setPageSize={setPageSize}
                setIsRefresh={setIsRefresh}
              />
            )}
            {currentTab === TABS[3] && (
              <LunchAttendance
                boarding={boarding}
                classId={classId}
                selectedDate={selectedDate}
                data={attendanceData}
                tableLoading={tableLoading}
                currentPage={currentPage}
                pageSize={pageSize}
                totalItems={totalItems}
                setCurrentPage={setCurrentPage}
                setPageSize={setPageSize}
                setIsRefresh={setIsRefresh}
              />
            )}
            {currentTab === TABS[4] && (
              <DaySchoolAttendence
                boarding={boarding}
                roomId={roomId}
                selectedDate={selectedDate}
                data={attendanceRoom}
                tableLoading={tableLoading}
                currentPage={currentPage}
                pageSize={pageSize}
                totalItems={totalItems}
                setCurrentPage={setCurrentPage}
                setPageSize={setPageSize}
                setIsRefresh={setIsRefresh}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
