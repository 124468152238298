export const SET_MARK_PAGE_FILTER_GRADE = "SET_MARK_PAGE_FILTER_GRADE";
export const SET_MARK_PAGE_FILTER_SEMESTER = "SET_MARK_PAGE_FILTER_SEMESTER";
export const SET_MARK_PAGE_FILTER_SUBJECT = "SET_MARK_PAGE_FILTER_SUBJECT";

export function setMarkPageFilterGradeAction(data) {
  return {
    type: SET_MARK_PAGE_FILTER_GRADE,
    payload: data,
  };
}

export function setMarkPageFilterSemesterAction(data) {
  return {
    type: SET_MARK_PAGE_FILTER_SEMESTER,
    payload: data,
  };
}

export function setMarkPageFilterSubjectAction(data) {
  return {
    type: SET_MARK_PAGE_FILTER_SUBJECT,
    payload: data,
  };
}
