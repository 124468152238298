import { Modal } from "antd";
import {
  closeIconThin,
  syncYellow,
  tickCircleGreenIcon,
  xCircleRed,
} from "asset/img/Images";
import React from "react";
import { PROGRESS_STATUS } from "util/constant";
import styles from "./styles.module.css";

export default function ResultModal({
  visible,
  onClose,
  result,
  successMsg = "Nhập dữ liệu thành công",
  inProgressMsg = "Quá trình nhập dữ liệu đang diễn ra",
  failMsg = "Lỗi! Nhập dữ liệu thất bại",
  log = null,
}) {
  // #region    VARIABLES //////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion VARIABLES //////////////////////////

  // #region    useEffect //////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion useEffect //////////////////////////

  // #region    FUNCTIONS //////////////////////////
  //////////////////////////////////////////////////
  const getTitle = () => {
    switch (result) {
      case PROGRESS_STATUS.success:
        return successMsg;
      case PROGRESS_STATUS.in_progress:
        return inProgressMsg;
      case PROGRESS_STATUS.failed:
        return failMsg;
      default:
        return inProgressMsg;
    }
  };

  const getTitleStyle = () => {
    switch (result) {
      case PROGRESS_STATUS.success:
        return styles.successTitle;
      case PROGRESS_STATUS.in_progress:
        return styles.inProgressTitle;
      case PROGRESS_STATUS.failed:
        return styles.failedTitle;
      default:
        return inProgressMsg;
    }
  };

  const getStatusIcon = () => {
    switch (result) {
      case PROGRESS_STATUS.success:
        return tickCircleGreenIcon;
      case PROGRESS_STATUS.in_progress:
        return syncYellow;
      case PROGRESS_STATUS.failed:
        return xCircleRed;
      default:
        return inProgressMsg;
    }
  };

  //////////////////////////////////////////////////
  // #endregion FUNCTIONS //////////////////////////

  // #region    VIEWS //////////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion VIEWS //////////////////////////////
  return (
    <Modal
      footer={null}
      open={visible}
      onCancel={onClose}
      closable={false}
      zIndex={101}
    >
      <div className="flex">
        <div className="flex-1 flex items-center">
          <img src={getStatusIcon()} className={`mRight16 ${styles.icon}`} />
          <p className={`flex-1 ${styles.title} ${getTitleStyle()}`}>
            {getTitle()}
          </p>
        </div>
        <button onClick={onClose} className="mLeft8">
          <img src={closeIconThin} className={styles.icon} />
        </button>
      </div>
      {result === PROGRESS_STATUS.in_progress && (
        <span className={`mTop8 ${styles.inProgressText}`}>
          Có thể kiểm tra kết quả bằng cách nhấn vào nút{" "}
          <span className={styles.inProgressTextBold}>Tiến trình đồng bộ</span>
        </span>
      )}
      {log && (
        <div
          className={`w-full overflow-y-auto flex flex-col items-center mTop8 ${styles.logWrapper}`}
        >
          {log}
        </div>
      )}
    </Modal>
  );
}
