import { message } from "antd";
import get from "lodash/get";
import isObject from "lodash/isObject";
import moment from "moment";
import React from "react";

const INNER_WIDTH = window.innerWidth;
const INNER_HEIGHT = window.innerHeight;
const DESIGN_WIDTH = 1440;
const DESIGN_HEIGHT = 900;
const MOBILE_DESIGN_WIDTH = 375;
const MOBILE_DESIGN_HEIGHT = 812;
const ZERO_ID = "00000000-0000-0000-0000-000000000000";

export const currencyFormat = (value) => {
  const temp = value || 0;
  let result = temp.toLocaleString("vi-VN", {
    style: "currency",
    currency: "VND",
  });

  return result;
};

export const deepClone = (item) => JSON.parse(JSON.stringify(item ?? ""));

export const compareFunc = ({
  itemA,
  itemB,
  fieldName = null,
  direction = "desc",
}) => {
  if (fieldName) {
    if (itemA[fieldName] > itemB[fieldName])
      return direction === "asc" ? 1 : -1;
    if (itemA[fieldName] < itemB[fieldName])
      return direction === "asc" ? -1 : 1;
    return 0;
  } else {
    if (itemA > itemB) return direction === "asc" ? 1 : -1;
    if (itemA < itemB) return direction === "asc" ? -1 : 1;
    return 0;
  }
};

export const downloadFileFromUrl = (url) => {
  if (typeof url === "string" && url?.includes("http")) {
    window.open(url, "_blank");
  }
};

export const rWidth = (value = 0, withpx = true) => {
  const result = (INNER_WIDTH * value) / DESIGN_WIDTH;
  return withpx ? `${result}px` : result;
};

export const rHeight = (value = 0, withpx = true) => {
  const result = (INNER_HEIGHT * value) / DESIGN_HEIGHT;
  return withpx ? `${result}px` : result;
};

export const rFont = (value = 0, withpx = true) => {
  const result = (INNER_WIDTH * value) / DESIGN_WIDTH;
  return withpx ? `${result}px` : result;
};

export const mrWidth = (value = 0, withpx = true) => {
  const result = (INNER_WIDTH * value) / MOBILE_DESIGN_WIDTH;
  return withpx ? `${result}px` : result;
};

export const mrHeight = (value = 0, withpx = true) => {
  const result = (INNER_HEIGHT * value) / MOBILE_DESIGN_HEIGHT;
  return withpx ? `${result}px` : result;
};

export const mrFont = (value = 0, withpx = true) => {
  const result = (INNER_WIDTH * value) / MOBILE_DESIGN_WIDTH;
  return withpx ? `${result}px` : result;
};

export const textStyle = (size = 14, weight = 400, color = "#000000") => {
  return {
    fontSize: size,
    fontWeight: weight.toString(),
    color: color,
  };
};
export const dynamicSort = (property) => {
  var sortOrder = 1;
  if (property[0] === "-") {
    sortOrder = -1;
    property = property.substr(1);
  }
  return function (a, b) {
    /* next line works with strings and numbers,
     * and you may want to customize it to your needs
     */
    var result =
      a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
    return result * sortOrder;
  };
};

export const generateEmptyDataForTimetable = (
  cellFormat,
  isUniversity = false
) => {
  const numberOfLessons = isUniversity ? 6 : 5;
  return [
    Array(numberOfLessons)
      .fill()
      .map(() => cellFormat),
    Array(numberOfLessons)
      .fill()
      .map(() => cellFormat),
    Array(numberOfLessons)
      .fill()
      .map(() => cellFormat),
    Array(numberOfLessons)
      .fill()
      .map(() => cellFormat),
    Array(numberOfLessons)
      .fill()
      .map(() => cellFormat),
    Array(numberOfLessons)
      .fill()
      .map(() => cellFormat),
    Array(numberOfLessons)
      .fill()
      .map(() => cellFormat),
  ];
};

export const findEthnicAndReligion= (arr, id) => arr.find(i => i?.id === id);

export const findKeyInObjectR = (obj, findingKey) => {
  // Nếu obj là string, trả về obj
  if (typeof obj === "string") return obj;
  // Duyệt tất cả field của obj, nếu tìm thấy findingKey, trả về value tương ứng
  const message = get(obj, findingKey);
  if (message !== undefined) {
    return message;
  }
  // Nếu obj không có key nào là findingKey, duyệt từng key
  for (let key in obj) {
    // nếu obj[key] là object, đệ quy hàm này để tìm value của findingKey
    if (isObject(obj[key])) {
      const message = findKeyInObjectR(obj[key], findingKey);
      if (message) {
        return message;
      }
    }
  }
  return "";
};

export const logMessageDirect = (msg, useAntMessage = true) => {
  return handleLogMessage(msg, useAntMessage);
};

export const logMessage = (error, useAntMessage = true) => {
  let msg = "";
  if (typeof error?.response?.data?.message === "string") {
    msg = error.response.data.message;
  } else if (typeof error?.data?.message === "string") {
    msg = error.data.message;
  } else if (typeof error?.message === "string") {
    msg = error.message;
  } else {
    msg = findKeyInObjectR(error, "message");
  }
  return handleLogMessage(msg, useAntMessage);
};

const handleLogMessage = (msg, useAntMessage) => {
  if (!msg?.includes?.(`\n`)) {
    if (useAntMessage) message.error(msg);
    else return msg;
  } else {
    const newMsg = msg?.split(`\n`);
    const msgElement = (
      <div className="w-full flex flex-col items-center">
        {newMsg?.map((i) => (
          <span key={i}>{i}</span>
        ))}
      </div>
    );
    if (useAntMessage) {
      message.error(msgElement);
    } else {
      return msgElement;
    }
  }
};

export const rgbToHex = (rgb) => {
  if (!rgb) return "#ffffff";
  return (
    "#" +
    [rgb.r, rgb.g, rgb.b]
      .map((x) => {
        const hex = x.toString(16);
        return hex.length === 1 ? "0" + hex : hex;
      })
      .join("")
  );
};

export const hexToRgb = (hex) => {
  if (!hex) {
    return {
      r: 255,
      g: 255,
      b: 255,
    };
  }
  const temp = hex
    .replace(
      /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
      (m, r, g, b) => "#" + r + r + g + g + b + b
    )
    .substring(1)
    .match(/.{2}/g)
    .map((x) => parseInt(x, 16));
  return {
    r: temp[0],
    g: temp[1],
    b: temp[2],
  };
};

export const disableDateRange = (value, disableRange = []) => {
  if (disableRange?.includes("past")) {
    if (moment(value).isBefore(moment())) {
      return true;
    }
  }
  if (disableRange?.includes("future")) {
    if (moment(value).isAfter(moment())) {
      return true;
    }
  }
  return false;
};

export const disableFutureDate = (value) => disableDateRange(value, ["future"]);

export const disablePastDate = (value) => disableDateRange(value, ["past"]);

export const scrollHorizontallyOnScroll = (event, refToScroll) => {
  event.preventDefault();
  refToScroll.scrollLeft += event.deltaY;
};

export const sanitize = ({
  obj,
  fieldMustBeArray = [],
  fieldMustBeObject = [],
  fieldMustBeZero = [],
  fieldMustBeNull = [],
  fieldMustBeString = [],
  fieldMustBeID = [],
}) => {
  const result = JSON.parse(
    JSON.stringify(obj, (key, value) => {
      if (value === null || value === undefined || value === "") {
        if (fieldMustBeArray?.includes(key)) {
          return [];
        }
        if (fieldMustBeObject?.includes(key)) {
          return {};
        }
        if (fieldMustBeZero?.includes(key)) {
          return 0;
        }
        if (fieldMustBeNull.includes(key)) {
          return null;
        }
        if (fieldMustBeString.includes(key)) {
          return "";
        }
        if (fieldMustBeID.includes(key)) {
          return ZERO_ID;
        }
      }
      return value;
    })
  );
  return result;
};
// Không được import biến từ constant vì file constant đang import từ file này
// Import vòng tròn gây lỗi

export const containsUppercase = (str) => /[A-Z]/.test(str);

export const containsSpecialCharacter = (str) =>
  /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(str);

export const getGenderText = (key) => {
  if (key === "male") return "Nam";
  if (key === "female") return "Nữ";
  return "-";
};

export const hasUpperCaseChar = (str) => {
  return str !== str.toLowerCase();
};
