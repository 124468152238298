import React from "react";

export default function Avatar({
  src = "",
  alt = "Avatar",
  className = "",
  imgClassName = "",
  size,
  onClick = () => {},
  imgIsRound = true,
}) {
  // #region    VARIABLES //////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion VARIABLES //////////////////////////

  // #region    useEffect //////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion useEffect //////////////////////////

  // #region    FUNCTIONS //////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion FUNCTIONS //////////////////////////

  // #region    VIEWS //////////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion VIEWS //////////////////////////////
  return (
    <div
      style={{ width: size, height: size, borderRadius: "50%" }}
      className={className}
      onClick={onClick}
    >
      <img
        src={src}
        style={{ borderRadius: imgIsRound ? "50%" : 0 }}
        className={imgClassName}
        alt={alt}
      />
    </div>
  );
}
