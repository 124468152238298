import { SET_SETTING_PAGE_EVALUATE_STRUCTURE_LIST_CURRENT_PAGE } from "redux/action/settingPageEvaluateStructureListAction";
import { deepClone } from "util/helper";

export default function settingPageEvaluateStructureListReducer(
  state = {
    currentPage: 1,
  },
  action
) {
  switch (action.type) {
    case SET_SETTING_PAGE_EVALUATE_STRUCTURE_LIST_CURRENT_PAGE:
      return {
        ...state,
        currentPage: deepClone(action.payload),
      };
    default:
      return state;
  }
}
