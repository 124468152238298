import { bellIcon } from "asset/img/Images";
import Img from "common/image/Img";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { filterNoti, getNotiCount, readAllNoti } from "util/APIs";
import { DATE_FORMAT } from "util/constant";
import NotiItem from "../NotiItem/NotiItem";
import styles from "./styles.module.css";

export default function NotiButton() {
  // #region    VARIABLES //////////////////////////
  //////////////////////////////////////////////////
  const [notiList, setNotiList] = useState([]);
  const [renderDateIndexes, setRenderDateIndexes] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 20;
  const [showNoti, setShowNoti] = useState(false);
  const bellWrapperRef = useRef(null);
  const userInfo = useSelector((state) => state?.app?.userInfo);
  //////////////////////////////////////////////////
  // #endregion VARIABLES //////////////////////////

  // #region    useEffect //////////////////////////
  //////////////////////////////////////////////////
  useEffect(() => {
    window.addEventListener("click", clickOutsideBoard);
    return () => {
      window.removeEventListener("click", clickOutsideBoard);
    };
  }, []);

  useEffect(() => {
    if (userInfo?.id) {
      getData();
    }
  }, [userInfo?.id]);
  //////////////////////////////////////////////////
  // #endregion useEffect //////////////////////////

  // #region    FUNCTIONS //////////////////////////
  //////////////////////////////////////////////////
  const clickOutsideBoard = (e) => {
    if (!bellWrapperRef?.current?.contains(e.target)) {
      setShowNoti(false);
    }
  };

  const toggleShowNoti = () => setShowNoti(!showNoti);

  const onReadAll = () => {
    readAllNoti().then(() => {
      getData();
    });
  };

  const getData = () => {
    const params = {
      user_id: userInfo?.id,
      page: currentPage,
      size: pageSize,
    };
    // filterNoti(params).then((res) => {
    //   if (res?.data?.code === "success") {
    //     const tempData = res?.data?.data || [];
    //     const newDataList = [...notiList, ...tempData];
    //     setNotiList(newDataList);
    //     handleSetRenderDateIndexes(newDataList);
    //     setCurrentPage(parseInt(res?.headers?.["x-page"]));
    //   }
    // });
    // getNotiCount().then((res) => {
    //   if (res?.data?.code === "success") {
    //     setUnreadCount(res?.data?.data?.count_unread);
    //   }
    // });
  };

  const handleSetRenderDateIndexes = (data) => {
    // Tác dụng của hàm này:
    // Những noti được đánh dấu sẽ có thêm phần ngày tháng ở bên trên
    if (data?.length > 0) {
      // Luôn đánh dấu noti đầu tiên
      let indexListTemp = [0];
      if (data?.length > 1) {
        // Duyệt từ noti thứ 2 trở đi
        for (let i = 1; i < data?.length; i++) {
          // Lấy noti được đánh dấu mới nhất
          const latestIndex = indexListTemp[indexListTemp.length - 1];
          const lastStoredDate = moment(data[latestIndex]?.created_at);
          // Lấy noti hiện tại
          const currentNotiDate = moment(data[i]?.created_at);
          // Nếu noti hiện tại có ngày khác với noti được đánh dấu mới nhất
          // Đánh dấu noti hiện tại
          if (!currentNotiDate.isSame(lastStoredDate, "day")) {
            indexListTemp.push(i);
          }
        }
        setRenderDateIndexes(indexListTemp);
      }
    }
  };

  const getDateText = (date) => {
    if (date) {
      let dateM = moment(date);
      let dateString = dateM.format(DATE_FORMAT);
      if (moment().startOf("day").isSame(dateM, "day")) {
        return `Hôm nay, ${dateString}`;
      }
      if (moment().subtract(1, "days").startOf("day").isSame(dateM, "day")) {
        return `Hôm qua, ${dateString}`;
      }
      return dateString;
    } else return "";
  };

  const handleScrollList = (e) => {
    if (e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight) {
      getData();
    }
  };
  //////////////////////////////////////////////////
  // #endregion FUNCTIONS //////////////////////////

  // #region    VIEWS //////////////////////////////
  //////////////////////////////////////////////////
  const renderNotiItem = (item, index) => {
    const isMarked = renderDateIndexes?.includes(index);
    return (
      <div key={`${item?.id}_${index}`}>
        {isMarked && (
          <div className="pTop14 pBottom10 pLeft20">
            <span className={styles.createdAt}>
              {getDateText(item?.created_at)}
            </span>
          </div>
        )}
        <div className={isMarked ? "" : "mTop10"}>
          <NotiItem data={item} />
        </div>
      </div>
    );
  };
  //////////////////////////////////////////////////
  // #endregion VIEWS //////////////////////////////
  return (
    <div className={`relative mRight18`} ref={bellWrapperRef}>
      <div className={`${styles.notiBtn}`} onClick={toggleShowNoti}>
        <Img src={bellIcon} alt="Notification" />
      </div>

      <div
        style={{ display: unreadCount > 0 ? "block" : "none" }}
        className={styles.redDot}
        onClick={toggleShowNoti}
      >
        <span className={styles.unreadCount}>{unreadCount}</span>
      </div>

      <div
        style={{ display: showNoti ? "block" : "none" }}
        className={styles.notiBoard}
        onScroll={handleScrollList}
      >
        <div className="flex justify-between mBottom24">
          <div className="pTop16 pLeft20">
            <span className={styles.noti}>
              {`Thông báo (${notiList?.length || 0})`}
            </span>
          </div>

          <div className="pTop18 pRight24 cursor-pointer" onClick={onReadAll}>
            <span className={styles.readAll}>Đánh dấu đã đọc</span>
          </div>
        </div>

        <div>{notiList?.map(renderNotiItem)}</div>
      </div>
    </div>
  );
}
