import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { setUserInfo } from "redux/action/app";
import { getTeacherData } from "util/APIs";

export default function useHandleLogin() {
  // #region    VARIABLES //////////////////////////
  //////////////////////////////////////////////////
  const dispatch = useDispatch();
  const history = useHistory();
  //////////////////////////////////////////////////
  // #endregion VARIABLES //////////////////////////

  // #region    useEffect //////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion useEffect //////////////////////////

  // #region    FUNCTIONS //////////////////////////
  //////////////////////////////////////////////////
  const handleLoginSuccess = async (data) => {
    try {
      localStorage.setItem("token", data?.jwt);
      var user_info = await getTeacherData();
      localStorage.setItem("user_info", JSON.stringify(user_info?.data));
      dispatch(setUserInfo({ ...(user_info?.data || {}) }));
    } catch (error) {}
  };
  //////////////////////////////////////////////////
  // #endregion FUNCTIONS //////////////////////////

  // #region    VIEWS //////////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion VIEWS //////////////////////////////
  return [handleLoginSuccess];
}
