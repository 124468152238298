import React from "react";
import styles from "./styles.module.css";

export default function InfoField({
  label,
  labelStyle = {},
  isRequired = false,
  input,
  className = "",
  labelClassName = "",
  labelWrapperClassName = "",
  inputWrapperClassName = "",
  direction = "horizontal",
  noLabel = false,
  isTextArea = false,
}) {
  // #region    VARIABLES //////////////////////////
  //////////////////////////////////////////////////
  //////////////////////////////////////////////////
  // #endregion VARIABLES //////////////////////////

  // #region    useEffect //////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion useEffect //////////////////////////

  // #region    FUNCTIONS //////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion FUNCTIONS //////////////////////////

  // #region    VIEWS //////////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion VIEWS //////////////////////////////
  return (
    <div
      className={`flex ${
        direction === "horizontal"
          ? isTextArea
            ? "items-start"
            : "items-center"
          : "flex-col"
      } ${styles.root} ${className}`}
    >
      {noLabel ? null : (
        <div
          className={`flex items-center ${
            direction === "horizontal" ? styles.labelWrapper : "mBottom6"
          } ${labelWrapperClassName}`}
        >
          <span
            style={labelStyle}
            className={`${styles.label} ${labelClassName}`}
          >
            {label}
          </span>
          {isRequired && (
            <span className={`mLeft4 ${styles.requireMark}`}>{` *`}</span>
          )}
        </div>
      )}
      <div className={`${styles.flex1} ${inputWrapperClassName}`}>{input}</div>
    </div>
  );
}
