import { fileWhiteIcon } from "asset/img/Images";
import StyledButton from "common/button/StyledButton";
import Img from "common/image/Img";
import React, { useRef } from "react";
import styles from "./styles.module.css";

export default function FilePicker({
  setFiles = (data) => {},
  disabled = false,
}) {
  // #region    VARIABLES //////////////////////////
  //////////////////////////////////////////////////
  const inputRef = useRef(null);
  //////////////////////////////////////////////////
  // #endregion VARIABLES //////////////////////////

  // #region    useEffect //////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion useEffect //////////////////////////

  // #region    FUNCTIONS //////////////////////////
  //////////////////////////////////////////////////
  const openFileInput = () => inputRef.current?.click();

  const handleSetFiles = (e) => {
    if (e?.target?.files?.length > 0) setFiles(e.target.files);
  };

  // Để có thể chọn lại file đã chọn
  const resetValue = (e) => (e.target.value = null);
  //////////////////////////////////////////////////
  // #endregion FUNCTIONS //////////////////////////

  // #region    VIEWS //////////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion VIEWS //////////////////////////////
  return (
    <>
      <input
        type="file"
        className="hidden"
        ref={inputRef}
        multiple={true}
        onChange={handleSetFiles}
        onClick={resetValue}
      />
      <div className="flex ">
        <StyledButton
          className={`flex items-center bg-blueDefault`}
          onClick={openFileInput}
          disabled={disabled}
        >
          <div className={styles.icon}>
            <Img src={fileWhiteIcon} alt="Upload" />
          </div>
          <div>
            <span className={styles.placeholderText}>Chọn file</span>
          </div>
        </StyledButton>
        {/* <StyledButton
          className={`flex mLeft40 items-center bg-blueDefault`}
          disabled={disabled}
        >
          <div className={styles.icon}>
            <Img src={fileWhiteIcon} alt="Upload" />
          </div>
          <div>
            <span className={styles.placeholderText}>Tải file mẫu</span>
          </div>
        </StyledButton> */}
      </div>
    </>
  );
}
