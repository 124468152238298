import { notification } from "antd";

const defaultInfoMessageDuration = 7;
const defaultSuccessMessageDuration = 3;
const defaultWarningMessageDuration = 10;
const defaultErrorMessageDuration = 12;

const sendSuccess = (
  message,
  duration = defaultSuccessMessageDuration,
  className
) => {
  notification["success"]({
    message,
    duration,
    className,
  });
};

const sendError = (
  message,
  duration = defaultErrorMessageDuration,
  className
) => {
  notification["error"]({
    message,
    duration,
    className,
  });
};

const sendInfo = (
  message,
  duration = defaultInfoMessageDuration,
  className
) => {
  notification["info"]({
    message,
    duration,
    className,
  });
};

const sendWarning = (
  message,
  duration = defaultWarningMessageDuration,
  className
) => {
  notification["warning"]({
    message,
    duration,
    className,
  });
};

export const Notification = {
  sendSuccess,
  sendError,
  sendInfo,
  sendWarning,
};
