import StyledButton from "common/button/StyledButton";
import React from "react";
import styles from "./styles.module.css";

export default function InfoSection({ title = "", data = [], style = "" }) {
  // #region    VARIABLES //////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion VARIABLES //////////////////////////

  // #region    useEffect //////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion useEffect //////////////////////////

  // #region    FUNCTIONS //////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion FUNCTIONS //////////////////////////

  // #region    VIEWS //////////////////////////////
  //////////////////////////////////////////////////
  const renderSmallSection = (section, sectionIndex) => {
    return (
      <div
        className={
          section?.wrapperClassName ? section.wrapperClassName : "mb-6"
        }
        key={`section_${sectionIndex}`}
      >
        {section?.title && (
          <div className="mBottom16">
            <span className={`font-bold`}>{section.title}</span>
            {section?.copyButton?.text ? (
              <StyledButton
                onClick={section?.copyButton?.onClick}
                disabled={section?.copyButton?.disabled}
                className={`mLeft8 bg-blueDefault ${styles.copyBtn}`}
              >
                <span className={styles.copyBtnText}>
                  {section?.copyButton?.text}
                </span>
              </StyledButton>
            ) : null}
          </div>
        )}
        <div className={`${styles.wrapperItems}`}>
          {section?.fields?.map((field, index) =>
            renderFieldItem(field, index, section, sectionIndex)
          )}
        </div>
      </div>
    );
  };

  const renderFieldItem = (field, index, section, sectionIndex) => {
    const isLastItem = index === section.fields.length - 1;
    return (
      <div
        className={`flex items-start ${styles.itemWrapper} ${
          isLastItem && section.fields.length % 3 === 1 && "sm:col-span-3"
        } ${isLastItem && section.fields.length % 3 === 2 && "sm:col-span-2"}`}
        key={`section_${sectionIndex}_field_${index}`}
      >
        {field}
      </div>
    );
  };
  //////////////////////////////////////////////////
  // #endregion VIEWS //////////////////////////////
  return (
    <div className={`pHorizontal32 ${styles.root} ${style}`}>
      {title && (
        <div className={`flex items-center mBottom16`}>
          <span className={`font-bold text-blueDefault`}>{title}</span>
        </div>
      )}
      <div className={``}>{data?.map(renderSmallSection)}</div>
    </div>
  );
}
