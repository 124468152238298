import { Tag } from "antd";
import { binIcon } from "asset/img/Images";
import { Notification } from "common/Notification";
import Img from "common/image/Img";
import SelectOption from "common/select/selectOption/SelectOption";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  // updateHomeroomeTeacher,
  // updateStaff,
  updateUser,
} from "util/APIs";
import styles from "./styles.module.css";

export default function TeacherItem({
  teacher,
  distributionList,
  index,
  list,
  currentTeacherId,
  setCurrentTeacherId,
  gradeId,
  classList,
  subjectList,
  reloadData,
  onDeleteAll,
}) {
  // #region    VARIABLES //////////////////////////
  //////////////////////////////////////////////////
  const userInfo = useSelector((state) => state?.app?.userInfo);
  const accountId = userInfo?.account?.id;

  const isLast = index === list?.length - 1;
  const isSelected = currentTeacherId === teacher?.id;
  const [homeroomClass, setHomeroomClass] = useState(null);
  const filterdDistributions = distributionList?.filter(
    (item) => item?.grades?.[0]?.id === gradeId
  );
  // const currentHomeroomClass = {
  //   ...(teacher?.teacher_info?.homeroom_class_info || {}),
  // };
  // const addedClassList =
  //   currentHomeroomClass?.id &&
  //   classList?.findIndex((i) => currentHomeroomClass?.id === i?.id) === -1
  //     ? [{ ...currentHomeroomClass }, ...classList]
  //     : [...classList];
  // const addedClassList =
  //   classList && classList.filter((item) => item?.staff?.length === 0);

  useEffect(() => {
    if (teacher && teacher?.id) {
      if (teacher?.classes?.length) {
        if (classList.find((c) => c.id === teacher?.classes?.[0]?.id)) {
          setHomeroomClass(teacher?.classes?.[0]?.id);
        } else {
          setHomeroomClass(null);
        }
      }
    }
    // setHomeroomClass(teacher?.teacher_info?.homeroom_class_info?.id || null);
  }, [classList, teacher, gradeId]);
  //////////////////////////////////////////////////
  // #endregion useEffect //////////////////////////

  // #region    FUNCTIONS //////////////////////////
  //////////////////////////////////////////////////
  const select = () => {
    setCurrentTeacherId(teacher?.id);
  };

  const handleChangeHomeroomClass = (value) => {
    try {
      const payload = {
        classes: [value],
      };
      const body = {
        data: payload,
      };
      updateUser(accountId, teacher?.id, body).then((res) => {
        if (res?.statusText === "OK") {
          Notification.sendSuccess("Cập nhật thành công!");
          reloadData?.();
        }
      });

      // const res = await updateHomeroomeTeacher({
      //   id: value,
      //   homeroom_teacher_id: teacher?.id || "",
      // });

      // if (res?.data?.code === "success") {
      //   message.success("Cập nhật thành công");
      //   reloadData && reloadData();
      // }
    } catch (err) {}
  };

  const handleDeleteAll = () => {
    onDeleteAll(filterdDistributions);
    // onDeleteAll(teacher);
  };
  //////////////////////////////////////////////////
  // #endregion FUNCTIONS //////////////////////////

  // #region    VIEWS //////////////////////////////
  //////////////////////////////////////////////////
  const renderTag = useMemo(() => {
    return filterdDistributions.map((value) => {
      if (value?.subjects?.length === 1) {
        const subjectName = value?.subjects?.[0]?.name;
        return value?.classes?.map?.((item) => (
          <Tag
            className={`pVertical4 ${styles.tag}`}
            color="#e7f0fe"
            key={`${item.id}`}
          >
            {`${subjectName} - ${item?.name}` || ""}
          </Tag>
        ));
      } else if (value?.classes?.length === 1) {
        const className = value?.classes?.[0]?.name;
        return value?.subjects?.map?.((item) => (
          <Tag
            className={`pVertical4 ${styles.tag}`}
            color="#e7f0fe"
            key={`${item.id}`}
          >
            {`${item?.name} - ${className}` || ""}
          </Tag>
        ));
      }
      return "";
    });
  }, [filterdDistributions]);

  // <Tag
  //   className={`pVertical4 ${styles.tag}`}
  //   color="#e7f0fe"
  //   key={`${value.id}`}
  // >
  //   {value?.name || ""}
  // </Tag>

  //////////////////////////////////////////////////
  // #endregion VIEWS //////////////////////////////
  return (
    <div
      onClick={select}
      className={`pHorizontal12 pVertical12 mTop12 cursor-pointer ${
        styles.root
      } ${isLast ? "mBottom12" : ""} ${isSelected ? styles.selected : ""}`}
    >
      <div className={`flex items-center ${styles.topWrapper}`}>
        <div
          className={`flex truncate mRight4 flex-col ${styles.teacherColumn}`}
        >
          <span className={`truncate ${styles.columnTitle}`}>
            {/* {teacher?.teacher_info?.full_name || ""} */}
            {teacher?.full_name || ""}
          </span>
          <span className="font-bold italic text-xs">
            {teacher?.teacher_info?.met_code}
          </span>
        </div>
        <div className={`flex mRight4 ${styles.classColumn}`}>
          <SelectOption
            // data={addedClassList}
            data={classList}
            className={styles.select}
            value={homeroomClass}
            setValue={handleChangeHomeroomClass}
            onClick={(e) => e?.stopPropagation()}
          />
        </div>
        <div className={`flex ${styles.countColumn}`}>
          <span className={`${styles.columnTitle}`}>
            Phân công giảng dạy: {filterdDistributions?.length || 0}
          </span>
        </div>
      </div>

      <div className="w-full flex items-center mTop12">
        <div className={`flex-1 ${styles.tagWrapper}`}>
          {/* {distribution?.subjects?.map?.(renderTag)} */}
          {renderTag}
        </div>
        <button
          disabled={filterdDistributions?.length === 0}
          onClick={handleDeleteAll}
          className={`${styles.removeIcon} ${
            filterdDistributions?.length === 0 ? styles.disabled : ""
          }`}
        >
          <Img src={binIcon} />
        </button>
      </div>
    </div>
  );
}
