import React from "react";
import styles from "./styles.module.css";

export default function CustomModal({
  children = null,
  className = "",
  visible,
  setVisible = (_visible) => {},
  closeOnClickOutside = true,
}) {
  // #region    VARIABLES //////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion VARIABLES //////////////////////////

  // #region    useEffect //////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion useEffect //////////////////////////

  // #region    FUNCTIONS //////////////////////////
  //////////////////////////////////////////////////
  const onClick = (e) => {
    e.stopPropagation();
    if (closeOnClickOutside) {
      setVisible(false);
    }
  };
  //////////////////////////////////////////////////
  // #endregion FUNCTIONS //////////////////////////

  // #region    VIEWS //////////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion VIEWS //////////////////////////////
  return (
    <div
      className={`${styles.root} ${className} ${visible ? "" : "hidden"}`}
      onClick={onClick}
    >
      {children}
    </div>
  );
}
