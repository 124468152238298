import {
  SET_STAFF_LIST_PAGE_CURRENT_PAGE,
  SET_STAFF_LIST_PAGE_FILTER_TEACHING_GROUP,
} from "redux/action/staffListPageAction";
import { deepClone } from "util/helper";

export default function staffListPageReducer(
  state = {
    teachingGroup: null,
    currentPage: 1,
  },
  action
) {
  switch (action.type) {
    case SET_STAFF_LIST_PAGE_FILTER_TEACHING_GROUP:
      return {
        ...state,
        teachingGroup: deepClone(action.payload),
      };
    case SET_STAFF_LIST_PAGE_CURRENT_PAGE:
      return {
        ...state,
        currentPage: deepClone(action.payload),
      };
    default:
      return state;
  }
}
