// @ts-nocheck
import React, { useCallback, useEffect, useState } from "react";
import { Select as SelectAntd } from "antd";

function Select({ options, value, onChange, mode, ...props }) {
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    setInputValue(value || "");
  }, [value]);

  const handleChange = useCallback(
    (v) => {
      setInputValue(v);
      onChange?.(v);
    },
    [onChange]
  );

  return (
    <SelectAntd
      {...props}
      value={inputValue || void 0}
      style={{
        width: "100%",
      }}
      getPopupContainer={(node) => node.parentNode}
      onChange={handleChange}
      options={options}
      mode={mode ? mode : ""}
      popupClassName="text-left"
      suffixIcon={
        <svg
          width={16}
          height={16}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.931 5a.8.8 0 00-.565 1.366l4.068 4.068a.8.8 0 001.132 0l4.068-4.068A.8.8 0 0012.07 5H3.93z"
            fill="#65627A"
          />
        </svg>
      }
      menuItemSelectedIcon={
        <svg
          width={16}
          height={16}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.06 4L6 13.06.94 8 2 6.94l4 4 8-8L15.06 4z"
            fill="#81B351"
          />
        </svg>
      }
    />
  );
}

export default Select;
