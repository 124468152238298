import InfoField from "common/CusInfoField/infoField/InfoField";
import SelectOption from "common/select/selectOption/SelectOption";
import React from "react";
import { ZERO_ID } from "util/constant";

export default function InfoFieldSelect({
  label = "",
  isRequired = false,
  data,
  value,
  setValue = (_value) => {},
  placeholder = "",
  valueFieldName = "id",
  valueTextName = "name",
  disabled = false,
  direction = "horizontal",
  mode = undefined,
  tagRender = undefined,
  showSearch = true,
  allowClear = true,
  noLabel = false,
  className = "",
  selectClassName = "",
  labelClassName = "",
  labelWrapperClassName = "",
  inputWrapperClassName = "",
  ...props
}) {
  // #region    VARIABLES //////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion VARIABLES //////////////////////////

  // #region    useEffect //////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion useEffect //////////////////////////

  // #region    FUNCTIONS //////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion FUNCTIONS //////////////////////////

  // #region    VIEWS //////////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion VIEWS //////////////////////////////
  return (
    <InfoField
      label={label}
      isRequired={isRequired}
      input={
        <SelectOption
          data={data}
          value={value === ZERO_ID ? "" : value}
          setValue={setValue}
          placeholder={placeholder}
          disabled={disabled}
          valueFieldName={valueFieldName}
          valueTextName={valueTextName}
          mode={mode}
          tagRender={tagRender}
          className={selectClassName}
          showSearch={showSearch}
          allowClear={allowClear}
          {...props}
        />
      }
      className={className}
      labelClassName={labelClassName}
      labelWrapperClassName={labelWrapperClassName}
      inputWrapperClassName={inputWrapperClassName}
      direction={direction}
      noLabel={noLabel}
    />
  );
}
