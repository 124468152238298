import {
  arrowDoubleLeft,
  arrowDoubleRight,
  arrowLeft,
  arrowRight,
} from "asset/img/Images";
import React, { useEffect, useState } from "react";
import { TABLE_PAGE_SIZES } from "util/constant";
import CusButton from "../../../CusButton";
import CusSelect from "../../../CusSelect";
import CusSpan from "../../../CusSpan";
import NumberButton from "./components/NumberButton";
import styles from "./styles.module.css";

// số lượng nút
const NUMBER_BUTTON_COUNT = 3;

export default function TablePaginator({
  page,
  setPage,
  pageSize,
  setPageSize,
  totalItems,
  loading,
  className = "",
  showPageSize = true,
}) {
  // #region    VARIABLE ////////////////////////////////////////////////////////////////////////////////////////////////////
  const [lastPage, setLastPage] = useState(0);
  const [disableChangePage, setDisableChangePage] = useState(false);
  const controlStyles = {
    width: "80px",
    height: "34px !important",
    borderRadius: "5px",
  };
  const showingFrom = (page - 1) * pageSize.value + 1;
  const showingTo =
    showingFrom - 1 + pageSize.value > totalItems
      ? totalItems
      : showingFrom - 1 + pageSize.value;
  const isOnFirstPage = page === 1;
  const isOnLastPage = showingTo === totalItems;
  // #endregion VARIABLE ////////////////////////////////////////////////////////////////////////////////////////////////////

  // #region      HOOK   ////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    const temp = Math.ceil(totalItems / pageSize?.value);
    if (!!temp) setLastPage(temp);
  }, [pageSize, totalItems]);
  // #endregion   HOOK   ////////////////////////////////////////////////////////////////////////////////////////////////////

  // #region    FUNCTION ////////////////////////////////////////////////////////////////////////////////////////////////////
  const handleSetPageSize = (e) => {
    setPageSize(e);
  };

  const handleSetPage = (value) => {
    setPage(value);
    setDisableChangePage(true);
    setTimeout(() => {
      setDisableChangePage(false);
    }, 500);
  };

  const goToPreviousPage = () => handleSetPage(page - 1);

  const goToNextPage = () => handleSetPage(page + 1);

  const goToFirstPage = () => handleSetPage(1);

  const goToLastPage = () => handleSetPage(lastPage);

  const getNumbersForButton = () => {
    let numbers = [];
    if (totalItems === 0) {
      numbers = [1];
    } else if (page <= Math.floor(NUMBER_BUTTON_COUNT / 2) + 1) {
      // Ở những page đầu
      for (let i = 1; i <= NUMBER_BUTTON_COUNT; i++) {
        numbers.push(i);
      }
    } else if (page >= lastPage - Math.floor(NUMBER_BUTTON_COUNT / 2)) {
      // Ở những page cuối
      for (let i = lastPage - NUMBER_BUTTON_COUNT + 1; i <= lastPage; i++) {
        numbers.push(i);
      }
    } else {
      // Ở những trang giữa
      numbers.push(page);
      for (let i = 1; i <= NUMBER_BUTTON_COUNT / 2; i++) {
        numbers.push(page + i);
      }
      for (let i = 1; i <= NUMBER_BUTTON_COUNT / 2; i++) {
        numbers.unshift(page - i);
      }
    }
    return numbers;
  };
  // #endregion FUNCTION ////////////////////////////////////////////////////////////////////////////////////////////////////

  // #region      VIEW   ////////////////////////////////////////////////////////////////////////////////////////////////////
  const renderNumberButtons = () => {
    const buttons = [];
    const numbers = getNumbersForButton();
    for (let i = 0; i < numbers?.length; i++) {
      buttons.push(
        <NumberButton
          key={`${i}_${numbers[i]}`}
          number={numbers[i]}
          currentPage={page}
          lastPage={lastPage}
          onClick={() => handleSetPage(numbers[i])}
          disabled={loading || disableChangePage}
        />
      );
    }
    return buttons;
  };

  return (
    <div className={`flex justify-end align-center ${className}`}>
      {/* <CusSpan className="mRight18">{`${showingFrom || 0} - ${
        showingTo || 0
      } trên ${totalItems || 0}`}</CusSpan> */}
      <div className="flex items-center">
        <CusButton
          icon={arrowDoubleLeft}
          onClick={goToFirstPage}
          disabled={loading || disableChangePage || isOnFirstPage}
          className={`mRight4 ${
            loading || disableChangePage || isOnFirstPage
              ? "cursor-disabled"
              : ""
          } ${styles.jumpMaxButton}`}
          iconClassName={styles.btnIcon}
        />
        <CusButton
          icon={arrowLeft}
          onClick={goToPreviousPage}
          disabled={loading || disableChangePage || isOnFirstPage}
          className={`mRight4 ${
            loading || disableChangePage || isOnFirstPage
              ? "cursor-disabled"
              : ""
          }`}
          iconClassName={styles.btnIcon}
        />
        {renderNumberButtons()}
        <CusButton
          icon={arrowRight}
          onClick={goToNextPage}
          disabled={loading || disableChangePage || isOnLastPage}
          className={`mRight4 ${
            loading || disableChangePage || isOnLastPage
              ? "cursor-disabled"
              : ""
          } ${styles.jumpOneButton}`}
          iconClassName={styles.btnIcon}
        />
        <CusButton
          icon={arrowDoubleRight}
          onClick={goToLastPage}
          disabled={loading || disableChangePage || isOnLastPage}
          className={`${
            loading || disableChangePage || isOnLastPage
              ? "cursor-disabled"
              : ""
          }`}
          iconClassName={styles.btnIcon}
        />
      </div>
      {showPageSize ? (
        <>
          <CusSpan className="mRight4">Hiển thị</CusSpan>
          <CusSelect
            options={TABLE_PAGE_SIZES}
            value={pageSize}
            onChange={handleSetPageSize}
            placeholder=""
            controlStyles={controlStyles}
            disabled={loading || disableChangePage}
            className="mRight4"
          />
        </>
      ) : null}
    </div>
  );
  // #endregion   VIEW   ////////////////////////////////////////////////////////////////////////////////////////////////////
}
