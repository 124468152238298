import React from "react";

function IconFile() {
  return (
    <svg
      width={12}
      height={12}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 2.5A1.5 1.5 0 013.5 1h3v2A1.5 1.5 0 008 4.5h2v5A1.5 1.5 0 018.5 11h-5A1.5 1.5 0 012 9.5v-7zm7.793 1L7.5 1.207V3a.5.5 0 00.5.5h1.793zM7 0h.086a1.5 1.5 0 011.06.44l2.415 2.414A1.5 1.5 0 0111 3.914V9.5A2.5 2.5 0 018.5 12h-5A2.5 2.5 0 011 9.5v-7A2.5 2.5 0 013.5 0H7z"
        fill="#394BC7"
      />
    </svg>
  );
}

export default IconFile;
