// @ts-nocheck
import IconFile from "asset/icons/IconFile";
import InfoFieldDatePicker from "common/CusInfoField/infoFieldDatePicker/InfoFieldDatePicker";
import InfoFieldSelect from "common/CusInfoField/infoFieldSelect/InfoFieldSelect";
import CusTable from "common/CusTable";
import FileList, { FileItem } from "common/FilePreviewer/FileList";
import "common/FilePreviewer/styles/index.scss";
import CustomBreadcrumb from "common/breadcrumb/CustomBreadcrumb";
import IsMobile from "hook/isMobile";
import MobileHeader from "mobile/layout/header/MobileHeader";
import { HEADER_TYPE } from "mobile/util/mobileConstant";
import { useSelector } from "react-redux";
import violateStyles from "page/violateManagementPage/styles.module.css";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { getGradeList, getSubjectList, makeGrade, importGrade } from "util/APIs";
import {
  formatDateValue,
  formatMessageType,
  formatMessageWorkflowState,
  messageTypeList,
  messageWorkFlowStateList,
} from "util/common";
import { TABLE_PAGE_SIZES } from "util/constant";
import { routes } from "util/routes";
import FilePicker from "common/filePicker/FilePicker";
import { Notification } from "common/Notification";
import MakeGrade from "./component/makeGrade"
import ImportGrade from "./component/importGrade"




function GraderSupportPage() {
  const isUsingMobile = IsMobile();
  const userInfo = useSelector((state) => state?.app?.userInfo);
  const accountId = userInfo?.account?.id;
  const schoolYear = useSelector(
    // (state) => state.schoolYear.selectedSchoolYear
    (state) => state
  );

  const [tableLoading, setTableLoading] = useState(false);
  const [grade, setGrade] = useState(null);
  const [gradeList, setGradeList] = useState([]);
  const [subject, setSubject] = useState(null);
  const [subjectList, setSubjectList] = useState([]);
  const [files, setFiles] = useState(null);




  return (
    <div className="flex flex-col h-full">
      {isUsingMobile ? (
        <MobileHeader
          type={HEADER_TYPE.middleTitle}
          title="Hỗ trợ chấm điểm"
          haveNotiButton={false}
        />
      ) : (
        <CustomBreadcrumb crumbs={[routes.GRADER_SUPPORT]} />
      )}
      <div className={`py-4 section-shadow bg-white`}>
        <h1 className="text-xl font-bold mb-2 mLeft20">Tính điểm: </h1>
        <MakeGrade />
        <h1 className="text-xl font-bold mb-2 mTop48 mLeft20">Tải điểm lên: </h1>
        <ImportGrade />
      </div>
    </div >
  );
}

export default GraderSupportPage;
