import React from "react";

function IconMinus(props) {
  return (
    <svg
      width={26}
      height={25}
      viewBox="0 0 26 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.345 13.39H6.066c-.563 0-1.02-.45-1.02-1.006s.457-1.005 1.02-1.005h14.277c.564 0 1.02.45 1.02 1.005 0 .556-.456 1.005-1.02 1.005z"
        fill="#231F20"
      />
      <mask
        id="a"
        style={{
          maskType: "luminance",
        }}
        maskUnits="userSpaceOnUse"
        x={5}
        y={11}
        width={17}
        height={3}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.345 13.39H6.066c-.563 0-1.02-.45-1.02-1.006s.457-1.005 1.02-1.005h14.277c.564 0 1.02.45 1.02 1.005 0 .556-.456 1.005-1.02 1.005z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#a)">
        <path
          fill="#C5CBD0"
          d="M0.968262 0.320312H25.443662V24.446112000000003H0.968262z"
        />
      </g>
    </svg>
  );
}

export default IconMinus;
