import React, { useCallback, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import IsMobile from "hook/isMobile";
import InfoFieldSelect from "common/CusInfoField/infoFieldSelect/InfoFieldSelect";
import {
  DataGrid,
  gridClasses,
  GridRowEditStopReasons,
} from "@mui/x-data-grid";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { styled, lighten, darken, alpha } from "@mui/material/styles";
import { randomArrayItem, randomId } from "@mui/x-data-grid-generator";
import {
  getGradeBookByClassAndSubject,
  getGradeBook,
  getSubjectsByTeacher,
  getGradeList,
  getClassList,
  getClassByTeacher,
  getGradeBookColumns,
  updateGradeBookColumns,
  getSubject,
} from "util/APIs";
import { useSelector } from "react-redux";
import styles from "./styles.module.css";
import MobileHeader from "mobile/layout/header/MobileHeader";
import { HEADER_TYPE } from "mobile/util/mobileConstant";
import CustomBreadcrumb from "common/breadcrumb/CustomBreadcrumb";
import { STUDENT_MANAGEMENT, routes } from "util/routes";

const roles = ["Market", "Finance", "Development"];
const StyledDataGrid = styled(DataGridPro)(({ theme }) => {
  const borderColor =
    theme.palette.mode === "light"
      ? lighten(alpha(theme.palette.divider, 1), 0.88)
      : darken(alpha(theme.palette.divider, 1), 0.68);

  const selectedCellBorder = alpha(theme.palette.primary.main, 0.5);

  return {
    [`& .${gridClasses.cell}`]: {
      border: `1px solid transparent`,
      borderRight: `1px solid ${borderColor}`,
      borderBottom: `1px solid ${borderColor}`,
    },
    [`& .${gridClasses.cell}.MuiDataGrid-pinnedColumns--left`]: {
      backgroundColor: "lightblue",
      fontWeight: "bold",
    },

    [`& .${gridClasses.cell}.Mui-selected`]: {
      borderColor: alpha(theme.palette.primary.main, 0.1),
    },
    [`& .${gridClasses.cell}.Mui-selected.${gridClasses["cell--rangeTop"]}`]: {
      borderTopColor: selectedCellBorder,
    },
    [`& .${gridClasses.cell}.Mui-selected.${gridClasses["cell--rangeBottom"]}`]:
      {
        borderBottomColor: selectedCellBorder,
      },
    [`& .${gridClasses.cell}.Mui-selected.${gridClasses["cell--rangeLeft"]}`]: {
      borderLeftColor: selectedCellBorder,
    },
    [`& .${gridClasses.cell}.Mui-selected.${gridClasses["cell--rangeRight"]}`]:
      {
        borderRightColor: selectedCellBorder,
      },
  };
});
const randomRole = () => {
  return randomArrayItem(roles);
};

// function EditToolbar(props) {
//   const { setRows, setRowModesModel } = props;

//   const handleClick = () => {
//     const id = randomId();
//     setRows((oldRows) => [
//       ...oldRows,
//       {
//         id,
//         stt: "",
//         maHs: "",
//         hoTen: "",
//         ngaySinh: "",
//         danhGiaThuongXuyen: evaluate.map((item) => ({
//           [item.id]: "",
//         })),
//         giuaKy: "",
//         cuoiKy: "",
//         diemTBHK: "",
//         nhanXet: "",
//         lienLac: "",
//         isNew: true,
//         lop: "",
//       },
//     ]);
//     setRowModesModel((oldModel) => ({
//       ...oldModel,
//       [id]: { mode: GridRowModes.Edit, fieldToFocus: "stt" },
//     }));
//   };

//   return (
//     <GridToolbarContainer>
//       <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
//         Thêm
//       </Button>
//     </GridToolbarContainer>
//   );
// }

export default function GradeBookPage() {
  const [evaluate, setEvaluate] = useState([]);
  const [academicProgramsColumns, setAcademicProgramsColumns] = useState([]);
  const [gradeDetail, setGradeDetail] = useState([]);
  const [gradeBook, setGradeBook] = useState([]);
  const userInfo = useSelector((state) => state?.app?.userInfo);
  const accountId = userInfo?.account?.id;
  const userId = userInfo?.user?.id;
  const [grade, setGrade] = useState(null);
  const [gradeList, setGradeList] = useState([]);
  const [classList, setClassList] = useState([]);
  const [classId, setClassId] = useState(null);
  const [subjectId, setSubjectId] = useState(null);
  const [subjectTeacher, setSubjectTeacher] = useState([]);
  const [subjectList, setSubjectList] = useState([]);
  const [rows, setRows] = useState([]);
  const [academicPrograms, setAcademicPrograms] = useState(null);
  const [groupedClasses, setGroupedClasses] = useState({});

  const isUsingMobile = IsMobile();

  const [gk, setGk] = useState({
    id: "0",
    group: "gk",
    column_gk_id: "0",
  });
  const [ck, setCk] = useState({
    id: "0",
    group: "ck",
    column_ck_id: "0",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (classId !== null && subjectId !== null) {
          const response = await getGradeBookColumns(
            accountId,
            classId,
            subjectId
          );
          if (classId == null || subjectId == null) {
            throw new Error("Lỗi khi tải dữ liệu từ API");
          }
          setAcademicProgramsColumns(response.data.data);
          setEvaluate(
            response.data.data.filter((e) => e.group.toLowerCase() == "tx")
          );
          const res = await getSubject(accountId, subjectId);

          if (res?.status === 200) {
            const data = res?.data;
            setAcademicPrograms(data.academic_programs[0]);
          }
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Lỗi khi tải dữ liệu từ API: ", error);
      }
    };

    fetchData();
  }, [subjectId, classId]);

  const getSubjectByTeacher = useCallback(async () => {
    const res = await getSubjectsByTeacher(accountId, userId);
    // setSubjectList(res.data.data);
    if (res?.status === 200) {
      const subjects_a = [];

      // Iterate through the classes
      const groupclass = {};
      const bodySubjectList = res.data.data;
      bodySubjectList.forEach((classInfo) => {
        const subjectName = classInfo.subjects[0].id;
        subjects_a.push(classInfo.subjects[0]);
        if (!groupclass[subjectName]) {
          groupclass[subjectName] = [];
        }

        groupclass[subjectName].push(classInfo.classes[0]);
      });
      setGroupedClasses(groupclass);
      const subjects = subjects_a
        .map((e) => e.id)
        // store the keys of the unique objects
        .map((e, i, final) => final.indexOf(e) === i && i)

        // eliminate the dead keys & store unique objects
        .filter((e) => subjects_a[e])
        .map((e) => subjects_a[e]);

      setSubjectId(subjects[0]?.id);
      setSubjectList(subjects);
      setSubjectTeacher(subjects[0]?.name);
      if (groupclass[subjects[0]]?.length) {
        groupclass[subjects[0]].sort((a, b) => {
          return a.id - b.id;
        });
        setClassList(groupclass[subjects[0]]);
      }
    }
  }, []);

  const formatGradeBookStudents = (data) => {
    return data.map((student) => {
      const danhGiaThuongXuyen = {};
      evaluate.forEach((item) => {
        danhGiaThuongXuyen[`column_${item.group}_${item.id}`] =
          student.gradebook[0]?.gradebook_value.filter(
            (e) => e.academic_programs_columns[0]?.name == item.name
          )[0]?.score;
      });
      const score = {};
      let gk_t = student.gradebook[0]?.gradebook_value.filter(
        (e) => e.academic_programs_columns[0]?.group == "GK"
      )[0];
      let ck_t = student.gradebook[0]?.gradebook_value.filter(
        (e) => e.academic_programs_columns[0]?.group == "CK"
      )[0];
      setGk(gk_t);
      setCk(ck_t);
      score[`column_gk`] = gk_t?.score;
      score[`column_ck`] = ck_t?.score;
      score[`column_gk_id`] = gk_t?.academic_programs_columns[0].id;
      score[`column_ck_id`] = ck_t?.academic_programs_columns[0].id;
      return {
        id: student.id,
        stt: student.stt,
        maHs: student.moeat_id,
        hoTen: student.full_name,
        ngaySinh: student.dob,
        ...score,
        diemTBHK: "",
        nhanXet: "",
        gradebook_id: student.gradebook[0]?.id,
        ...danhGiaThuongXuyen,
      };
    });
  };

  useEffect(() => {
    if (groupedClasses[subjectId] !== undefined) {
      setSubjectTeacher(subjectList.filter((e) => e.id == subjectId)[0]?.name);
      const groupclass = groupedClasses[subjectId].sort((a, b) => {
        return a.id - b.id;
      });
      const uniqueClassList = groupclass.filter(
        (item, index, self) => self.findIndex((c) => c.id === item.id) === index
      );

      setClassList(uniqueClassList);
      setClassId(groupedClasses[subjectId][0]?.id);
    }
  }, [subjectId]);

  const getGradeBookDetail = useCallback(async () => {
    if (classId !== null && subjectId !== null) {
      const res = await getGradeBookByClassAndSubject(
        accountId,
        classId,
        subjectId
      );

      if (res?.status === 200) {
        const data = res?.data?.data;
        setGradeDetail(data);
        setRows(formatGradeBookStudents(data));
      }
    }
  }, [classId, subjectId, evaluate]);

  useEffect(() => {
    getSubjectByTeacher();
  }, [getSubjectByTeacher]);

  useEffect(() => {
    getGradeBookDetail();
  }, [getGradeBookDetail]);

  const [rowModesModel, setRowModesModel] = useState({});

  const [isLoading, setIsLoading] = useState(true);

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const processRowUpdate = async (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    console.log("newRow", newRow);
    const oldRow = rows.filter((e) => e.id == newRow.id)[0];
    const columns = Object.keys(newRow).filter((e) => e.includes("column"));
    for (let index = 0; index < columns.length; index++) {
      const column_name = columns[index];

      const [tmp, group, id] = column_name.split("_");
      let data = {
        students: [newRow.id],
        subjects: [subjectId],
        classes: [classId],
        academic_programs: [academicPrograms?.id],
        graderId: userId,
        terms: [1],
      };
      if (group === "gk") {
        data.academic_programs_columns = [parseInt(oldRow.column_gk_id)];
      } else if (group === "ck") {
        data.academic_programs_columns = [parseInt(oldRow.column_ck_id)];
      } else {
        data.academic_programs_columns = [parseInt(id)];
      }

      if (oldRow[column_name] != newRow[column_name]) {
        data.score = newRow[column_name];
        console.log("data", data);
        await updateGradeBookColumns(accountId, { data });
      }
    }

    setRows(rows.map((row) => (row.id == newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };
  function validateNumber(value) {
    const number = parseInt(value, 10);
    return !isNaN(number) && number >= 1 && number <= 10;
  }
  const columns = [
    {
      field: "stt",
      headerName: "STT",
      width: 100,
      editable: true,
      pinnable: false,
    },
    {
      field: "maHs",
      headerName: "Mã HS",
      width: 200,
      editable: true,
      pinnable: false,
    },
    {
      field: "hoTen",
      headerName: "Họ và tên",
      width: 220,
      editable: true,
    },
    {
      field: "ngaySinh",
      headerName: "Ngày sinh",
      type: "date",
      width: 220,
      editable: true,
      valueGetter: (params) => {
        return new Date(params.value);
      },
      valueFormatter: (params) => {
        const date = new Date(params.value);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
      },
    },

    ...evaluate.map((item) => ({
      field: `column_${item.group}_${item.id}`,
      headerName: item.name,
      type: "number",
      width: 220,
      editable: true,
      valueParser: (newValue) => {
        const parsedValue = parseFloat(newValue);
        if (!isNaN(parsedValue)) {
          if (parsedValue < 1) {
            return 1;
          } else if (parsedValue > 10) {
            return 10;
          }
          return parsedValue;
        } else {
          return;
        }
      },
      valueFormatter: (params) => {
        const parsedValue = parseFloat(params.value);
        if (!isNaN(parsedValue)) {
          const formattedValue = Number(parsedValue).toFixed(1);
          return formattedValue;
        } else {
          return;
        }
      },
    })),
    {
      field: `column_gk`,
      headerName: "Giữa kỳ",
      type: "number",
      width: 220,
      editable: true,
      valueParser: (newValue) => {
        const parsedValue = parseFloat(newValue);
        if (!isNaN(parsedValue)) {
          if (parsedValue < 1) {
            return 1;
          } else if (parsedValue > 10) {
            return 10;
          }
          return parsedValue;
        } else {
          return;
        }
      },
      valueFormatter: (params) => {
        const parsedValue = parseFloat(params.value);
        if (!isNaN(parsedValue)) {
          const formattedValue = Number(parsedValue).toFixed(1);
          return formattedValue;
        } else {
          return;
        }
      },
    },
    {
      field: `column_ck`,
      headerName: "Cuối kỳ",
      type: "number",
      width: 220,
      editable: true,
      valueParser: (newValue) => {
        const parsedValue = parseFloat(newValue);
        if (!isNaN(parsedValue)) {
          if (parsedValue < 1) {
            return 1;
          } else if (parsedValue > 10) {
            return 10;
          }
          return parsedValue;
        } else {
          return;
        }
      },
      valueFormatter: (params) => {
        const parsedValue = parseFloat(params.value);
        if (!isNaN(parsedValue)) {
          const formattedValue = Number(parsedValue).toFixed(1);
          return formattedValue;
        } else {
          return;
        }
      },
    },
    {
      field: "diemTBHK",
      headerName: "Điểm TBHK",
      type: "number",
      width: 220,
      editable: true,
      valueParser: (newValue) => {
        const parsedValue = parseFloat(newValue);
        if (!isNaN(parsedValue)) {
          if (parsedValue < 1) {
            return 1;
          } else if (parsedValue > 10) {
            return 10;
          }
          return parsedValue;
        } else {
          return;
        }
      },
      valueFormatter: (params) => {
        const parsedValue = parseFloat(params.value);
        if (!isNaN(parsedValue)) {
          const formattedValue = Number(parsedValue).toFixed(1);
          return formattedValue;
        } else {
          return;
        }
      },
    },
    { field: "nhanXet", headerName: "Nhận xét", width: 220, editable: true },
  ];
  const columnGroupingModel = [
    {
      groupId: "Đánh giá thường xuyên",
      children: [
        ...evaluate.map((item) => ({
          field: `column_${item.group}_${item.id}`,
        })),
      ],
      valueParser: (newValue) => {
        const parsedValue = parseFloat(newValue);
        if (parsedValue < 1) {
          return 1;
        } else if (parsedValue > 10) {
          return 10;
        }
        return isNaN(parsedValue) ? null : parsedValue;
      },
    },
  ];

  return (
    <div>
      {isUsingMobile ? (
        <MobileHeader
          type={HEADER_TYPE.middleTitle}
          title="Bảng điểm"
          haveNotiButton={false}
        />
      ) : (
        <CustomBreadcrumb
          crumbs={[STUDENT_MANAGEMENT, routes.GRADEBOOK]}
          style={styles.breadcrumb}
        />
      )}
      <div className="p-4">
        <InfoFieldSelect
          label="Môn"
          data={subjectList}
          value={subjectTeacher}
          setValue={setSubjectId}
          direction="vertical"
          className="flex-1 "
          valueTextName="name"
        />
        {/* <InfoFieldSelect
          label="Khối"
          data={gradeList}
          value={grade}
          setValue={setGrade}
          direction="vertical"
          className="flex-1"
        /> */}
        <InfoFieldSelect
          label="Lớp"
          data={classList}
          value={classId}
          setValue={setClassId}
          direction="vertical"
          className="flex-1 pb-8"
        />

        <Box
          sx={{
            height: 500,
            width: "100%",
            "& .actions": {
              color: "text.secondary",
            },
            "& .textPrimary": {
              color: "text.primary",
            },
          }}
        >
          <div style={{ height: "100%", width: "100%" }}>
            <StyledDataGrid
              rows={rows}
              columns={columns}
              editMode="row"
              rowModesModel={rowModesModel}
              onRowModesModelChange={handleRowModesModelChange}
              onRowEditStop={handleRowEditStop}
              processRowUpdate={processRowUpdate}
              slotProps={{ toolbar: { setRows, setRowModesModel } }}
              columnGroupingModel={columnGroupingModel}
              experimentalFeatures={{ columnGrouping: true }}
              initialState={{
                pinnedColumns: { left: ["stt"], right: ["actions"] },
              }}
            />
          </div>
        </Box>
      </div>
    </div>
  );
}
