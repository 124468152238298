import { MenuOutlined } from "@ant-design/icons";
import { Table } from "antd";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import TableHeader from "./components/TableHeader";
import TablePaginator from "./components/TablePaginator";
import styles from "./styles.module.css";

const CusTable = (
  {
    columns,
    data,
    havePagination = true,
    haveSelection = false,
    headerLeftButtons = [],
    headerRightButtons = [],
    size = "middle",
    belowHeader = null,
    page = 1,
    setPage = (_page) => {},
    pageSize = 50,
    setPageSize = (_pageSize) => {},
    totalItems = 50,
    loading,
    showPageSize = true,
    rowKey = "id",
    isDraggable = false,
    onDragEnd = ({ oldIndex, newIndex }) => {},
    wrapperClassName = "",
    ...props
  },
  ref
) => {
  // #region    VARIABLE ////////////////////////////////////////////////////////////////////////////////////////////////////
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [checkAll, setCheckAll] = useState(false);
  const onSelectRowChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
    if (selectedRowKeys?.length === pageSize) setCheckAll(true);
    else setCheckAll(false);
  };
  const rowSelection = {
    columnTitle: <></>,
    selectedRowKeys,
    onChange: onSelectRowChange,
  };
  const SortableItem = SortableElement((props) => <tr {...props} />);
  const SortableBody = SortableContainer((props) => <tbody {...props} />);
  // #endregion VARIABLE ////////////////////////////////////////////////////////////////////////////////////////////////////

  // #region      HOOK   ////////////////////////////////////////////////////////////////////////////////////////////////////
  useImperativeHandle(
    ref,
    () => ({
      selectedIds: selectedRowKeys,
      setSelectedIds: setSelectedRowKeys,
    }),
    [selectedRowKeys, setSelectedRowKeys]
  );

  // Khi data thay đổi (thay đổi currentPage, pageSize, ...) thì bỏ những item đã check
  useEffect(() => {
    onSelectRowChange([]);
  }, [data]);
  // #endregion   HOOK   ////////////////////////////////////////////////////////////////////////////////////////////////////

  // #region    FUNCTION ////////////////////////////////////////////////////////////////////////////////////////////////////
  const handleSetCheckAll = () => {
    setCheckAll(!checkAll);
    if (!checkAll) setSelectedRowKeys(data?.map((i) => i.id) || []);
    else setSelectedRowKeys([]);
  };

  const DraggableContainer = (props) => (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass={styles.rowDragging}
      onSortEnd={onDragEnd}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = data.findIndex((x) => x?.index === restProps["data-row-key"]);
    return <SortableItem index={index} {...restProps} />;
  };
  // #endregion FUNCTION ////////////////////////////////////////////////////////////////////////////////////////////////////

  // #region      VIEW   ////////////////////////////////////////////////////////////////////////////////////////////////////
  const tableCustomProps = {};
  if (isDraggable) {
    tableCustomProps.components = {
      body: {
        wrapper: DraggableContainer,
        row: DraggableBodyRow,
      },
    };
  }

  const DragHandle = SortableHandle(() => (
    <MenuOutlined
      style={{
        cursor: "grab",
        color: "#999",
      }}
    />
  ));

  const sortColumn = {
    title: "",
    width: 50,
    className: styles.dragVisible,
    render: () => <DragHandle />,
  };

  return (
    <div
      className={`cus-table-wrapper flex flex-col flex-1 ${wrapperClassName}`}
    >
      {haveSelection ? (
        <TableHeader
          checkAll={checkAll}
          setCheckAll={handleSetCheckAll}
          selectedRowKeys={selectedRowKeys}
          leftButtons={headerLeftButtons}
          rightButtons={headerRightButtons}
        />
      ) : null}
      {belowHeader ? belowHeader() : null}
      <div className="flex-1">
        <Table
          {...props}
          rowKey={rowKey}
          columns={isDraggable ? [sortColumn, ...columns] : columns}
          dataSource={data}
          pagination={false}
          rowSelection={haveSelection ? rowSelection : undefined}
          size={size}
          loading={loading}
          className={styles.cusTable}
          {...tableCustomProps}
        />
      </div>
      {havePagination ? (
        <TablePaginator
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          totalItems={totalItems}
          loading={loading}
          showPageSize={showPageSize}
          className="mTop20"
        />
      ) : null}
    </div>
  );
  // #endregion   VIEW   ////////////////////////////////////////////////////////////////////////////////////////////////////
};

export default forwardRef(CusTable);
