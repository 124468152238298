import React from "react";
import { useLocation } from "react-router-dom";
import { NO_LAYOUT_LINKS } from "util/constant";
import BottomNavbar from "./bottomNavbar/BottomNavbar";
import styles from "./styles.module.css";

export default function MobileLayout({ children }) {
  // #region    VARIABLES //////////////////////////
  //////////////////////////////////////////////////
  const location = useLocation();
  var userInfo = localStorage.getItem('user_info');
  const SHOW_NAVBAR_LINKS = [
    "/student-schedule",
    "/result",
    "/school-fee",
    "/menu",
  ];
  //////////////////////////////////////////////////
  // #endregion VARIABLES //////////////////////////

  // #region    useEffect //////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion useEffect //////////////////////////

  // #region    FUNCTIONS //////////////////////////
  //////////////////////////////////////////////////
  const isShowLayout = () => {
    return (
      NO_LAYOUT_LINKS.findIndex((item) => item === location.pathname) === -1
    );
  };

  const isShowNavbar = () => {
    return (
      SHOW_NAVBAR_LINKS.findIndex((item) => item === location.pathname) !== -1
    );
  };
  //////////////////////////////////////////////////
  // #endregion FUNCTIONS //////////////////////////

  // #region    VIEWS //////////////////////////////
  //////////////////////////////////////////////////
  const renderLayout = () => {
    if(userInfo === null) {
      localStorage.clear();
    }
    if (userInfo == 'undefined') {
      localStorage.removeItem("user_info");
      localStorage.removeItem("token");
    }
    if (!isShowLayout()) return <>{children}</>;
    return (
      <div className={`${styles.root}`}>
        <div
          className={`${styles.contentWrapper} ${
            isShowNavbar()
              ? styles.contentWithNavbar
              : styles.contentWithoutNavbar
          }`}
        >
          {children}
        </div>
        {/* {isShowNavbar() ? <BottomNavbar /> : null} */}
      </div>
    );
  };
  //////////////////////////////////////////////////
  // #endregion VIEWS //////////////////////////////
  return renderLayout();
}
