import { demoAvatar, optionEllipsisBlue } from "asset/img/Images";
import Img from "common/image/Img";
import moment from "moment";
import React, { useState } from "react";
import { DATE_FORMAT, DEFAULT_AVATAR } from "util/constant";
import styles from "./styles.module.css";

export default function NotiItem({ data }) {
  // #region    VARIABLES //////////////////////////
  //////////////////////////////////////////////////
  const [showOption, setShowOption] = useState(false);
  //////////////////////////////////////////////////
  // #endregion VARIABLES //////////////////////////

  // #region    useEffect //////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion useEffect //////////////////////////

  // #region    FUNCTIONS //////////////////////////
  //////////////////////////////////////////////////
  const handleShowOption = () => setShowOption(true);

  const hideOption = () => setShowOption(false);
  //////////////////////////////////////////////////
  // #endregion FUNCTIONS //////////////////////////

  // #region    VIEWS //////////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion VIEWS //////////////////////////////
  return (
    <div
      className={`flex items-center ${styles.noti}`}
      onMouseEnter={handleShowOption}
      onMouseLeave={hideOption}
    >
      <div className={`mRight20 ${styles.notiIcon}`}>
        <Img src={demoAvatar} alt="Noti" />
      </div>

      <div className={`flex flex-col flex-1`}>
        <span className={styles.title}>{data?.title}</span>
        <span className={`mTop2 ${styles.content}`}>{data?.content}</span>
        <span className={`mTop4 ${styles.createdAt}`}>
          {moment(data?.created_at).format(DATE_FORMAT)}
        </span>
      </div>

      <div
        className={`mLeft24 cursor-pointer ${!showOption && "invisible"}`}
        onClick={() => {}}
      >
        <Img src={optionEllipsisBlue} alt="Option" />
      </div>
    </div>
  );
}
