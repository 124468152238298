import React from "react";
import ReactSelect from "react-select";
import CusSpan from "../CusSpan";

export default function CusSelect({
  isSearchable = false,
  controlStyles = {},
  label = "",
  placeholder = "",
  valueField = "id",
  labelField = "name",
  ...props
}) {
  const styles = {
    control: (provided) => ({
      ...provided,
      borderColor: "#d3d9e0",
      borderRadius: "10px",
      minHeight: "34px",
      ...controlStyles,
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize: "14px",
      color: "#3e4559",
    }),
    menuList: (provided) => ({
      ...provided,
      fontSize: "14px",
      color: "#3e4559",
    }),
  };
  const dropdownIndicatorStyles = (base) => {
    const customStyles = {
      padding: "6px",
    };
    return { ...base, ...customStyles };
  };

  return (
    <>
      {label ? (
        <div className="mBottom10">
          <CusSpan className="fw7">{label}</CusSpan>
        </div>
      ) : null}
      <ReactSelect
        isSearchable={isSearchable}
        components={{
          IndicatorSeparator: () => null,
        }}
        getOptionValue={(option) => option[valueField]}
        getOptionLabel={(option) => option[labelField]}
        menuPlacement="auto"
        placeholder={placeholder}
        styles={{
          dropdownIndicator: dropdownIndicatorStyles,
          ...styles,
        }}
        {...props}
      />
    </>
  );
}
