import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Menu } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { setSidebarActiveKey } from "redux/action/app";
import color from "util/color";
import styles from "./styles.module.css";

export default function MenuItem({
  item,
  isSubMenuItem = false,
  sidebarActiveKey,
  hoverKey,
  setHoverKey,
}) {
  // #region    VARIABLES //////////////////////////
  //////////////////////////////////////////////////
  const school = useSelector((state) => state.school.selectedSchool);
  const userInfo = JSON.parse(localStorage.getItem("user_info"));
  const [isSelected, setIsSelected] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [icon, setIcon] = useState(null);
  const dispatch = useDispatch();
  const isUseMarquee = item?.name?.length > 25;
  //////////////////////////////////////////////////
  // #endregion VARIABLES //////////////////////////

  // #region    useEffect //////////////////////////
  //////////////////////////////////////////////////
  useEffect(() => {
    setIsHovering(item.key === hoverKey);
  }, [item.key, hoverKey]);

  useEffect(() => {
    setIsSelected(item.key === sidebarActiveKey);
  }, [item.key, sidebarActiveKey]);

  useEffect(() => {
    let temp = null;
    if (!isSubMenuItem) {
      let imgSrc = item.icon;
      if (isSelected) {
        imgSrc = item.activeIcon;
      }
      temp = <img src={imgSrc} />;
    }
    setIcon(temp);
  }, [item.icon, item.activeIcon, isSelected]);
  //////////////////////////////////////////////////
  // #endregion useEffect //////////////////////////

  // #region    FUNCTIONS //////////////////////////
  //////////////////////////////////////////////////
  const handleClick = () => {
    if (item.key === "homework_cohota") {
      const currentSchool = userInfo?.school_info?.find(
        (i) => i?.id === school?.id
      );
      if (currentSchool?.cohota_url_login) {
        window.location.href = currentSchool?.cohota_url_login;
      }
    } else {
      dispatch(setSidebarActiveKey(item.key));
    }
  };
  //////////////////////////////////////////////////
  // #endregion FUNCTIONS //////////////////////////

  // #region    VIEWS //////////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion VIEWS //////////////////////////////
  return (
    <Menu.Item
      key={item.key}
      icon={icon}
      onMouseEnter={() => setHoverKey(item.key)}
      onMouseLeave={() => setHoverKey(null)}
      onClick={handleClick}
      className={`${styles.item} ${
        isHovering || isSelected ? styles.highlightItem : ""
      } ${isSelected ? styles.selectedItem : ""}`}
    >
      <Link
        to={{
          pathname: item.link,
        }}
        className={`pTop2 flex items-center ${
          isUseMarquee ? "marquee-wrapper" : ""
        }`}
      >
        {isSubMenuItem ? (
          <FontAwesomeIcon
            icon={faCircle}
            style={{
              color: `${isSelected ? color.primary : color.blackText2}`,
              width: "4px",
              marginRight: "10px",
            }}
          />
        ) : null}
        <span
          className={`${styles.menuItemText} ${
            isSubMenuItem ? "" : styles.notSubMenuItemText
          } ${isSelected ? styles.highlightText : ""} ${
            isUseMarquee && isHovering
              ? `marquee-text ${styles.marqueeText}`
              : ""
          }`}
        >
          {item?.name}
        </span>
      </Link>
    </Menu.Item>
  );
}
