import { rectangleTab, rectangleTabActive } from "asset/img/Images";
import React, { useEffect, useState } from "react";
import color from "util/color";
import { textStyle } from "util/helper";
import styles from "./styles.module.css";

export default function RectangleTab({
  style = {},
  className = "",
  isSelected = false,
  onClick = () => {},
  text = "",
  index = 0,
  totalCount,
}) {
  // #region    VARIABLES //////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion VARIABLES //////////////////////////

  // #region    useEffect //////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion useEffect //////////////////////////

  // #region    FUNCTIONS //////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion FUNCTIONS //////////////////////////

  // #region    VIEWS //////////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion VIEWS //////////////////////////////
  const [backgroundSize, setBackgroundSize] = useState("160px 40px");
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 860) {
        setBackgroundSize("135px 40px");
      }
      if (window.innerWidth <= 400) {
        setBackgroundSize("120px 40px");
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); 
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div
      style={{
        backgroundImage: `url(${
          isSelected ? rectangleTabActive : rectangleTab
        })`,
        zIndex: isSelected ? totalCount : totalCount - 1 - index,
        backgroundSize,
        ...style,
      }}
      className={`${styles.tab} ${
        index !== 0 ? styles.notFirstTab : ""
      } ${className}`}
      onClick={onClick}
      key={text}
    >
      <div
        className={`h-full flex justify-center items-center ${styles.layoutWrapper}`}
      >
        <span
          className={`ellipsis ${styles.text} ${
            isSelected && styles.selectedText
          }`}
        >
          {text}
        </span>
      </div>
    </div>
  );
}
