import { Tooltip } from "antd";
import React from "react";
import styles from "./styles.module.css";

export default function SimpleTooltip({ children }) {
  // #region    VARIABLES //////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion VARIABLES //////////////////////////

  // #region    useEffect //////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion useEffect //////////////////////////

  // #region    FUNCTIONS //////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion FUNCTIONS //////////////////////////

  // #region    VIEWS //////////////////////////////
  //////////////////////////////////////////////////
  const tooltipContent = (
    <div className={styles.tooltipBackground}>
      <span>Xem thông tin chi tiết</span>
    </div>
  );
  //////////////////////////////////////////////////
  // #endregion VIEWS //////////////////////////////
  return (
    <Tooltip
      placement="top"
      overlayInnerStyle={{
        background: "transparent",
        padding: 0,
        borderRadius: "8px",
      }}
      color="#4879f6"
      title={tooltipContent}
    >
      {children}
    </Tooltip>
  );
}
