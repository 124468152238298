import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import ELoading from "../components/ELoading";
import IconCancel16 from "../icons/IconCancel16";
import IconDownload from "../icons/IconDownload";
import FileContentViewer from "./FileContentViewer/FileContentViewer";
import { downloadFileFromBlob, getFileNameWithExtension } from "./fileUtils";

const PreviewContent = memo(
  ({ fileIndex, fileInfo, fetchBlob, pageNumber, onClose }) => {
    const abortController = useRef(null);
    const { name, url, contentType, size } = fileInfo;
    const [loading, setLoading] = useState(true);
    const [previewUrl, setPreviewUrl] = useState(null);
    const [blobInfo, setBlobInfo] = useState(null);

    const loadingStepRef = useRef(1);

    useEffect(
      () => {
        const fetchData = async (currentLoadingStep) => {
          await setLoading(true);
          setPreviewUrl(url);
          let blobInfo = fileInfo.blob || null;
          if (!blobInfo && fetchBlob) {
            if (abortController.current) {
              abortController.current.abort();
            }
            abortController.current = new window.AbortController();

            blobInfo = await fetchBlob(
              fileIndex,
              fileInfo,
              abortController.current
            );
          }
          if (currentLoadingStep === loadingStepRef.current) {
            if (blobInfo) {
              setBlobInfo(blobInfo);
            } else {
              setBlobInfo(null);
            }
          }
          setLoading(false);
        };
        fetchData(loadingStepRef.current);
        return () => {
          if (abortController.current) {
            abortController.current.abort();
            loadingStepRef.current = loadingStepRef.current + 1;
          }
        };
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [fileIndex]
    );

    const download = useCallback(() => {
      if (!loading) {
        downloadFileFromBlob(
          blobInfo,
          getFileNameWithExtension(name, contentType)
        );
      }
    }, [blobInfo, name, contentType, loading]);

    return (
      <>
        <div className="file-preview-header">
          <button
            className="square-button file-preview-header__close"
            onClick={onClose}
          >
            <IconCancel16 />
          </button>
          <div className="file-preview-header__page-number">{pageNumber}</div>
          <div className="file-name">{name}</div>
          <button
            className="square-button file-preview-header__actions"
            disabled={loading || !blobInfo}
            onClick={download}
          >
            <IconDownload />
          </button>
        </div>
        <div className="file-preview-body">
          <ELoading isLoading={loading} />
          {!loading && (
            <FileContentViewer
              previewUrl={previewUrl}
              name={name}
              contentType={contentType}
              onDownload={download}
            />
          )}
        </div>
      </>
    );
  }
);

PreviewContent.displayName = "PreviewContent";

export default PreviewContent;
