import { EllipsisOutlined } from "@ant-design/icons";
import { Dropdown, Input, Menu, message, Modal, Tree, Button } from "antd";
import { searchIcon } from "asset/img/Images";
import { isPermitted } from "authen/authenHelper";
import CustomBreadcrumb from "common/breadcrumb/CustomBreadcrumb";
import StyledButton from "common/button/StyledButton";
import InfoFieldText from "common/CusInfoField/infoFieldText/InfoFieldText";
import InfoFieldTextArea from "common/CusInfoField/infoFieldTextArea/infoFiledTextArea";
import InfoFieldSelect from "common/CusInfoField/infoFieldSelect/InfoFieldSelect";
import { Select, Space } from "antd";
import Img from "common/image/Img";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  createCategory,
  deleteCategory,
  filterCategory,
  updateCategory,
  getGroupSms,
  createAnnouncement,
  getStudentListByClass,
  getClassId,
} from "util/APIs";
import {
  PERMISSION_OBJECT,
  PERMISSION_TYPE,
  SEARCH_DELAY_TIME,
  ZERO_ID,
} from "util/constant";
import styles from "./styles.module.css";
import { Notification } from "common/Notification";
import FilePicker from "common/filePicker/FilePicker";
import FileShower from "common/filePicker/component/FileShower";
import InfoField from "common/CusInfoField/infoField/InfoField";
import IsMobile from "hook/isMobile";
import MobileHeader from "mobile/layout/header/MobileHeader";
import { HEADER_TYPE } from "mobile/util/mobileConstant";
import { routes } from "util/routes";
import { Checkbox } from "antd";

export default function SmsAdmin() {
  // #region    VARIABLES //////////////////////////
  //////////////////////////////////////////////////
  const isUsingMobile = IsMobile();
  const userInfo = useSelector((state) => state?.app?.userInfo);
  const accountId = userInfo?.account?.id;
  const [value, setValue] = useState("staff");
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [type, setType] = useState("ke_hoach");
  const [files, setFiles] = useState(null);
  const [validate, setValidate] = useState(true);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [classList, setClassList] = useState([]);
  const [classId, setClassId] = useState([]);
  const [nameClasses, setNameClasses] = useState([]);
  const [studentList, setStudentList] = useState([]);
  const [studentId, setStudentId] = useState([]);
  const [studentName, setStudentName] = useState([]);
  const [ruleList, setRuleList] = useState([]);
  const [group, setGroup] = useState([]);
  const [groupId, setGroupId] = useState([]);
  const [smsPending, setSmsPending] = useState(false);
  //////////////////////////////////////////////////
  // #endregion VARIABLES //////////////////////////

  // #region    useEffect //////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion useEffect //////////////////////////

  // #region    FUNCTIONS //////////////////////////
  //////////////////////////////////////////////////
  const roleManage = [
    { id: "staff", name: "Nhân sự" },
    { id: "parents", name: "Phụ huynh" },
    { id: "students", name: "Học sinh" },
    { id: "group", name: "Nhóm thành viên" },
  ];
  const onChangeCheckbox = (e) => {
    setSmsPending(e.target.checked);
  };
  const handleShowConfirmModal = (teacher) => {
    setShowConfirmModal(true);
  };
  //////////////////////////////////////////////////
  // #endregion FUNCTIONS //////////////////////////
  const handleCreateAnnouncement = async () => {
    // setShowConfirmModal(true);
    // const data = {
    //   data: {
    //     title,
    //     body,
    //     roles: value,
    //     type,
    //   },
    // };
    const payload = {
      title: title.trim(),
      body: body.trim(),
      roles: value,
      sms: smsPending,
      type,
      student_ids: studentId,
    };
    const formData = new FormData();
    formData.append("data", JSON.stringify(payload));
    if (files) {
      for (let f of files) {
        formData.append("file", f);
      }
    }
    const res = await createAnnouncement(accountId, formData);
    if (res?.status === 200) {
      Notification.sendSuccess("Gửi thông báo thành công.");
      setFiles(null);
      setTitle("");
      setBody("");

      setShowConfirmModal(false);
    }
  };
  const handleCreateAnnouncementGroup = async () => {
    // setShowConfirmModal(true);
    // const data = {
    //   data: {
    //     title,
    //     body,
    //     roles: value,
    //     type,
    //   },
    // };
    const payload = {
      title: title.trim(),
      body: body.trim(),
      roles: value,
      type,
      sms: smsPending,
      group: groupId,
    };
    const formData = new FormData();
    formData.append("data", JSON.stringify(payload));
    if (files) {
      for (let f of files) {
        formData.append("file", f);
      }
    }
    const res = await createAnnouncement(accountId, formData);
    if (res?.status === 200) {
      Notification.sendSuccess("Gửi thông báo thành công.");
      setFiles(null);
      setTitle("");
      setBody("");
      setShowConfirmModal(false);
    }
  };
  const handleCreateAnnouncementAll = async () => {
    // setShowConfirmModal(true);
    // const data = {
    //   data: {
    //     title,
    //     body,
    //     roles: value,
    //     type,
    //   },
    // };
    const payload = {
      title: title.trim(),
      body: body.trim(),
      roles: value,
      type,
      sms: smsPending,
    };
    const formData = new FormData();
    formData.append("data", JSON.stringify(payload));
    if (files) {
      for (let f of files) {
        formData.append("file", f);
      }
    }
    const res = await createAnnouncement(accountId, formData);
    if (res?.status === 200) {
      Notification.sendSuccess("Gửi thông báo thành công.");
      setFiles(null);
      setTitle("");
      setBody("");
      setShowConfirmModal(false);
    }
  };

  const getGroups = useCallback(async () => {
    const res = await getGroupSms(accountId);
    if (res?.status === 200) {
      const data = res?.data.data;
      console.log("dataGroup", data);
      setGroup(data);
      if (data?.length > 0) {
        setGroupId(data[0].id);
      }
    }
  }, [accountId]);
  const getClass = useCallback(async () => {
    const params = {
      skip_joins: true,
    };
    const res = await getClassId(accountId, params);
    if (res?.status === 200) {
      const data = res?.data?.data;
      setClassList(data);
      setNameClasses(data[0]?.name);
      if (data?.length > 0) {
        setClassId(data[0]?.id);
      }
    }
  }, [accountId]);

  const getStudentByClass = useCallback(async () => {
    const params = {
      orderBy: "stt",
    };
    if (classId !== null && classId.length > 0) {
      const promises = classId.map(async (id) => {
        const res = await getStudentListByClass(accountId, [id], params);
        const klass = classList.find((e) => e.id === id);

        console.log("res", res);
        if (res?.status === 200) {
          const data = res?.data?.data;
          const categoryData = data.map((item) => {
            item["category"] = klass?.name;
            return item;
          });

          setStudentList((prevStudentList) => [
            ...prevStudentList,
            ...categoryData,
          ]);
          setStudentName(data[0]?.full_name);

          if (data?.length > 0) {
            setStudentId(data[0]?.id);
          }
        }
      });

      await Promise.all(promises);
    }
  }, [accountId, classId, classList, getStudentListByClass]);

  useEffect(() => {
    const uniqueStudentList = studentList.filter(
      (item, index, self) => self.findIndex((c) => c.id === item.id) === index
    );
    const optionStudents = uniqueStudentList?.map((e) => ({
      label: e?.full_name,
      value: e?.id,
      category: e?.category,
    }));
    const formatOptions = _.chain(optionStudents)
      .groupBy("category")
      .map((value, key) => ({ label: key, options: value }))
      .value();
    setRuleList(formatOptions);
  }, [studentList]);

  useEffect(() => {
    getGroups();
  }, [getGroups]);
  useEffect(() => {
    getClass();
  }, [getClass]);
  useEffect(() => {
    getStudentByClass();
  }, [getStudentByClass, classId, classList]);
  useEffect(() => {
    if (title !== "" && body !== "") {
      if (title.trim() !== "" && body.trim() !== "") {
        setValidate(false);
      }
    } else {
      setValidate(true);
    }
  }, [body, title]);

  // useEffect(() => {

  // }, [classId, setRuleList, getClass]);

  const handleAddAttachments = (values) => {
    const temp = [...values];
    setFiles(temp);
  };

  const handleRemoveAttachment = (_value, index) => {
    let temp = [...files];
    temp.splice(index, 1);
    setFiles(temp);
  };

  // #region    VIEWS //////////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion VIEWS //////////////////////////////
  const handleChange = (value) => {
    setClassId(value);
  };
  const handleChangeStudent = (value) => {
    setStudentId(value);
  };
  const handleChangeGroup = (value) => {
    setGroupId(value);
  };
  const filterOptions = (input, option) => {
    return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };
  const options = classList.map((e) => ({
    label: e?.name,
    value: e?.id,
    category: e?.name,
  }));
  const optionGroups = group.map((e) => ({
    label: e?.name,
    value: e?.id,
    category: e?.name,
  }));

  return (
    <div className={`flex flex-col h-full`}>
      {/* <CustomBreadcrumb crumbs={["/sms"]} /> */}
      {isUsingMobile ? (
        <MobileHeader
          type={HEADER_TYPE.middleTitle}
          title="Quản lý thông báo"
          haveNotiButton={false}
        />
      ) : (
        <CustomBreadcrumb crumbs={[routes.ADMIN_NOTIFICATION]} />
      )}
      <div className="flex flex-1 flex-column">
        <div
          className={`section-shadow bg-white left-wrapper ${styles.smsWrapper}`}
        >
          <InfoFieldText
            label="Tiêu đề"
            isRequired={true}
            value={title}
            setValue={setTitle}
            placeholder="Nhập tiêu đề của tin nhắn"
            disabled={false}
          />
          <InfoFieldTextArea
            label="Nội dung"
            isRequired={true}
            value={body}
            setValue={setBody}
            placeholder="Nhập nội dung của tin nhắn"
            inputClassName="w-full"
            className={"mt-5"}
          />
          {}
          {value === "students" ? (
            <div className="border-2 px-4 my-5 radius">
              <InfoFieldSelect
                label="Gửi tới vai trò"
                isRequired={true}
                data={roleManage}
                value={value}
                setValue={setValue}
                placeholder="Chọn loại của tin nhắn"
                disabled={false}
                allowClear={false}
                className={"mt-5 w-3/5"}
              />
              {value === "students" && (
                <>
                  <div className="mt-5 flex">
                    <Select
                      mode="multiple"
                      style={{
                        width: "100%",
                      }}
                      placeholder="Lớp"
                      onChange={handleChange}
                      filterOption={filterOptions}
                      optionLabelProp="label"
                      className="my-8"
                      options={options}
                      optionRender={(option) => (
                        <Space>
                          <span role="img" aria-label={option.data.label}>
                            {option.data.emoji}
                          </span>
                          {option.data.desc}
                        </Space>
                      )}
                    />
                  </div>
                  <div>
                    <Select
                      mode="multiple"
                      style={{
                        width: "100%",
                      }}
                      placeholder="Danh sách học sinh"
                      filterOption={filterOptions}
                      onChange={handleChangeStudent}
                      optionLabelProp="label"
                      className="my-8"
                      options={ruleList}
                      optionRender={(option) => (
                        <Space>
                          <span role="img" aria-label={option.data.label}>
                            {option.data.emoji}
                          </span>
                          {option.data.desc}
                        </Space>
                      )}
                    />
                  </div>
                </>
              )}
            </div>
          ) : value === "group" ? (
            <>
              <div className="border-2 px-4 my-5 radius">
                <InfoFieldSelect
                  label="Gửi tới vai trò"
                  isRequired={true}
                  data={roleManage}
                  value={value}
                  setValue={setValue}
                  placeholder="Chọn loại của tin nhắn"
                  disabled={false}
                  allowClear={false}
                  className={"mt-5 w-3/5"}
                />

                <div className="mt-5 flex">
                  <Select
                    mode="multiple"
                    style={{
                      width: "100%",
                    }}
                    placeholder="Nhóm"
                    // defaultValue={className}
                    filterOption={filterOptions}
                    onChange={handleChangeGroup}
                    optionLabelProp="label"
                    className="my-8"
                    options={optionGroups}
                    optionRender={(option) => (
                      <Space>
                        <span role="img" aria-label={option.data.label}>
                          {option.data.emoji}
                        </span>
                        {option.data.desc}
                      </Space>
                    )}
                  />
                </div>
              </div>
            </>
          ) : (
            <InfoFieldSelect
              label="Gửi tới vai trò"
              isRequired={true}
              data={roleManage}
              value={value}
              setValue={setValue}
              placeholder="Chọn loại của tin nhắn"
              disabled={false}
              allowClear={false}
              className={"mt-5 w-3/5"}
            />
          )}

          <InfoFieldSelect
            label="Loại thông báo"
            isRequired={true}
            data={[
              { id: "ke_hoach", name: "Kế hoạch" },
              { id: "hoc_tap", name: "Học tập" },
            ]}
            value={type}
            setValue={setType}
            allowClear={false}
            placeholder="Chọn loại của tin nhắn"
            disabled={false}
            className={"mt-5 w-3/5"}
          />
          <Checkbox className="mt-5" onChange={onChangeCheckbox}>
            SMS
          </Checkbox>

          <div className="flex items-start mt-6">
            <div className="mRight12">
              <FilePicker setFiles={handleAddAttachments} />
            </div>
            <FileShower
              files={files}
              removeFile={handleRemoveAttachment}
              fileOnClick={() => {}}
            />
          </div>

          <div className="mt-10 flex justify-end">
            <StyledButton
              disabled={validate}
              className={`bg-blueDefault `}
              onClick={handleShowConfirmModal}
            >
              <span className="text-base font-semibold text-white">
                Gửi tin nhắn
              </span>
            </StyledButton>
          </div>
        </div>
      </div>
      {value === "students" && (
        <Modal
          title="Xác nhận"
          centered
          visible={showConfirmModal}
          onOk={handleCreateAnnouncement}
          onCancel={() => setShowConfirmModal(false)}
          okText="Gửi"
          cancelText="Hủy"
        >
          <p>
            Bạn có chắc muốn gửi tin nhắn?{" "}
            {/* {dataForDelete?.teacher_info?.full_name}? */}
          </p>
        </Modal>
      )}
      {value === "group" && (
        <Modal
          title="Xác nhận"
          centered
          visible={showConfirmModal}
          onOk={handleCreateAnnouncementGroup}
          onCancel={() => setShowConfirmModal(false)}
          okText="Gửi"
          cancelText="Hủy"
        >
          <p>
            Bạn có chắc muốn gửi tin nhắn?{" "}
            {/* {dataForDelete?.teacher_info?.full_name}? */}
          </p>
        </Modal>
      )}
      {(value === "staff" || value === "parents") && (
        <Modal
          title="Xác nhận"
          centered
          visible={showConfirmModal}
          onOk={handleCreateAnnouncementAll}
          onCancel={() => setShowConfirmModal(false)}
          okText="Gửi"
          cancelText="Hủy"
        >
          <p>
            Bạn có chắc muốn gửi tin nhắn?{" "}
            {/* {dataForDelete?.teacher_info?.full_name}? */}
          </p>
        </Modal>
      )}
    </div>
  );
}
