import React, { useRef } from "react";
import { scrollHorizontallyOnScroll } from "util/helper";
import RectangleTab from "./RectangleTab";
import styles from "./styles.module.css";

export default function RectangleTabs({
  className = "",
  data = [],
  currentTab,
  setCurrentTab,
  valueField = "id",
  textField = "name",
}) {
  // #region    VARIABLES //////////////////////////
  //////////////////////////////////////////////////
  const wrapperRef = useRef(null);
  //////////////////////////////////////////////////
  // #endregion VARIABLES //////////////////////////

  // #region    useEffect //////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion useEffect //////////////////////////

  // #region    FUNCTIONS //////////////////////////
  //////////////////////////////////////////////////
  const handleOnWheel = (event) => {
    scrollHorizontallyOnScroll(event, wrapperRef?.current);
  };
  //////////////////////////////////////////////////
  // #endregion FUNCTIONS //////////////////////////

  // #region    VIEWS //////////////////////////////
  //////////////////////////////////////////////////
  const renderTab = (tab, index) => {
    const isSelected = currentTab?.[valueField] === tab?.[valueField];
    return (
      <RectangleTab
        isSelected={isSelected}
        onClick={() => setCurrentTab(tab)}
        key={`${tab?.[valueField]}_${index}`}
        text={tab?.[textField] || ""}
        index={index}
        totalCount={data?.length}
      />
    );
  };
  //////////////////////////////////////////////////
  // #endregion VIEWS //////////////////////////////
  return (
    <div
      ref={wrapperRef}
      onWheel={handleOnWheel}
      className={`flex overflow-x-auto hide-scrollbar ${styles.wrapper} ${className}`}
    >
      {data?.map(renderTab)}
    </div>
  );
}
