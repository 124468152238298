import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  facebookIcon,
  globeIcon,
  insIcon,
  thuThiemLogo,
} from "asset/img/Images";
import Img from "common/image/Img";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { TEACHER_DEFAULT_LINK, is1920OrMore } from "util/constant";
import { rHeight, rWidth } from "util/helper";
import Slide from "./component/slide/Slide";

import styles from "./styles.module.css";
import { redirectAuth2, login, getTeacherData } from "util/APIs";
import InfoFieldPassword from "common/CusInfoField/infoFieldPassword/InfoFieldPassword";
import InfoFieldText from "common/CusInfoField/infoFieldText/InfoFieldText";
import { setUserInfo, setSidebarActiveKey } from "redux/action/app";
import useHandleLogin from "hook/handleLoginSuccess";
import IsMobile from "hook/isMobile";

export default function LocalLoginPage() {
  // #region    VARIABLES //////////////////////////
  //////////////////////////////////////////////////
  const dispatch = useDispatch();
  const isUsingMobile = IsMobile();
  const userInfo = useSelector((state) => state?.app?.userInfo);

  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const smallImages = [1, 2, 3, 4];
  //////////////////////////////////////////////////
  // #endregion VARIABLES //////////////////////////

  // #region    useEffect //////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion useEffect //////////////////////////

  // #region    VIEWS //////////////////////////////
  //////////////////////////////////////////////////
  const renderSmallImage = (_item, index) => (
    <div
      style={{
        width: "48px",
        height: "48px",
        borderRadius: "50%",
        marginLeft: index !== 0 ? "24px" : 0,
        backgroundColor: "#fafafa",
      }}
      key={index}
    >
      {/* <Img src={item} alt='People' /> */}
    </div>
  );
  //////////////////////////////////////////////////
  // #endregion VIEWS //////////////////////////////

  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [handleLoginSuccess] = useHandleLogin();
  if (userInfo.user?.context_type === "students") {
    history.push("/lunch-register");
  }
  const handleLogin = async () => {
    const res = await login(name, password);
    if (res?.status === 200) {
      console.log("OK");
      console.log(res?.data?.user?.context_type);
      if (res?.data?.user?.context_type === "canteen") {
        localStorage.setItem("token", res?.data?.jwt);
        var user_info = await getTeacherData();
        localStorage.setItem("user_info", JSON.stringify(user_info?.data));
        dispatch(setUserInfo({ ...(user_info?.data || {}) }));
        dispatch(setSidebarActiveKey('canteen_management'));
        history.push('/canteen-management');
      } else {
        localStorage.setItem("token", res?.data?.jwt);
        var user_info = await getTeacherData();
        localStorage.setItem("user_info", JSON.stringify(user_info?.data));
        dispatch(setUserInfo({ ...(user_info?.data || {}) }));
        history.push('/user/home');
      }
    }
  };

  return (
    <div className="flex">
      {isUsingMobile ? (
        ""
      ) : (
        <div
          className={` flex flex-col justify-end items-start bg-cover bg-no-repeat ${styles.leftWrapper}`}
          style={{
            width: 470,
            padding: `0 60px ${is1920OrMore ? "50" : "12"}px`,
          }}
        >
          <span className={styles.brandName}>Educentric</span>
          <Slide />
          <div className="flex mTop32">{smallImages.map(renderSmallImage)}</div>
          <div style={{ marginTop: rHeight(108) }} className={`flex`}>
            <div style={{ width: "32px", height: "32px" }}>
              <Img src={facebookIcon} alt="facebook" />
            </div>
            <div
              style={{
                width: "32px",
                height: "32px",
                marginLeft: "8px",
              }}
            >
              <Img src={globeIcon} alt="website" />
            </div>
            <div
              style={{
                width: "32px",
                height: "32px",
                marginLeft: "8px",
              }}
            >
              <Img src={insIcon} alt="instagram" />
            </div>
          </div>
        </div>
      )}

      <div
        style={{ paddingTop: rHeight(121) }}
        className={`h-screen bg-cover bg-no-repeat flex flex-col justify-start items-center flex-1 ${styles.loginWrapper}`}
      >
        <div style={{ width: rWidth(88), height: rWidth(88) }}>
          <Img src={thuThiemLogo} alt="logo" />
        </div>
        <span className={styles.greetText}>Chào mừng đến với Educentric</span>
        <span className={styles.title}>ĐĂNG NHẬP</span>
        <div
          className={`text-white flex flex-col ${styles.formWrapper}`}
          style={{ width: '90%' }}
        >
          <InfoFieldText
            label="Tên đăng nhập"
            className={styles.mrInput}
            isRequired={true}
            value={name}
            setValue={setName}
            placeholder="Tên đăng nhập"
            direction="vertical"
          />
          ,
          <InfoFieldPassword
            label="Mật khẩu"
            className={styles.mrInput}
            isRequired={true}
            value={password}
            setValue={setPassword}
            placeholder="Mật khẩu"
            direction="vertical"
          />
          ,
          <button
            style={{ height: rHeight(59), marginTop: rHeight(24) }}
            className={`flex justify-center items-center bg-blueDefault ${styles.loginButton}`}
            onClick={handleLogin}
            disabled={loading}
          >
            {loading && <FontAwesomeIcon icon={faCircleNotch} spin />}
            {!loading && <span className={styles.continue}>Đăng nhập</span>}
          </button>
        </div>
      </div>
    </div>
  );
}
