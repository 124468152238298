import { arrowLeftBlack } from "asset/img/Images";
import React from "react";
import { useHistory } from "react-router-dom";
import styles from "./styles.module.css";
import { routes } from "util/routes";

export default function BackButton({ className = "" }) {
  // #region    VARIABLES //////////////////////////
  //////////////////////////////////////////////////
  const history = useHistory();
  //////////////////////////////////////////////////
  // #endregion VARIABLES //////////////////////////

  // #region    useEffect //////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion useEffect //////////////////////////

  // #region    FUNCTIONS //////////////////////////
  //////////////////////////////////////////////////
  const goBack = () => {
    // history.goBack();
    history.push(routes.HOME);
  };
  //////////////////////////////////////////////////
  // #endregion FUNCTIONS //////////////////////////

  // #region    VIEWS //////////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion VIEWS //////////////////////////////
  return (
    <button className={`${styles.root} ${className}`} onClick={goBack}>
      <img src={arrowLeftBlack} alt="Back" />
    </button>
  );
}
