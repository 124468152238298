import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Breadcrumb } from "antd";
import useIsSchoolType from "hook/isUniversity";
import STTA from "hook/schoolTypeTextAdapter";
import React from "react";
import { Link } from "react-router-dom";
import { UNIVERSITY } from "util/constant";
import { LINK_INFO } from "util/sidebars";
import styles from "./styles.module.css";

export default function CustomBreadcrumb({
  crumbs,
  style = "",
  noMBottom = false,
}) {
  // #region    VARIABLES //////////////////////////
  //////////////////////////////////////////////////
  const isUniversity = useIsSchoolType(UNIVERSITY);
  const separateIcon = (
    <FontAwesomeIcon icon={faCircle} className={styles.icon} />
  );
  //////////////////////////////////////////////////
  // #endregion VARIABLES //////////////////////////

  // #region    useEffect //////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion useEffect //////////////////////////

  // #region    FUNCTIONS //////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion FUNCTIONS //////////////////////////

  // #region    VIEWS //////////////////////////////
  //////////////////////////////////////////////////
  const renderItem = () => {
    return crumbs.map((crumb, index) => {
      const text = STTA(
        LINK_INFO.find((i) => i.link === crumb)?.breadcrumbTitle,
        isUniversity
      );
      const isLast = index === crumbs.length - 1;
      if (!isLast)
        return (
          <Breadcrumb.Item key={crumb}>
            <Link to={crumb} className={styles.text}>
              {text}
            </Link>
          </Breadcrumb.Item>
        );
      else
        return (
          <Breadcrumb.Item key={crumb}>
            <span className={styles.text}>{text}</span>
          </Breadcrumb.Item>
        );
    });
  };
  //////////////////////////////////////////////////
  // #endregion VIEWS //////////////////////////////
  return (
    <div className={`${!noMBottom && "mBottom26"} ${styles.root} ${style}`}>
      <Breadcrumb separator={separateIcon}>{renderItem()}</Breadcrumb>
    </div>
  );
}
