import { Button, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styles from "../../styles.module.css";
import InfoFieldDatePicker from "common/CusInfoField/infoFieldDatePicker/InfoFieldDatePicker";
import InfoFieldSelect from "common/CusInfoField/infoFieldSelect/InfoFieldSelect";
import InfoFieldText from "common/CusInfoField/infoFieldText/InfoFieldText";
import InfoSection from "common/CusInfoField/infoSection/InfoSection";
import { Notification } from "common/Notification";
import { createStudent, getEthnics, getReligions } from "util/APIs";
import { GENDER, ID_ISSUED_PLACES } from "util/constant";
import { findEthnicAndReligion } from "util/helper";

function AddStudentModal({ onSuccess }) {
  const userInfo = useSelector((state) => state?.app?.userInfo);
  const accountId = userInfo?.account?.id;

  const [isOpen, setIsOpen] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dob, setDob] = useState("");
  const [gender, setGender] = useState("");
  const [ethnic, setEthnic] = useState("");
  const [ethnicList, setEthnicList] = useState([]);
  const [religion, setReligion] = useState("");
  const [religionList, setReligionList] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [idNo, setIdNo] = useState("");
  const [idIssueDate, setIdIssueDate] = useState(undefined);
  const [idIssuePlace, setIdIssuePlace] = useState(undefined);

  const personalFields = [
    {
      title: "",
      fields: [
        <InfoFieldText
          label="Họ"
          isRequired={true}
          value={firstName}
          setValue={setFirstName}
          placeholder="Nhập họ"
          direction="vertical"
        />,
        <InfoFieldText
          label="Tên"
          isRequired={true}
          value={lastName}
          setValue={setLastName}
          placeholder="Nhập tên"
          direction="vertical"
        />,
        <InfoFieldDatePicker
          label="Ngày sinh"
          value={dob}
          setValue={setDob}
          placeholder="Ngày/tháng/năm"
          direction="vertical"
        />,
        <InfoFieldSelect
          label="Giới tính"
          data={GENDER}
          value={gender}
          setValue={setGender}
          placeholder="Chọn giới tính"
          direction="vertical"
          valueTextName="name"
          valueFieldName="value"
        />,
        <InfoFieldSelect
          label="Dân tộc"
          data={ethnicList}
          value={ethnic}
          setValue={setEthnic}
          placeholder="Chọn dân tộc"
          direction="vertical"
          valueTextName="name"
          valueFieldName="id"
        />,
        <InfoFieldSelect
          label="Tôn giáo"
          data={religionList}
          value={religion}
          setValue={setReligion}
          placeholder="Chọn tôn giáo"
          direction="vertical"
          valueTextName="name"
          valueFieldName="id"
        />,
        <InfoFieldText
          label="Số điện thoại"
          value={phoneNumber}
          setValue={setPhoneNumber}
          placeholder="Nhập số điện thoại"
          direction="vertical"
        />,
        <InfoFieldText
          label="Email"
          value={email}
          setValue={setEmail}
          placeholder="Nhập Email"
          direction="vertical"
        />,
        <InfoFieldText
          label="CMND / CCCD"
          value={idNo}
          setValue={setIdNo}
          placeholder="Nhập số CMND / CCCD"
          direction="vertical"
        />,
        <InfoFieldDatePicker
          label="Ngày cấp"
          value={idIssueDate}
          setValue={setIdIssueDate}
          placeholder="Ngày/tháng/năm"
          direction="vertical"
        />,
        <InfoFieldSelect
          label="Nơi cấp"
          data={ID_ISSUED_PLACES}
          value={idIssuePlace}
          setValue={setIdIssuePlace}
          placeholder="Chọn nơi cấp CMND / CCCD"
          direction="vertical"
          valueTextName="name"
          valueFieldName="id"
        />,
      ],
    },
  ];

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSubmit = async () => {
    const payload = {
      data: {
        first_name: firstName,
        last_name: lastName,
        dob: dob,
        gender: gender,
        ethnic: findEthnicAndReligion(ethnicList, ethnic),
        religion: findEthnicAndReligion(religionList, religion),
        phone_number: phoneNumber,
        email: email,
        citizen_identify: {
          id_no: idNo,
          issued_on: idIssueDate,
          place_of_issue: idIssuePlace,
        },
      },
    };
    if (!firstName.trim() || !lastName.trim()) {
      Notification.sendError("Vui lòng nhập thông tin bắt buộc.");
      return;
    }
    createStudent(accountId, payload).then((res) => {
      if (res?.statusText === "OK") {
        Notification.sendSuccess("Thêm học sinh thành công!");
        toggle();
        onSuccess?.();
      }
    });
  };

  useEffect(() => {
    getEthnics().then((res) => {
      if (res?.statusText === "OK") {
        setEthnicList(res?.data);
      }
    });
    getReligions().then((res) => {
      if (res?.statusText === "OK") {
        setReligionList(res?.data?.data);
      }
    });
  }, []);

  return (
    <>
      <Button
        onClick={toggle}
        className={`flex justify-center items-center ${styles.searchBtn}`}
      >
        <span className={`${styles.searchBtnText}`}>Thêm học sinh</span>
      </Button>
      <Modal
        title="Thêm học sinh"
        open={isOpen}
        onCancel={toggle}
        footer={null}
        destroyOnClose={true}
        width={900}
      >
        <InfoSection data={personalFields} />
        <div key="button-row" className="flex items-center justify-center">
          <Button
            size="large"
            key="cancel"
            className="bg-gray-300 rounded-sm mRight12"
            onClick={toggle}
          >
            Huỷ bỏ
          </Button>
          <Button
            size="large"
            htmlType="submit"
            className="bg-blue-500 text-white rounded-sm"
            onClick={handleSubmit}
          >
            Lưu
          </Button>
        </div>
      </Modal>
    </>
  );
}

export default AddStudentModal;
