import { get, post, put, remove } from "./APICaller";
import { API_URL, API_URL_TEST, MASS_UPLOAD_URL } from "./constant";

export const checkLogin = (username) => {
  return post(`${API_URL}/user/login`, {
    user_name: username,
  });
};

export const login = (username, password) => {
  return post(`${API_URL}/auth/local`, {
    identifier: username,
    password: password,
  });
};

export const checkOauth = (params) => {
  return post(`${API_URL}/oauth2/auth`, params);
};

export const oauthLogin = (params) => {
  return post(`${API_URL}/oauth2/login`, params);
};

export const getOauthSetting = (params) => {
  return get(`${API_URL}/oauth2/get-setting-oauth2`, params);
};

export const createOauthSetting = (params) => {
  return post(`${API_URL}/oauth2`, params);
};

export const updateOauthSetting = (params) => {
  return put(`${API_URL}/oauth2`, params);
};

export const getIntegrateCohota = (params) => {
  return get(`${API_URL}/school-integrate-cohota`, params);
};

export const createIntegrateCohota = (params) => {
  return post(`${API_URL}/school-integrate-cohota`, params);
};

export const updateIntegrateCohota = (params) => {
  return put(`${API_URL}/school-integrate-cohota/${params?.id}`, params);
};
//#region Students
export const filterStudent = (params) => {
  return get(`${API_URL}/student/filter`, params);
};

export const createStudent = (accountId, body) => {
  return post(`${API_URL}/accounts/${accountId}/students`, body);
};

export const updateStudent = (accountId, id, body) => {
  return put(`${API_URL}/accounts/${accountId}/students/${id}`, body);
};

export const filterStudentTranscript = (params) => {
  return get(`${API_URL}/student/get-student-transcript`, params);
};

export const filterStudySummary = (params) => {
  return get(`${API_URL}/student/get-study-summary`, params);
};

export const changePlaceStudent = (params) => {
  return post(`${API_URL}/student/change-place`, params);
};

export const getStudent = (id) => {
  return get(`${API_URL}/student/get-one/${id}`);
};

export const getStudentSchoolYear = ({ student_id = "" }) => {
  return get(`${API_URL}/student/get-school-year-of-student`, {
    student_id,
  });
};

export const updateStudentStatus = (params) => {
  return post(`${API_URL}/student/update-status`, params);
};

export const updateStudentPriority = (params) => {
  return post(`${API_URL}/student/update-multi-priority`, params);
};

export const setStudentDefaultPriorityByClass = (params) => {
  return post(`${API_URL}/student/set-default-priority`, params);
};

export const filterStudySummaryPs = (params) => {
  return get(`${API_URL}/student/get-study-summary-ps`, params);
};
//#endregion

//#region Parent
export const filterParent = ({
  sort = "last_name",
  page = 1,
  size = 10,
  id_no = "",
}) => {
  return get(`${API_URL}/parent/filter`, { sort, page, size, id_no });
};
//#endregion

//#region Locations
export const getCountryList = () => {
  return get(`${API_URL}/locations/countries`);
};

export const getProvinceByCountry = (countryCode) => {
  return get(`${API_URL}/locations/${countryCode}/provinces`);
};

export const getDistrictByProvince = (provinceCode, countryCode) => {
  return get(
    `${API_URL}/locations/${countryCode}/provinces/${provinceCode}/districts`
  );
};

export const getSubDistrictByDistrict = (
  wardCode,
  provinceCode,
  countryCode
) => {
  return get(
    `${API_URL}/locations/${countryCode}/provinces/${provinceCode}/districts/${wardCode}/wards`
  );
};
//#endregion

//#region Media
export const uploadFile = (file, objectType) => {
  let params = new FormData();
  params.append("file", file);
  params.append("object_type", objectType);
  return post(`${API_URL}/media/upload-file`, params, {
    "Content-Type": "multipart/form-data",
  });
};

export const uploadAttachment = (params) => {
  let form = new FormData();
  form.append("file", params.file);
  form.append("object_type", params.object_type);
  if (params?.object_id) form.append("object_id", params.object_id);
  return post(`${API_URL}/media/attachment`, form, {
    "Content-Type": "multipart/form-data",
  });
};
//#endregion

//#region School Year
export const filterSchoolYear = ({
  sort,
  page,
  size,
  is_active,
  school_id = "",
  search = "",
}) => {
  return get(`${API_URL}/school-year/filter`, {
    sort,
    page,
    size,
    is_active,
    school_id,
    search,
  });
};

export const createSchoolYear = (params) => {
  return post(`${API_URL}/school-year`, params);
};

export const deleteSchoolYear = (id) => {
  return remove(`${API_URL}/school-year/${id}`);
};
//#endregion

//#region Grade
// export const filterGrade = ({ page_name = "", ...params }) => {
//   return get(`${API_URL}/grade/filter`, { page_name, ...params });
// }; api khối, lớp cũ
export const filterGrade = (accountId, params) => {
  return get(`${API_URL}/accounts/${accountId}/teaching_distributions`, params);
};

export const getGradeById = (id) => {
  return get(`${API_URL}/grade/get-one/${id}`);
};

export const createGrade = (params) => {
  return post(`${API_URL}/grade`, params);
};

export const updateGrade = (params) => {
  return put(`${API_URL}/grade/${params.id}`, params);
};

export const deleteGrade = (id) => {
  return remove(`${API_URL}/grade/${id}`);
};
//#endregion

//#region Class
export const filterClass = ({ page_name = "", ...params }) => {
  return get(`${API_URL}/class/filter`, { page_name, ...params });
};

export const createClass = (params) => {
  return post(`${API_URL}/class`, params);
};

export const updateClass = (params) => {
  return put(`${API_URL}/class/${params.id}`, params);
};

export const deleteClass = (id) => {
  return remove(`${API_URL}/class/${id}`);
};

export const exportClassReport = (params) => {
  return post(`${API_URL}/class/export-class-info`, params);
};

export const cloneClassSameGrade = (params) => {
  return post(`${API_URL}/class/clone-class-same-grade`, params);
};

export const filterClassBySchoolYear = (params) => {
  return get(`${API_URL}/class/filter-class-by-school-year`, params);
};

export const updateHomeroomeTeacher = (params) => {
  return post(`${API_URL}/class/update-homeroom-teacher`, params);
};
//#endregion

//#region User
export const filterUser = (params) => {
  return get(`${API_URL}/user/filter`, params);
};

export const createUser = (params) => {
  return post(`${API_URL}/user`, params);
};

export const deleteUser = (id) => {
  return remove(`${API_URL}/user/${id}`);
};

export const changePassword = (params) => {
  return post(`${API_URL}/user/change-password`, params);
};

export const initPasswordFirstTimeAPI = (params) => {
  return post(`${API_URL}/user/update-password-first-time`, params);
};
//#endregion

//#region Role
export const filterRole = (params) => {
  return get(`${API_URL}/role/filter`, params);
};

export const createRole = (params) => {
  return post(`${API_URL}/role`, params);
};

export const updateRole = (params) => {
  return put(`${API_URL}/role/${params.id}`, params);
};

export const deleteRole = (id) => {
  return remove(`${API_URL}/role/${id}`);
};
//#endregion

//#region Permission
export const getPermissionTree = (params) => {
  return get(`${API_URL}/permission/get-tree`, params);
};
//#endregion

//#region Transcript
export const filterTranscript = (
  sort,
  page,
  size,
  class_id = null,
  student_id = null,
  semester = null
) => {
  return get(
    `${API_URL}/transcript/filter?sort=${sort}&page=${page}&size=${size}&class_id=${
      class_id ?? ""
    }&student_id=${student_id ?? ""}&semester=${semester ?? ""}`
  );
};

export const updateMultiTranscript = (params) => {
  return post(`${API_URL}/transcript/update-multi`, params);
};
//#endregion

//#region Average transcript
export const calculateAverageTranscript = (params) => {
  return post(`${API_URL}/average-transcript/calculate-average`, params);
};

export const checkExistsAverageTranscript = (params) => {
  return post(`${API_URL}/average-transcript/check-exist`, params);
};

export const updateMultiComment = (params) => {
  return post(`${API_URL}/average-transcript/update-multi-comment`, params);
};
//#endregion

//#region Schedule
export const filterSchedule = ({
  sort = "priority",
  page = 1,
  size = 9999,
  class_id,
  type = "class",
  semester,
}) => {
  return get(
    `${API_URL}/schedule/filter?sort=${sort}&page=${page}&size=${size}&object_id=${class_id}&type=${type}&semester=${semester}`
  );
};

export const updateSchedule = (params) => {
  return put(`${API_URL}/schedule/${params.id}`, params);
};
//#endregion

//#region Class has subject
export const filterClassHasSubject = (params) => {
  return get(`${API_URL}/class-has-subject/filter`, params);
};
export const createClassHasSubject = (params) => {
  return post(`${API_URL}/class-has-subject`, params);
};
export const updateClassHasSubject = (params) => {
  return put(`${API_URL}/class-has-subject/${params.id}`, params);
};
export const deleteClassHasSubject = (id) => {
  return remove(`${API_URL}/class-has-subject/${id}`);
};

export const updateByTeachingGroup = (params) => {
  return post(`${API_URL}/class-has-subject/update-by-teaching-group`, params);
};

export const unassignAllSubjectForTeacher = (params) => {
  return post(`${API_URL}/class-has-subject/unassign-all-subject`, params);
};
//#endregion

//#region Subject
export const filterSubject = ({
  sort = "priority",
  page,
  size,
  grade_id = "",
  class_id = "",
  search = "",
}) => {
  return get(`${API_URL}/subject/filter`, {
    sort,
    page,
    size,
    grade_id,
    class_id,
    search,
  });
};

export const createSubject = (params) => {
  return post(`${API_URL}/subject`, params);
};

export const updateSubject = (params) => {
  return put(`${API_URL}/subject/${params.id}`, params);
};

export const deleteSubject = (id) => {
  return remove(`${API_URL}/subject/${id}`);
};
//#endregion

//#region event
export const getAttendanceEvent = (accountId, params) => {
  return get(`${API_URL}/accounts/${accountId}/event/attendance`, params);
};

export const updateAttendanceEvent = (accountId, id, params) => {
  return put(`${API_URL}/accounts/${accountId}/event/attendance/${id}`, params);
};

//#endregion
//#region Staff
export const filterStaff = (params) => {
  return get(`${API_URL}/staff/filter`, params);
};

export const createStaff = (accountId, params) => {
  return post(`${API_URL}/accounts/${accountId}/staff`, params);
};

export const getAttendanceByDay = (accountId, classId, params) => {
  return get(
    `${API_URL}/accounts/${accountId}/classes/${classId}/attendance_staff_patrol`,
    params
  );
};
export const handleAttendanceRawData = (accountId, params) => {
  return post(
    `${API_URL}/accounts/${accountId}/process_attendance_raw`,
    params
  );
};
export const handleBoardingAttendance = (accountId, params) => {
  return post(
    `${API_URL}/accounts/${accountId}/boarding/process_attendance`,
    params
  );
};
export const updateAttendanceStatusByDate = (accountId, id, params) => {
  return put(
    `${API_URL}/accounts/${accountId}/attendance_staff_patrol/${id}`,
    params
  );
};

export const updateStaff = (id, params) => {
  return put(`${API_URL}/staff/${id}`, params);
};

//#endregion

//#region Subject Management
export const filterSubjectManagement = (params) => {
  return get(`${API_URL}/subject-management/filter`, params);
};

export const createSubjectManagement = (params) => {
  return post(`${API_URL}/subject-management`, params);
};

export const updateSubjectManagement = (params) => {
  return put(`${API_URL}/subject-management/${params.id}`, params);
};

export const deleteSubjectManagement = (id) => {
  return remove(`${API_URL}/subject-management/${id}`);
};
//#endregion

//#region Teaching Group
export const filterTeachingGroup = (params) => {
  return get(`${API_URL}/teaching-group/filter`, params);
};

export const createTeachingGroup = (params) => {
  return post(`${API_URL}/teaching-group`, params);
};

export const deleteTeachingGroup = (id) => {
  return remove(`${API_URL}/teaching-group/${id}`);
};

export const updateTeachingGroup = (params) => {
  return put(`${API_URL}/teaching-group/${params.id}`, params);
};
//#endregion

//#region Teaching Group Has Subject
export const filterTeachingGroupSubject = (params) => {
  return get(`${API_URL}/teaching-group-has-subject-management/filter`, params);
};

export const createTeachingGroupSubject = (params) => {
  return post(`${API_URL}/teaching-group-has-subject-management`, params);
};

export const deleteTeachingGroupSubject = (id) => {
  return remove(`${API_URL}/teaching-group-has-subject-management/${id}`);
};
//#endregion

//#region Teaching Group Has Teacher
export const filterTeachingGroupTeacher = (params) => {
  return get(`${API_URL}/teaching-group-has-teacher/filter`, params);
};

export const createTeachingGroupTeacher = (params) => {
  return post(`${API_URL}/teaching-group-has-teacher`, params);
};

export const deleteTeachingGroupTeacher = (id) => {
  return remove(`${API_URL}/teaching-group-has-teacher/${id}`);
};

export const getTeacherDetailsById = (accountId, staffId) => {
  return get(`${API_URL}/accounts/${accountId}/staff/${staffId}`);
};
//#endregion

//#region School
export const filterSchool = (params) => {
  return get(`${API_URL}/school/filter`, params);
};

export const getSchoolById = (id) => {
  return get(`${API_URL}/school/get-one/${id}`);
};

export const updateSchool = (params) => {
  return put(`${API_URL}/school/${params?.id}`, params);
};

export const createSchool = (params) => {
  return post(`${API_URL}/school`, params);
};

export const deleteSchool = (id) => {
  return remove(`${API_URL}/school/${id}`);
};
//#endregion

//#region Attendance
export const filterAttendance = (params) => {
  return get(`${API_URL}/attendance/filter`, params);
};

export const updateAttendanceByDate = (params) => {
  return post(`${API_URL}/attendance/update-attendance-by-date`, params);
};

export const filterStudentAttendance = (params) => {
  return get(`${API_URL}/student/get-student-attendance`, params);
};

export const checkExistsAttendance = (params) => {
  return post(`${API_URL}/attendance/check-exist-attendance-of-class`, params);
};

export const updateMultiAttendance = (params) => {
  return post(`${API_URL}/attendance/update-multi-attendance-by-date`, params);
};

export const getNumberOfAttendancesByCard = (account_id, class_id, params) => {
  return get(
    `${API_URL}/accounts/${account_id}/classes/${class_id}/total_attendances_by_card`,
    params
  );
};

export const getViolateStudents = (account_id, class_id, params) => {
  return get(
    `${API_URL}/accounts/${account_id}/classes/${class_id}/students/evaluations`,
    params
  );
};

export const getParentCheckAttendance = (accountId, params) => {
  return get(`${API_URL}/accounts/${accountId}/parent_xin_phep`, params);
};

export const createParentCheckAttendance = (acountId, params) => {
  return post(`${API_URL}/accounts/${acountId}/parent_xin_phep`, params);
};
//#endregion

//#region Subject has score
export const filterSubjectHasScore = (params) => {
  return get(`${API_URL}/subject-has-score/filter`, params);
};

export const updateSubjectHasScore = (params) => {
  return post(`${API_URL}/subject-has-score`, params);
};
//#endregion

//#region Score
export const getListScore = (params) => {
  return get(`${API_URL}/score/filter`, params);
};

export const getListFESS = (params) => {
  return get(`${API_URL}/score/follow-evaluate-subject-score`, params);
};
//#endregion

//#region Study summary
export const updateStudySummary = (params) => {
  return put(`${API_URL}/study-summary/${params.id}`, params);
};

export const getStudyInfoBySchoolYear = (params) => {
  return get(`${API_URL}/student/get-study-info-by-school-year`, params);
};

export const getClassReportAPI = (params) => {
  return get(`${API_URL}/study-summary/class-performance-report`, params);
};

export const calcStudySummaryAPI = (params) => {
  return post(`${API_URL}/study-summary/calculated-study-summary`, params);
};
//#endregion

//#region Homework
export const getFollowEvaluateHomework = (params) => {
  return get(`${API_URL}/homework/follow-evaluate-homework`, params);
};

export const filterHomework = (params) => {
  return get(`${API_URL}/homework/filter`, params);
};

export const createHomework = (params) => {
  return post(`${API_URL}/homework`, params);
};

export const updateHomework = (params) => {
  return put(`${API_URL}/homework/${params.id}`, params);
};

export const deleteHomework = (id) => {
  return remove(`${API_URL}/homework/${id}`);
};

export const getHomeworkByStudent = (params) => {
  return get(`${API_URL}/homework/get-homework-by-student`, params);
};
//#endregion

//#region Homework detail
export const filterHomeworkDetail = (params) => {
  return get(`${API_URL}/homework-detail/filter`, params);
};

export const updateTranscriptHandIn = (params) => {
  return post(`${API_URL}/homework-detail/update-transcript-hand-in`, params);
};

export const homeworkHandIn = (params) => {
  return put(`${API_URL}/homework-detail/hand-in/${params.id}`, params);
};
//#endregion

//#region Order
export const filterOrder = (params) => {
  return get(`${API_URL}/order/filter`, params);
};

export const createOrder = (params) => {
  return post(`${API_URL}/order`, params);
};

export const updateOrder = (params) => {
  return put(`${API_URL}/order`, params);
};

export const filterSpecificOrder = (params) => {
  return get(`${API_URL}/specific-order/filter`, params);
};

export const updateMultiSpecificOrder = (params) => {
  return post(`${API_URL}/specific-order/update-multi`, params);
};
//#endregion

//#region Danh mục khoản thu
export const filterProduct = (params) => {
  return get(`${API_URL}/product/filter`, params);
};

export const createProduct = (params) => {
  return post(`${API_URL}/product`, params);
};

export const updateProduct = (params) => {
  return put(`${API_URL}/product/${params.id}`, params);
};

export const deleteProduct = (id) => {
  return remove(`${API_URL}/product/${id}`);
};

export const getProductByCategory = ({ category_id = "" }) => {
  return get(`${API_URL}/product/get-all-product-by-category-id`, {
    category_id,
  });
};
//#endregion

//#region Loại danh mục
export const filterCategory = (params) => {
  return get(`${API_URL}/category/filter`, params);
};

export const createCategory = (params) => {
  return post(`${API_URL}/category`, params);
};

export const updateCategory = (params) => {
  return put(`${API_URL}/category/${params.id}`, params);
};

export const deleteCategory = (id) => {
  return remove(`${API_URL}/category/${id}`);
};
//#endregion

//#region Notification
export const filterNoti = ({
  sort = "created_at desc",
  page = 1,
  size = 9999,
  user_id = "",
}) => {
  return get(`${API_URL}/notify-info/filter`, {
    sort,
    page,
    size,
    user_id,
  });
};

export const getNotiCount = () => {
  return get(`${API_URL}/notify-info/count-notify`);
};

export const readAllNoti = () => {
  return post(`${API_URL}/notify-info/read-all`);
};
//#endregion

//#region Dashboard
export const getOverviewSchoolYear = (params) => {
  return get(`${API_URL}/dashboard/overview-school-year`, params);
};

export const getOverviewGrade = (params) => {
  return get(`${API_URL}/dashboard/overview-grade`, params);
};

export const getOverviewTeachingGroup = (params) => {
  return get(`${API_URL}/dashboard/overview-teaching-group`, params);
};

export const getTeachingGroupCountLessons = (params) => {
  return get(`${API_URL}/teaching-group/dashboard-count-lesson`, params);
};

export const getLessonListBySession = (accountId, params) => {
  const originalParams = {
    orderBy: "name",
    ...params,
  };
  return get(`${API_URL}/accounts/${accountId}/lesson`, originalParams);
};
//#endregion

//#region School record
export const getSchoolReportOfStudent = (params) => {
  return get(`${API_URL}/school-record/get-school-record-of-student`, params);
};

export const getSchoolReportBySchoolYear = (params) => {
  return get(
    `${API_URL}/school-record/get-school-record-by-school-year`,
    params
  );
};
//#endregion

//#region Setting
export const filterSetting = (params = {}) => {
  return get(`${API_URL}/setting/filter`, {
    sort: "created_at desc",
    page: 1,
    size: 9999,
    ...params,
  });
};

export const createSetting = (params) => {
  return post(`${API_URL}/setting`, params);
};

export const updateSetting = (params) => {
  return put(`${API_URL}/setting/${params.id}`, params);
};

export const deleteSetting = (id) => {
  return remove(`${API_URL}/setting/${id}`);
};
//#endregion

//#region Score Group
export const filterScoreGroup = (params) => {
  return get(`${API_URL}/score-group/filter`, params);
};

export const getOneScoreGroup = (id) => {
  return get(`${API_URL}/score-group/get-one/${id}`);
};

export const createScoreGroup = (params) => {
  return post(`${API_URL}/score-group`, params);
};

export const updateScoreGroup = (params) => {
  return put(`${API_URL}/score-group/${params?.id}`, params);
};

export const deleteScoreGroup = (id) => {
  return remove(`${API_URL}/score-group/${id}`);
};
//#endregion

//#region Subject structure
export const filterSubjectStructure = (params) => {
  return get(`${API_URL}/subject-structure/filter`, params);
};

export const getOneSubjectStructure = (id) => {
  return get(`${API_URL}/subject-structure/get-one/${id}`);
};

export const createSubjectStructure = (params) => {
  return post(`${API_URL}/subject-structure`, params);
};

export const updateSubjectStructure = (params) => {
  return put(`${API_URL}/subject-structure/${params.id}`, params);
};

export const deleteSubjectStructure = (id) => {
  return remove(`${API_URL}/subject-structure/${id}`);
};
//#endregion

//#region Timetable
export const generateTimetable = (params) => {
  return post(`${API_URL}/timetable/generate`, params);
};

export const getTimetableOfGrade = (params) => {
  return get(`${API_URL}/timetable/timetable-of-grade`, params);
};

export const getTimetableOfClass = (key) => {
  return get(
    `${API_URL_TEST}/accounts/:account_id/timetable?filters[classes.id]=${key}`
  );
};

export const applyGeneratedTimetableAPI = (params) => {
  return post(`${API_URL}/timetable`, params);
};
//#endregion

//#region Timetable setting
export const getTimetableSetting = (params) => {
  return get(`${API_URL}/timetable-setting`, params);
};

export const createTimetableSetting = (params) => {
  return post(`${API_URL}/timetable-setting`, params);
};
//#endregion

export const getTeacherTimetable = (params) => {
  return get(`${API_URL}/timetable/timetable-of-teacher`, params);
};

export const checkCloseSemester = (params) => {
  return post(`${API_URL}/average-transcript/check-exist`, params);
};

export const closeSemesterAPI = (params) => {
  return post(`${API_URL}/close-semester`, params);
};

export const filterCertificate = (params) => {
  return get(`${API_URL}/study-summary/certificate-of-merit`, params);
};

//#region Type staff
export const filterTypeStaff = (params) => {
  return get(`${API_URL}/type-staff/filter`, params);
};

export const getOneTypeStaff = (id) => {
  return get(`${API_URL}/type-staff/get-one/${id}`);
};

export const createTypeStaff = (params) => {
  return post(`${API_URL}/type-staff`, params);
};

export const updateTypeStaff = (params) => {
  return put(`${API_URL}/type-staff/${params.id}`, params);
};

export const deleteTypeStaff = (id) => {
  return remove(`${API_URL}/type-staff/${id}`);
};
//#endregion

//#region reason
export const filterReason = (params) => {
  return get(`${API_URL}/reason/filter`, params);
};

export const createReason = (params) => {
  return post(`${API_URL}/reason`, params);
};

export const updateReason = (params) => {
  return put(`${API_URL}/reason/${params?.id}`, params);
};

export const deleteReason = (id) => {
  return remove(`${API_URL}/reason/${id}`);
};
//#endregion

export const studentQuitAndSuspendAPI = (params) => {
  return post(`${API_URL}/out-of-school`, params);
};

export const filterTimeline = (params) => {
  return get(`${API_URL}/timeline/filter`, params);
};

//#region Type Area
export const filterTypeArea = (params) => {
  return get(`${API_URL}/type-area/filter`, params);
};

export const getOneTypeArea = (id) => {
  return get(`${API_URL}/type-area/get-one/${id}`);
};

export const createTypeArea = (params) => {
  return post(`${API_URL}/type-area`, params);
};

export const updateTypeArea = (params) => {
  return put(`${API_URL}/type-area/${params?.id}`, params);
};

export const deleteTypeArea = (id) => {
  return remove(`${API_URL}/type-area/${id}`);
};
//#endregion

//#region Area
export const filterArea = (params) => {
  return get(`${API_URL}/area/filter`, params);
};

export const getOneArea = (id) => {
  return get(`${API_URL}/area/get-one/${id}`);
};

export const createArea = (params) => {
  return post(`${API_URL}/area`, params);
};

export const updateArea = (params) => {
  return put(`${API_URL}/area/${params?.id}`, params);
};

export const deleteArea = (id) => {
  return remove(`${API_URL}/area/${id}`);
};
//#endregion

export const massUploadStudent = (params) => {
  let newParams = new FormData();
  newParams.append("file", params.file);
  newParams.append("school_year_id", params.school_year_id);
  newParams.append("object_group", params.object_group);
  return post(
    `${MASS_UPLOAD_URL}/import/student`,
    newParams,
    {
      "Content-Type": "multipart/form-data",
    },
    false
  );
};

export const massUploadTeacher = (params) => {
  let newParams = new FormData();
  newParams.append("file", params.file);
  newParams.append("school_id", params.school_id);
  return post(
    `${MASS_UPLOAD_URL}/import/staff`,
    newParams,
    {
      "Content-Type": "multipart/form-data",
    },
    false
  );
};

export const massUploadTranscript = (params) => {
  let newParams = new FormData();
  newParams.append("file", params?.file);
  newParams.append("semester", params?.semester);
  newParams.append("class_id", params?.class_id);
  newParams.append("subject_id", params?.subject_id);
  newParams.append("school_year_id", params?.school_year_id);
  return post(
    `${MASS_UPLOAD_URL}/import/transcript`,
    newParams,
    {
      "Content-Type": "multipart/form-data",
    },
    false
  );
};

export const massUploadConduct = (params) => {
  let newParams = new FormData();
  newParams.append("file", params?.file);
  newParams.append("school_year_id", params?.school_year_id);
  newParams.append("class_id", params?.class_id);
  newParams.append("semester", params?.semester);
  return post(
    `${MASS_UPLOAD_URL}/import/student-conduct`,
    newParams,
    {
      "Content-Type": "multipart/form-data",
    },
    false
  );
};

export const massUploadTeachingDistribution = (params) => {
  let newParams = new FormData();
  newParams.append("file", params.file);
  newParams.append("school_year_id", params.school_year_id);
  return post(
    `${MASS_UPLOAD_URL}/import/teaching-distribution`,
    newParams,
    {
      "Content-Type": "multipart/form-data",
    },
    false
  );
};

//#region student-capacity-quality
export const filterStudentCapacityQuality = (params) => {
  return get(`${API_URL}/student-capacity-quality/filter`, params);
};

export const createUpdateStudentCapacityQuality = (params) => {
  return post(`${API_URL}/student-capacity-quality`, params);
};
//#endregion

//#region Study comment setting
export const createCommentSetting = (params) => {
  return post(`${API_URL}/study-comment-setting`, params);
};

export const updateCommentSetting = (params) => {
  return put(`${API_URL}/study-comment-setting/${params?.id}`, params);
};

export const getOneCommentSetting = (id) => {
  return get(`${API_URL}/study-comment-setting/get-one/${id}`);
};

export const getCommentSettingBySubject = (params) => {
  return get(`${API_URL}/study-comment-setting/get-by-subject`, params);
};

export const filterCommentSetting = (params) => {
  return get(`${API_URL}/study-comment-setting/filter`, params);
};

export const deleteCommentSetting = (id) => {
  return remove(`${API_URL}/study-comment-setting/${id}`);
};

export const updateMultiComment2 = (params) => {
  return post(`${API_URL}/study-comment/update-multi`, params);
};
//#endregion

//#region Profession Assessment Criteria
export const getProfessionAssessmentCriteria = (params) => {
  return get(`${API_URL}/staff/staff-profession-assessment-criteria?`, params);
};

export const getProfessionAssessmentCriteriaStaff = (params) => {
  return get(`${API_URL}/profession-assessment-criteria/staff`, params);
};

export const getOneProfessionAssessmentCriteriaStaff = (id) => {
  return get(`${API_URL}/profession-assessment-criteria/get-one/${id}`);
};

export const saveMultiDetailProfessionAssessmentCriteriaStaff = (params) => {
  return post(
    `${API_URL}/profession-assessment-criteria-detail/save-multi`,
    params
  );
};
//#endregion

//#region Forgot password
export const forgotPassword = (params) => {
  return post(`${API_URL}/user/forgot-password`, params);
};

export const verifyOTP = (params) => {
  return post(`${API_URL}/otp/verify`, params);
};

export const resetPassword = (params) => {
  return post(`${API_URL}/user/reset-password`, params);
};
//#endregion

//#region Login SSO
export const loginSSO = (params) => {
  return get(`${API_URL}/school/login-sso`, params);
};
//#endregion

export const cohotaSyncDataAPI = (params) => {
  return post(`${API_URL}/integrate/push-unintegreate-cohota-data`, params);
};

export const checkIntegrateCohotaAPI = (params) => {
  return post(`${API_URL}/integrate/check-integreate-cohota`, params);
};

export const pullScoreCohotaAPI = (params) => {
  return post(`${API_URL}/integrate/pull-score-cohota`, params);
};

export const checkPullScoreCohotaAPI = (params) => {
  return post(`${API_URL}/integrate/check-pull-score-cohota`, params);
};

//#region Capacity quality management
export const getListCapacityQualityManagement = (params) => {
  return get(
    `${API_URL}/capacity-quality-management/get-list-by-grade`,
    params
  );
};
//#endregion

//#region Student capacity quality ps
export const studentCapacityQualityPsCreateMulti = (params) => {
  return post(`${API_URL}/student-capacity-quality-ps/create-multi`, params);
};
//#endregion

//#region Evaluate value
export const getListEvaluateValue = (params) => {
  return post(`${API_URL}/evaluate-value/get-list`, params);
};
//#endregion

//#region Study summary ps
export const calcStudySummaryPs = (params) => {
  return post(`${API_URL}/study-summary-ps/calc-study-summary-ps`, params);
};

export const getStudySummaryColumns = (params) => {
  return post(`${API_URL}/study-summary-ps/get-study-summary-columns`, params);
};

export const filterCertificatePs = (params) => {
  return get(`${API_URL}/study-summary-ps/certificate-of-merit-ps`, params);
};
//#endregion

//#region
export const filterStudentCapacityQualityPs = (params) => {
  return get(`${API_URL}/student-capacity-quality-ps/filter`, params);
};
//#endregion

export const getListTeachingDistributionForView = (params) => {
  return post(`${API_URL}/teaching-distribution-for-view/get-list`, params);
};

export const getOauthJWT = (params) => {
  return get(`${API_URL}/auth/google/callback${params}`);
};

export const redirectAuth2 = `${API_URL}/connect/google`;

export const getTeacherData = () => {
  return get(`${API_URL}/users/me`);
};

export const updateUser = (accountId, id, body) => {
  return put(`${API_URL}/accounts/${accountId}/staff/${id}`, body);
};

export const getEthnics = () => {
  return get(`${API_URL}/ethnics`);
};

export const getReligions = () => {
  return get(`${API_URL}/religions`);
};

export const createAnnouncement = (accountId, body) => {
  return post(`${API_URL}/accounts/${accountId}/announcements`, body);
};

export const getStaffList = (accountId, params) => {
  return get(`${API_URL}/accounts/${accountId}/staff`, params);
};

export const getStudentList = (accountId, params) => {
  return get(`${API_URL}/accounts/${accountId}/students`, params);
};

// export const getStudentList = (search, current, size) => {
//   if (search) {
//     if (search.match(/^\d/))
//       return get(
//         `${API_URL}/accounts/1/students?populate=["classes"]&filters[classes.name][$contains]=${search}&pagination[page]=${current}&pagination[pageSize]=${size}`
//       );
//     return get(
//       `${API_URL}/accounts/1/students?filters[last_name][$contains]=${search}&pagination[page]=${current}&pagination[pageSize]=${size}`
//     );
//   }
//   return get(
//     `${API_URL}/accounts/1/students?pagination[page]=${current}&pagination[pageSize]=${size}`
//   );
// };

export const deleteStaff = (id) => {
  return remove(`${API_URL}/accounts/1/staff/${id}`);
};

export const deleteStudent = (id) => {
  return remove(`${API_URL}/accounts/1/students/${id}`);
};

// teaching management
export const getRegisterNotebookList = (accountId, params) => {
  return get(`${API_URL}/accounts/${accountId}/register_notebooks`, params);
};
export const getLessonList = (accountId, params) => {
  const originalParams = {
    orderBy: "name",
    ...params,
  };
  return get(`${API_URL}/accounts/${accountId}/lesson`, originalParams);
};
export const getRegisterNotebookRecordsById = (
  accountId,
  registerNotebookId,
  params
) => {
  return get(
    `${API_URL}/accounts/${accountId}/register_notebook_records/${registerNotebookId}`,
    params
  );
};

export const getRegisterNotebookRecords = (accountId, params) => {
  return get(
    `${API_URL}/accounts/${accountId}/register_notebook_records/`,
    params
  );
};
export const getRegisterNotebookRecordsByTeacher = (
  accountId,
  teacher_id,
  params
) => {
  return get(
    `${API_URL}/accounts/${accountId}/teacher/${teacher_id}/register_notebook_records/`,
    params
  );
};

export const getGradeList = (accountId, params) => {
  const originalParams = {
    orderBy: "name",
    ...params,
  };
  return get(`${API_URL}/accounts/${accountId}/grades`, originalParams);
};

export const getClassListByTeacher = (accountId, teacherId, params) => {
  const originalParams = {
    // orderBy: "name",
    ...params,
  };
  return get(
    `${API_URL}/accounts/${accountId}/teacher/classes/${teacherId}`,
    originalParams
  );
};

export const getClassList = (accountId, params) => {
  const originalParams = {
    orderBy: "name",
    ...params,
  };
  return get(`${API_URL}/accounts/${accountId}/classes`, originalParams);
};

export const getClassListByGrade = (accountId, gradeId, params) => {
  const originalParams = {
    orderBy: "name",
    ...params,
  };
  return get(
    `${API_URL}/accounts/${accountId}/grade/classes/${gradeId}`,
    originalParams
  );
};

export const getSubjectList = (accountId, params) => {
  return get(`${API_URL}/accounts/${accountId}/subjects`, params);
};

export const getStudentListByClass = (accountId, classId, params) => {
  return get(
    `${API_URL}/accounts/${accountId}/classes/${classId}/students`,
    params
  );
};
export const getClassId = (accountId, classId, params) => {
  return get(`${API_URL}/accounts/${accountId}/classes`, params);
};

export const getRuleList = (accountId, params) => {
  return get(`${API_URL}/accounts/${accountId}/rules`, params);
};
export const getRegisterNotebookRuleList = (accountId, params) => {
  return get(
    `${API_URL}/accounts/${accountId}/register_notebook_rules`,
    params
  );
};

export const getLessonEvaluationList = (accountId, params) => {
  return get(`${API_URL}/accounts/${accountId}/lesson/evaluations`, params);
};

export const updateOneRegisterNotebookRecord = (
  accountId,
  registerNotebookId,
  body
) => {
  return put(
    `${API_URL}/accounts/${accountId}/register_notebook_records/${registerNotebookId}`,
    body
  );
};

export const createOneRegisterNotebookRecord = (accountId, body) => {
  return post(
    `${API_URL}/accounts/${accountId}/register_notebook_records`,
    body
  );
};

export const getSessionList = (accountId, params) => {
  const originalParams = {
    orderBy: "id",
    ...params,
  };
  return get(`${API_URL}/accounts/${accountId}/sessions`, originalParams);
};

export const getAttendanceList = (accountId, params) => {
  return get(`${API_URL}/accounts/${accountId}/attendances`, params);
};

export const getAttendanceListByClass = (accountId, classId, params) => {
  return get(
    `${API_URL}/accounts/${accountId}/classes/${classId}/attendances`,
    params
  );
};

export const updateAttendanceOneStudent = (accountId, id, body) => {
  return put(`${API_URL}/accounts/${accountId}/attendance_by_date/${id}`, body);
};

export const createAttendanceOneStudent = (accountId, body) => {
  return post(`${API_URL}/accounts/${accountId}/attendance_by_date`, body);
};

export const getAttendanceEvaluationList = (accountId, params) => {
  return get(
    `${API_URL}/accounts/${accountId}/attendances/evaluations`,
    params
  );
};

export const updateAttendanceEvaluations = (accountId, id, body) => {
  return put(
    `${API_URL}/accounts/${accountId}/attendances/evaluations/${id}`,
    body
  );
};

export const exportAttendance = (accountId, params) => {
  return get(`${API_URL}/accounts/${accountId}/attendances/export`, params);
};

export const getMessageList = (params) => {
  return get(`${API_URL}/users/me/messages`, params);
};

export const getDetailStudent = (accountId, id, params) => {
  if (typeof accountId == "undefined") {
    const userInfo = JSON.parse(localStorage.getItem("user_info")) || [];
    accountId = userInfo?.account?.id;
  }
  return get(`${API_URL}/accounts/${accountId}/students/${id}`, params);
};

export const getTeachingDistributions = (accountId, params) => {
  return get(`${API_URL}/accounts/${accountId}/teaching_distributions`, params);
};

export const deleteTeachingDistribution = (accountId, id) => {
  return remove(
    `${API_URL}/accounts/${accountId}/teaching_distributions/${id}`
  );
};

export const deleteTeachingDistributionsOneStaff = (accountId, staffId) => {
  return remove(
    `${API_URL}/accounts/${accountId}/staff/${staffId}/teaching_distributions`
  );
};

export const createTeachingDistribution = (accountId, body) => {
  return post(`${API_URL}/accounts/${accountId}/teaching_distributions`, body);
};

export const updateTeachingDistribution = (accountId, id, body) => {
  return put(
    `${API_URL}/accounts/${accountId}/teaching_distributions/${id}`,
    body
  );
};

// menu
export const getMenu = (accountId, body) => {
  return get(`${API_URL}/accounts/${accountId}/menu`, body);
};

export const deleteMenu = (accountId, id) => {
  return remove(`${API_URL}/accounts/${accountId}/menu/${id}`);
};

export const createMenu = (accountId, body) => {
  return post(`${API_URL}/accounts/${accountId}/menu`, body);
};
export const postAdminCanteen = (accountId, body) => {
  return post(`${API_URL}/accounts/${accountId}/menu/makegrant`, body);
};

export const updateMenu = (accountId, id, body) => {
  return put(`${API_URL}/accounts/${accountId}/menu/${id}`, body);
};

// lunch-registry
export const getLunchRegistry = (accountId, body) => {
  return get(`${API_URL}/accounts/${accountId}/lunch-registry`, body);
};

export const createLunchRegistry = (accountId, body) => {
  return post(`${API_URL}/accounts/${accountId}/lunch-registry`, body);
};

export const updateLunchRegistry = (accountId, id, body) => {
  return put(`${API_URL}/accounts/${accountId}/lunch-registry/${id}`, body);
};

export const getStudentViolateRules = (accountId, params) => {
  return get(`${API_URL}/accounts/${accountId}/students-violate-rules`, params);
};
export const getStudentViolateRulesList = (accountId, params) => {
  return get(
    `${API_URL}/accounts/${accountId}/students-violate-rules-list`,
    params
  );
};
export const updateStudentViolateRules = (accountId, id, body) => {
  return put(
    `${API_URL}/accounts/${accountId}/students-violate-rules/${id}`,
    body
  );
};

export const createStudentViolateRules = (accountId, body) => {
  return post(`${API_URL}/accounts/${accountId}/students-violate-rules`, body);
};

// gradebook
export const getGradeBookByClassAndSubject = (
  accountId,
  classId,
  subjectId
) => {
  return get(
    `${API_URL}/accounts/${accountId}/classes/${classId}/subjects/${subjectId}/gradebook`
  );
};

export const getGradeBook = (accountId) => {
  return get(`${API_URL}/accounts/${accountId}/gradebooks`);
};

// getSubjects

export const getSubjectsByTeacher = (accountId, userId, params) => {
  return get(
    `${API_URL}/accounts/${accountId}/users/${userId}/subjects`,
    params
  );
};

export const getClassByUser = (accountId, userId) => {
  return get(`${API_URL}/accounts/${accountId}/classes/${userId}`);
};

// cotdiem
export const getGradeBookColumns = (accountId, classId, subjectId) => {
  return get(
    `${API_URL}/accounts/${accountId}/classes/${classId}/subjects/${subjectId}/gradebook_columns`
  );
};

export const updateGradeBookColumns = (accountId, body) => {
  return post(`${API_URL}/accounts/${accountId}/gradebooks`, body);
};

export const getClassByTeacher = (accountId, userId, gradeId) => {
  return get(
    `${API_URL}/accounts/${accountId}/teacher/classes/${userId}?filters[grades.id]=${gradeId}`
  );
};

export const getSubject = (accountId, subject_id) => {
  return get(`${API_URL}/accounts/${accountId}/subjects/${subject_id}`);
};

// roomAttendenced
export const getRoomAttendance = (accountId, teachingId) => {
  return get(`${API_URL}/accounts/${accountId}/teacher/boarding/${teachingId}`);
};

export const getAttendanceRoomId = (accountId, roomId) => {
  return get(
    `${API_URL}/accounts/${accountId}/boarding-room/${roomId}/students`
  );
};

export const getAttendanceByClassDate = (accountId, roomId, params) => {
  return get(
    `${API_URL}/accounts/${accountId}/boarding-room/${roomId}/attendance`,
    params
  );
};

export const getSignatures = (accountId, params) => {
  return get(`${API_URL}/accounts/${accountId}/signatures`, params);
};

export const updateSignatures = (accountId, id, body) => {
  return put(`${API_URL}/accounts/${accountId}/signatures/${id}`, body);
};
export const createSignatures = (accountId, body) => {
  return post(`${API_URL}/accounts/${accountId}/signatures`, body);
};

export const makeGrade = (accountId, body) => {
  return post(`${API_URL}/accounts/${accountId}/grader_support`, body);
};

export const importGrade = (accountId, body) => {
  return post(`${API_URL}/accounts/${accountId}/grader_support/import`, body);
};

export const getTerms = (accountId, params) => {
  return get(`${API_URL}/accounts/${accountId}/terms`, params);
};

export const getAcademicColumns = (accountId, params) => {
  return get(
    `${API_URL}/accounts/${accountId}/academic_program_columns`,
    params
  );
};

export const updateAttendanceDaySchool = (accountId, id, params) => {
  return put(`${API_URL}/accounts/${accountId}/attendance/${id}`, params);
};

// sms

export const getGroupSms = (accountId, params) => {
  return get(`${API_URL}/accounts/${accountId}/announcement_group`, params);
};
export const postGroupSms = (accountId) => {
  return post(`${API_URL}/accounts/${accountId}/announcements`);
};
