import { HEADER_TYPE } from "mobile/util/mobileConstant";
import React, { forwardRef } from "react";
import BrandnameHeader from "./component/BrandnameHeader/BrandnameHeader";
import MiddleTitleHeader from "./component/MiddleTitleHeader/MiddleTitleHeader";

function MobileHeader(
  {
    type = HEADER_TYPE.middleTitle,
    title = "",
    haveNotiButton = true,
    haveBackButton = true,
  },
  ref
) {
  // #region    VARIABLES //////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion VARIABLES //////////////////////////

  // #region    useEffect //////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion useEffect //////////////////////////

  // #region    FUNCTIONS //////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion FUNCTIONS //////////////////////////

  // #region    VIEWS //////////////////////////////
  //////////////////////////////////////////////////
  const renderHeader = () => {
    switch (type) {
      case HEADER_TYPE.brandname:
        return <BrandnameHeader />;
      case HEADER_TYPE.middleTitle:
        return (
          <MiddleTitleHeader
            title={title}
            haveNotiButton={haveNotiButton}
            haveBackButton={haveBackButton}
          />
        );
      default:
        return null;
    }
  };
  //////////////////////////////////////////////////
  // #endregion VIEWS //////////////////////////////
  return <div ref={ref}>{renderHeader()}</div>;
}

export default forwardRef(MobileHeader);
