import { Form, message } from "antd";
import { newEditIcon, tickGreen, xRed } from "asset/img/Images";
import { isPermitted } from "authen/authenHelper";
import InfoFieldCheckbox from "common/CusInfoField/infoFieldCheckbox/InfoFieldCheckbox";
import InfoFieldDatePicker from "common/CusInfoField/infoFieldDatePicker/InfoFieldDatePicker";
import InfoFieldNumber from "common/CusInfoField/infoFieldNumber";
import InfoFieldSelect from "common/CusInfoField/infoFieldSelect/InfoFieldSelect";
import InfoFieldSpan from "common/CusInfoField/infoFieldSpan/InfoFieldSpan";
import InfoFieldText from "common/CusInfoField/infoFieldText/InfoFieldText";
import InfoSection from "common/CusInfoField/infoSection/InfoSection";
import RectangleTabs from "common/RectangleTabs";
import CustomBreadcrumb from "common/breadcrumb/CustomBreadcrumb";
import StyledButton from "common/button/StyledButton";
import Img from "common/image/Img";
import ImagePicker from "common/imagePicker/ImagePicker";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { getSetting } from "util/APIHelper";
import {
  filterSubjectManagement,
  getCountryList,
  getDistrictByProvince,
  getProvinceByCountry,
  getSubDistrictByDistrict,
  updateStaff,
  updateStudent,
  getEthnics,
  getReligions,
  getDetailStudent,
} from "util/APIs";
import {
  DATE_FORMAT,
  GENDER,
  OBJECT_TYPE_BE,
  PERMISSION_OBJECT,
  PERMISSION_TYPE,
  SETTING_GROUP_KEYS,
  TABLE_PAGE_SIZES,
  is1920OrMore,
} from "util/constant";
// import { disableFutureDate, sanitize } from "util/helper";
// import {
//   teacherArrayFields,
//   teacherStringFields,
//   teacherZeroFields,
//   validateTeacher,
// } from "util/validate/teacher";
import styles from "./styles.module.css";
import { ID_ISSUED_PLACES } from "util/constant";
import { findEthnicAndReligion } from "util/helper";
import { useSelector } from "react-redux";
import { routes } from "util/routes";
import { Notification } from "common/Notification";
import CusTable from "common/CusTable";

export default function StudentDetailEditPage() {
  // #region    VARIABLES //////////////////////////
  //////////////////////////////////////////////////
  const isAllowToUpdate = isPermitted(
    PERMISSION_OBJECT.students_management,
    PERMISSION_TYPE.update
  );
  const TABS = [
    {
      id: "personal",
      name: "Thông tin cá nhân",
    },
    {
      id: "parent",
      name: "Thông tin phụ huynh",
    },
    {
      id: "evaluation",
      name: "Thông tin vi phạm",
    },
  ];
  const history = useHistory();
  const [form] = Form.useForm();
  const { studentId } = useParams();
  const userInfo = useSelector((state) => state?.app?.userInfo);
  const accountId = userInfo?.account?.id;
  const [isEditting, setIsEditting] = useState(false);
  const [currentTab, setCurrentTab] = useState(TABS[0]);
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // Personal fields - 1
  const [datas, setDatas] = useState([]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dob, setDob] = useState("");
  const [gender, setGender] = useState("");
  const [ethnic, setEthnic] = useState("");
  const [ethnicList, setEthnicList] = useState([]);
  const [religion, setReligion] = useState("");
  const [religionList, setReligionList] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [idNo, setIdNo] = useState("");
  const [idIssueDate, setIdIssueDate] = useState(undefined);
  const [idIssuePlace, setIdIssuePlace] = useState(undefined);
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // Contact fields - 1
  const [placeOfPermanentProvinceList, setPlaceOfPermanentProvinceList] =
    useState([]);
  const [placeOfPermanentProvince, setPlaceOfPermanentProvince] =
    useState(undefined);
  const [placeOfPermanentDistrictList, setPlaceOfPermanentDistrictList] =
    useState([]);
  const [placeOfPermanentDistrict, setPlaceOfPermanentDistrict] =
    useState(undefined);
  const [placeOfPermanentSubDistrictList, setPlaceOfPermanentSubDistrictList] =
    useState([]);
  const [placeOfPermanentSubDistrict, setPlaceOfPermanentSubDistrict] =
    useState(undefined);
  const [placeOfPermanentQuarterHamlet, setPlaceOfPermanentQuarterHamlet] =
    useState("");
  const [placeOfPermanentDescription, setPlaceOfPermanentDescription] =
    useState("");
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // Contact fields - 2
  const [placeOfTemporaryProvinceList, setPlaceOfTemporaryProvinceList] =
    useState([]);
  const [placeOfTemporaryProvince, setPlaceOfTemporaryProvince] =
    useState(undefined);
  const [placeOfTemporaryDistrictList, setPlaceOfTemporaryDistrictList] =
    useState([]);
  const [placeOfTemporaryDistrict, setPlaceOfTemporaryDistrict] =
    useState(undefined);
  const [placeOfTemporarySubDistrictList, setPlaceOfTemporarySubDistrictList] =
    useState([]);
  const [placeOfTemporarySubDistrict, setPlaceOfTemporarySubDistrict] =
    useState(undefined);
  const [placeOfTemporaryQuarterHamlet, setPlaceOfTemporaryQuarterHamlet] =
    useState("");
  const [placeOfTemporaryDescription, setPlaceOfTemporaryDescription] =
    useState("");
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // Mother Information
  const [motherFirstName, setMotherFirstName] = useState("");
  const [motherLastName, setMotherLastName] = useState("");
  const [motherJob, setMotherJob] = useState("");
  const [motherDob, setMotherDob] = useState("");
  const [motherPhoneNumber, setMotherPhoneNumber] = useState("");
  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(TABLE_PAGE_SIZES[0]);
  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // Father Information
  const [fatherFirstName, setFatherFirstName] = useState("");
  const [fatherLastName, setFatherLastName] = useState("");
  const [fatherJob, setFatherJob] = useState("");
  const [fatherDob, setFatherDob] = useState("");
  const [fatherPhoneNumber, setFatherPhoneNumber] = useState("");
  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const personalFields = [
    {
      title: "",
      fields: [
        <InfoFieldText
          label="Họ"
          isRequired={true}
          value={firstName}
          setValue={setFirstName}
          placeholder="Nhập họ"
          direction="vertical"
          disabled={!isEditting}
        />,
        <InfoFieldText
          label="Tên"
          isRequired={true}
          value={lastName}
          setValue={setLastName}
          placeholder="Nhập tên"
          direction="vertical"
          disabled={!isEditting}
        />,
        <InfoFieldDatePicker
          label="Ngày sinh"
          value={dob}
          setValue={setDob}
          placeholder="Ngày/tháng/năm"
          direction="vertical"
          disabled={!isEditting}
        />,
        <InfoFieldSelect
          label="Giới tính"
          data={GENDER}
          value={gender}
          setValue={setGender}
          placeholder="Chọn giới tính"
          direction="vertical"
          disabled={!isEditting}
          valueTextName="name"
          valueFieldName="value"
        />,
        <InfoFieldSelect
          label="Dân tộc"
          data={ethnicList}
          value={ethnic}
          setValue={setEthnic}
          placeholder="Chọn dân tộc"
          direction="vertical"
          disabled={!isEditting}
          valueTextName="name"
          valueFieldName="id"
        />,
        <InfoFieldSelect
          label="Tôn giáo"
          data={religionList}
          value={religion}
          setValue={setReligion}
          placeholder="Chọn tôn giáo"
          direction="vertical"
          disabled={!isEditting}
          valueTextName="name"
          valueFieldName="id"
        />,
        <InfoFieldText
          label="Số điện thoại"
          value={phoneNumber}
          setValue={setPhoneNumber}
          placeholder="Nhập số điện thoại"
          direction="vertical"
          disabled={!isEditting}
        />,
        <InfoFieldText
          label="Email"
          value={email}
          setValue={setEmail}
          placeholder="Nhập Email"
          direction="vertical"
          disabled={!isEditting || !isAllowToUpdate}
        />,
        <InfoFieldText
          label="CMND / CCCD"
          value={idNo}
          setValue={setIdNo}
          placeholder="Nhập số CMND / CCCD"
          direction="vertical"
          disabled={!isEditting}
        />,
        <InfoFieldDatePicker
          label="Ngày cấp"
          value={idIssueDate}
          setValue={setIdIssueDate}
          placeholder="Ngày/tháng/năm"
          direction="vertical"
          disabled={!isEditting}
        />,
        <InfoFieldSelect
          label="Nơi cấp"
          data={ID_ISSUED_PLACES}
          value={idIssuePlace}
          setValue={setIdIssuePlace}
          placeholder="Chọn nơi cấp CMND / CCCD"
          direction="vertical"
          disabled={!isEditting}
          valueTextName="name"
          valueFieldName="id"
        />,
      ],
    },
  ];
  const contactFields = [
    {
      title: "Địa chỉ thường trú",
      fields: [
        <InfoFieldText
          label="Quốc gia"
          placeholder="Việt Nam"
          direction="vertical"
          disabled={true}
        />,
        <InfoFieldSelect
          label="Tỉnh / TP"
          isRequired={true}
          data={placeOfPermanentProvinceList}
          value={placeOfPermanentProvince}
          setValue={setPlaceOfPermanentProvince}
          placeholder="Chọn tỉnh / thành phố"
          direction="vertical"
          disabled={!isEditting}
          valueTextName="name"
          valueFieldName="code"
        />,
        <InfoFieldSelect
          label="Quận / Huyện"
          isRequired={true}
          data={placeOfPermanentDistrictList}
          value={placeOfPermanentDistrict}
          setValue={setPlaceOfPermanentDistrict}
          placeholder="Chọn quận / huyện"
          direction="vertical"
          disabled={!isEditting || !placeOfPermanentProvince}
          valueTextName="name"
          valueFieldName="code"
        />,
        <InfoFieldSelect
          label="Phường / Xã"
          isRequired={true}
          data={placeOfPermanentSubDistrictList}
          value={placeOfPermanentSubDistrict}
          setValue={setPlaceOfPermanentSubDistrict}
          placeholder="Chọn phường / xã"
          direction="vertical"
          disabled={
            !isEditting ||
            !placeOfPermanentProvince ||
            !placeOfPermanentDistrict
          }
          valueTextName="name"
          valueFieldName="code"
        />,
        <InfoFieldText
          label="Khu phố / Thôn"
          value={placeOfPermanentQuarterHamlet}
          setValue={setPlaceOfPermanentQuarterHamlet}
          placeholder="Nhập khu phố / thôn"
          direction="vertical"
          disabled={!isEditting}
        />,
        <InfoFieldText
          label="Số nhà"
          value={placeOfPermanentDescription}
          setValue={setPlaceOfPermanentDescription}
          placeholder="Nhập số nhà"
          direction="vertical"
          disabled={!isEditting}
        />,
      ],
    },
  ];
  const contactFields2 = [
    {
      title: "Địa chỉ tạm trú",
      fields: [
        <InfoFieldText
          label="Quốc gia"
          placeholder="Việt Nam"
          direction="vertical"
          disabled={true}
        />,
        <InfoFieldSelect
          label="Tỉnh / TP"
          isRequired={true}
          data={placeOfTemporaryProvinceList}
          value={placeOfTemporaryProvince}
          setValue={setPlaceOfTemporaryProvince}
          placeholder="Chọn tỉnh / thành phố"
          direction="vertical"
          disabled={!isEditting}
          valueTextName="name"
          valueFieldName="code"
        />,
        <InfoFieldSelect
          label="Quận / Huyện"
          isRequired={true}
          data={placeOfTemporaryDistrictList}
          value={placeOfTemporaryDistrict}
          setValue={setPlaceOfTemporaryDistrict}
          placeholder="Chọn quận / huyện"
          direction="vertical"
          disabled={!isEditting || !placeOfTemporaryProvince}
          valueTextName="name"
          valueFieldName="code"
        />,
        <InfoFieldSelect
          label="Phường / Xã"
          isRequired={true}
          data={placeOfTemporarySubDistrictList}
          value={placeOfTemporarySubDistrict}
          setValue={setPlaceOfTemporarySubDistrict}
          placeholder="Chọn phường / xã"
          direction="vertical"
          disabled={
            !isEditting ||
            !placeOfTemporaryProvince ||
            !placeOfTemporaryDistrict
          }
          valueTextName="name"
          valueFieldName="code"
        />,
        <InfoFieldText
          label="Khu phố / Thôn"
          value={placeOfTemporaryQuarterHamlet}
          setValue={setPlaceOfTemporaryQuarterHamlet}
          placeholder="Nhập khu phố / thôn"
          direction="vertical"
          disabled={!isEditting}
        />,
        <InfoFieldText
          label="Số nhà"
          value={placeOfTemporaryDescription}
          setValue={setPlaceOfTemporaryDescription}
          placeholder="Nhập số nhà"
          direction="vertical"
          disabled={!isEditting}
        />,
      ],
    },
  ];
  const motherFields = [
    {
      title: "Thông tin mẹ",
      fields: [
        <InfoFieldText
          label="Họ"
          isRequired={true}
          value={motherFirstName}
          setValue={setMotherFirstName}
          placeholder="Nhập họ"
          direction="vertical"
          disabled={!isEditting}
        />,
        <InfoFieldText
          label="Tên"
          isRequired={true}
          value={motherLastName}
          setValue={setMotherLastName}
          placeholder="Nhập tên"
          direction="vertical"
          disabled={!isEditting}
        />,
        <InfoFieldDatePicker
          label="Ngày sinh"
          value={motherDob}
          setValue={setMotherDob}
          placeholder="Ngày/tháng/năm"
          direction="vertical"
          disabled={!isEditting}
        />,
        <InfoFieldText
          label="Công việc"
          value={motherJob}
          setValue={setMotherJob}
          placeholder="Nhập công việc"
          direction="vertical"
          disabled={!isEditting}
        />,
        <InfoFieldText
          label="Số điện thoại"
          value={motherPhoneNumber}
          setValue={setMotherPhoneNumber}
          placeholder="Nhập số điện thoại"
          direction="vertical"
          disabled={!isEditting}
        />,
      ],
    },
  ];
  const fatherFields = [
    {
      title: "Thông tin cha",
      fields: [
        <InfoFieldText
          label="Họ"
          isRequired={true}
          value={fatherFirstName}
          setValue={setFatherFirstName}
          placeholder="Nhập họ"
          direction="vertical"
          disabled={!isEditting}
        />,
        <InfoFieldText
          label="Tên"
          isRequired={true}
          value={fatherLastName}
          setValue={setFatherLastName}
          placeholder="Nhập tên"
          direction="vertical"
          disabled={!isEditting}
        />,
        <InfoFieldDatePicker
          label="Ngày sinh"
          value={fatherDob}
          setValue={setFatherDob}
          placeholder="Ngày/tháng/năm"
          direction="vertical"
          disabled={!isEditting}
        />,
        <InfoFieldText
          label="Công việc"
          value={fatherJob}
          setValue={setFatherJob}
          placeholder="Nhập công việc"
          direction="vertical"
          disabled={!isEditting}
        />,
        <InfoFieldText
          label="Số điện thoại"
          value={fatherPhoneNumber}
          setValue={setFatherPhoneNumber}
          placeholder="Nhập số điện thoại"
          direction="vertical"
          disabled={!isEditting}
        />,
      ],
    },
  ];

  const responsive = {
    setResponsiveCol: () => {
      if (!is1920OrMore) {
        // columns[1].width = 120;
        // columns[2].width = 120;
        // columns[3].width = 120;
      }
    },
    nodeWrapperWidth: () => {
      if (is1920OrMore) return "560px";
      if (!is1920OrMore) return "560px";
    },
    tableScroll: () => {
      if (is1920OrMore) return {};
      else
        return {
          x: 1000,
          y: 490,
        };
    },
  };

  const totalScore = datas.reduce((sum, record) => {
    if (record.rules) {
      return (
        sum +
        record.rules.reduce((recordSum, item) => recordSum + item.score, 0)
      );
    }
    return sum;
  }, 0);

  const columns = [
    {
      title: "STT",
      align: "center",
      key: "STT",
      render: (value, record, index) =>
        (currentPage - 1) * pageSize.value + index + 1,
      width: "50px",
      fixed: "left",
    },
    {
      title: "Ngày vi phạm",
      dataIndex: "date",
      align: "center",
      render: (text) => (
        <span>{text ? moment(text).format(DATE_FORMAT) : "--"}</span>
      ),
      width: "15%",
    },
    {
      title: "Nội dung vi phạm",
      dataIndex: "rules",
      align: "center",
      render: (text) => {
        return (
          <div>
            {text &&
              text.map((item, index) => (
                <p key={item.id} className={`${styles.violateContent}`}>
                  <span className={`${styles.violateIndx}`}>{index + 1}.</span>
                  {item.title}
                </p>
              ))}
          </div>
        );
      },
    },
    {
      title: "Điểm trừ",
      dataIndex: "rules",
      align: "center",
      render: (text) => {
        return (
          <div>
            {text &&
              text.map((item, index) => (
                <p key={item.id} className={`${styles.score}`}>
                  {item.score}
                </p>
              ))}
          </div>
        );
      },
      width: "15%",
    },
  ];

  const handleUpdateStudent = () => {
    const params = {
      data: {
        first_name: firstName,
        last_name: lastName,
        dob: dob,
        gender: gender,
        ethnic: findEthnicAndReligion(ethnicList, ethnic),
        religion: findEthnicAndReligion(religionList, religion),
        phone_number: phoneNumber,
        email: email,
        citizen_identify: {
          id_no: idNo,
          issued_on: idIssueDate,
          place_of_issue: idIssuePlace,
        },
        permanent_address: {
          country: "Việt Nam",
          province_city: placeOfPermanentProvince,
          district: placeOfPermanentDistrict,
          sub_district: placeOfPermanentSubDistrict,
          quarter_hamlet: placeOfPermanentQuarterHamlet,
          description: placeOfPermanentDescription,
        },
        temporary_address: {
          country: "Việt Nam",
          province_city: placeOfTemporaryProvince,
          district: placeOfTemporaryDistrict,
          sub_district: placeOfTemporarySubDistrict,
          quarter_hamlet: placeOfTemporaryQuarterHamlet,
          description: placeOfTemporaryDescription,
        },
        parents_information: {
          mother_infomation: {
            first_name: motherFirstName,
            last_name: motherLastName,
            job: motherJob,
            dob: motherDob,
            phone_number: motherPhoneNumber,
          },
          father_infomation: {
            first_name: fatherFirstName,
            last_name: fatherLastName,
            job: fatherJob,
            dob: fatherDob,
            phone_number: fatherPhoneNumber,
          },
        },
      },
    };
    updateStudent(accountId, studentId, params).then((res) => {
      if (res?.statusText === "OK") {
        Notification.sendSuccess("Cập nhật thành công!");
        goBack();
        toggleEdit();
      }
    });
  };

  const toggleEdit = () => {
    setIsEditting(!isEditting);
  };

  const discardEdit = () => {
    setIsEditting(false);
  };

  const goBack = () => history.push(routes.STUDENT_INFO);

  const getData = useCallback(async () => {
    const res = await getDetailStudent(accountId, studentId);
    if (res?.status === 200) {
      const data = res?.data;
      if (data) {
        setDatas(res?.data?.attendance_evaluations);
        const citizen_identify = JSON.parse(data?.citizen_identify);
        const ethnic = JSON.parse(data?.ethnic);
        const parents_information = JSON.parse(data?.parents_information);
        const permanent_address = JSON.parse(data?.permanent_address);
        const religion = JSON.parse(data?.religion);
        const temporary_address = JSON.parse(data?.temporary_address);
        getEthnics().then((res) => {
          if (res?.statusText === "OK") {
            setEthnicList(res?.data);
          }
        });
        setEthnic(data?.user?.ethnic?.id);
        getReligions().then((res) => {
          if (res?.statusText === "OK") {
            setReligionList(res?.data?.data);
          }
        });
        setFirstName(data?.first_name);
        setLastName(data?.last_name);
        setDob(data?.dob);
        setGender(data?.gender);
        setEthnic(ethnic?.id);
        setReligion(religion?.id);
        setPhoneNumber(data?.phone_number);
        setEmail(data?.email);
        setIdNo(citizen_identify?.id_no);
        setIdIssueDate(citizen_identify?.issued_on);
        setIdIssuePlace(citizen_identify?.place_of_issue);

        getProvinceByCountry("vn").then((res) => {
          if (res?.statusText === "OK") {
            setPlaceOfPermanentProvinceList(res?.data);
          }
        });
        setPlaceOfPermanentProvince(permanent_address?.province_city);
        if (permanent_address?.province_city) {
          getDistrictByProvince(permanent_address?.province_city, "vn").then(
            (res) => {
              if (res?.statusText === "OK") {
                setPlaceOfPermanentDistrictList(res?.data);
              }
            }
          );
        }
        setPlaceOfPermanentDistrict(permanent_address?.district || null);
        if (permanent_address?.district) {
          getSubDistrictByDistrict(
            permanent_address?.district,
            permanent_address?.province_city,
            "vn"
          ).then((res) => {
            if (res?.statusText === "OK") {
              setPlaceOfPermanentSubDistrictList(res?.data);
            }
          });
        }
        setPlaceOfPermanentSubDistrict(permanent_address?.sub_district);
        setPlaceOfPermanentQuarterHamlet(permanent_address?.quarter_hamlet);
        setPlaceOfPermanentDescription(permanent_address?.description);
        ////////////////////////////////////////////////////////////////////////////////////////////////////

        ////////////////////////////////////////////////////////////////////////////////////////////////////
        getProvinceByCountry("vn").then((res) => {
          if (res?.statusText === "OK") {
            setPlaceOfTemporaryProvinceList(res?.data);
          }
        });
        setPlaceOfTemporaryProvince(temporary_address?.province_city);
        if (temporary_address?.province_city) {
          getDistrictByProvince(temporary_address?.province_city, "vn").then(
            (res) => {
              if (res?.statusText === "OK") {
                setPlaceOfTemporaryDistrictList(res?.data);
              }
            }
          );
        }
        setPlaceOfTemporaryDistrict(temporary_address?.district || null);
        if (temporary_address?.district) {
          getSubDistrictByDistrict(
            temporary_address?.district,
            temporary_address?.province_city,
            "vn"
          ).then((res) => {
            if (res?.statusText === "OK") {
              setPlaceOfTemporarySubDistrictList(res?.data);
            }
          });
        }
        setPlaceOfTemporarySubDistrict(temporary_address?.sub_district);
        setPlaceOfTemporaryQuarterHamlet(temporary_address?.quarter_hamlet);
        setPlaceOfTemporaryDescription(temporary_address?.description);
        ////////////////////////////////////////////////////////////////////////////////////////////////////

        ////////////////////////////////////////////////////////////////////////////////////////////////////
        setMotherFirstName(parents_information?.mother_infomation?.first_name);
        setMotherLastName(parents_information?.mother_infomation?.last_name);
        setMotherJob(parents_information?.mother_infomation?.job);
        setMotherDob(parents_information?.mother_infomation?.dob);
        setMotherPhoneNumber(
          parents_information?.mother_infomation?.phone_number
        );
        ////////////////////////////////////////////////////////////////////////////////////////////////////

        ////////////////////////////////////////////////////////////////////////////////////////////////////
        setFatherFirstName(parents_information?.father_infomation?.first_name);
        setFatherLastName(parents_information?.father_infomation?.last_name);
        setFatherJob(parents_information?.father_infomation?.job);
        setFatherDob(parents_information?.father_infomation?.dob);
        setFatherPhoneNumber(
          parents_information?.father_infomation?.phone_number
        );
      }
      // setDetailInfo(res?.data?.data);
    }
  }, [accountId, studentId]);

  const mergedColumns = columns.map((col) => {
    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
      }),
    };
  });

  useEffect(() => {
    getData();
  }, [getData]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (placeOfPermanentProvince !== undefined) {
      getDistrictByProvince(placeOfPermanentProvince, "vn").then((res) => {
        if (res?.statusText === "OK") {
          setPlaceOfPermanentDistrictList(res?.data);
        }
      });
    }
  }, [placeOfPermanentProvince]);

  useEffect(() => {
    if (placeOfPermanentDistrict !== undefined) {
      getSubDistrictByDistrict(
        placeOfPermanentDistrict,
        placeOfPermanentProvince,
        "vn"
      ).then((res) => {
        if (res?.statusText === "OK") {
          setPlaceOfPermanentSubDistrictList(res?.data);
        }
      });
    }
  }, [placeOfPermanentDistrict, placeOfPermanentProvince]);

  useEffect(() => {
    if (placeOfTemporaryProvince !== undefined) {
      getDistrictByProvince(placeOfTemporaryProvince, "vn").then((res) => {
        if (res?.statusText === "OK") {
          setPlaceOfTemporaryDistrictList(res?.data);
        }
      });
    }
  }, [placeOfTemporaryProvince]);

  useEffect(() => {
    if (placeOfTemporaryDistrict !== undefined) {
      getSubDistrictByDistrict(
        placeOfTemporaryDistrict,
        placeOfTemporaryProvince,
        "vn"
      ).then((res) => {
        if (res?.statusText === "OK") {
          setPlaceOfTemporarySubDistrictList(res?.data);
        }
      });
    }
  }, [placeOfTemporaryDistrict, placeOfTemporaryProvince]);

  return (
    <div className={`flex flex-col h-full ${styles.root}`}>
      <div className="flex justify-between">
        <CustomBreadcrumb
          crumbs={["/teacher-detail/edit"]}
          style={`${styles.breadcrumb}`}
        />
        <StyledButton onClick={goBack} theme="blue">
          Quay về
        </StyledButton>
      </div>
      <div
        // className={`flex mb-4 w-full ${styles.heightWrap}`}
        className="flex mb-4 w-full"
      >
        <div className={"pb-8 section-shadow h-full w-full"}>
          <div
            className={`grid grid-cols-3 gap-x-8 gap-y-3 px-8 py-4 ${styles.background}`}
          >
            <div />
            <div />
            <div className={`flex justify-end`}>
              {!isEditting && currentTab?.id !== TABS[2].id && (
                <button onClick={toggleEdit} className={styles.btn}>
                  <Img
                    src={newEditIcon}
                    alt="Edit"
                    className={styles.bRadius8}
                  />
                </button>
              )}
              {isEditting && (
                <>
                  <div
                    className={`${styles.btn} mRight24`}
                    onClick={discardEdit}
                  >
                    <Img src={xRed} alt="Cancel" />
                  </div>
                  <div
                    className={`${styles.btn}`}
                    onClick={handleUpdateStudent}
                  >
                    <Img src={tickGreen} alt="Accept" />
                  </div>
                </>
              )}
            </div>
          </div>
          <RectangleTabs
            data={TABS}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            className="pl-8 bg-background"
          />
          <div
          // className={`${styles.heightWrap2} overflow-y-auto hide-scrollbar`}
          >
            {currentTab?.id === TABS[0].id && (
              <div className={styles.pTop20}>
                <InfoSection
                  title="THÔNG TIN CÁ NHÂN"
                  data={personalFields}
                  style={styles.mBottom24}
                />
                <InfoSection title="THÔNG TIN LIÊN HỆ" data={contactFields} />
                <InfoSection title="" data={contactFields2} />
              </div>
            )}
            {currentTab?.id === TABS[1].id && (
              <div className={styles.pTop20}>
                <InfoSection
                  title="THÔNG TIN PHỤ HUYNH"
                  data={motherFields}
                  style={styles.mBottom24}
                />
                <InfoSection title="" data={fatherFields} />
              </div>
            )}
            {currentTab?.id === TABS[2].id && (
              <div className={styles.pTop20}>
                <InfoSection title="THÔNG TIN VI PHẠM" />
                <div className={`mx-6 mt-5`}>
                  <Form form={form} component={false}>
                    <CusTable
                      rowKey="id"
                      data={datas}
                      columns={mergedColumns}
                      havePagination={false}
                      rowClassName="editable-row"
                      scroll={responsive.tableScroll()}
                    />
                  </Form>
                </div>
                <div className="mx-6 mt-5">
                  <span className={`${styles.totalscore}`}>
                    Tổng số điểm trừ: {totalScore}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
