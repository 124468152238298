import { useEffect, useState } from "react";

export default function IsMobile() {
  // #region    VARIABLES //////////////////////////
  //////////////////////////////////////////////////
  const [width, setWidth] = useState(window.innerWidth);
  //////////////////////////////////////////////////
  // #endregion VARIABLES //////////////////////////

  // #region    useEffect //////////////////////////
  //////////////////////////////////////////////////
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
  //////////////////////////////////////////////////
  // #endregion useEffect //////////////////////////

  // #region    FUNCTIONS //////////////////////////
  //////////////////////////////////////////////////
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  //////////////////////////////////////////////////
  // #endregion FUNCTIONS //////////////////////////

  return width < 768;
}
