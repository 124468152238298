import { message, Upload } from "antd";
import { cameraWhiteIcon } from "asset/img/Images";
import Img from "common/image/Img";
import React, { forwardRef } from "react";
import { uploadFile } from "util/APIs";
import { IMAGE_BASE_URL, IMAGE_TYPE } from "util/constant";
import styles from "./styles.module.css";

function ImagePicker(
  {
    imageUrl,
    setImageUrl,
    alt,
    objectType,
    disabled = false,
    className = "",
    imgMaxSize = 2,
  },
  ref
) {
  // #region    VARIABLES //////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion VARIABLES //////////////////////////

  // #region    useEffect //////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion useEffect //////////////////////////

  // #region    FUNCTIONS //////////////////////////
  //////////////////////////////////////////////////
  function validation(file) {
    const validFileType = IMAGE_TYPE.includes(file.type);
    if (!validFileType) {
      message.error("Bạn chỉ có thể upload hình có định dạng jpg/png/jpeg!");
    }
    const validFileSize = file.size / 1024 / 1024 <= imgMaxSize;
    if (!validFileSize) {
      message.error(
        `Hình ảnh phải có kích thước nhỏ hơn hoặc bằng ${imgMaxSize}MB!`
      );
    }
    return validFileType && validFileSize;
  }

  function handleChange(info) {
    if (info.file.status === "uploading") {
      return;
    }
    if (info.file.status === "done") {
      uploadFile(info.file.originFileObj, objectType).then((res) => {
        if (res?.data?.code === "success") {
          const imageLocationBE = getImageLocationBE(objectType);
          setImageUrl(`${imageLocationBE}/${res?.data?.data}`);
        }
      });
    }
  }

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const getImageLocationBE = (objectType) => {
    return `${IMAGE_BASE_URL}/${objectType}`;
  };
  //////////////////////////////////////////////////
  // #endregion FUNCTIONS //////////////////////////

  // #region    VIEWS //////////////////////////////
  //////////////////////////////////////////////////
  const uploadButton = (
    <div
      className={`flex flex-col justify-center items-center imagepicker-filter`}
    >
      <div className={styles.icon}>
        <Img src={cameraWhiteIcon} alt="Upload" />
      </div>
      <div>
        <span className={styles.placeholderText}>Tải ảnh lên</span>
      </div>
    </div>
  );
  //////////////////////////////////////////////////
  // #endregion VIEWS //////////////////////////////
  return (
    <Upload
      name={alt}
      listType="picture-card"
      className={`avatar-uploader ${className}`}
      showUploadList={false}
      customRequest={dummyRequest}
      beforeUpload={validation}
      onChange={handleChange}
      disabled={disabled}
      ref={ref}
    >
      {imageUrl && <Img src={imageUrl} alt={alt} className={styles.img} />}
      {uploadButton}
    </Upload>
  );
}

export default forwardRef(ImagePicker);
