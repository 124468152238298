import { useCallback, useEffect, useState } from "react";
import { common } from "./common";
import { fetchBlob } from "./fileUtils";

const useFilePreviewer = ({ isOpen, fileList, currentFileIndex, onClose }) => {
  const [files, setFiles] = useState([]);
  const [currentFile, setCurrentFile] = useState({
    index: 0,
    info: null,
    fetchBlob: null,
  });

  // get file list
  useEffect(() => {
    let clonedFiles = fileList.map((f) => ({
      name: f.name,
      url: f.url,
      contentType: f.type,
      size: f.size,
    }));
    setFiles(clonedFiles);
  }, [fileList]);

  const fetchFileBlob = useCallback(
    (files) => async (fileIndex, fileInfo, abortControler) => {
      if (files[fileIndex].blob) {
        return files[fileIndex].blob;
      }
      const blobInfo = await fetchBlob(
        fileInfo?.url,
        fileInfo?.contentType,
        abortControler
      );
      if (blobInfo) {
        let newFiles = common.replaceItemInArray(files)(
          (_, i) => i === fileIndex,
          {
            blob: blobInfo,
          }
        );
        setFiles(newFiles);
      }
      return blobInfo;
    },
    []
  );

  // get current by index
  const getFileInfoByIndex = useCallback(
    (fileIndex) => {
      if (files.length && files[fileIndex]) {
        let currentFile = files[fileIndex];
        setCurrentFile({
          index: fileIndex,
          info: currentFile,
          fetchBlob: fetchFileBlob(files),
        });
        return;
      }
      setCurrentFile({
        index: 0,
        info: null,
      });
    },
    [files, fetchFileBlob]
  );

  // get file info
  useEffect(
    () => {
      if (isOpen) {
        getFileInfoByIndex(currentFileIndex);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentFileIndex, isOpen]
  );

  // on navigate
  const onNavigate = useCallback(
    (isPrev) => () => {
      let nextIndex = currentFile.index + 1;
      if (isPrev) {
        nextIndex = currentFile.index - 1;
      }
      if (nextIndex < 0) {
        nextIndex = files.length - 1;
      } else if (nextIndex > files.length - 1) {
        nextIndex = 0;
      }
      getFileInfoByIndex(nextIndex);
    },
    [files, currentFile, getFileInfoByIndex]
  );

  // on key navigate
  const onKeyNavigate = useCallback(
    (e) => {
      if (e.keyCode === 37) {
        // go prev
        onNavigate(true)();
      } else if (e.keyCode === 39) {
        // go next
        onNavigate(false)();
      } else if (e.keyCode === 27) {
        onClose();
      }
    },
    [onNavigate, onClose]
  );

  return {
    currentFile,
    onNavigate,
    onKeyNavigate,
  };
};

export default useFilePreviewer;
