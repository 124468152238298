import React, { memo, useEffect, useRef } from "react";
import { Page } from "react-pdf";
import "./../../../styles/index.scss";

const PdfPage = memo(
  ({
    pageNo,
    width,
    height,
    scale,
    customTextRenderer,
    getDocumentRef,
    onAccess,
  }) => {
    const pageRef = useRef(null);
    const observer = useRef(null);
    const pageNoRef = useRef(pageNo);
    const onAccessRef = useRef(onAccess);

    useEffect(() => {
      pageNoRef.current = pageNo;
    });
    useEffect(() => {
      onAccessRef.current = onAccess;
    });

    useEffect(() => {
      const documentRef = getDocumentRef();
      if (documentRef) {
        const documentRefHeight = documentRef.getBoundingClientRect().height;
        const observerCallback = (entries, documentHeight) => {
          entries.forEach((entry) => {
            const { isIntersecting, intersectionRect } = entry;
            if (
              isIntersecting &&
              intersectionRect.height > documentHeight / 2
            ) {
              onAccessRef.current(pageNoRef.current);
            }
          });
        };
        if (!pageRef.current) {
          return;
        }
        observer.current = new IntersectionObserver(
          (e) => observerCallback(e, documentRefHeight),
          {
            root: documentRef,
            rootMargin: "0px",
            threshold: [
              0.1, 0.15, 0.2, 0.25, 0.3, 0.35, 0.4, 0.45, 0.5, 0.55, 0.6, 0.65,
              0.7, 0.75, 0.8, 0.85, 0.9,
            ],
          }
        );
        //
        observer.current.observe(pageRef?.current);
      }

      return () => {
        if (observer.current && pageRef?.current) {
          // eslint-disable-next-line react-hooks/exhaustive-deps
          observer.current.unobserve(pageRef?.current);
        }
      };
    }, [getDocumentRef]);

    return (
      <Page
        className="pdf-viewer__page"
        inputRef={pageRef}
        pageNumber={pageNo}
        scale={scale}
        width={width}
        height={height}
        customTextRenderer={customTextRenderer}
      />
    );
  }
);

PdfPage.displayName = "PdfPage";
export default PdfPage;
