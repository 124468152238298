import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { API_URL } from "util/constant";

const imgPlaceholder = null;

const isValidURL = (string) => {
  let pattern =
    /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
  return pattern.test(string);
};

const defaultOptions = {
  root: null,
  rootMargin: "0px",
  threshold: 0,
};

class LazyImage extends PureComponent {
  rendered = false;

  getStyle = () => {
    const { width, height } = this.props;
    let styleObj = {};
    if (width) {
      styleObj.width = width;
    }
    if (height) {
      styleObj.height = height;
    }
    return styleObj;
  };

  componentWillUnmount() {
    if (this.observer && this.imgElm) {
      this.observer.unobserve(this.imgElm);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.src !== this.props.src ||
      prevProps.blob !== this.props.blob
    ) {
      if (this.rendered) {
        this.renderImage();
      }
    }
  }

  componentDidMount() {
    const options = { ...defaultOptions, ...(this.props?.options || {}) };
    this.observer = new IntersectionObserver(this.observerCallback, options);
    this.observer.observe(this.imgElm);
  }

  observerCallback = (entries) => {
    entries.forEach((entry) => {
      const { isIntersecting } = entry;
      if (isIntersecting && this.imgElm) {
        this.observer.unobserve(this.imgElm);
        this.rendered = true;
        this.renderImage();
      }
    });
  };

  setImgElm = (r) => {
    this.imgElm = r;
  };

  setImageSource = (imgSrc, ratioWH) => {
    if (this.imgElm) {
      this.imgElm.setAttribute(`src`, `${imgSrc}`);
      this.props.keepRatio &&
        ratioWH &&
        this.imgElm.setAttribute(`height`, `${this.props.width / ratioWH}`);
      this.imgElm.classList.remove("loading");
    }
  };

  renderImageFormSrc = (imgSrc) => {
    if (imgSrc) {
      const imgLoader = new Image();
      imgLoader.src = imgSrc;
      imgLoader.onload = () => {
        const ratioWH = imgLoader.width / imgLoader.height;
        this.setImageSource(imgSrc, ratioWH);
      };
      imgLoader.onerror = () => {
        this.setImageSource(imgPlaceholder);
      };
    } else {
      this.setImageSource(imgPlaceholder);
    }
  };

  renderImage = () => {
    let imgSrc;
    if (this.props.src) {
      imgSrc = this.props.src;
      let testSrc = imgSrc;
      if (`${testSrc}`.search("blob:") === 0) {
        testSrc = testSrc.substring(5);
      }
      if (!isValidURL(testSrc)) {
        imgSrc = `${API_URL}${imgSrc}`;
      }
      this.renderImageFormSrc(imgSrc);
    } else if (this.props.blob) {
      if (this.props.blob instanceof File && FileReader) {
        const reader = new FileReader();
        reader.onload = (e) => {
          imgSrc = e.target.result;
          this.renderImageFormSrc(imgSrc);
        };
        reader.readAsDataURL(this.props.blob);
      }
    }
  };

  render() {
    return (
      <div
        className={`lazy-image ${this.props.className || ""}`}
        style={this.getStyle()}
        title={this.props.title}
      >
        <img
          className="lazy-image-img loading"
          src={this.props.placeHolder}
          width={this.props.width}
          height={this.props.height}
          ref={this.setImgElm}
          alt={this.props.alt}
        />
      </div>
    );
  }
}
LazyImage.defaultProps = {
  alt: "",
  title: "",
  placeHolder: "",
  keepRatio: false,
};
LazyImage.propTypes = {
  src: PropTypes.string,
  className: PropTypes.string,
  placeHolder: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default LazyImage;
