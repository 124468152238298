import { Button, Space } from "antd";
import { newEditIcon } from "asset/img/Images";
import CusTable from "common/CusTable";
import { Notification } from "common/Notification";
import React, { useCallback, useMemo, useState, useRef } from "react";
import { useSelector } from "react-redux";
import {
  updateAttendanceOneStudent,
  updateAttendanceStatusByDate,
  getAttendanceEvent,
  updateAttendanceDaySchool,
  getAttendanceByClassDate,
} from "util/APIs";
import {
  STATUS_ATTENDANCE_DAY_SCHOOL,
  ATTENDANCE_DAY_SCHOOL_STATUS,
} from "util/constant";
import styles from "../styles.module.css";
import style from "./styles.module.css";
import IsMobile from "hook/isMobile";
import FileList, { FileItem } from "common/FilePreviewer/FileList";
import IconFile from "asset/icons/IconFile";

import UploadField from "./UploadAttendance";
import moment from "moment";

function DaySchoolAttendence({
  data,
  roomId,
  selectedDate,
  section,
  currentLesson,
  tableLoading,
  getAttendanceRoomId,
  currentPage,
  setCurrentPage,
  pageSize,
  setPageSize,
  totalItems,
  setIsRefresh,
  scroll,
}) {
  const isUsingMobile = IsMobile();
  const userInfo = useSelector((state) => state?.app?.userInfo);
  const accountId = userInfo?.account?.id;
  const [editingKey, setEditingKey] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [isSubmittedAll, setIsSubmittedAll] = useState(true);
  const isAllowToUpdate = true;
  const isEditing = useCallback(
    (record) => {
      return record?.id === editingKey;
    },
    [editingKey]
  );

  const edit = useCallback(
    (record) => {
      if (editingKey === record?.id) {
        setEditingKey("");
      } else {
        setEditingKey(record?.id);
      }
    },
    [editingKey]
  );

  const handleAttendanceCheck = useCallback(
    async (isChecked, data, status) => {
      const date = {
        "filters[date]": moment(selectedDate).format("YYYY-MM-DD"),
      };
      const item = {
        boarding_attendance: isChecked && status === "isPresent" ? true : false,
        date: moment(selectedDate).format("YYYY-MM-DD"),
      };
      const payload = {
        data: item,
      };
      const res = await updateAttendanceDaySchool(
        accountId,
        data.boarding_attendance_id,
        payload
      );
      console.log("dataput", res);
      if (res?.status === 200) {
        Notification.sendSuccess("Điểm danh thành công");
        setIsRefresh(true);
      }
    },
    [accountId, selectedDate, setIsRefresh]
  );
  const viewFile = (values) => {
    const lst = values ? JSON.parse(values) : [];
    if (lst?.length) {
      const formattedFiles = lst?.map?.((item) => ({
        name: item.title,
        url: item.url,
        type: item.mimetype,
      }));

      return (
        <FileList attachments={formattedFiles}>
          <FileItem {...formattedFiles[0]}>
            {({ onOpen }) => {
              return (
                <div onClick={onOpen}>
                  <div className={`${style.viewFile}`}>
                    <IconFile />
                  </div>
                </div>
              );
            }}
          </FileItem>
        </FileList>
      );
    }
  };

  const columns = useMemo(() => {
    let columnLst = [
      {
        title: "STT",
        align: "center",
        key: "STT",
        render: (value, record, index) =>
          (currentPage - 1) * pageSize.value + index + 1,
        width: "5%",
      },
      {
        title: "Họ Tên",
        align: "left",
        key: `students`,
        dataIndex: "full_name",
        render: (text, record) => <div>{text}</div>,
        width: "10%",
        className: styles.fixedCol,
      },
      {
        title: "Điểm danh",
        key: "boarding_attendance",
        align: "center",
        width: "20%",
        render: (value, record) => {
          const editable = isEditing(record);
          let isPresent = value?.boarding_attendance === true;
          let isNoAuthLeave = value?.boarding_attendance === false;

          return (
            <Space size={"middle"}>
              <Button
                shape="circle"
                className={`late border-0 ${
                  isPresent
                    ? styles.presentActive
                    : styles.attendanceButtonDefault
                }`}
                // disabled={!editable || !isAllowToUpdate}
                onClick={() =>
                  handleAttendanceCheck(!isPresent, record, "isPresent")
                }
              >
                N
              </Button>
              <Button
                shape="circle"
                className={`noAuthLeave border-0 ${
                  isNoAuthLeave
                    ? styles.noAuthLeaveActive
                    : styles.attendanceButtonDefault
                }`}
                // disabled={!editable || !isAllowToUpdate}
                onClick={() =>
                  handleAttendanceCheck(!isNoAuthLeave, record, "isNoAuthLeave")
                }
              >
                K
              </Button>
            </Space>
          );
        },
      },

      // {
      //   title: "",
      //   dataIndex: "operation",
      //   align: "center",
      //   width: "5%",
      //   render: (_, record) => (
      //     <Button
      //       type="link"
      //       disabled={!isSubmittedAll || !isAllowToUpdate}
      //       onClick={() => edit(record)}
      //     >
      //       <img src={newEditIcon} alt="Edit" />
      //     </Button>
      //   ),
      // },
    ];
    if (isUsingMobile) {
      columnLst = columnLst.filter((item) => item.dataIndex !== "dob");
    }
    return columnLst;
  }, [
    currentPage,
    edit,
    handleAttendanceCheck,
    isAllowToUpdate,
    isEditing,
    isSubmittedAll,
    isUsingMobile,
    pageSize.value,
  ]);

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record) ? 1 : 0,
      }),
    };
  });

  return (
    <>
      <CusTable
        rowKey="id"
        data={data}
        columns={columns}
        loading={tableLoading}
        havePagination={totalItems > pageSize.value}
        rowClassName="bg-white attendance-row"
        page={currentPage}
        setPage={setCurrentPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        totalItems={totalItems}
        scroll={{ x: "500px", y: 480 }}
        // scroll={scroll}
      />
    </>
  );
}

export default DaySchoolAttendence;
