import { message } from "antd";
import { newEditIcon, tickGreen, xRed } from "asset/img/Images";
import { isPermitted } from "authen/authenHelper";
import InfoFieldCheckbox from "common/CusInfoField/infoFieldCheckbox/InfoFieldCheckbox";
import InfoFieldDatePicker from "common/CusInfoField/infoFieldDatePicker/InfoFieldDatePicker";
import InfoFieldNumber from "common/CusInfoField/infoFieldNumber";
import InfoFieldSelect from "common/CusInfoField/infoFieldSelect/InfoFieldSelect";
import InfoFieldSpan from "common/CusInfoField/infoFieldSpan/InfoFieldSpan";
import InfoFieldText from "common/CusInfoField/infoFieldText/InfoFieldText";
import InfoSection from "common/CusInfoField/infoSection/InfoSection";
import RectangleTabs from "common/RectangleTabs";
import CustomBreadcrumb from "common/breadcrumb/CustomBreadcrumb";
import StyledButton from "common/button/StyledButton";
import Img from "common/image/Img";
import ImagePicker from "common/imagePicker/ImagePicker";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getSetting } from "util/APIHelper";
import {
  filterSubjectManagement,
  getCountryList,
  getDistrictByProvince,
  getProvinceByCountry,
  getSubDistrictByDistrict,
  updateStaff,
  updateUser,
  getEthnics,
  getReligions,
  getTeacherData,
  getTeacherDetailsById,
} from "util/APIs";
import {
  GENDER,
  OBJECT_TYPE_BE,
  PERMISSION_OBJECT,
  PERMISSION_TYPE,
  SETTING_GROUP_KEYS,
} from "util/constant";
import {
  disableFutureDate,
  findEthnicAndReligion,
  sanitize,
} from "util/helper";
import {
  teacherArrayFields,
  teacherStringFields,
  teacherZeroFields,
  validateTeacher,
} from "util/validate/teacher";
import styles from "./styles.module.css";
import { ID_ISSUED_PLACES } from "util/constant";
import { useCallback } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { useSelector } from "react-redux";

export default function TeacherDetailEditPage() {
  // #region    VARIABLES //////////////////////////
  //////////////////////////////////////////////////
  const isAllowToUpdate = isPermitted(
    PERMISSION_OBJECT.staffs_management,
    PERMISSION_TYPE.update
  );
  const TABS = [
    {
      id: "personal",
      name: "Thông tin cá nhân",
    },
    {
      id: "staff",
      name: "Thông tin giáo viên",
    },
  ];
  const history = useHistory();
  const { staffId } = useParams();
  const userInfo = useSelector((state) => state?.app?.userInfo);
  const accountId = userInfo?.account?.id;
  const [isSelfUpdate, setIsSelfUpdate] = useState(
    history?.location?.state?.data === undefined
  );
  const [isEditting, setIsEditting] = useState(false);
  const [currentTab, setCurrentTab] = useState(TABS[0]);
  const [userId, setUserId] = useState(staffId);
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // Personal fields - 1
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dob, setDob] = useState("");
  const [gender, setGender] = useState("");
  const [ethnic, setEthnic] = useState("");
  const [ethnicList, setEthnicList] = useState([]);
  const [religion, setReligion] = useState("");
  const [religionList, setReligionList] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [idNo, setIdNo] = useState("");
  const [idIssueDate, setIdIssueDate] = useState(undefined);
  const [idIssuePlace, setIdIssuePlace] = useState(undefined);
  const [boGdId, setBoGdId] = useState("");
  const [soHcmId, setSoHcmId] = useState("");
  const [taxId, setTaxId] = useState("");
  const [bankId, setBankId] = useState("");
  const [bankName, setBankName] = useState("");
  const [bankBranch, setBankBranch] = useState("");
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // Contact fields - 1
  const [placeOfPermanentProvinceList, setPlaceOfPermanentProvinceList] =
    useState([]);
  const [placeOfPermanentProvince, setPlaceOfPermanentProvince] =
    useState(undefined);
  const [placeOfPermanentDistrictList, setPlaceOfPermanentDistrictList] =
    useState([]);
  const [placeOfPermanentDistrict, setPlaceOfPermanentDistrict] =
    useState(undefined);
  const [placeOfPermanentSubDistrictList, setPlaceOfPermanentSubDistrictList] =
    useState([]);
  const [placeOfPermanentSubDistrict, setPlaceOfPermanentSubDistrict] =
    useState(undefined);
  const [placeOfPermanentQuarterHamlet, setPlaceOfPermanentQuarterHamlet] =
    useState("");
  const [placeOfPermanentDescription, setPlaceOfPermanentDescription] =
    useState("");
  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const personalFields = [
    {
      title: "",
      fields: [
        <InfoFieldText
          label="Họ"
          isRequired={true}
          value={firstName}
          setValue={setFirstName}
          placeholder="Nhập họ"
          direction="vertical"
          disabled={!isEditting}
        />,
        <InfoFieldText
          label="Tên"
          isRequired={true}
          value={lastName}
          setValue={setLastName}
          placeholder="Nhập tên"
          direction="vertical"
          disabled={!isEditting}
        />,
        <InfoFieldDatePicker
          label="Ngày sinh"
          value={dob}
          setValue={setDob}
          placeholder="Ngày/tháng/năm"
          direction="vertical"
          disabled={!isEditting}
        />,
        <InfoFieldSelect
          label="Giới tính"
          data={GENDER}
          value={gender}
          setValue={setGender}
          placeholder="Chọn giới tính"
          direction="vertical"
          disabled={!isEditting}
          valueTextName="name"
          valueFieldName="value"
        />,
        <InfoFieldSelect
          label="Dân tộc"
          data={ethnicList}
          value={ethnic}
          setValue={setEthnic}
          placeholder="Chọn dân tộc"
          direction="vertical"
          disabled={!isEditting}
          valueTextName="name"
          valueFieldName="id"
        />,
        <InfoFieldSelect
          label="Tôn giáo"
          data={religionList}
          value={religion}
          setValue={setReligion}
          placeholder="Chọn tôn giáo"
          direction="vertical"
          disabled={!isEditting}
          valueTextName="name"
          valueFieldName="id"
        />,
        <InfoFieldText
          label="Số điện thoại"
          value={phoneNumber}
          setValue={setPhoneNumber}
          placeholder="Nhập số điện thoại"
          direction="vertical"
          disabled={!isEditting}
        />,
        <InfoFieldText
          label="Email"
          value={email}
          setValue={setEmail}
          placeholder="Nhập Email"
          direction="vertical"
          disabled={!isEditting || !isAllowToUpdate}
        />,
        <InfoFieldText
          label="CMND / CCCD"
          value={idNo}
          setValue={setIdNo}
          placeholder="Nhập số CMND / CCCD"
          direction="vertical"
          disabled={!isEditting}
        />,
        <InfoFieldDatePicker
          label="Ngày cấp"
          value={idIssueDate}
          setValue={setIdIssueDate}
          placeholder="Ngày/tháng/năm"
          direction="vertical"
          disabled={!isEditting}
        />,
        <InfoFieldSelect
          label="Nơi cấp"
          data={ID_ISSUED_PLACES}
          value={idIssuePlace}
          setValue={setIdIssuePlace}
          placeholder="Chọn nơi cấp CMND / CCCD"
          direction="vertical"
          disabled={!isEditting}
          valueTextName="name"
          valueFieldName="id"
        />,
      ],
    },
  ];
  const contactFields = [
    {
      title: "Địa chỉ thường trú",
      fields: [
        <InfoFieldText
          label="Quốc gia"
          placeholder="Việt Nam"
          direction="vertical"
          disabled={true}
        />,
        <InfoFieldSelect
          label="Tỉnh / TP"
          isRequired={true}
          data={placeOfPermanentProvinceList}
          value={placeOfPermanentProvince}
          setValue={setPlaceOfPermanentProvince}
          placeholder="Chọn tỉnh / thành phố"
          direction="vertical"
          disabled={!isEditting}
          valueTextName="name"
          valueFieldName="code"
        />,
        <InfoFieldSelect
          label="Quận / Huyện"
          isRequired={true}
          data={placeOfPermanentDistrictList}
          value={placeOfPermanentDistrict}
          setValue={setPlaceOfPermanentDistrict}
          placeholder="Chọn quận / huyện"
          direction="vertical"
          disabled={!isEditting || !placeOfPermanentProvince}
          valueTextName="name"
          valueFieldName="code"
        />,
        <InfoFieldSelect
          label="Phường / Xã"
          isRequired={true}
          data={placeOfPermanentSubDistrictList}
          value={placeOfPermanentSubDistrict}
          setValue={setPlaceOfPermanentSubDistrict}
          placeholder="Chọn phường / xã"
          direction="vertical"
          disabled={
            !isEditting ||
            !placeOfPermanentProvince ||
            !placeOfPermanentDistrict
          }
          valueTextName="name"
          valueFieldName="code"
        />,
        <InfoFieldText
          label="Khu phố / Thôn"
          value={placeOfPermanentQuarterHamlet}
          setValue={setPlaceOfPermanentQuarterHamlet}
          placeholder="Nhập khu phố / thôn"
          direction="vertical"
          disabled={!isEditting}
        />,
        <InfoFieldText
          label="Số nhà"
          value={placeOfPermanentDescription}
          setValue={setPlaceOfPermanentDescription}
          placeholder="Nhập số nhà"
          direction="vertical"
          disabled={!isEditting}
        />,
      ],
    },
  ];
  const teacherFields = [
    {
      title: "",
      fields: [
        <InfoFieldText
          label="Mã số thuế"
          value={taxId}
          setValue={setTaxId}
          placeholder="Nhập mã số thuế"
          direction="vertical"
          disabled={!isEditting}
        />,
        <InfoFieldText
          label="Mã sở"
          value={soHcmId}
          setValue={setSoHcmId}
          placeholder="Nhập mã sở"
          direction="vertical"
          disabled={!isEditting || !isAllowToUpdate}
        />,
        <InfoFieldText
          label="Mã bộ"
          value={boGdId}
          setValue={setBoGdId}
          placeholder="Nhập mã bộ"
          direction="vertical"
          disabled={!isEditting || !isAllowToUpdate}
        />,
        <InfoFieldText
          label="Số tài khoản ngân hàng"
          value={bankId}
          setValue={setBankId}
          placeholder="Nhập số tài khoản ngân hàng"
          direction="vertical"
          disabled={!isEditting}
        />,
        <InfoFieldText
          label="Tên ngân hàng"
          value={bankName}
          setValue={setBankName}
          placeholder="Nhập tên ngân hàng"
          direction="vertical"
          disabled={!isEditting}
        />,
        <InfoFieldText
          label="Tên chi nhánh ngân hàng"
          value={bankBranch}
          setValue={setBankBranch}
          placeholder="Nhập tên chi nhánh ngân hàng"
          direction="vertical"
          disabled={!isEditting}
        />,
      ],
    },
  ];
  //////////////////////////////////////////////////
  // #endregion VARIABLES //////////////////////////

  // #region    useEffect //////////////////////////33333333

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (placeOfPermanentProvince !== undefined) {
      getDistrictByProvince(placeOfPermanentProvince, "vn").then((res) => {
        if (res?.statusText === "OK") {
          setPlaceOfPermanentDistrictList(res?.data);
        }
      });
    }
  }, [placeOfPermanentProvince]);

  useEffect(() => {
    if (placeOfPermanentDistrict !== undefined) {
      getSubDistrictByDistrict(
        placeOfPermanentDistrict,
        placeOfPermanentProvince,
        "vn"
      ).then((res) => {
        if (res?.statusText === "OK") {
          setPlaceOfPermanentSubDistrictList(res?.data);
        }
      });
    }
  }, [placeOfPermanentDistrict]);
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion useEffect //////////////////////////
  const handleDataUser = useCallback(async () => {
    const res = await getTeacherDetailsById(accountId, staffId);
    if (res?.status === 200) {
      res.data.citizen_identify =
        res.data.citizen_identify !== null
          ? typeof res?.data?.citizen_identify === "string"
            ? JSON.parse(res?.data?.citizen_identify)
            : res?.data?.citizen_identify
          : {};
      res.data.permanent_address =
        res.data.permanent_address !== null
          ? typeof res?.data?.permanent_address === "string"
            ? JSON.parse(res?.data?.permanent_address)
            : res?.data?.permanent_address
          : {};
      res.data.ethnic =
        res.data.ethnic !== null
          ? typeof res?.data?.ethnic === "string"
            ? JSON.parse(res?.data?.ethnic)
            : res?.data?.ethnic
          : {};
      res.data.religion =
        res.data.religion !== null
          ? typeof res?.data?.religion === "string"
            ? JSON.parse(res?.data?.religion)
            : res?.data?.religion
          : {};
      res.data.bank_information =
        res.data.bank_information !== null
          ? typeof res?.data?.bank_information === "string"
            ? JSON.parse(res?.data?.bank_information)
            : res?.data?.bank_information
          : {};
      ////////////////////////////////////////////////////////////////////////////////////////////////////
      setFirstName(res?.data.first_name);
      setLastName(res?.data.last_name);
      setDob(res?.data.dob);
      setGender(res?.data.gender);
      getEthnics().then((res) => {
        if (res?.statusText === "OK") {
          setEthnicList(res?.data);
        }
      });
      setEthnic(res?.data.ethnic?.id);
      getReligions().then((res) => {
        if (res?.statusText === "OK") {
          setReligionList(res?.data?.data);
        }
      });
      setReligion(res?.data?.religion?.id);
      setPhoneNumber(res?.data?.phone_number);
      setEmail(res?.data?.email);
      setIdNo(res?.data?.citizen_identify?.id_no);
      setIdIssueDate(res?.data?.citizen_identify?.issued_on);
      setIdIssuePlace(res?.data?.citizen_identify?.place_of_issue);
      setTaxId(res?.data?.tax_id);
      setSoHcmId(res?.data?.so_hcm_id);
      setBoGdId(res?.data?.bo_gd_id);
      setBankId(res?.data?.bank_information?.bank_id);
      setBankName(res?.data?.bank_information?.bank_name);
      setBankBranch(res?.data?.bank_information?.bank_branch);
      ////////////////////////////////////////////////////////////////////////////////////////////////////

      ////////////////////////////////////////////////////////////////////////////////////////////////////
      getProvinceByCountry("vn").then((res) => {
        if (res?.statusText === "OK") {
          setPlaceOfPermanentProvinceList(res?.data);
        }
      });
      setPlaceOfPermanentProvince(res?.data?.permanent_address?.province_city);
      if (res?.data?.permanent_address?.province_city) {
        getDistrictByProvince(
          res?.data?.permanent_address?.province_city,
          "vn"
        ).then((res) => {
          if (res?.statusText === "OK") {
            setPlaceOfPermanentDistrictList(res?.data);
          }
        });
      }
      setPlaceOfPermanentDistrict(
        res?.data?.permanent_address?.district || null
      );
      if (res?.data?.permanent_address?.district) {
        getSubDistrictByDistrict(
          res?.data?.permanent_address?.district,
          res?.data?.permanent_address?.province_city,
          "vn"
        ).then((res) => {
          if (res?.statusText === "OK") {
            setPlaceOfPermanentSubDistrictList(res?.data);
          }
        });
      }
      setPlaceOfPermanentSubDistrict(
        res?.data?.permanent_address?.sub_district
      );
      setPlaceOfPermanentQuarterHamlet(
        res?.data?.permanent_address?.quarter_hamlet
      );
      setPlaceOfPermanentDescription(res?.data?.permanent_address?.description);
    }
  }, [accountId, staffId]);

  const handleGetMe = useCallback(async () => {
    const res = await getTeacherData();
    if (res?.status === 200 && res?.data.staff) {
      setUserId(res?.data.staff.id);
      res.data.staff.citizen_identify =
        res.data?.staff.citizen_identify !== null
          ? typeof res?.data?.staff?.citizen_identify === "string"
            ? JSON.parse(res?.data?.staff?.citizen_identify)
            : res?.data?.staff?.citizen_identify
          : {};
      res.data.staff.permanent_address =
        res.data?.staff.permanent_address !== null
          ? typeof res?.data?.staff?.permanent_address === "string"
            ? JSON.parse(res?.data?.staff?.permanent_address)
            : res?.data?.staff?.permanent_address
          : {};
      res.data.staff.ethnic =
        res.data?.staff.ethnic !== null
          ? typeof res?.data?.staff?.ethnic === "string"
            ? JSON.parse(res?.data?.staff?.ethnic)
            : res?.data?.staff?.ethnic
          : {};
      res.data.staff.religion =
        res.data?.staff.religion !== null
          ? typeof res?.data?.staff?.religion === "string"
            ? JSON.parse(res?.data?.staff?.religion)
            : res?.data?.staff?.religion
          : {};
      res.data.staff.bank_information =
        res.data?.staff.bank_information !== null
          ? typeof res?.data?.staff?.bank_information === "string"
            ? JSON.parse(res?.data?.staff?.bank_information)
            : res?.data?.staff?.bank_information
          : {};
      ////////////////////////////////////////////////////////////////////////////////////////////////////
      setFirstName(res?.data?.staff.first_name);
      setLastName(res?.data?.staff.last_name);
      setDob(res?.data?.staff.dob);
      setGender(res?.data?.staff.gender);
      getEthnics().then((res) => {
        if (res?.statusText === "OK") {
          setEthnicList(res?.data);
        }
      });
      setEthnic(res?.data?.staff.ethnic?.id);
      getReligions().then((res) => {
        if (res?.statusText === "OK") {
          setReligionList(res?.data?.data);
        }
      });
      setReligion(res?.data?.staff?.religion?.id);
      setPhoneNumber(res?.data?.staff?.phone_number);
      setEmail(res?.data?.staff?.email);
      setIdNo(res?.data?.staff?.citizen_identify?.id_no);
      setIdIssueDate(res?.data?.staff?.citizen_identify?.issued_on);
      setIdIssuePlace(res?.data?.staff?.citizen_identify?.place_of_issue);
      setTaxId(res?.data?.staff?.tax_id);
      setSoHcmId(res?.data?.staff?.so_hcm_id);
      setBoGdId(res?.data?.staff?.bo_gd_id);
      setBankId(res?.data?.staff?.bank_information?.bank_id);
      setBankName(res?.data?.staff?.bank_information?.bank_name);
      setBankBranch(res?.data?.staff?.bank_information?.bank_branch);
      ////////////////////////////////////////////////////////////////////////////////////////////////////

      ////////////////////////////////////////////////////////////////////////////////////////////////////
      getProvinceByCountry("vn").then((res) => {
        if (res?.statusText === "OK") {
          setPlaceOfPermanentProvinceList(res?.data);
        }
      });
      setPlaceOfPermanentProvince(
        res?.data?.staff?.permanent_address?.province_city
      );
      if (res?.data?.staff?.permanent_address?.province_city) {
        getDistrictByProvince(
          res?.data?.staff?.permanent_address?.province_city,
          "vn"
        ).then((res) => {
          if (res?.statusText === "OK") {
            setPlaceOfPermanentDistrictList(res?.data);
          }
        });
      }
      setPlaceOfPermanentDistrict(
        res?.data?.staff?.permanent_address?.district || null
      );
      if (res?.data?.staff?.permanent_address?.district) {
        getSubDistrictByDistrict(
          res?.data?.staff.permanent_address.district,
          res?.data?.staff.permanent_address.province_city,
          "vn"
        ).then((res) => {
          if (res?.statusText === "OK") {
            setPlaceOfPermanentSubDistrictList(res?.data);
          }
        });
      }
      setPlaceOfPermanentSubDistrict(
        res?.data?.staff?.permanent_address?.sub_district
      );
      setPlaceOfPermanentQuarterHamlet(
        res?.data?.staff?.permanent_address?.quarter_hamlet
      );
      setPlaceOfPermanentDescription(
        res?.data?.staff?.permanent_address?.description
      );
    }
  }, []);

  useEffect(() => {
    if (staffId) {
      handleDataUser();
    } else {
      handleGetMe();
    }
  }, [staffId, handleDataUser, handleGetMe]);
  // #region    FUNCTIONS //////////////////////////
  //////////////////////////////////////////////////

  const handleUpdateTeacher = () => {
    const params = {
      data: {
        id: userId,
        first_name: firstName,
        last_name: lastName,
        dob: dob,
        gender: gender,
        ethnic: findEthnicAndReligion(ethnicList, ethnic),
        religion: findEthnicAndReligion(religionList, religion),
        phone_number: phoneNumber,
        email: email,
        citizen_identify: {
          id_no: idNo,
          issued_on: idIssueDate,
          place_of_issue: idIssuePlace,
        },
        permanent_address: {
          country: "Việt Nam",
          province_city: placeOfPermanentProvince,
          district: placeOfPermanentDistrict,
          sub_district: placeOfPermanentSubDistrict,
          quarter_hamlet: placeOfPermanentQuarterHamlet,
          description: placeOfPermanentDescription,
        },

        tax_id: taxId,
        bank_information: {
          bank_id: bankId,
          bank_name: bankName,
          bank_branch: bankBranch,
        },
        digital_signature: null,
        bo_gd_id: boGdId,
        so_hcm_id: soHcmId,
      },
    };

    updateUser(accountId, userId, params).then((res) => {
      if (res?.statusText === "OK") {
        message.success("Cập nhật thành công!");

        // params.data["files"] = userInfo.user.files;
        // params.data["roles"] = userInfo?.user?.roles;
        // params.data["tabs"] = userInfo?.user?.tabs;
        // userInfo["user"] = params.data;

        // if (isSelfUpdate) {
        //   setUserInfo({ ...userInfo });
        //   localStorage.setItem("user_info", JSON.stringify(userInfo));
        // } else {
        //   goBack();
        // }
        clearData();
        toggleEdit();
      }
    });
  };

  const clearData = () => { };

  const toggleEdit = () => {
    setIsEditting(!isEditting);
  };

  const discardEdit = () => {
    setIsEditting(false);
  };

  const goBack = () => history.push("/admin/staff-management");
  //////////////////////////////////////////////////
  // #endregion FUNCTIONS //////////////////////////

  // #region    VIEWS //////////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion VIEWS //////////////////////////////
  return (
    <div className={`flex flex-col h-full ${styles.root}`}>
      <div className="flex justify-between">
        <CustomBreadcrumb
          crumbs={["/teacher-detail/edit"]}
          style={`${styles.breadcrumb}`}
        />
        {!isSelfUpdate && (
          <StyledButton onClick={goBack} theme="blue">
            Quay về
          </StyledButton>
        )}
      </div>
      <div
        // className={`flex mb-4 w-full ${styles.heightWrap}`}
        className="flex mb-4 w-full"
      >
        <div className={"pb-8 section-shadow h-full w-full"}>
          <div
            className={`grid grid-cols-3 gap-x-8 gap-y-3 px-8 py-4 ${styles.background}`}
          >
            <div />
            <div />
            <div className={`flex justify-end`}>
              {!isEditting && (
                <button onClick={toggleEdit} className={styles.btn}>
                  <Img
                    src={newEditIcon}
                    alt="Edit"
                    className={styles.bRadius8}
                  />
                </button>
              )}
              {isEditting && (
                <>
                  <div
                    className={`${styles.btn} mRight24`}
                    onClick={discardEdit}
                  >
                    <Img src={xRed} alt="Cancel" />
                  </div>
                  <div
                    className={`${styles.btn}`}
                    onClick={handleUpdateTeacher}
                  >
                    <Img src={tickGreen} alt="Accept" />
                  </div>
                </>
              )}
            </div>
          </div>
          <RectangleTabs
            data={TABS}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            className="pl-8 bg-background"
          />
          <div
          // className={`${styles.heightWrap2} overflow-y-auto hide-scrollbar`}
          >
            {currentTab?.id === TABS[0].id && (
              <div className={styles.pTop20}>
                <InfoSection
                  title="THÔNG TIN CÁ NHÂN"
                  data={personalFields}
                  style={styles.mBottom24}
                />
                <InfoSection title="THÔNG TIN LIÊN HỆ" data={contactFields} />
              </div>
            )}
            {currentTab?.id === TABS[1].id && (
              <div className={styles.pTop20}>
                <InfoSection
                  title="THÔNG TIN GIÁO VIÊN"
                  data={teacherFields}
                  style={styles.mBottom24}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
