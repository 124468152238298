import moment from "moment";
import { excelGlobalStyles } from "util/excelGlobalStyles";
import * as xlsx from "xlsx-js-style";

const styles = {
  centerStyle: {
    alignment: {
      horizontal: "center",
    },
  },
  headerStyle: {
    font: {
      bold: true,
    },
    fill: {
      fgColor: {
        rgb: "A6A6A6",
      },
    },
  },
  bigTitleStyle: {
    alignment: {
      horizontal: "center",
    },
    font: {
      sz: 15,
      bold: true,
    },
  },
  bigTitleOne: {
    fill: {
      fgColor: {
        rgb: "B8CCE4",
      },
    },
  },
  bigTitleTwo: {
    fill: {
      fgColor: {
        rgb: "FCD5B4",
      },
    },
  },
};

export const handleExport = (
  subjectScoreData,
  gradeList,
  semesterList,
  subjectList,
  classList,
  grade,
  semester,
  subject,
  classId,
  transcriptData
) => {
  const level_1_data = subjectScoreData.filter(
    (x) => x?.score_info?.coefficient === 1
  );
  const level_2_data = subjectScoreData.filter(
    (x) => x?.score_info?.coefficient === 2
  );
  const level_3_data = subjectScoreData.filter(
    (x) => x?.score_info?.coefficient === 3
  );
  const level_0_data = subjectScoreData.filter(
    (x) => x?.score_info?.coefficient === 0
  );
  const firstEmptyCells = 4; // Các ô thụt vào ở phía bên trái dòng chữ "Kiểm tra thường xuyên", bằng số cột bên trái các cột điểm

  const heading = [
    [""],
    ["Khối", gradeList.find((i) => i.id === grade).name],
    ["Học kỳ", semesterList.find((i) => i.key === semester).value],
    ["Môn học", subjectList.find((i) => i.id === subject).name],
    ["Lớp", classList.find((i) => i.id === classId).name],
    [
      "",
      "",
      "",
      "Kiểm tra thường xuyên",
      ...Array(level_1_data?.length - 1).fill(""),
      "Kiểm tra định kỳ",
    ],
    [
      "Họ tên",
      "Mã học sinh",
      "Ngày sinh",
      ...level_1_data?.map((i) => i?.score_info?.name),
      ...level_2_data?.map((i) => i?.score_info?.name),
      ...level_3_data?.map((i) => i?.score_info?.name),
      ...level_0_data?.map((i) => i?.score_info?.name),
    ],
  ];

  // Tính địa chỉ của 2 ô lớn
  const bigCellOneColumnStart = firstEmptyCells;
  const bigCellOneColumnEnd = firstEmptyCells + level_1_data?.length - 1;
  const bigCellTwoColumnStart = firstEmptyCells + level_1_data?.length;
  const bigCellTwoColumnEnd =
    level_1_data?.length +
    firstEmptyCells +
    level_2_data?.length +
    level_3_data?.length -
    1;
  const bigCellsRow = heading.length - 2;

  const dataForExcel = transcriptData.map((i) => {
    const lvl1Scores = {},
      lvl2Scores = {},
      lvl3Scores = {},
      lvl0Scores = {};
    level_1_data.forEach((val, index) => {
      lvl1Scores[`coefficient_1_${index + 1}`] =
        i?.transcript_info.find(
          (x) => x?.subject_has_score_id === val?.id && x?.semester === semester
        )?.value_number || "";
    });
    level_2_data.forEach((val, index) => {
      lvl2Scores[`coefficient_2_${index + 1}`] =
        i?.transcript_info.find(
          (x) => x?.subject_has_score_id === val?.id && x?.semester === semester
        )?.value_number || "";
    });
    level_3_data.forEach((val, index) => {
      lvl3Scores[`coefficient_3_${index + 1}`] =
        i?.transcript_info.find(
          (x) => x?.subject_has_score_id === val?.id && x?.semester === semester
        )?.value_number || "";
    });
    level_0_data.forEach((val, index) => {
      lvl0Scores[`coefficient_0_${index + 1}`] =
        i?.transcript_info.find(
          (x) => x?.subject_has_score_id === val?.id && x?.semester === semester
        )?.value_text || "";
    });
    return {
      full_name: i.full_name,
      code: i.code,
      birthday: moment(i.birthday).format("DD/MM/YYYY"),
      ...lvl1Scores,
      ...lvl2Scores,
      ...lvl3Scores,
      ...lvl0Scores,
    };
  });
  const workbook = xlsx.utils.book_new();
  const worksheet = xlsx.utils.json_to_sheet([]);
  xlsx.utils.sheet_add_aoa(worksheet, heading, {
    origin: "B1",
  });
  xlsx.utils.sheet_add_json(worksheet, dataForExcel, {
    origin: `B${heading.length + 1}`,
    skipHeader: true,
  });

  // Start, end, row, column
  // Merge 2 cell lớn cho 2 dòng chữ "Kiểm tra thường xuyên" "Kiểm tra định kỳ"
  worksheet["!merges"] = [
    {
      s: {
        c: bigCellOneColumnStart,
        r: bigCellsRow,
      },
      e: {
        c: bigCellOneColumnEnd,
        r: bigCellsRow,
      },
    },
    {
      s: {
        c: bigCellTwoColumnStart,
        r: bigCellsRow,
      },
      e: {
        c: bigCellTwoColumnEnd,
        r: bigCellsRow,
      },
    },
  ];

  // Set width cho các column
  worksheet["!cols"] = [
    10,
    ...Array(9).fill({
      width: 20,
    }),
  ];

  // Styling
  // Add style cho 2 ô title lớn
  const cellRef1 = xlsx.utils.encode_cell({
    c: bigCellOneColumnStart,
    r: bigCellsRow,
  });
  const cellRef2 = xlsx.utils.encode_cell({
    c: bigCellTwoColumnStart,
    r: bigCellsRow,
  });
  worksheet[cellRef1].s = {
    ...styles.bigTitleStyle,
    ...styles.bigTitleOne,
  };
  worksheet[cellRef2].s = {
    ...styles.bigTitleStyle,
    ...styles.bigTitleTwo,
  };
  // Duyệt từng ô để add style
  const endRowIndex = heading.length + dataForExcel.length;
  const endColumnIndex = heading[heading.length - 1]?.length + 1; // Độ dài của mảng cuối cùng trong heading
  for (let rowI = 0; rowI < endRowIndex; rowI++) {
    for (let colI = 0; colI < endColumnIndex; colI++) {
      let cellStyles = {};
      if (rowI >= bigCellsRow + 1) {
        cellStyles = {
          ...cellStyles,
          ...excelGlobalStyles.bordered,
          ...styles.centerStyle,
        };
      }
      if (rowI === bigCellsRow + 1) {
        cellStyles = { ...cellStyles, ...styles.headerStyle };
      }
      if (Object.keys(cellStyles).length > 0) {
        const cell = xlsx.utils.encode_cell({ c: colI, r: rowI });
        if (worksheet?.[cell]) {
          worksheet[cell].s = { ...cellStyles };
        }
      }
    }
  }

  xlsx.utils.book_append_sheet(workbook, worksheet, "Điểm số");
  xlsx.writeFile(workbook, `diem_so_${moment().format("DD_MM_YYYY")}.xlsx`);
};
