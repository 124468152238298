import { editPic, pencilWhite } from "asset/img/Images";
import InfoFieldDatePicker from "common/CusInfoField/infoFieldDatePicker/InfoFieldDatePicker";
import InfoFieldSelect from "common/CusInfoField/infoFieldSelect/InfoFieldSelect";
import InfoFieldText from "common/CusInfoField/infoFieldText/InfoFieldText";
import { Notification } from "common/Notification";
import CustomModal from "mobile/component/customModal/CustomModal";
import MobileHeader from "mobile/layout/header/MobileHeader";
import { HEADER_TYPE } from "mobile/util/mobileConstant";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  getDetailStudent,
  getDistrictByProvince,
  getEthnics,
  getProvinceByCountry,
  getReligions,
  getSubDistrictByDistrict,
  updateStudent,
} from "util/APIs";
import color from "util/color";
import {
  DATE_FORMAT,
  GENDER,
  ID_ISSUED_PLACES,
  TABLE_PAGE_SIZES,
  is1920OrMore,
} from "util/constant";
import { findEthnicAndReligion, textStyle } from "util/helper";
import { routes } from "util/routes";
import InfoItem from "../profilePage/component/infoItem/InfoItem";
import styles from "./styles.module.css";
import { Form } from "antd";
import moment from "moment";
import InfoSection from "common/CusInfoField/infoSection/InfoSection";
import CusTable from "common/CusTable";

const INFO_TABS = [
  {
    key: "general",
    name: "Thông tin cá nhân",
  },
  {
    key: "parent",
    name: "Thông tin phụ huynh",
  },
  {
    id: "evaluation",
    name: "Thông tin vi phạm",
  },
];

export default function StudentPage() {
  const history = useHistory();
  const { studentId } = useParams();
  const userInfo = useSelector((state) => state?.app?.userInfo);
  const accountId = userInfo?.account?.id;

  const [selectedTab, setSelectedTab] = useState(INFO_TABS[0]);
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [datas, setDatas] = useState([]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const [expandPersonal, setExpandPersonal] = useState(false);
  const [isEdittingPersonal, setIsEdittingPersonal] = useState(false);
  const [expandContact0, setExpandContact0] = useState(false);
  const [expandContact2, setExpandContact2] = useState(false);
  const [isEdittingContact0, setIsEdittingContact0] = useState(false);
  const [isEdittingContact2, setIsEdittingContact2] = useState(false);
  const [expandMother, setExpandMother] = useState(false);
  const [expandFather, setExpandFather] = useState(false);
  const [isEdittingMother, setIsEdittingMother] = useState(false);
  const [isEdittingFather, setIsEdittingFather] = useState(false);
  const isShowUpdateBtn =
    isEdittingPersonal ||
    isEdittingContact0 ||
    isEdittingMother ||
    isEdittingFather;
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // Personal fields - 1
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dob, setDob] = useState("");
  const [gender, setGender] = useState("");
  const [ethnic, setEthnic] = useState("");
  const [ethnicList, setEthnicList] = useState([]);
  const [religion, setReligion] = useState("");
  const [religionList, setReligionList] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [idNo, setIdNo] = useState("");
  const [idIssueDate, setIdIssueDate] = useState(undefined);
  const [idIssuePlace, setIdIssuePlace] = useState(undefined);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(TABLE_PAGE_SIZES[0]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // Contact fields - 1
  const [placeOfPermanentProvinceList, setPlaceOfPermanentProvinceList] =
    useState([]);
  const [placeOfPermanentProvince, setPlaceOfPermanentProvince] =
    useState(undefined);
  const [placeOfPermanentDistrictList, setPlaceOfPermanentDistrictList] =
    useState([]);
  const [placeOfPermanentDistrict, setPlaceOfPermanentDistrict] =
    useState(undefined);
  const [placeOfPermanentSubDistrictList, setPlaceOfPermanentSubDistrictList] =
    useState([]);
  const [placeOfPermanentSubDistrict, setPlaceOfPermanentSubDistrict] =
    useState(undefined);
  const [placeOfPermanentQuarterHamlet, setPlaceOfPermanentQuarterHamlet] =
    useState("");
  const [placeOfPermanentDescription, setPlaceOfPermanentDescription] =
    useState("");
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // Contact fields - 2
  const [placeOfTemporaryProvinceList, setPlaceOfTemporaryProvinceList] =
    useState([]);
  const [placeOfTemporaryProvince, setPlaceOfTemporaryProvince] =
    useState(undefined);
  const [placeOfTemporaryDistrictList, setPlaceOfTemporaryDistrictList] =
    useState([]);
  const [placeOfTemporaryDistrict, setPlaceOfTemporaryDistrict] =
    useState(undefined);
  const [placeOfTemporarySubDistrictList, setPlaceOfTemporarySubDistrictList] =
    useState([]);
  const [placeOfTemporarySubDistrict, setPlaceOfTemporarySubDistrict] =
    useState(undefined);
  const [placeOfTemporaryQuarterHamlet, setPlaceOfTemporaryQuarterHamlet] =
    useState("");
  const [placeOfTemporaryDescription, setPlaceOfTemporaryDescription] =
    useState("");
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // Mother Information
  const [motherFirstName, setMotherFirstName] = useState("");
  const [motherLastName, setMotherLastName] = useState("");
  const [motherJob, setMotherJob] = useState("");
  const [motherDob, setMotherDob] = useState("");
  const [motherPhoneNumber, setMotherPhoneNumber] = useState("");
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // Father Information
  const [fatherFirstName, setFatherFirstName] = useState("");
  const [fatherLastName, setFatherLastName] = useState("");
  const [fatherJob, setFatherJob] = useState("");
  const [fatherDob, setFatherDob] = useState("");
  const [fatherPhoneNumber, setFatherPhoneNumber] = useState("");

  const personalFields = [
    {
      title: "Thông tin cá nhân",
      fields: [
        <InfoFieldText
          label="Họ"
          isRequired={true}
          value={firstName}
          setValue={setFirstName}
          placeholder="Nhập họ"
          direction="vertical"
          disabled={!isEdittingPersonal}
        />,
        <InfoFieldText
          label="Tên"
          isRequired={true}
          value={lastName}
          setValue={setLastName}
          placeholder="Nhập tên"
          direction="vertical"
          disabled={!isEdittingPersonal}
        />,
        <InfoFieldDatePicker
          label="Ngày sinh"
          value={dob}
          setValue={setDob}
          placeholder="Ngày/tháng/năm"
          direction="vertical"
          disabled={!isEdittingPersonal}
        />,
        <InfoFieldSelect
          label="Giới tính"
          data={GENDER}
          value={gender}
          setValue={setGender}
          placeholder="Chọn giới tính"
          direction="vertical"
          disabled={!isEdittingPersonal}
          valueTextName="name"
          valueFieldName="value"
        />,
        <InfoFieldSelect
          label="Dân tộc"
          data={ethnicList}
          value={ethnic}
          setValue={setEthnic}
          placeholder="Chọn dân tộc"
          direction="vertical"
          disabled={!isEdittingPersonal}
          valueTextName="name"
          valueFieldName="id"
        />,
        <InfoFieldSelect
          label="Tôn giáo"
          data={religionList}
          value={religion}
          setValue={setReligion}
          placeholder="Chọn tôn giáo"
          direction="vertical"
          disabled={!isEdittingPersonal}
          valueTextName="name"
          valueFieldName="id"
        />,
        <InfoFieldText
          label="Số điện thoại"
          value={phoneNumber}
          setValue={setPhoneNumber}
          placeholder="Nhập số điện thoại"
          direction="vertical"
          disabled={!isEdittingPersonal}
        />,
        <InfoFieldText
          label="Email"
          value={email}
          setValue={setEmail}
          placeholder="Nhập Email"
          direction="vertical"
          disabled={!isEdittingPersonal}
        />,
        <InfoFieldText
          label="CMND / CCCD"
          value={idNo}
          setValue={setIdNo}
          placeholder="Nhập số CMND / CCCD"
          direction="vertical"
          disabled={!isEdittingPersonal}
        />,
        <InfoFieldDatePicker
          label="Ngày cấp"
          value={idIssueDate}
          setValue={setIdIssueDate}
          placeholder="Ngày/tháng/năm"
          direction="vertical"
          disabled={!isEdittingPersonal}
        />,
        <InfoFieldSelect
          label="Nơi cấp"
          data={ID_ISSUED_PLACES}
          value={idIssuePlace}
          setValue={setIdIssuePlace}
          placeholder="Chọn nơi cấp CMND / CCCD"
          direction="vertical"
          disabled={!isEdittingPersonal}
          valueTextName="name"
          valueFieldName="id"
        />,
      ],
    },
  ];
  const contactFields = [
    {
      title: "Địa chỉ thường trú",
      fields: [
        <InfoFieldText
          label="Quốc gia"
          placeholder="Việt Nam"
          direction="vertical"
          disabled={true}
        />,
        <InfoFieldSelect
          label="Tỉnh / TP"
          isRequired={true}
          data={placeOfPermanentProvinceList}
          value={placeOfPermanentProvince}
          setValue={setPlaceOfPermanentProvince}
          placeholder="Chọn tỉnh / thành phố"
          direction="vertical"
          disabled={!isEdittingContact0}
          valueTextName="name"
          valueFieldName="code"
        />,
        <InfoFieldSelect
          label="Quận / Huyện"
          isRequired={true}
          data={placeOfPermanentDistrictList}
          value={placeOfPermanentDistrict}
          setValue={setPlaceOfPermanentDistrict}
          placeholder="Chọn quận / huyện"
          direction="vertical"
          disabled={!isEdittingContact0 || !placeOfPermanentProvince}
          valueTextName="name"
          valueFieldName="code"
        />,
        <InfoFieldSelect
          label="Phường / Xã"
          isRequired={true}
          data={placeOfPermanentSubDistrictList}
          value={placeOfPermanentSubDistrict}
          setValue={setPlaceOfPermanentSubDistrict}
          placeholder="Chọn phường / xã"
          direction="vertical"
          disabled={
            !isEdittingContact0 ||
            !placeOfPermanentProvince ||
            !placeOfPermanentDistrict
          }
          valueTextName="name"
          valueFieldName="code"
        />,
        <InfoFieldText
          label="Khu phố / Thôn"
          value={placeOfPermanentQuarterHamlet}
          setValue={setPlaceOfPermanentQuarterHamlet}
          placeholder="Nhập khu phố / thôn"
          direction="vertical"
          disabled={!isEdittingContact0}
        />,
        <InfoFieldText
          label="Số nhà"
          value={placeOfPermanentDescription}
          setValue={setPlaceOfPermanentDescription}
          placeholder="Nhập số nhà"
          direction="vertical"
          disabled={!isEdittingContact0}
        />,
      ],
    },
  ];
  const contactFields2 = [
    {
      title: "Địa chỉ tạm trú",
      fields: [
        <InfoFieldText
          label="Quốc gia"
          placeholder="Việt Nam"
          direction="vertical"
          disabled={true}
        />,
        <InfoFieldSelect
          label="Tỉnh / TP"
          isRequired={true}
          data={placeOfTemporaryProvinceList}
          value={placeOfTemporaryProvince}
          setValue={setPlaceOfTemporaryProvince}
          placeholder="Chọn tỉnh / thành phố"
          direction="vertical"
          disabled={!isEdittingContact2}
          valueTextName="name"
          valueFieldName="code"
        />,
        <InfoFieldSelect
          label="Quận / Huyện"
          isRequired={true}
          data={placeOfTemporaryDistrictList}
          value={placeOfTemporaryDistrict}
          setValue={setPlaceOfTemporaryDistrict}
          placeholder="Chọn quận / huyện"
          direction="vertical"
          disabled={!isEdittingContact2 || !placeOfTemporaryProvince}
          valueTextName="name"
          valueFieldName="code"
        />,
        <InfoFieldSelect
          label="Phường / Xã"
          isRequired={true}
          data={placeOfTemporarySubDistrictList}
          value={placeOfTemporarySubDistrict}
          setValue={setPlaceOfTemporarySubDistrict}
          placeholder="Chọn phường / xã"
          direction="vertical"
          disabled={
            !isEdittingContact2 ||
            !placeOfTemporaryProvince ||
            !placeOfTemporaryDistrict
          }
          valueTextName="name"
          valueFieldName="code"
        />,
        <InfoFieldText
          label="Khu phố / Thôn"
          value={placeOfTemporaryQuarterHamlet}
          setValue={setPlaceOfTemporaryQuarterHamlet}
          placeholder="Nhập khu phố / thôn"
          direction="vertical"
          disabled={!isEdittingContact2}
        />,
        <InfoFieldText
          label="Số nhà"
          value={placeOfTemporaryDescription}
          setValue={setPlaceOfTemporaryDescription}
          placeholder="Nhập số nhà"
          direction="vertical"
          disabled={!isEdittingContact2}
        />,
      ],
    },
  ];
  const motherFields = [
    {
      title: "Thông tin mẹ",
      fields: [
        <InfoFieldText
          label="Họ"
          isRequired={true}
          value={motherFirstName}
          setValue={setMotherFirstName}
          placeholder="Nhập họ"
          direction="vertical"
          disabled={!isEdittingMother}
        />,
        <InfoFieldText
          label="Tên"
          isRequired={true}
          value={motherLastName}
          setValue={setMotherLastName}
          placeholder="Nhập tên"
          direction="vertical"
          disabled={!isEdittingMother}
        />,
        <InfoFieldDatePicker
          label="Ngày sinh"
          value={motherDob}
          setValue={setMotherDob}
          placeholder="Ngày/tháng/năm"
          direction="vertical"
          disabled={!isEdittingMother}
        />,
        <InfoFieldText
          label="Công việc"
          value={motherJob}
          setValue={setMotherJob}
          placeholder="Nhập công việc"
          direction="vertical"
          disabled={!isEdittingMother}
        />,
        <InfoFieldText
          label="Số điện thoại"
          value={motherPhoneNumber}
          setValue={setMotherPhoneNumber}
          placeholder="Nhập số điện thoại"
          direction="vertical"
          disabled={!isEdittingMother}
        />,
      ],
    },
  ];
  const fatherFields = [
    {
      title: "Thông tin cha",
      fields: [
        <InfoFieldText
          label="Họ"
          isRequired={true}
          value={fatherFirstName}
          setValue={setFatherFirstName}
          placeholder="Nhập họ"
          direction="vertical"
          disabled={!isEdittingFather}
        />,
        <InfoFieldText
          label="Tên"
          isRequired={true}
          value={fatherLastName}
          setValue={setFatherLastName}
          placeholder="Nhập tên"
          direction="vertical"
          disabled={!isEdittingFather}
        />,
        <InfoFieldDatePicker
          label="Ngày sinh"
          value={fatherDob}
          setValue={setFatherDob}
          placeholder="Ngày/tháng/năm"
          direction="vertical"
          disabled={!isEdittingFather}
        />,
        <InfoFieldText
          label="Công việc"
          value={fatherJob}
          setValue={setFatherJob}
          placeholder="Nhập công việc"
          direction="vertical"
          disabled={!isEdittingFather}
        />,
        <InfoFieldText
          label="Số điện thoại"
          value={fatherPhoneNumber}
          setValue={setFatherPhoneNumber}
          placeholder="Nhập số điện thoại"
          direction="vertical"
          disabled={!isEdittingFather}
        />,
      ],
    },
  ];

  const responsive = {
    tableScroll: () => {
      if (is1920OrMore) return {};
      else
        return {
          x: 1000,
          y: 490,
        };
    },
  };

  const totalScore = datas.reduce((sum, record) => {
    if (record.rules) {
      return (
        sum +
        record.rules.reduce((recordSum, item) => recordSum + item.score, 0)
      );
    }
    return sum;
  }, 0);

  const columns = [
    {
      title: "STT",
      align: "center",
      key: "STT",
      render: (value, record, index) =>
        (currentPage - 1) * pageSize.value + index + 1,
      width: "50px",
      fixed: "left",
    },
    {
      title: "Ngày vi phạm",
      dataIndex: "date",
      align: "center",
      render: (text) => (
        <span>{text ? moment(text).format(DATE_FORMAT) : "--"}</span>
      ),
      width: "15%",
    },
    {
      title: "Nội dung vi phạm",
      dataIndex: "rules",
      align: "center",
      render: (text) => {
        return (
          <div>
            {text &&
              text.map((item, index) => (
                <p key={item.id} className={`${styles.violateContent}`}>
                  <span className={`${styles.violateIndx}`}>{index + 1}.</span>
                  {item.title}
                </p>
              ))}
          </div>
        );
      },
    },
    {
      title: "Điểm trừ",
      dataIndex: "rules",
      align: "center",
      render: (text) => {
        return (
          <div>
            {text &&
              text.map((item, index) => (
                <p key={item.id} className={`${styles.score}`}>
                  {item.score}
                </p>
              ))}
          </div>
        );
      },
      width: "15%",
    },
  ];

  const getData = useCallback(async () => {
    const res = await getDetailStudent(accountId, studentId);
    if (res?.status === 200) {
      const data = res?.data;
      if (data) {
        setDatas(res?.data?.attendance_evaluations);
        const citizen_identify = JSON.parse(data?.citizen_identify);
        const ethnic = JSON.parse(data?.ethnic);
        const parents_information = JSON.parse(data?.parents_information);
        const permanent_address = JSON.parse(data?.permanent_address);
        const religion = JSON.parse(data?.religion);
        const temporary_address = JSON.parse(data?.temporary_address);
        getEthnics().then((res) => {
          if (res?.statusText === "OK") {
            setEthnicList(res?.data);
          }
        });
        setEthnic(data?.user?.ethnic?.id);
        getReligions().then((res) => {
          if (res?.statusText === "OK") {
            setReligionList(res?.data?.data);
          }
        });
        setFirstName(data?.first_name);
        setLastName(data?.last_name);
        setDob(data?.dob);
        setGender(data?.gender);
        setEthnic(ethnic?.id);
        setReligion(religion?.id);
        setPhoneNumber(data?.phone_number);
        setEmail(data?.email);
        setIdNo(citizen_identify?.id_no);
        setIdIssueDate(citizen_identify?.issued_on);
        setIdIssuePlace(citizen_identify?.place_of_issue);

        getProvinceByCountry("vn").then((res) => {
          if (res?.statusText === "OK") {
            setPlaceOfPermanentProvinceList(res?.data);
          }
        });
        setPlaceOfPermanentProvince(permanent_address?.province_city);
        if (permanent_address?.province_city) {
          getDistrictByProvince(permanent_address?.province_city, "vn").then(
            (res) => {
              if (res?.statusText === "OK") {
                setPlaceOfPermanentDistrictList(res?.data);
              }
            }
          );
        }
        setPlaceOfPermanentDistrict(permanent_address?.district || null);
        if (permanent_address?.district) {
          getSubDistrictByDistrict(
            permanent_address?.district,
            permanent_address?.province_city,
            "vn"
          ).then((res) => {
            if (res?.statusText === "OK") {
              setPlaceOfPermanentSubDistrictList(res?.data);
            }
          });
        }
        setPlaceOfPermanentSubDistrict(permanent_address?.sub_district);
        setPlaceOfPermanentQuarterHamlet(permanent_address?.quarter_hamlet);
        setPlaceOfPermanentDescription(permanent_address?.description);
        ////////////////////////////////////////////////////////////////////////////////////////////////////

        ////////////////////////////////////////////////////////////////////////////////////////////////////
        getProvinceByCountry("vn").then((res) => {
          if (res?.statusText === "OK") {
            setPlaceOfTemporaryProvinceList(res?.data);
          }
        });
        setPlaceOfTemporaryProvince(temporary_address?.province_city);
        if (temporary_address?.province_city) {
          getDistrictByProvince(temporary_address?.province_city, "vn").then(
            (res) => {
              if (res?.statusText === "OK") {
                setPlaceOfTemporaryDistrictList(res?.data);
              }
            }
          );
        }
        setPlaceOfTemporaryDistrict(temporary_address?.district || null);
        if (temporary_address?.district) {
          getSubDistrictByDistrict(
            temporary_address?.district,
            temporary_address?.province_city,
            "vn"
          ).then((res) => {
            if (res?.statusText === "OK") {
              setPlaceOfTemporarySubDistrictList(res?.data);
            }
          });
        }
        setPlaceOfTemporarySubDistrict(temporary_address?.sub_district);
        setPlaceOfTemporaryQuarterHamlet(temporary_address?.quarter_hamlet);
        setPlaceOfTemporaryDescription(temporary_address?.description);
        ////////////////////////////////////////////////////////////////////////////////////////////////////

        ////////////////////////////////////////////////////////////////////////////////////////////////////
        setMotherFirstName(parents_information?.mother_infomation?.first_name);
        setMotherLastName(parents_information?.mother_infomation?.last_name);
        setMotherJob(parents_information?.mother_infomation?.job);
        setMotherDob(parents_information?.mother_infomation?.dob);
        setMotherPhoneNumber(
          parents_information?.mother_infomation?.phone_number
        );
        ////////////////////////////////////////////////////////////////////////////////////////////////////

        ////////////////////////////////////////////////////////////////////////////////////////////////////
        setFatherFirstName(parents_information?.father_infomation?.first_name);
        setFatherLastName(parents_information?.father_infomation?.last_name);
        setFatherJob(parents_information?.father_infomation?.job);
        setFatherDob(parents_information?.father_infomation?.dob);
        setFatherPhoneNumber(
          parents_information?.father_infomation?.phone_number
        );
      }
      // setDetailInfo(res?.data?.data);
    }
  }, [accountId, studentId]);

  useEffect(() => {
    getData();
  }, [getData]);

  const mergedColumns = columns.map((col) => {
    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
      }),
    };
  });

  ////////////////////////////////////////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (placeOfPermanentProvince !== undefined) {
      getDistrictByProvince(placeOfPermanentProvince, "vn").then((res) => {
        if (res?.statusText === "OK") {
          setPlaceOfPermanentDistrictList(res?.data);
        }
      });
    }
  }, [placeOfPermanentProvince]);

  useEffect(() => {
    if (placeOfPermanentDistrict !== undefined) {
      getSubDistrictByDistrict(
        placeOfPermanentDistrict,
        placeOfPermanentProvince,
        "vn"
      ).then((res) => {
        if (res?.statusText === "OK") {
          setPlaceOfPermanentSubDistrictList(res?.data);
        }
      });
    }
  }, [placeOfPermanentDistrict, placeOfPermanentProvince]);

  const showConfirmModal = () => {
    setConfirmModalVisible(true);
  };

  const hideConfirmModal = () => {
    setConfirmModalVisible(false);
  };

  const goBack = () => history.push(routes.STUDENT_INFO);

  const handleUpdateStudent = () => {
    const params = {
      data: {
        first_name: firstName,
        last_name: lastName,
        dob: dob,
        gender: gender,
        ethnic: findEthnicAndReligion(ethnicList, ethnic),
        religion: findEthnicAndReligion(religionList, religion),
        phone_number: phoneNumber,
        email: email,
        citizen_identify: {
          id_no: idNo,
          issued_on: idIssueDate,
          place_of_issue: idIssuePlace,
        },
        permanent_address: {
          country: "Việt Nam",
          province_city: placeOfPermanentProvince,
          district: placeOfPermanentDistrict,
          sub_district: placeOfPermanentSubDistrict,
          quarter_hamlet: placeOfPermanentQuarterHamlet,
          description: placeOfPermanentDescription,
        },
        temporary_address: {
          country: "Việt Nam",
          province_city: placeOfTemporaryProvince,
          district: placeOfTemporaryDistrict,
          sub_district: placeOfTemporarySubDistrict,
          quarter_hamlet: placeOfTemporaryQuarterHamlet,
          description: placeOfTemporaryDescription,
        },
        parents_information: {
          mother_infomation: {
            first_name: motherFirstName,
            last_name: motherLastName,
            job: motherJob,
            dob: motherDob,
            phone_number: motherPhoneNumber,
          },
          father_infomation: {
            first_name: fatherFirstName,
            last_name: fatherLastName,
            job: fatherJob,
            dob: fatherDob,
            phone_number: fatherPhoneNumber,
          },
        },
      },
    };
    updateStudent(accountId, studentId, params).then((res) => {
      if (res?.statusText === "OK") {
        Notification.sendSuccess("Cập nhật thành công!");
        goBack();
      }
    });
  };

  //////////////////////////////////////////////////
  // #endregion FUNCTIONS //////////////////////////

  // #region    VIEWS //////////////////////////////
  //////////////////////////////////////////////////
  const renderTab = (item) => {
    const isSelected = item?.key === selectedTab?.key;
    return (
      <button
        onClick={() => setSelectedTab(item)}
        className={`flex-1 ${styles.semester} ${
          isSelected ? styles.choosingSemester : ""
        }`}
        key={item?.key}
      >
        <span style={textStyle(14, 700, isSelected ? color.white : "#313437")}>
          {item?.name}
        </span>
      </button>
    );
  };
  //////////////////////////////////////////////////

  return (
    <div className={`flex flex-col ${styles.root}`}>
      <MobileHeader
        type={HEADER_TYPE.middleTitle}
        title="Thông tin học sinh"
        haveNotiButton={false}
      />
      <div className={`flex ${styles.semesterWrapper}`}>
        {INFO_TABS.map(renderTab)}
      </div>
      <div
        className={`flex flex-col ${styles.infoWrapper} ${
          isShowUpdateBtn ? styles.mBottom90 : ""
        }`}
      >
        <div
          className={`mTop16 ${
            selectedTab.key === INFO_TABS[0].key ? "" : "hidden"
          }`}
        >
          <InfoItem
            data={personalFields[0]}
            expanded={expandPersonal}
            setExpanded={setExpandPersonal}
            isEditting={isEdittingPersonal}
            setEditting={setIsEdittingPersonal}
          />
          <InfoItem
            data={contactFields[0]}
            expanded={expandContact0}
            setExpanded={setExpandContact0}
            isEditting={isEdittingContact0}
            setEditting={setIsEdittingContact0}
            className="mTop16"
          />
          <InfoItem
            data={contactFields2[0]}
            expanded={expandContact2}
            setExpanded={setExpandContact2}
            isEditting={isEdittingContact2}
            setEditting={setIsEdittingContact2}
            className="mTop16"
          />
        </div>

        <div
          className={`mTop16 ${
            selectedTab.key === INFO_TABS[1].key ? "" : "hidden"
          }`}
        >
          <InfoItem
            data={motherFields[0]}
            expanded={expandMother}
            setExpanded={setExpandMother}
            isEditting={isEdittingMother}
            setEditting={setIsEdittingMother}
          />
          <InfoItem
            data={fatherFields[0]}
            expanded={expandFather}
            setExpanded={setExpandFather}
            isEditting={isEdittingFather}
            setEditting={setIsEdittingFather}
          />
        </div>

        <div
          className={`mTop16 ${
            selectedTab.key === INFO_TABS[2].key ? "" : "hidden"
          }`}
        >
          <InfoSection title="THÔNG TIN VI PHẠM" />
          <div className={`mx-6 mt-5`}>
            <Form form={form} component={false}>
              <CusTable
                rowKey="id"
                data={datas}
                columns={mergedColumns}
                havePagination={false}
                rowClassName="editable-row"
                scroll={responsive.tableScroll()}
              />
            </Form>
          </div>
          <div className="mx-6 mt-5">
            <span className={`${styles.totalscore}`}>
              Tổng số điểm trừ: {totalScore}
            </span>
          </div>
        </div>
      </div>

      <div
        className={`${styles.updateBtnWrapper} ${
          isShowUpdateBtn ? "" : "hidden"
        }`}
      >
        <button
          className={`w-full ${styles.updateBtn}`}
          onClick={showConfirmModal}
        >
          <span className={styles.updateText}>Cập nhật</span>
        </button>
      </div>

      <CustomModal
        className={styles.confirmModal}
        visible={confirmModalVisible}
        setVisible={setConfirmModalVisible}
      >
        <div className={`flex flex-col flex-1 items-center ${styles.content}`}>
          <div className="relative">
            <img src={editPic} alt="Confirm edit" />
            <img src={pencilWhite} alt="Pen" className={styles.pen} />
          </div>
          <div className="flex flex-col items-center mTop24">
            <span className={styles.confirmText2}>Xác nhận</span>
            <span className={styles.confirmText}>
              Bạn có chắc muốn cập nhật thông tin?
            </span>
          </div>
          <div className="flex w-full">
            <button
              className={`${styles.confirmBtn} ${styles.cancelBtn} mRight10`}
              onClick={hideConfirmModal}
            >
              <span className={styles.cancelText}>Hủy bỏ</span>
            </button>
            <button className={styles.confirmBtn} onClick={handleUpdateStudent}>
              <span className={styles.confirmText3}>Xác nhận</span>
            </button>
          </div>
        </div>
      </CustomModal>
    </div>
  );
}
