import React, { memo, useEffect, useMemo, useRef } from "react";
import PortalReactDOM from "react-dom";
import "./../styles/index.scss";
import PreviewContent from "./PreviewContent";
import useFilePreviewer from "./useFilePreviewer";

const IconBack = () => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 1L4 8L11 15"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const IconNext = () => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 1L12 8L5 15"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const FilePreviewer = memo((props) => {
  const { isOpen, fileList, onClose } = props;
  const { currentFile, onNavigate, onKeyNavigate } = useFilePreviewer(props);
  const containerRef = useRef();

  useEffect(() => {
    const { common } = require("./common");
    if (isOpen) {
      if (!document.body.classList.contains("modal-open")) {
        document.body.classList.add("modal-open");
      }
      if (containerRef.current) {
        containerRef.current.focus();
      }
    } else {
      common.removeModalClass();
    }

    return () => {
      common.removeModalClass();
    };
  }, [isOpen]);

  const pageNumber = useMemo(() => {
    return (
      <span>
        {currentFile?.index + 1} / {fileList.length}
      </span>
    );
  }, [currentFile?.index, fileList.length]);
  return typeof document === "object"
    ? PortalReactDOM.createPortal(
        <div
          tabIndex="-1"
          style={{
            position: "relative",
            display: "block",
            zIndex: "1500",
          }}
        >
          {isOpen && fileList.length !== 0 && (
            <>
              <div
                className="file-previewer-container"
                tabIndex="1"
                ref={containerRef}
                onKeyDown={onKeyNavigate}
              >
                {fileList.length > 1 && (
                  <div className="nav-back" onClick={onNavigate(true)}>
                    <button>
                      <IconBack />
                    </button>
                  </div>
                )}
                {currentFile.info && (
                  <PreviewContent
                    fileIndex={currentFile.index}
                    fileInfo={currentFile.info}
                    fetchBlob={currentFile.fetchBlob}
                    pageNumber={pageNumber}
                    onClose={onClose}
                  />
                )}
                {fileList.length > 1 && (
                  <div className="nav-next" onClick={onNavigate(false)}>
                    <button>
                      <IconNext />
                    </button>
                  </div>
                )}
                <div className="file-previewer-backdrop" onClick={onClose} />
              </div>
            </>
          )}
        </div>,
        document.querySelector("body")
      )
    : null;
});

FilePreviewer.displayName = "FilePreviewer";
export default FilePreviewer;
