import { useCallback, useState } from "react";
import {
  downloadFileFromBlob,
  fetchBlob,
  getFileNameWithExtension,
} from "../Preview/fileUtils";
import { usePreviewContext } from "../context/PreviewContext";

const useBlob = ({ name, url, contentType }) => {
  const { translate, toast } = usePreviewContext();
  const [blob, setBlob] = useState(null);

  const getBlob = useCallback(
    async (url, type) => {
      if (blob) {
        return blob;
      }
      const blobInfo = await fetchBlob(url, type);
      if (blobInfo) {
        setBlob(blobInfo);
        return blobInfo;
      }
      return null;
    },
    [blob]
  );

  const download = useCallback(
    async (e) => {
      e.stopPropagation();
      let blobInfo = await getBlob(url, contentType);
      if (blobInfo) {
        downloadFileFromBlob(
          blobInfo,
          getFileNameWithExtension(name, contentType)
        );
      } else {
        toast?.error(
          translate(
            "learner.assessment.error_can_not_download",
            "Không thể tải xuống"
          )
        );
      }
    },
    [getBlob, url, contentType, name, toast, translate]
  );

  return {
    blob,
    download,
  };
};

export default useBlob;
