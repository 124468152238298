import { Input, InputNumber, message } from "antd";
import { isPermitted } from "authen/authenHelper";
import InfoFieldSelect from "common/CusInfoField/infoFieldSelect/InfoFieldSelect";
import InfoFieldText from "common/CusInfoField/infoFieldText/InfoFieldText";
import CusTable from "common/CusTable";
import ExportButton from "common/ExportButton";
import CustomBreadcrumb from "common/breadcrumb/CustomBreadcrumb";
import StyledButton from "common/button/StyledButton";
import SelectOption from "common/select/selectOption/SelectOption";
import useIsSchoolType from "hook/isUniversity";
import cloneDeep from "lodash/cloneDeep";
import moment from "moment";
import CommentModal from "page/capacityQualityPage/components/CommentModal";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  calculateAverageTranscript,
  checkExistsAverageTranscript,
  filterClass,
  filterGrade,
  filterSetting,
  filterStudentTranscript,
  filterSubject,
  filterSubjectHasScore,
  getCommentSettingBySubject,
  getListEvaluateValue,
  updateMultiComment2,
  updateMultiTranscript,
} from "util/APIs";
import {
  DATE_FORMAT,
  PERMISSION_OBJECT,
  PERMISSION_TYPE,
  PRIMARY_SCHOOL,
  SCORE_INFO_TYPE,
  SEMESTERS,
  SETTING_GROUP_KEYS,
  ZERO_ID,
  is1920OrMore,
} from "util/constant";
import CheckPullScoreCohotaButton from "./components/CheckPullScoreCohotaButton";
import PullScoreCohotaButton from "./components/PullScoreCohotaButton";
import { handleExport } from "./excelExport";
import styles from "./styles.module.css";

export default function TranscriptDetailPage(props) {
  // #region    VARIABLES //////////////////////////
  //////////////////////////////////////////////////
  const isAllowToUpdate = isPermitted(
    PERMISSION_OBJECT.transcript,
    PERMISSION_TYPE.update
  );

  const transcriptInfo = props.location.state || {};

  const schoolYear = useSelector(
    (state) => state.schoolYear.selectedSchoolYear
  );

  const isPrimarySchool = useIsSchoolType(PRIMARY_SCHOOL);

  const [level0Length, setLevel0Length] = useState(0);
  const [level1Length, setLevel1Length] = useState(0);
  const [level2Length, setLevel2Length] = useState(0);
  const [level3Length, setLevel3Length] = useState(0);
  const [semester, setSemester] = useState(transcriptInfo?.semester);
  const [subject, setSubject] = useState(transcriptInfo?.subject_id);
  const [classId, setClassId] = useState(transcriptInfo?.class_id);
  const [commentSettingList, setCommentSettingList] = useState([]);
  const [changingTranscript, setChangingTranscript] = useState([]);
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [scoreTypeTextList, setScoreTypeTextList] = useState([]);
  const [subjectScoreData, setSubjectScoreData] = useState([]);
  const [grade, setGrade] = useState(transcriptInfo?.grade_id);
  const [modalInputValue, setModalInputValue] = useState("");
  const [changingComment, setChangingComment] = useState([]);
  const [transcriptData, setTranscriptData] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [changingCell, setChangingCell] = useState(null);
  const [modalInputID, setModalInputID] = useState(null);
  const [subjectList, setSubjectList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [modalKey, setModalKey] = useState(null);
  const [classList, setClassList] = useState([]);
  const [gradeList, setGradeList] = useState([]);
  const [pageSize, setPageSize] = useState(999);
  const isChangingData =
    changingTranscript?.length > 0 || changingComment?.length > 0;
  const [semesterList, setSemesterList] = useState([
    SEMESTERS[0],
    SEMESTERS[1],
  ]);
  const history = useHistory();

  // Dùng null để phân biệt đã check hay chưa
  // Nếu đã check value sẽ là true / false
  const [isExistsAvgTranscript, setIsExistsAvgTranscript] = useState(null);
  //////////////////////////////////////////////////
  // #endregion VARIABLES //////////////////////////

  // #region    useEffect //////////////////////////
  //////////////////////////////////////////////////
  useEffect(() => {
    getStudyCommentSetting();
  }, []);

  useEffect(() => {
    if (subject) getSubjectScores();
  }, [subject]);

  useEffect(() => {
    if (schoolYear && schoolYear !== null) getGrade();
  }, [schoolYear]);

  useEffect(() => {
    if (grade && grade !== null) {
      getSubject();
      getClass();
      getScoreTypeTextList();
    }
  }, [grade]);

  useEffect(() => {
    if (classId && classId !== null && subjectScoreData?.length > 0) {
      getData();
    }
  }, [classId, subjectScoreData, isExistsAvgTranscript, isAllowToUpdate]);

  useEffect(() => {
    if (classId && subject && semester) {
      checkExistsAvgTranscript();
    }
  }, [classId, subject, semester]);
  //////////////////////////////////////////////////
  // #endregion useEffect //////////////////////////

  // #region    FUNCTIONS //////////////////////////
  //////////////////////////////////////////////////
  const noUpdate = () => false;

  // Hàm shouldCellRerender sẽ được call ở mỗi cell mỗi khi có 1 cell bất kì thay đổi value (select, text, ...)
  // Hàm shouldCellRerender trả về boolean quyết định xem từng cell có cần re-render hay không
  // Lưu ý param record ở hàm này là 1 dòng trong biến transcriptData
  const shouldScoreCellRerender = (record, subject_has_score_id) => {
    if (!!changingCell) {
      if (
        !!changingCell?.student_id &&
        !!changingCell?.subject_has_score_id &&
        changingCell?.student_id === record?.id &&
        changingCell?.subject_has_score_id === subject_has_score_id
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  const shouldCommentCellRerender = (record, column_key) => {
    if (!!changingCell) {
      if (
        !!changingCell?.student_id &&
        !!changingCell?.column_key &&
        changingCell?.student_id === record?.id &&
        changingCell?.column_key === column_key
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  const defaultColumns = [
    {
      title: "STT",
      align: "center",
      key: `STT`,
      render: (value, record, index) =>
        (currentPage - 1) * pageSize + index + 1,
      width: 50,
      shouldCellUpdate: noUpdate,
    },
    {
      title: "Họ",
      key: `first_name`,
      render: (text, record) => <span>{record?.first_name}</span>,
      width: is1920OrMore ? 150 : 120,
      shouldCellUpdate: noUpdate,
    },
    {
      title: "Tên",
      key: `last_name`,
      render: (text, record) => <span>{record?.last_name}</span>,
      width: is1920OrMore ? 150 : 120,
      shouldCellUpdate: noUpdate,
    },
    {
      title: "Mã học sinh",
      dataIndex: "code",
      key: "code",
      width: is1920OrMore ? 150 : 100,
      shouldCellUpdate: noUpdate,
    },
    {
      title: "Ngày sinh",
      dataIndex: "birthday",
      key: "birthday",
      align: "left",
      render: (value) => <span>{moment(value).format(DATE_FORMAT)}</span>,
      width: 150,
      shouldCellUpdate: noUpdate,
    },
  ];

  const getStudyCommentSetting = async () => {
    try {
      const res = await getCommentSettingBySubject({
        subject_id: transcriptInfo?.subject_id,
      });

      if (res.data?.code === "success") {
        setCommentSettingList(res.data?.data);
      }
    } catch {}
  };

  const checkExistsAvgTranscript = async () => {
    try {
      const params = {
        class_id: classId,
        subject_id: subject,
        semester: semester,
      };

      const res = await checkExistsAverageTranscript(params);
      if (res?.data?.code === "success") {
        setIsExistsAvgTranscript(res?.data?.data?.exist);
      }
    } catch (error) {}
  };

  const getGrade = () => {
    const params = {
      sort: "created_at desc",
      page: 1,
      size: 9999,
      school_year_id: schoolYear?.id,
      page_name: PERMISSION_OBJECT.transcript,
    };
    filterGrade(params).then((res) => {
      if (res?.data.code === "success") {
        const data = res?.data?.data;
        setGradeList(data);
      }
    });
  };

  const getSubject = () => {
    filterSubject({
      sort: "priority",
      page: 1,
      size: 999,
      grade_id: grade,
    }).then((res) => {
      if (res?.data.code === "success") {
        const data = res?.data?.data;
        setSubjectList(data);
      }
    });
  };

  const getClass = () => {
    filterClass({
      sort: "created_at",
      page: 1,
      size: 999,
      grade_id: grade || "",
      page_name: PERMISSION_OBJECT.transcript,
    }).then((res) => {
      if (res?.data.code === "success") {
        const data = res?.data?.data;
        setClassList(data);
      }
    });
  };

  const getData = async () => {
    try {
      const params = {
        sort: "last_name",
        page: 1,
        size: 9999,
        class_id: classId,
        subject_id: subject,
        school_year_id: schoolYear?.id,
        semester,
        preload: "TranscriptInfo",
      };

      setTableLoading(true);

      const res = await filterStudentTranscript(params);

      if (res?.data.code === "success") {
        setTranscriptData([...res?.data?.data]);
        setTableLoading(false);
      }
    } catch {}
  };

  const getSubjectScores = () => {
    filterSubjectHasScore({
      page: 1,
      size: 999,
      subject_id: subject,
    }).then((res) => {
      if (res?.data?.code === "success") {
        setSubjectScoreData(res?.data?.data);
      }
    });
  };

  const getScoreTypeTextList = async () => {
    try {
      if (isPrimarySchool) {
        const res = await getListEvaluateValue({
          grade_id: grade,
          evaluate_group: "",
          type: "subject",
        });
        setScoreTypeTextList(res?.data?.data || []);
      } else {
        const res = await filterSetting({
          setting_group_key: SETTING_GROUP_KEYS.value_score_type_text,
        });
        setScoreTypeTextList(res?.data?.data || []);
      }
    } catch (error) {}
  };

  const getScoreFromChanging = (
    subject_has_score_id,
    student_id,
    score_type
  ) => {
    const temp = cloneDeep(changingTranscript);
    const index = temp?.findIndex(
      (i) =>
        i?.subject_has_score_id === subject_has_score_id &&
        i?.student_id === student_id
    );
    if (index > -1) return temp[index]?.[score_type];
    else return null;
  };

  const getCommentIdFromChanging = (record) => {
    const temp = cloneDeep(changingComment);
    const existedObject = temp.find((x) => x?.student_id === record?.id);
    return sanitizeZeroID(existedObject?.study_comment_setting_id);
  };

  const getCommentFromChanging = (record) => {
    const temp = cloneDeep(changingComment);
    const existedObject = temp.find((x) => x?.student_id === record?.id);
    return existedObject?.comment;
  };

  const sanitizeZeroID = (value) => {
    if (value === ZERO_ID) return null;
    return value;
  };

  const openCommentModal = (key, id, currentValue) => {
    setModalKey(key);
    setModalInputID(id);
    setModalInputValue(currentValue);
    setShowCommentModal(true);
  };

  const closeCommentModal = () => {
    setShowCommentModal(false);
  };

  const onConfirmComment = () => {
    handleUpdateCommentContent(modalInputValue, modalInputID, modalKey);
    closeCommentModal();
  };

  const onCommentFocus = (e) => {
    e?.target?.blur();
  };

  const onClickCommentInput = (key, student_id, currentValue) => {
    openCommentModal?.(key, student_id, currentValue);
  };

  const getScoreColumn = (val, level) => {
    return {
      title: <span>{val.score_info?.name}</span>,
      key: `${val?.score_info?.id}_score_lvl_${level}`,
      align: "center",
      width: 120,
      ellipsis: true,
      render: (value, record) => {
        const scoreVal = record?.transcript_info?.find(
          (x) => x?.subject_has_score_id === val?.id && x?.semester === semester
        );

        if (
          val?.score_info?.type === SCORE_INFO_TYPE.number ||
          val?.score_info?.type === SCORE_INFO_TYPE.score
        )
          return (
            <InputNumber
              min={0}
              max={10}
              key={`${record?.score_id}_input`}
              value={
                getScoreFromChanging(val?.id, record?.id, "value_number") ||
                scoreVal?.value_number
              }
              className={`bg-transparent ${styles.input}`}
              onPressEnter={(e) =>
                updatePoint(e, val?.id, record?.id, scoreVal?.value_number)
              }
              onBlur={(e) =>
                updatePoint(e, val?.id, record?.id, scoreVal?.value_number)
              }
              onStep={(value) =>
                updatePoint(
                  {
                    target: {
                      value,
                    },
                  },
                  val?.id,
                  record?.id,
                  scoreVal?.value_number
                )
              }
              disabled={!isAllowToUpdate || isExistsAvgTranscript}
              precision={isPrimarySchool ? 0 : undefined}
            />
          );

        if (
          val?.score_info?.type === SCORE_INFO_TYPE.text ||
          val?.score_info?.type === SCORE_INFO_TYPE.comment
        )
          return (
            <SelectOption
              data={scoreTypeTextList}
              value={
                getScoreFromChanging(val?.id, record?.id, "value_text") ||
                scoreVal?.value_text
              }
              allowClear={false}
              setValue={(e) => updateText(e, val?.id, record?.id)}
              valueFieldName={isPrimarySchool ? "id" : "key"}
              valueTextName={isPrimarySchool ? "name" : "value"}
              disabled={!isAllowToUpdate || isExistsAvgTranscript}
            />
          );

        return null;
      },
      shouldCellUpdate: (record) => shouldScoreCellRerender(record, val?.id),
    };
  };

  const columns = useMemo(() => {
    const level_1_data = subjectScoreData?.filter(
      (x) => x?.score_info?.coefficient === 1
    );
    const level_2_data = subjectScoreData?.filter(
      (x) => x?.score_info?.coefficient === 2
    );
    const level_3_data = subjectScoreData?.filter(
      (x) => x?.score_info?.coefficient === 3
    );
    const level_0_data = subjectScoreData?.filter(
      (x) => x?.score_info?.coefficient === 0
    );

    const level_1_columns = level_1_data?.map((val) => getScoreColumn(val, 1));
    const level_2_columns = level_2_data?.map((val) => getScoreColumn(val, 2));
    const level_3_columns = level_3_data?.map((val) => getScoreColumn(val, 3));
    const level_0_columns = level_0_data?.map((val) => {
      return {
        title: "Nhận xét",
        key: `judge_score`,
        align: "center",
        width: 120,
        render: (value, record) => {
          const scoreVal = record?.transcript_info?.find(
            (x) =>
              x?.subject_has_score_id === val?.id && x?.semester === semester
          );

          return (
            <Input
              key={`${record?.score_id}_input`}
              value={
                getScoreFromChanging(val?.id, record?.id, "value_text") ||
                scoreVal?.value_text
              }
              className={`bg-transparent`}
              onPressEnter={(e) => updateNote(e, val?.id, record?.id)}
              onBlur={(e) => updateNote(e, val?.id, record?.id)}
              disabled={!isAllowToUpdate}
            />
          );
        },
        shouldCellUpdate: (record) => shouldScoreCellRerender(record, val?.id),
      };
    });

    const judgeColumns = [
      {
        title: "Tên nhận xét",
        key: "study_comment_setting_id",
        align: "center",
        width: 220,
        render: (value, record) => {
          return (
            <InfoFieldSelect
              data={commentSettingList}
              value={
                getCommentIdFromChanging(record) ||
                sanitizeZeroID(
                  record?.study_comment_info?.study_comment_setting_id
                )
              }
              setValue={(e) =>
                handleUpdateCommentId(e, record?.id, "study_comment_setting_id")
              }
              allowClear={false}
              placeholder="Chọn tên nhận xét"
              disabled={!isAllowToUpdate}
              noLabel={true}
              className={`bg-transparent text-left`}
              selectClassName={styles.commentSelect}
            />
          );
        },
        shouldCellUpdate: (record) =>
          shouldCommentCellRerender(record, "study_comment_setting_id"),
      },
      {
        title: "Nhận xét của GVBM",
        key: "comment",
        align: "center",
        width: 300,
        render: (value, record) => {
          return (
            <InfoFieldText
              value={
                getCommentFromChanging(record) ||
                record?.study_comment_info?.comment ||
                ""
              }
              key={`${record?.score_id}_input`}
              placeholder="Nội dung nhận xét (không quá 500 kí tự)"
              disabled={!isAllowToUpdate}
              noLabel={true}
              maxLength={500}
              onFocus={onCommentFocus}
              onClick={() =>
                onClickCommentInput(
                  "comment",
                  record?.id,
                  getCommentFromChanging(record) ||
                    record?.study_comment_info?.comment ||
                    ""
                )
              }
              setValue={(e) => {
                handleUpdateCommentContent(e, record?.id, "comment");
              }}
              className="bg-transparent"
              inputClassName="cursor-pointer"
            />
          );
        },
        shouldCellUpdate: (record) =>
          shouldCommentCellRerender(record, "comment"),
      },
    ];

    const averageColumns = [];
    if (!isPrimarySchool) {
      averageColumns.push({
        title: "ĐTB",
        key: `average_column`,
        align: "center",
        width: 120,
        ellipsis: true,
        render: (value, record) => {
          const averageVal = record?.average_transcript_info?.[0];
          const isNumber =
            averageVal?.type === SCORE_INFO_TYPE.number ||
            averageVal?.type === SCORE_INFO_TYPE.score;
          const scoreVal = isNumber
            ? averageVal?.value_number
            : scoreTypeTextList?.find((i) => i?.key === averageVal?.value_text)
                ?.value || "";
          if (averageVal?.type) {
            return <span className={styles.avgText}>{scoreVal}</span>;
          }
          return null;
        },
        // shouldCellUpdate: noUpdate,
      });
    }

    setLevel1Length(level_1_columns?.length || 0);
    setLevel2Length(level_2_columns?.length || 0);
    setLevel3Length(level_3_columns?.length || 0);
    setLevel0Length(level_0_columns?.length || 0);

    return [
      ...defaultColumns,
      ...level_1_columns,
      ...level_2_columns,
      ...level_3_columns,
      ...level_0_columns,
      ...averageColumns,
      ...judgeColumns,
    ];
  }, [
    currentPage,
    pageSize,
    subjectScoreData,
    semester,
    isExistsAvgTranscript,
    isAllowToUpdate,
    scoreTypeTextList,
    commentSettingList,
    changingTranscript,
    changingComment,
    changingCell,
    isPrimarySchool,
  ]);

  const updatePoint = (e, subject_has_score_id, student_id, old_point) => {
    const value = isPrimarySchool ? Math.round(e.target.value) : e.target.value;
    if (value === "" || value === null || value === undefined) return;
    let point = parseFloat(value);
    if (point === old_point) return;
    if (point > 10) point = 10;
    if (point < 0) point = 0;
    if (isNaN(point)) point = null;

    const params = {
      semester,
      subject_has_score_id,
      student_id,
      value_number: point,
    };

    updateChangingTranscript(params);
  };

  const updateNote = (e, subject_has_score_id, student_id) => {
    const value = e.target.value;
    const params = {
      semester,
      subject_has_score_id,
      student_id,
      value_text: value,
    };

    updateChangingTranscript(params);
  };

  const updateText = (value, subject_has_score_id, student_id) => {
    let valueText = "";

    if (isPrimarySchool) {
      valueText = scoreTypeTextList.find((x) => x.id === value)?.name || "";
    } else valueText = value;

    const params = {
      semester,
      subject_has_score_id,
      student_id,
      value_text: valueText,
      evaluate_value_id: isPrimarySchool ? value : null,
    };

    updateChangingTranscript(params);
  };

  const calculateAverage = async () => {
    try {
      if (isChangingData) {
        const res = await saveAll(true);
        if (!res) return;
      }
      const params = {
        school_year_id: schoolYear?.id,
        class_id: classId,
        subject_id: subject,
        semester,
      };
      const res = await calculateAverageTranscript(params);
      if (res?.data?.code === "success") {
        message.success("Tính điểm trung bình thành công!");
        checkExistsAvgTranscript();
        getData();
      }
    } catch (error) {}
  };

  const exportExcel = () => {
    handleExport(
      subjectScoreData,
      gradeList,
      semesterList,
      subjectList,
      classList,
      grade,
      semester,
      subject,
      classId,
      transcriptData
    );
  };

  const updateChangingTranscript = (item) => {
    setChangingCell({
      subject_has_score_id: item?.subject_has_score_id,
      student_id: item?.student_id,
    });
    setChangingTranscript((prevArr) => {
      let elemIndex = prevArr.findIndex(
        (x) =>
          x?.subject_has_score_id === item?.subject_has_score_id &&
          x?.student_id === item?.student_id
      );
      if (elemIndex > -1) {
        prevArr[elemIndex]["value_number"] = item?.value_number;
        prevArr[elemIndex]["value_text"] = item?.value_text;
      } else {
        prevArr = [...prevArr, item];
      }
      return prevArr;
    });
  };

  const saveAll = async (isCalculateAvg = false) => {
    try {
      const promises = [];
      if (changingTranscript?.length > 0) {
        const params = {
          school_year_id: schoolYear?.id,
          class_id: classId,
          data_transcript: changingTranscript,
        };

        promises.push(updateMultiTranscript(params));
      }

      if (changingComment?.length > 0) {
        promises.push(
          updateMultiComment2({
            class_id: classId,
            subject_id: subject,
            semester: semester,
            data_comment: changingComment,
          })
        );
      }

      if (promises?.length > 0) {
        const responses = await Promise.all(promises);
        if (responses?.every((i) => i?.data?.code === "success")) {
          message.success("Lưu thành công");
          setChangingTranscript([]);
          setChangingComment([]);
          if (!isCalculateAvg) getData();
          return true;
        }
      }

      return false;
    } catch {
      return false;
    }
  };

  const handleUpdateCommentId = (e, student_id, column_key) => {
    let comment = "";
    let selectedComment = commentSettingList.find((x) => x?.id === e);
    if (selectedComment) comment = selectedComment?.content;

    const params = {
      study_comment_setting_id: e,
      student_id,
      comment,
    };

    updateChangingComment(params, column_key);
  };

  const handleUpdateCommentContent = (e, student_id, column_key) => {
    const params = {
      comment: e,
      student_id,
    };

    updateChangingComment(params, column_key);
  };

  const updateChangingComment = (item, column_key) => {
    setChangingCell({
      student_id: item?.student_id,
      column_key,
    });
    setChangingComment((prevArr) => {
      let elemIndex = prevArr.findIndex(
        (x) => x?.student_id === item?.student_id
      );

      if (elemIndex > -1) {
        prevArr[elemIndex]["study_comment_setting_id"] =
          item?.study_comment_setting_id ??
          prevArr[elemIndex]["study_comment_setting_id"];
        prevArr[elemIndex]["comment"] = item?.comment;
      } else {
        prevArr = [...prevArr, item];
      }
      return prevArr;
    });
  };

  const goBack = () => {
    history.goBack();
  };
  //////////////////////////////////////////////////
  // #endregion FUNCTIONS //////////////////////////

  // #region    VIEWS //////////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion VIEWS //////////////////////////////
  return (
    <div className={`py-5 h-full content-background ${styles.root}`}>
      <div className={`flex justify-between items-center`}>
        <CustomBreadcrumb
          crumbs={["/home", "/transcript", "/transcript-detail"]}
        />
        <div className={`flex justify-end mBottom26`}>
          <CheckPullScoreCohotaButton
            schoolYearId={schoolYear?.id}
            semester={semester}
            classId={classId}
            subject={subject}
          />
          <PullScoreCohotaButton
            schoolYearId={schoolYear?.id}
            semester={semester}
            classId={classId}
            subject={subject}
          />
          <ExportButton onClick={exportExcel} />
        </div>
      </div>
      <div className={`bg-white px-4 py-4 section-shadow`}>
        <div className="grid grid-cols-4 gap-x-4 pb-4">
          <SelectOption
            data={semesterList}
            value={semester}
            setValue={setSemester}
            valueFieldName="key"
            valueTextName="value"
            className={`mr-3`}
            disabled={true}
          />
          <SelectOption
            data={gradeList}
            value={grade}
            setValue={setGrade}
            className={`mr-3`}
            disabled={true}
          />

          <SelectOption
            data={subjectList}
            value={subject}
            setValue={setSubject}
            className={`mr-3`}
            disabled={true}
          />

          <SelectOption
            data={classList}
            value={classId}
            setValue={setClassId}
            className={`mr-3`}
            allowClear={false}
          />
        </div>

        <div className={`flex items-end mBottom12`}>
          <StyledButton
            onClick={goBack}
            theme="blue"
            className={`mRight12 ${styles.btn}`}
          >
            Quay về
          </StyledButton>
          <StyledButton
            onClick={calculateAverage}
            disabled={
              !(schoolYear?.id && classId && subject && semester) ||
              isExistsAvgTranscript === true ||
              isPrimarySchool === true ||
              !transcriptInfo?.isAllowToUpdate
            }
            className={`bg-blueDefault mRight12 ${styles.btn} ${
              (isExistsAvgTranscript === true || isPrimarySchool === true) &&
              "hidden"
            }`}
          >
            <span className="text-base font-semibold text-white">
              Tính điểm trung bình
            </span>
          </StyledButton>
          <StyledButton
            className={`bg-blueDefault ${styles.btn}`}
            onClick={() => saveAll(false)}
            disabled={!isChangingData}
          >
            <span className="text-base font-semibold text-white">Lưu</span>
          </StyledButton>
        </div>
        <CusTable
          rowKey="id"
          data={transcriptData}
          columns={columns}
          havePagination={false}
          scroll={{ y: 600 }}
          loading={tableLoading}
        />

        <CommentModal
          visible={showCommentModal}
          onClose={closeCommentModal}
          onConfirm={onConfirmComment}
          inputValue={modalInputValue}
          setInputValue={setModalInputValue}
        />
      </div>
    </div>
  );
}
