import { InputNumber } from "antd";
import { triangleDownIcon } from "asset/img/Images";
import SelectOption from "common/select/selectOption/SelectOption";
import React, { useState } from "react";
import ConditionItem from "./components/ConditionItem";
import styles from "./styles.module.css";

const SUBJECT_COUNT = Array(3).fill(1); // => [1, 1, 1]

export default function ClassItem({
  data,
  index,
  titleList = [],
  subjectList = [],
}) {
  // #region    VARIABLES //////////////////////////
  //////////////////////////////////////////////////
  const isFirst = index === 0;
  const [expanded, setExpanded] = useState(false);
  //////////////////////////////////////////////////
  // #endregion VARIABLES //////////////////////////

  // #region    useEffect //////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion useEffect //////////////////////////

  // #region    FUNCTIONS //////////////////////////
  //////////////////////////////////////////////////
  const toggleExpand = () => setExpanded((prev) => !prev);

  const handleSetTitle = (value) => {};

  const handleSetTotal = (value) => {};
  //////////////////////////////////////////////////
  // #endregion FUNCTIONS //////////////////////////

  // #region    VIEWS //////////////////////////////
  //////////////////////////////////////////////////
  const renderChild = (_, index) => (
    <ConditionItem
      key={`condition_item_${index}`}
      index={index}
      subjectList={subjectList}
      data={{}}
    />
  );
  //////////////////////////////////////////////////
  // #endregion VIEWS //////////////////////////////
  return (
    <>
      <div className={`${styles.root} ${isFirst ? "" : styles.borderTop}`}>
        <button
          onClick={toggleExpand}
          className={`w-full pLeft8 flex justify-between items-center ${styles.row}`}
        >
          <div className="flex items-center mRight20">
            <div className="mRight16">
              <img
                src={triangleDownIcon}
                className={`${styles.triangle} ${!expanded && styles.rotate90}`}
              />
            </div>
            <div className={`mRight16 flex justify-start ${styles.cellText}`}>
              <span className={styles.text}>Khối 8</span>
            </div>
            <div className={`flex justify-start ${styles.cellText}`}>
              <span className={styles.text}>Lớp 9A9</span>
            </div>
          </div>

          <div className="flex items-center">
            <InputNumber
              min={0}
              max={1000}
              value={data?.total || 0}
              onChange={handleSetTotal}
              className={`styled-input flex items-center mRight16 ${styles.input}`}
            />
            <div className={styles.input}>
              <SelectOption
                data={titleList}
                value={data?.title}
                setValue={handleSetTitle}
                allowClear={false}
              />
            </div>
          </div>
        </button>
      </div>
      {expanded && SUBJECT_COUNT.map(renderChild)}
    </>
  );
}
