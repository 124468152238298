export const SET_SCHOOL_LIST = "SET_SCHOOL_LIST";
export const SET_SELECTED_SCHOOL = "SET_SELECTED_SCHOOL";

export function setSchoolListAction(data) {
  return {
    type: SET_SCHOOL_LIST,
    payload: data,
  };
}

export function setSelectedSchoolAction(data) {
  return {
    type: SET_SELECTED_SCHOOL,
    payload: data,
  };
}
