import React, { memo } from "react";
import { usePreviewContext } from "../../context/PreviewContext";
import IconDownload2 from "../../icons/IconDownload2";

const NoPreview = memo(({ onDownload }) => {
  const { translate } = usePreviewContext();

  return (
    <div className="no-preview">
      <label>{translate("learner.assessment.there_are_no_previews_yet")}</label>
      <button className="btn-fill--hightlight h32" onClick={onDownload}>
        <IconDownload2 />
        <span>{translate("common_text.download", "Tải xuống")}</span>
      </button>
    </div>
  );
});

NoPreview.displayName = "NoPreview";
export default NoPreview;
