import { createContext, memo, useContext, useMemo } from 'react';

const Context = createContext({
    translate: (key, defaulValue) => defaulValue,
    toast: {
        error: message => console.error(message),
    },
});

export const PreviewContextProvider = memo(({ children, value }) => {
    const defaulValue = useContext(Context);
    const combinedValue = useMemo(
        () => ({ ...defaulValue, ...value }),
        [defaulValue, value]
    );
    return (
        <Context.Provider value={combinedValue}>{children}</Context.Provider>
    );
});
PreviewContextProvider.displayName = 'PreviewContextProvider';

export const PreviewContextConsumer = Context.Consumer;
export const usePreviewContext = () => useContext(Context);
