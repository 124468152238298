import { message, Modal } from "antd";
import ConfirmCloseComponent from "common/ConfirmCloseComponent";
import InfoFieldCheckbox from "common/CusInfoField/infoFieldCheckbox/InfoFieldCheckbox";
import InfoFieldSelect from "common/CusInfoField/infoFieldSelect/InfoFieldSelect";
import FileShower from "common/filePicker/component/FileShower";
import FilePicker from "common/filePicker/FilePicker";
import ResultModal from "common/ResultModal";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getSetting } from "util/APIHelper";
import { massUploadStudent } from "util/APIs";
import { PROGRESS_STATUS, SETTING_GROUP_KEYS } from "util/constant";
import { logMessage, logMessageDirect } from "util/helper";

export default function ImportStudentModal({
  title = "Nhập liệu từ file Excel",
  visible,
  onClose = () => {},
  successCallback,
}) {
  // #region    VARIABLES //////////////////////////
  //////////////////////////////////////////////////
  const schoolYear = useSelector(
    (state) => state.schoolYear.selectedSchoolYear
  );
  const [importResult, setImportResult] = useState(PROGRESS_STATUS.success);
  const school = useSelector((state) => state.school.selectedSchool);
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [isAsiaTemplate, setIsAsiaTemplate] = useState(false);
  const [resultVisible, setResultVisible] = useState(false);
  const [selectedLevel, setSelectedLevel] = useState("");
  const [templates, setTemplates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [levels, setLevels] = useState([]);
  const [files, setFiles] = useState(null);
  const [log, setLog] = useState(null);
  //////////////////////////////////////////////////
  // #endregion VARIABLES //////////////////////////

  // #region    useEffect //////////////////////////
  //////////////////////////////////////////////////
  useEffect(() => {
    const temp = school?.level?.map((i) => ({
      id: i + "_student_info",
      name: i,
    }));
    setLevels(temp);
    setSelectedLevel(temp?.[0]?.id || "");
  }, [school?.level]);

  useEffect(() => {
    setTemplates([
      "https://docs.google.com/spreadsheets/d/1K91tWzWzgjTO0T0U_WQPeAE0ojL8_9Z4/edit#gid=1240703195",
    ]);
    // getSetting(SETTING_GROUP_KEYS.template_import, setTemplates);
  }, []);

  useEffect(() => {
    if (templates?.length > 0) {
      setSelectedTemplate(
        templates?.find((i) => i.key === selectedLevel)?.value || ""
      );
    }
  }, [selectedLevel, templates]);
  //////////////////////////////////////////////////
  // #endregion useEffect //////////////////////////

  // #region    FUNCTIONS //////////////////////////
  //////////////////////////////////////////////////
  const handleAddAttachments = (values) => {
    const temp = [...values];
    setFiles(temp);
  };

  const handleRemoveAttachment = (_value, index) => {
    let temp = [...files];
    temp.splice(index, 1);
    setFiles(temp);
  };

  const validation = () => {
    if (!files?.length) {
      message.error("Vui lòng chọn file nhập liệu");
      return false;
    }
    if (!schoolYear?.id) {
      message.error("Vui lòng chọn niên khóa hiện hành");
      return false;
    }
    if (!selectedLevel) {
      message.error("Vui lòng chọn cấp bậc trường");
      return false;
    }
    return true;
  };

  const handleConfirm = async () => {
    try {
      setLoading(true);
      if (!validation()) return;
      const params = {
        file: files?.[0],
        school_year_id: schoolYear?.id,
        object_group: isAsiaTemplate ? "student_a_chau" : selectedLevel,
      };
      const res = await massUploadStudent(params);
      if (res?.data?.code === "success") {
        onClose();
        setImportResult(PROGRESS_STATUS.success);
        if (res?.data?.data) {
          setLog(logMessageDirect(res?.data?.data, false));
        }
        successCallback && successCallback();
      } else {
        setImportResult(PROGRESS_STATUS.failed);
        setLog(logMessage(res, false));
      }
    } catch (error) {
      setImportResult(PROGRESS_STATUS.failed);
    } finally {
      setLoading(false);
      if (validation()) showResult();
    }
  };

  const showResult = () => setResultVisible(true);

  const hideResult = () => setResultVisible(false);
  //////////////////////////////////////////////////
  // #endregion FUNCTIONS //////////////////////////

  // #region    VIEWS //////////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion VIEWS //////////////////////////////
  return (
    <>
      <Modal
        title={<span className={`font-bold text-lg`}>{title}</span>}
        open={visible}
        onCancel={loading ? () => {} : onClose}
        onOk={handleConfirm}
        cancelText="Hủy"
        okText="Nhập"
        confirmLoading={loading}
        zIndex={100}
      >
        {/* {levels?.length > 1 && (
          <InfoFieldSelect
            label="Cấp bậc"
            data={levels}
            value={selectedLevel}
            setValue={setSelectedLevel}
            allowClear={false}
            className="mBottom12"
          />
        )} */}
        {selectedTemplate && (
          <a
            href={selectedTemplate}
            target="_blank"
            download
            className="text-blueDefault cursor-pointer"
          >
            Tải file mẫu
          </a>
        )}
        <div className="mTop12 flex items-start">
          <div className="mRight12">
            <FilePicker setFiles={handleAddAttachments} />
          </div>
          <FileShower
            files={files}
            removeFile={handleRemoveAttachment}
            fileOnClick={() => {}}
          />
        </div>
        <ConfirmCloseComponent isActive={loading} />
      </Modal>
      <ResultModal
        visible={resultVisible}
        result={importResult}
        onClose={hideResult}
        log={log}
      />
    </>
  );
}
