import {
  SET_SETTING_PAGE_CLASS_LIST_CURRENT_PAGE,
  SET_SETTING_PAGE_CLASS_LIST_FILTER_GRADE,
  SET_SETTING_PAGE_CLASS_LIST_FILTER_MAIN_SESSION,
  SET_SETTING_PAGE_CLASS_LIST_FILTER_TYPE,
} from "redux/action/settingPageClassListAction";
import { FILTER_ALL_OBJECT_ID_NAME } from "util/constant";
import { deepClone } from "util/helper";

export default function settingPageClassListReducer(
  state = {
    filterMainSession: null,
    filterType: null,
    filterGrade: FILTER_ALL_OBJECT_ID_NAME,
    currentPage: 1,
  },
  action
) {
  switch (action.type) {
    case SET_SETTING_PAGE_CLASS_LIST_FILTER_MAIN_SESSION:
      return {
        ...state,
        filterMainSession: deepClone(action.payload),
      };
    case SET_SETTING_PAGE_CLASS_LIST_FILTER_TYPE:
      return {
        ...state,
        filterType: deepClone(action.payload),
      };
    case SET_SETTING_PAGE_CLASS_LIST_FILTER_GRADE:
      return {
        ...state,
        filterGrade: deepClone(action.payload),
      };
    case SET_SETTING_PAGE_CLASS_LIST_CURRENT_PAGE:
      return {
        ...state,
        currentPage: deepClone(action.payload),
      };
    default:
      return state;
  }
}
