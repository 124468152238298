import { FileType } from "../enums/FileType";

/**
 * Download file from Blob
 * @param {Blob} blob
 * @param {String} fileName
 */
const downloadFileFromBlob = (blob, fileName) => {
  if (blob instanceof Blob) {
    const blobUrl = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = blobUrl;
    link.target = "_blank";
    link.rel = "noopener noreferrer";
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  }
};

/**
 * Fetch blob by url & mimeType
 * @param {*} url
 * @param {*} type
 * @returns
 */
const fetchBlob = async (url, type, abortController) => {
  let restObj = {
    method: "GET",
    headers: {},
  };
  if (abortController) {
    restObj.signal = abortController.signal;
  }
  try {
    const response = await fetch(url, restObj);
    const content = await response.blob();
    let options = {};
    if (type) {
      options.type = type;
    }
    return new Blob([content], options);
  } catch (e) {
    return null;
  }
};

/**
 * Get file name with extension
 * @param {String} currentName
 * @param {String} mimeType
 * @returns {String}
 */
const getFileNameWithExtension = (currentName, mimeType) => {
  const mime = require("mime-types");
  const extensions = mime.extensions[mimeType];
  if (extensions?.length) {
    const currentExt = getExtensionFromString(currentName);
    if (currentExt && extensions.some((v) => v === currentExt)) {
      return currentName;
    }
    return `${currentName}.${extensions[0]}`;
  }
  return currentName;
};

/**
 * Get extension from string
 * @param {String} string
 * @returns
 */
const getExtensionFromString = (string) => {
  const regexp = /\.([0-9a-z]+)(?:[\?#]|$)/i;
  let extension = string.match(regexp);
  return extension ? extension[1] || "" : "";
};

/**
 * Blob to base64
 * @param {Blob} blob
 * @returns
 */
const blobToBase64Async = async (blob) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onerror = (e) => reject(fileReader.error);
    fileReader.onloadend = (e) => {
      const dataUrl = fileReader.result;
      const base64 = dataUrl.substring(dataUrl.indexOf(",") + 1);
      resolve(base64);
    };
    fileReader.readAsDataURL(blob);
  });
};

/**
 * Get Files from datatransfer
 * @param {*} dataTransferItems
 * @returns
 */
const getFilesWebkitDataTransferItems = async (dataTransferItems) => {
  let files = [];
  const traverseFileTree = async (item, path = "", itemAsFile) => {
    if (item.isFile) {
      let itemFile = null;
      if (itemAsFile) {
        itemFile = itemAsFile;
      } else {
        itemFile = await _getFileEntry(item);
      }
      if (!itemFile.type) {
        const mime = require("mime-types");
        let newType = mime.lookup(itemFile.name) || "text/plain";
        itemFile = new File([itemFile], itemFile.name, {
          type: newType,
        });
      }
      itemFile.filepath = path + itemFile.name; //save full path
      files.push(itemFile);
    } else if (item.isDirectory) {
      const entries = await _readDirectoryEntries(item);
      for (let entr of entries) {
        await traverseFileTree(entr, path + item.name + "/");
      }
    }
  };
  for (const item of dataTransferItems) {
    await traverseFileTree(item.webkitGetAsEntry(), "", item.getAsFile());
  }
  return files;
};
const _getFileEntry = (fileEntry) =>
  new Promise((resolve) => {
    fileEntry.file((f) => {
      resolve(f);
    });
  });
const _readDirectoryEntries = (item) =>
  new Promise((resolve) => {
    let dirReader = item.createReader();
    dirReader.readEntries((entries) => {
      resolve(entries);
    });
  });

/**
 * Get FileType from mimetype
 * @param {String} mimeType
 * @returns
 */
const getFileTypeFromMimeType = (mimeType) => {
  const { constant } = require("./constant");
  if (constant.SUPPORTED_IMAGE_TYPES.some((t) => t === mimeType)) {
    return FileType.IMAGE;
  }
  if (constant.PDF_MIMETYPES.some((t) => t === mimeType)) {
    return FileType.PDF;
  }
  return "";
};

export {
  downloadFileFromBlob,
  fetchBlob,
  getFileNameWithExtension,
  getExtensionFromString,
  blobToBase64Async,
  getFilesWebkitDataTransferItems,
  getFileTypeFromMimeType,
};
