import { belowUniversityTexts, universityTexts } from "./data";

// School type text adapter
export default function STTA(text, isUniversity = false) {
  // #region    VARIABLES //////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion VARIABLES //////////////////////////

  // #region    useEffect //////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion useEffect //////////////////////////

  // #region    FUNCTIONS //////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion FUNCTIONS //////////////////////////
  const result = isUniversity
    ? universityTexts?.[text]
    : belowUniversityTexts?.[text];
  // Nếu chưa khai báo thì trả ra chuỗi đã truyền vào
  if (typeof result === "string") {
    return result;
  } else {
    return text;
  }
}
