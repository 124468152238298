import { useSelector } from "react-redux";

export default function useIsSchoolType(type) {
  // #region    VARIABLES //////////////////////////
  //////////////////////////////////////////////////
  const school = useSelector((state) => state.school.selectedSchool);
  // const school = JSON.parse(localStorage.getItem("school")) || {};
  //////////////////////////////////////////////////
  // #endregion VARIABLES //////////////////////////

  // #region    useEffect //////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion useEffect //////////////////////////

  // #region    FUNCTIONS //////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion FUNCTIONS //////////////////////////
  return school?.level?.includes(type);
}
