import { message } from "antd";

export const validateTeacher = (data) => {
  if (!data?.full_name) {
    message.error("Vui lòng nhập Họ tên");
    return false;
  }
  if (!data?.birthday) {
    message.error("Vui lòng nhập Ngày sinh");
    return false;
  }
  if (!data?.nationality) {
    message.error("Vui lòng nhập Quốc tịch");
    return false;
  }
  if (!data?.ethnic) {
    message.error("Vui lòng chọn Dân tộc");
    return false;
  }
  if (!data?.place_of_birth_info?.description) {
    message.error("Vui lòng nhập Nơi sinh");
    return false;
  }

  if (!data?.place_of_permanent_info?.province_city) {
    message.error("Vui lòng nhập Tỉnh/TP thường trú");
    return false;
  }
  if (!data?.place_of_permanent_info?.district) {
    message.error("Vui lòng nhập Quận/Huyện thường trú");
    return false;
  }
  if (!data?.place_of_permanent_info?.sub_district) {
    message.error("Vui lòng nhập Phường/Xã thường trú");
    return false;
  }

  if (!data?.current_address?.province_city) {
    message.error("Vui lòng nhập Tỉnh/TP hiện tại");
    return false;
  }
  if (!data?.current_address?.district) {
    message.error("Vui lòng nhập Quận/Huyện hiện tại");
    return false;
  }
  if (!data?.current_address?.sub_district) {
    message.error("Vui lòng nhập Phường/Xã hiện tại");
    return false;
  }
  if (!data?.current_address?.description) {
    message.error("Vui lòng nhập Số nhà hiện tại");
    return false;
  }
  // if (!data?.status) {
  //   message.error("Vui lòng chọn Trạng thái cán bộ");
  //   return false;
  // }
  return true;
};

export const teacherStringFields = [
  "province_city",
  "district",
  "sub_district",
  "gender",
  "religion",
  "position",
  "main_teaching_subject",
  "sub_teaching_subject",
  "position_group",
  "contract_form",
  "concurrent_position",
  "rank",
  "salary_scale",
  "regular_training_result",
  "professional_qualification",
  "political_theory_degree",
  "educational_management_degree",
  "participate_education_management_training",
  "participate_manager_cadres_training",
  "participate_new_textbook_training",
  "primary_language",
  "foreign_language_training",
  "foreign_language_certificate_group",
  "foreign_language_certificate_type",
  "foreign_language_level",
  "it_level",
  "main_major",
  "main_level",
  "secondary_major",
  "secondary_level",
  "has_minority_language_certificate",
  "evaluate_result",
  "good_teacher_level",
  "form_teacher_level",
  "leader_of_pioneer_level",
];

export const teacherArrayFields = [
  "level",
  "teaching_language_program",
  "concurrent_position",
];

export const teacherZeroFields = [
  "reality_lesson_per_week",
  "concurrent_lesson_per_week",
];
