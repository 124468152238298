import React from "react";
import styles from "./styles.module.css";

export default function CusButton({
  text = "",
  icon,
  iconPosition = "left",
  onClick = () => {},
  style = {},
  className = "",
  iconClassName = "",
  textClassName = "",
  disabled = false,
  ...props
}) {
  const haveIconOnLeft = !!icon && iconPosition === "left";
  const haveIconOnRight = !!icon && iconPosition === "right";

  const renderIcon = () => (
    <img
      src={icon}
      alt=""
      className={`${!text ? "w100 h100" : ""} ${
        iconClassName ? iconClassName : styles.defaultIcon
      }`}
    />
  );

  return (
    <button
      onClick={onClick}
      disabled={disabled}
      style={style}
      className={`flex align-center cursor-pointer ${styles.cusButton} ${
        disabled ? "opa50" : ""
      } ${className}`}
      {...props}
    >
      {haveIconOnLeft ? renderIcon() : null}
      {!!text ? (
        <span
          className={`${haveIconOnLeft && "mLeft6"} ${
            haveIconOnRight && "mRight6"
          } ${textClassName}`}
        >
          {text}
        </span>
      ) : null}
      {haveIconOnRight ? renderIcon() : null}
    </button>
  );
}
