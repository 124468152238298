import { closeIcon, fileIcon2 } from "asset/img/Images";
import Img from "common/image/Img";
import React from "react";
import styles from "./styles.module.css";

export default function FileShower({
  files,
  cols = 3,
  emptyText = "",
  isShowDeleteBtn = true,
  removeFile = (_file, _index) => {},
  fileOnClick = (_file, _index) => {},
}) {
  // #region    VARIABLES //////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion VARIABLES //////////////////////////

  // #region    useEffect //////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion useEffect //////////////////////////

  // #region    FUNCTIONS //////////////////////////
  //////////////////////////////////////////////////
  const isShowName = (file) => {
    let result = false;
    if (file?.name || file?.file_name) result = true;
    if (typeof file === "string" && getFileFullnameFromPath(file))
      result = true;
    return result;
  };

  const getFileFullnameFromPath = (path) => {
    // path: https://media.educentric.vn/media/homework_attachment/cebf87b9-b233-43ff-a9e6-069621898120/14_12_2021_14_06_47_avatar.jpg
    const temp = path?.split("/");
    if (!temp?.length) return "";
    const serverFileFullName = temp[temp.length - 1];
    // serverFileFullName: 14_12_2021_14_06_47_avatar.jpg
    const temp2 = serverFileFullName?.split(".");
    if (!temp2?.length) return "";
    const extension = temp2?.pop() || "";
    // extension: jpg
    const serverFileName = temp2?.join(".") || "";
    // serverFileName: 14_12_2021_14_06_47_avatar
    const originFileName = serverFileName?.substring(20) || "";
    // originFileName: avatar
    if (originFileName && extension) return `${originFileName}.${extension}`;
    else return "";
  };

  const handleRemove = (event, file, index) => {
    event.stopPropagation();
    removeFile(file, index);
  };
  //////////////////////////////////////////////////
  // #endregion FUNCTIONS //////////////////////////

  // #region    VIEWS //////////////////////////////
  //////////////////////////////////////////////////
  const renderFile = (file, index) => {
    return (
      <div
        className={`flex flex-col items-center ${styles.fileWrapper}`}
        onClick={() => fileOnClick(file, index)}
        key={`${index}`}
      >
        <div className={styles.fileImg}>
          <Img src={fileIcon2} alt="file" />
          {isShowDeleteBtn ? (
            <div
              className={`${styles.removeBtn}`}
              onClick={(event) => handleRemove(event, file, index)}
            >
              <Img src={closeIcon} alt="remove" />
            </div>
          ) : undefined}
        </div>
        {isShowName(file) ? (
          <div className={`truncate ${styles.fileNameWrapper}`}>
            <span className={`${styles.fileName}`}>
              {file?.name ||
                file?.file_name ||
                getFileFullnameFromPath(file) ||
                ""}
            </span>
          </div>
        ) : undefined}
      </div>
    );
  };
  //////////////////////////////////////////////////
  // #endregion VIEWS //////////////////////////////
  if (files?.length > 0) {
    return (
      <div className={`grid grid-cols-${cols} ${styles.root}`}>
        {files.map(renderFile)}
      </div>
    );
  } else {
    return (
      <div>
        <span>{emptyText}</span>
      </div>
    );
  }
}
