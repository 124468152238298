import { Button, Input, message, Modal } from "antd";
import { binIcon, newEditIcon, searchIcon } from "asset/img/Images";
import { isPermitted } from "authen/authenHelper";
import CustomBreadcrumb from "common/breadcrumb/CustomBreadcrumb";
import CusTable from "common/CusTable";
import ExportButton from "common/ExportButton";
import Img from "common/image/Img";
import ImportButton from "common/ImportButton";
import SelectOption from "common/select/selectOption/SelectOption";
import SimpleTooltip from "common/tooltip/simpleTooltip/SimpleTooltip";
import useIsSchoolType from "hook/isUniversity";
import STTA from "hook/schoolTypeTextAdapter";
import useStaffListPageRedux from "hook/useStaffListPageRedux";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { deleteStaff, getStaffList } from "util/APIs";
import {
  DATE_FORMAT,
  PERMISSION_OBJECT,
  PERMISSION_TYPE,
  SEARCH_DELAY_TIME,
  SETTING_GROUP_KEYS,
  TABLE_PAGE_SIZES,
  UNIVERSITY,
} from "util/constant";
import { getGenderText } from "util/helper";
import ImportTeacherModal from "./components/ImportTeacherModal";
import AddTeacherModal from "./components/AddTeacherModal";
import styles from "./styles.module.css";
import { formatDateValue } from "util/common";
import MobileHeader from "mobile/layout/header/MobileHeader";
import { HEADER_TYPE } from "mobile/util/mobileConstant";
import { routes } from "util/routes";
import IsMobile from "hook/isMobile";

export default function TeacherManagementPage() {
  // #region    VARIABLES //////////////////////////
  //////////////////////////////////////////////////
  const isUsingMobile = IsMobile();
  const userInfo = useSelector((state) => state?.app?.userInfo);
  const accountId = userInfo?.account?.id;
  const school = useSelector((state) => state.school.selectedSchool);

  const isAllowToCreate = isPermitted(
    PERMISSION_OBJECT.staff_management,
    PERMISSION_TYPE.create
  );
  const isAllowToUpdate = isPermitted(
    PERMISSION_OBJECT.staff_management,
    PERMISSION_TYPE.update
  );
  const isAllowToDelete = isPermitted(
    PERMISSION_OBJECT.staff_management,
    PERMISSION_TYPE.delete
  );
  const schoolYear = useSelector(
    (state) => state.schoolYear.selectedSchoolYear
  );
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(TABLE_PAGE_SIZES[0]);
  const [showImportModal, setShowImportModal] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [teacherList, setTeacherList] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [deleteId, setDeleteId] = useState("");
  const [search, setSearch] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const history = useHistory();

  const columns = [
    {
      title: "STT",
      align: "center",
      key: "STT",
      render: (value, record, index) =>
        (currentPage - 1) * pageSize.value + index + 1,
      width: "50px",
      fixed: "left",
    },
    {
      title: "Họ",
      align: "left",
      key: `first_name`,
      dataIndex: "first_name",
      render: (text, record) => <span>{record?.first_name}</span>,
      width: 200,
    },
    {
      title: "Tên",
      align: "left",
      key: `last_name`,
      dataIndex: "last_name",
      render: (text, record) => <span>{record?.last_name}</span>,
      width: 200,
    },
    {
      title: "Giới tính",
      align: "left",
      key: `gender`,
      dataIndex: "gender",
      render: (text, record) => <span>{getGenderText(record?.gender)}</span>,
      width: 100,
    },
    {
      title: "Ngày sinh",
      align: "left",
      key: `birthday`,
      dataIndex: "birthday",
      render: (text, record) => formatDateValue(record?.dob),
      width: 200,
    },
    {
      title: " ",
      align: "right",
      key: "action",
      render: (value, record) => {
        return (
          <div className="flex justify-end pr-4">
            <button
              onClick={() => goToEditPage(record)}
              disabled={!isAllowToUpdate}
            >
              <img src={newEditIcon} alt="Edit" className="mr-4" />
            </button>
            <button
              onClick={() => onClickDelete(record?.id)}
              disabled={!isAllowToDelete}
            >
              <img src={binIcon} alt="Delete" />
            </button>
          </div>
        );
      },
      width: 100,
    },
  ];
  //////////////////////////////////////////////////
  // #endregion VARIABLES //////////////////////////

  // #region    useEffect //////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion useEffect //////////////////////////

  // #region    FUNCTIONS //////////////////////////
  //////////////////////////////////////////////////

  const handleSetPageSize = (size) => {
    setPageSize(size);
    getData(1, size?.value);
  };

  const getData = useCallback(
    (page = null, size = null) => {
      if (page === null) page = currentPage;
      if (size === null) size = pageSize?.value;
      setTableLoading(true);
      const params = {
        "pagination[page]": page,
        "pagination[pageSize]": pageSize.value,
      };
      if (search) {
        params["filters[full_name][$containsi]"] = search;
      }
      getStaffList(accountId, params).then((res) => {
        if (res?.status === 200) {
          setTeacherList(res?.data?.data);
          setTotalItems(parseInt(res?.data?.pagination?.total));
          setCurrentPage(parseInt(res?.data?.pagination?.page));
          setTableLoading(false);
        }
      });
    },
    [accountId, currentPage, pageSize.value, search]
  );

  const handleGoToCreate = () => {
    // history.push({
    //   pathname: "/teacher-detail",
    //   state: {
    //     schoolYearData,
    //     teachingGroupData: teachingGroup,
    //   },
    // });
  };

  const handleSetSearch = (e) => {
    setSearch(e.target.value);
  };

  const goToEditPage = (record) => {
    const account = JSON.parse(localStorage.getItem("user_info")).account;
    const staffId = record.id;
    history.push({
      pathname: `/admin/staff-management/edit/${staffId}`,
      state: {
        data: {
          account: account,
          user: record,
        },
      },
    });
  };

  const onClickDelete = (id) => {
    setDeleteId(id);
    setShowConfirmModal(true);
  };

  const handleDelete = async () => {
    try {
      const res = await deleteStaff(deleteId);
      if (res?.statusText === "OK") {
        setShowConfirmModal(false);
        message.success("Xóa thành công");
        getData();
      }
    } catch (error) {}
  };

  const openImportModal = () => {
    setShowImportModal(true);
  };
  const hideImportModal = () => {
    setShowImportModal(false);
  };

  const importSuccessCallback = () => {
    getData(1);
  };
  //////////////////////////////////////////////////
  // #endregion FUNCTIONS //////////////////////////

  // #region    VIEWS //////////////////////////////
  //////////////////////////////////////////////////
  const rowWithTooltip = (props) => (
    <SimpleTooltip>
      <tr {...props} />
    </SimpleTooltip>
  );

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    if (typeof search !== "string") return;
    const searchTimeout = setTimeout(() => {
      getData(1);
    }, SEARCH_DELAY_TIME);
    return () => clearTimeout(searchTimeout);
  }, [getData, search]);

  //////////////////////////////////////////////////
  // #endregion VIEWS //////////////////////////////
  return (
    <div className={`h-full flex flex-col ${styles.root}`}>
      {isUsingMobile ? (
        <MobileHeader
          type={HEADER_TYPE.middleTitle}
          title="Quản lý nhân sự"
          haveNotiButton={false}
        />
      ) : (
        <CustomBreadcrumb crumbs={[routes.ADMIN_STAFF_MANAGEMENT]} />
      )}
      {/* <div className={`flex justify-between items-center mBottom26`}>
        <CustomBreadcrumb
          crumbs={["/admin/staff-management"]}
          style={styles.breadcrumb}
        />
      </div> */}
      <div
        className={`section-shadow flex-1 flex flex-col ${styles.sectionLayout}`}
      >
        {/* FILTER FIELDS */}
        <div
          // className={`flex justify-between items-center ${styles.mBottom24}`}
          className={`${styles.filters}`}
        >
          {/* <div className={`flex-1 flex items-center`}> */}
          <Input
            className={`styled-input mRight12 ${styles.searchBar}`}
            value={search}
            onChange={handleSetSearch}
            prefix={
              <div className={`mRight8`}>
                <Img src={searchIcon} alt="search" />
              </div>
            }
            placeholder="Tìm kiếm theo tên"
          />
          {/* </div> */}
          <div className={`${styles.rightFilters}`}>
            <ImportButton
              onClick={openImportModal}
              disabled={true}
              className="mRight12"
            />
            {/* <ExportButton onClick={confirmDownloadReport} className="mRight12" /> */}
            <AddTeacherModal />
          </div>
        </div>

        <CusTable
          rowKey="id"
          data={teacherList}
          columns={columns}
          components={{
            body: {
              row: rowWithTooltip,
            },
          }}
          page={currentPage}
          setPage={setCurrentPage}
          pageSize={pageSize}
          setPageSize={handleSetPageSize}
          totalItems={totalItems}
          loading={tableLoading}
          havePagination={totalItems > pageSize.value}
          scroll={{ x: "1000px" }}
        />
      </div>

      <Modal
        title={<span className={`font-bold text-lg`}>Xác nhận</span>}
        centered
        visible={showConfirmModal}
        onOk={handleDelete}
        onCancel={() => setShowConfirmModal(false)}
        okText="Xóa"
        cancelText="Hủy"
      >
        <p>Bạn có chắc muốn xóa Thông tin nhân sự này?</p>
      </Modal>

      <ImportTeacherModal
        visible={showImportModal}
        onClose={hideImportModal}
        successCallback={importSuccessCallback}
      />
    </div>
  );
}
