import {
  SET_STUDENT_LIST_PAGE_CURRENT_PAGE,
  SET_STUDENT_LIST_PAGE_FILTER_CLASS,
  SET_STUDENT_LIST_PAGE_FILTER_GRADE,
} from "redux/action/studentListPageAction";
import { deepClone } from "util/helper";

export default function studentListPageReducer(
  state = {
    filterGrade: null,
    filterClass: null,
    currentPage: 1,
  },
  action
) {
  switch (action.type) {
    case SET_STUDENT_LIST_PAGE_FILTER_GRADE:
      return {
        ...state,
        filterGrade: deepClone(action.payload),
      };
    case SET_STUDENT_LIST_PAGE_FILTER_CLASS:
      return {
        ...state,
        filterClass: deepClone(action.payload),
      };
    case SET_STUDENT_LIST_PAGE_CURRENT_PAGE:
      return {
        ...state,
        currentPage: deepClone(action.payload),
      };
    default:
      return state;
  }
}
