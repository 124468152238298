import {
  SET_SUBSIDEBAR_PROFILE_ACTIVE_MENU,
  SET_SUBSIDEBAR_SCHOOL_ACTIVE_MENU,
  SET_SUBSIDEBAR_SETTING_ACTIVE_MENU,
} from "redux/action/setting";
import { deepClone } from "util/helper";
import {
  PERSONAL_INFORMATION_SUBSIDEBAR_ITEMS,
  SCHOOL_SUBSIDEBAR_ITEMS,
  SETTING_SUBSIDEBAR_ITEMS,
} from "util/sidebars";

export default function settingReducer(
  state = {
    profileActiveMenu: { ...PERSONAL_INFORMATION_SUBSIDEBAR_ITEMS[0] },
    schoolActiveMenu: { ...SCHOOL_SUBSIDEBAR_ITEMS[0] },
    settingActiveMenu: { ...SETTING_SUBSIDEBAR_ITEMS[0] },
  },
  action
) {
  switch (action.type) {
    case SET_SUBSIDEBAR_PROFILE_ACTIVE_MENU:
      return {
        ...state,
        profileActiveMenu: deepClone(action.payload),
      };
    case SET_SUBSIDEBAR_SCHOOL_ACTIVE_MENU:
      return {
        ...state,
        schoolActiveMenu: deepClone(action.payload),
      };
    case SET_SUBSIDEBAR_SETTING_ACTIVE_MENU:
      return {
        ...state,
        settingActiveMenu: deepClone(action.payload),
      };
    default:
      return state;
  }
}
