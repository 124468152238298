import React from "react";

function IconDownload2() {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6464 6.14645L8.5 10.2929V1H7.5V10.2929L3.35355 6.14645L2.64645 6.85355L7.64645 11.8536C7.84171 12.0488 8.15829 12.0488 8.35355 11.8536L13.3536 6.85355L12.6464 6.14645ZM15 14H1V15H15V14Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default IconDownload2;
