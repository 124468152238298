export const excelBorderedStyle = {
  style: "thin",
  color: {
    rgb: "000000",
  },
};

export const excelGlobalStyles = {
  tableHeader: {
    font: {
      bold: true,
    },
    fill: {
      fgColor: {
        rgb: "D9D9D9",
      },
    },
  },
  bordered: {
    border: {
      top: excelBorderedStyle,
      right: excelBorderedStyle,
      bottom: excelBorderedStyle,
      left: excelBorderedStyle,
    },
  },
  textCenter: {
    alignment: {
      horizontal: "center",
      vertical: "center",
      wrapText: true,
    },
  },
  bigTitle: {
    font: {
      sz: 20,
      bold: true,
    },
  },
  bold: {
    font: {
      bold: true,
    },
  },
};
