import { yellowBell } from "asset/img/Images";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getNotiCount } from "util/APIs";
import { mrHeight, mrWidth } from "util/helper";
import styles from "./styles.module.css";
import { routes } from "util/routes";

export default function NotiButton({ className = "" }) {
  // #region    VARIABLES //////////////////////////
  //////////////////////////////////////////////////
  const history = useHistory();
  const [unreadCount, setUnreadCount] = useState(0);
  //////////////////////////////////////////////////
  // #endregion VARIABLES //////////////////////////

  // #region    useEffect //////////////////////////
  //////////////////////////////////////////////////
  useEffect(() => {
    getNotiC();
  }, []);
  //////////////////////////////////////////////////
  // #endregion useEffect //////////////////////////

  // #region    FUNCTIONS //////////////////////////
  //////////////////////////////////////////////////
  // const goToNotiPage = () => history.push("/notification");
  const goToNotiPage = () => history.push(routes.MESSAGE);

  const getNotiC = () => {
    // getNotiCount().then((res) => {
    //   if (res?.data?.code === "success") {
    //     setUnreadCount(res?.data?.data?.count_unread);
    //   }
    // });
    setUnreadCount(1);
  };
  //////////////////////////////////////////////////
  // #endregion FUNCTIONS //////////////////////////

  // #region    VIEWS //////////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion VIEWS //////////////////////////////
  return (
    <button
      style={{ padding: `${mrHeight(8)} ${mrWidth(7)}` }}
      className={`${styles.root} ${className}`}
      onClick={goToNotiPage}
    >
      <img
        src={yellowBell}
        alt="Noti"
        style={{ width: mrWidth(22), height: mrHeight(26) }}
      />
      <div
        style={{
          width: mrWidth(12),
          height: mrWidth(12),
          display: unreadCount > 0 ? "block" : "none",
        }}
        className={styles.redDot}
      />
    </button>
  );
}
