import React, { useEffect, useState } from "react";
import { useSpringCarousel } from "react-spring-carousel-js";
import styles from "./styles.module.css";

export default function Slide() {
  // #region    VARIABLES //////////////////////////
  //////////////////////////////////////////////////
  const [currentIndex, setCurrentIndex] = useState(0);
  const texts = [
    "Multi Platform School Information System - Giải pháp quản lý thông tin trường học đa nền tảng",
    "Multi Platform School Information System - Giải pháp quản lý thông tin trường học đa nền tảng",
    "Multi Platform School Information System - Giải pháp quản lý thông tin trường học đa nền tảng",
  ];
  const { carouselFragment, useListenToCustomEvent, slideToNextItem } =
    useSpringCarousel({
      withLoop: true,
      items: texts.map((i, index) => {
        return {
          id: index.toString(),
          renderItem: (
            <div className={`w-full flex items-center ${styles.carouselItem}`}>
              <span className={styles.slideText}>{i}</span>
            </div>
          ),
        };
      }),
    });
  useListenToCustomEvent((data) => {
    if (data.eventName === "onSlideStartChange") {
      setCurrentIndex(data.nextItem);
    }
  });
  //////////////////////////////////////////////////
  // #endregion VARIABLES //////////////////////////

  // #region    useEffect //////////////////////////
  //////////////////////////////////////////////////
  useEffect(() => {
    setInterval(slideToNextItem, 5000);
  }, []);
  //////////////////////////////////////////////////
  // #endregion useEffect //////////////////////////

  // #region    FUNCTIONS //////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion FUNCTIONS //////////////////////////

  // #region    VIEWS //////////////////////////////
  //////////////////////////////////////////////////
  const renderIndicator = (_item, index) => (
    <div
      className={`${index === currentIndex ? styles.activeIndi : styles.indi} ${
        index !== 0 && styles.mLeft14
      }`}
      key={index.toString()}
    />
  );
  //////////////////////////////////////////////////
  // #endregion VIEWS //////////////////////////////
  return (
    <>
      <div className={`mTop20`}>{carouselFragment}</div>
      <div className={`w-full flex mTop20`}>{texts.map(renderIndicator)}</div>
    </>
  );
}
