import React, { useEffect } from "react";

export default function ConfirmCloseComponent({ isActive = true }) {
  // #region    VARIABLES //////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion VARIABLES //////////////////////////

  // #region    useEffect //////////////////////////
  //////////////////////////////////////////////////
  useEffect(
    isActive
      ? () => {
          window.addEventListener("beforeunload", showAlert);
          window.addEventListener("unload", handleTabClosing);
          return () => {
            window.removeEventListener("beforeunload", showAlert);
            window.removeEventListener("unload", handleTabClosing);
          };
        }
      : () => {},
    [isActive]
  );
  //////////////////////////////////////////////////
  // #endregion useEffect //////////////////////////

  // #region    FUNCTIONS //////////////////////////
  //////////////////////////////////////////////////
  const handleTabClosing = () => {};

  const showAlert = (event) => {
    event.preventDefault();
    event.returnValue = "";
  };
  //////////////////////////////////////////////////
  // #endregion FUNCTIONS //////////////////////////

  // #region    VIEWS //////////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion VIEWS //////////////////////////////
  return <></>;
}
