export const belowUniversityTexts = {
  "Phân phối chương trình": "Phân phối chương trình",
  Khối: "Khối",
  "Học sinh": "Học sinh",
  khối: "khối",
  "Tổ giảng dạy": "Tổ giảng dạy",
  "tổ giảng dạy": "tổ giảng dạy",
  "chương trình học": "chương trình học",
  "cấu trúc PPCT": "cấu trúc PPCT",
};

export const universityTexts = {
  "Phân phối chương trình": "Cấu trúc môn học",
  Khối: "Khoa",
  "Học sinh": "Sinh viên",
  "Giáo viên": "Giảng viên",
  khối: "khoa",
  "Tổ giảng dạy": "Khoa",
  "tổ giảng dạy": "khoa",
  "chương trình học": "kế hoạch giảng dạy",
  "cấu trúc PPCT": "cấu trúc môn học",
};
