export const SET_SUBSIDEBAR_PROFILE_ACTIVE_MENU =
  "SET_SUBSIDEBAR_PROFILE_ACTIVE_MENU";
export const SET_SUBSIDEBAR_SCHOOL_ACTIVE_MENU =
  "SET_SUBSIDEBAR_SCHOOL_ACTIVE_MENU";
export const SET_SUBSIDEBAR_SETTING_ACTIVE_MENU =
  "SET_SUBSIDEBAR_SETTING_ACTIVE_MENU";

export function setSubsidebarProfileActiveMenu(data) {
  return {
    type: SET_SUBSIDEBAR_PROFILE_ACTIVE_MENU,
    payload: data,
  };
}

export function setSubsidebarSchoolActiveMenu(data) {
  return {
    type: SET_SUBSIDEBAR_SCHOOL_ACTIVE_MENU,
    payload: data,
  };
}

export function setSubsidebarSettingActiveMenu(data) {
  return {
    type: SET_SUBSIDEBAR_SETTING_ACTIVE_MENU,
    payload: data,
  };
}
