export const SET_STAFF_LIST_PAGE_FILTER_TEACHING_GROUP =
  "SET_STAFF_LIST_PAGE_FILTER_TEACHING_GROUP";
export const SET_STAFF_LIST_PAGE_CURRENT_PAGE =
  "SET_STAFF_LIST_PAGE_CURRENT_PAGE";

export function setStaffListPageFilterTeachingGroupAction(data) {
  return {
    type: SET_STAFF_LIST_PAGE_FILTER_TEACHING_GROUP,
    payload: data,
  };
}

export function setStaffListPageCurrentPageAction(data) {
  return {
    type: SET_STAFF_LIST_PAGE_CURRENT_PAGE,
    payload: data,
  };
}
