import { Modal } from "antd";
import InfoFieldTextArea from "common/CusInfoField/infoFieldTextArea/infoFiledTextArea";
import React from "react";

export default function CommentModal({
  visible,
  onClose,
  onConfirm,
  inputValue,
  setInputValue,
}) {
  // #region    VARIABLES //////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion VARIABLES //////////////////////////

  // #region    useEffect //////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion useEffect //////////////////////////

  // #region    FUNCTIONS //////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion FUNCTIONS //////////////////////////

  // #region    VIEWS //////////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion VIEWS //////////////////////////////
  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      onOk={onConfirm}
      cancelText="Hủy"
      okText="Xác nhận"
      closable={false}
    >
      <InfoFieldTextArea
        noLabel
        value={inputValue}
        setValue={setInputValue}
        placeholder="Nội dung nhận xét (không quá 500 kí tự)"
        maxLength={500}
        rows={10}
      />
    </Modal>
  );
}
