import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  NO_LAYOUT_LINKS,
  SIDEBAR_COLLAPSE_WIDTH,
  SIDEBAR_EXPAND_WIDTH,
} from "util/constant";
import Header from "./header/Header";
import Sidebar from "./Sidebar";
import styles from "./styles.module.css";

export default function Layout({ children }) {
  // #region    VARIABLES //////////////////////////
  //////////////////////////////////////////////////
  var userInfo = localStorage.getItem("user_info");
  const location = useLocation();
  const sidebarCollapse = useSelector((state) => state.app.sidebarCollapse);
  const sidebarCurrentWidth = sidebarCollapse
    ? SIDEBAR_COLLAPSE_WIDTH
    : SIDEBAR_EXPAND_WIDTH;
  //////////////////////////////////////////////////
  // #endregion VARIABLES //////////////////////////

  // #region    useEffect //////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion useEffect //////////////////////////

  // #region    FUNCTIONS //////////////////////////
  //////////////////////////////////////////////////
  const isShowLayout = () => {
    return (
      NO_LAYOUT_LINKS.findIndex((item) => item === location.pathname) === -1
    );
  };
  //////////////////////////////////////////////////
  // #endregion FUNCTIONS //////////////////////////

  // #region    VIEWS //////////////////////////////
  //////////////////////////////////////////////////
  const renderLayout = () => {
    if (userInfo === null) {
      localStorage.clear();
    }
    if (userInfo == "undefined") {
      localStorage.removeItem("user_info");
      localStorage.removeItem("token");
    }
    if (!isShowLayout()) return <>{children}</>;
    return (
      <div className={`flex content-background ${styles.root}`}>
        <Sidebar />
        <div className="flex flex-col flex-1">
          <Header />
          <div
            className={`flex flex-col flex-1 ${styles.content}`}
            style={{ maxWidth: `calc(100vw - ${sidebarCurrentWidth})` }}
          >
            {children}
          </div>
          <div className="text-center pb-2">
            <span className={styles.copyRightText}>
              Copyright © 2022. All rights reserved.
            </span>
          </div>
        </div>
      </div>
    );
  };
  //////////////////////////////////////////////////
  // #endregion VIEWS //////////////////////////////
  return <>{renderLayout()}</>;
}
