import React from "react";

function IconDownload() {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="colorize--color-text-2"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.53033 5.71967L7.25 9.43934V0H8.75V9.43934L12.4697 5.71967L13 5.18934L14.0607 6.25L13.5303 6.78033L8.53033 11.7803C8.23744 12.0732 7.76256 12.0732 7.46967 11.7803L2.46967 6.78033L1.93934 6.25L3 5.18934L3.53033 5.71967ZM16 14.5H0V16H16V14.5Z"
        fill="currentColor"
      />
    </svg>
  );
}
export default IconDownload;
