import _isNumber from "lodash/isNumber";
import { useCallback, useEffect, useRef, useState } from "react";

const DEFAULT_PAGE_WIDTH = 800;

const useScaleHandler = () => {
  const [scaleValue, setScaleValue] = useState(1);
  const [scaleInfo, setScaleInfo] = useState({
    height: null,
    width: null,
    scale: 1,
  });
  const [containerSize, setContainerSize] = useState({ height: 0, width: 0 });
  const scaleInfoRef = useRef(scaleInfo);
  useEffect(() => {
    scaleInfoRef.current = scaleInfo;
  });

  useEffect(() => {
    console.log(containerSize.width);
    // const documentRect = getDocumentRef()?.getBoundingClientRect();
    if (containerSize.width) {
      if (_isNumber(scaleValue)) {
        let pageWidth = containerSize.width;
        if (pageWidth > DEFAULT_PAGE_WIDTH) {
          pageWidth = DEFAULT_PAGE_WIDTH;
        }
        setScaleInfo({
          ...scaleInfoRef.current,
          width: pageWidth,
          height: null,
          scale: scaleValue,
        });
      } else if (scaleValue === "fit_to_width") {
        setScaleInfo({
          ...scaleInfoRef.current,
          width: containerSize.width,
          height: null,
          scale: 1,
        });
      } else if (scaleValue === "fit_to_height") {
        setScaleInfo({
          ...scaleInfoRef.current,
          width: null,
          height: containerSize.height,
          scale: 1,
        });
      }
    }
  }, [containerSize.width, containerSize.height, scaleValue]);

  const getScaleOptions = useCallback(() => {
    return [
      {
        label: "pdf.fit_to_width",
        defaultLabel: "Vừa với chiều rộng",
        value: "fit_to_width",
      },
      {
        label: "pdf.fit_to_height",
        defaultLabel: "Vừa với chiều cao",
        value: "fit_to_height",
      },
      {
        value: 0.5,
      },
      {
        value: 0.75,
      },
      {
        value: 1,
      },
      {
        value: 1.25,
      },
      {
        value: 1.5,
      },
      {
        value: 1.75,
      },
      {
        value: 2,
      },
      {
        value: 3,
      },
    ];
  }, []);

  const changeScaleValue = useCallback((value) => {
    setScaleValue(value);
  }, []);

  return {
    scaleValue,
    scaleInfo,
    changeScaleValue,
    getScaleOptions,
  };
};

export default useScaleHandler;
