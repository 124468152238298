import { Menu } from "antd";
import {
  arrowDownSidebarBlue,
  arrowRightSidebar,
  premiumIcon,
} from "asset/img/Images";
import Img from "common/image/Img";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import MenuItem from "./components/MenuItem";
import styles from "./styles.module.css";

const SubMenu = ({ item, hoverKey, setHoverKey, sidebarActiveKey }) => {
  // #region    VARIABLES //////////////////////////
  //////////////////////////////////////////////////
  const { SubMenu: AntSubMenu } = Menu;
  const [subMenuIsSelected, setSubMenuIsSelected] = useState(false);
  const sidebarOpenKeys = useSelector((state) => state.app.sidebarOpenKeys);

  const isUseMarquee = item?.name?.length > 21;
  //////////////////////////////////////////////////
  // #endregion VARIABLES //////////////////////////

  // #region    useEffect //////////////////////////
  //////////////////////////////////////////////////
  useEffect(() => {
    if (
      item.key === hoverKey ||
      item?.children?.findIndex((i) => i.key === sidebarActiveKey) > -1
    ) {
      setSubMenuIsSelected(true);
    } else {
      setSubMenuIsSelected(false);
    }
  }, [item?.key, item?.children, hoverKey, sidebarActiveKey]);
  //////////////////////////////////////////////////
  // #endregion useEffect //////////////////////////

  // #region    FUNCTIONS //////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion FUNCTIONS //////////////////////////

  // #region    VIEWS //////////////////////////////
  //////////////////////////////////////////////////
  const renderMenuItem = (item, isSubMenuItem = false) => {
    return (
      <MenuItem
        item={item}
        isSubMenuItem={isSubMenuItem}
        sidebarActiveKey={sidebarActiveKey}
        hoverKey={hoverKey}
        setHoverKey={setHoverKey}
        key={item.key}
      />
    );
  };

  const renderTitle = () => (
    <div className={`flex items-center`}>
      <div className={`flex-1 ${isUseMarquee ? "marquee-wrapper h-10" : ""}`}>
        <span
          className={`${styles.menuItemText} ${styles.subMenuItemText} ${
            subMenuIsSelected ? styles.highlightText : ""
          } ${
            isUseMarquee && item.key === hoverKey
              ? `marquee-text ${styles.marqueeText}`
              : ""
          }`}
        >
          {item?.name}
        </span>
      </div>

      {item?.isPremium ? (
        <img src={premiumIcon} className={`mLeft2 ${styles.chevron}`} />
      ) : null}
      <div className={`${!item?.isPremium && "mLeft26"} ${styles.chevron}`}>
        <Img
          src={
            sidebarOpenKeys[0] === item?.key
              ? arrowDownSidebarBlue
              : arrowRightSidebar
          }
        />
      </div>
    </div>
  );
  //////////////////////////////////////////////////
  // #endregion VIEWS //////////////////////////////
  if (!item.children) {
    return renderMenuItem(item);
  }
  return (
    <AntSubMenu
      key={item.key}
      eventKey={item.key}
      icon={<img src={subMenuIsSelected ? item.activeIcon : item.icon} />}
      title={renderTitle()}
      onMouseEnter={() => setHoverKey(item.key)}
      onMouseLeave={() => setHoverKey(null)}
      className={styles.pLeft6}
    >
      {item?.children?.map((child) => renderMenuItem(child, true))}
    </AntSubMenu>
  );
};

export default SubMenu;
