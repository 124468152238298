import React from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import InfoFieldSelect from "../../common/CusInfoField/infoFieldSelect/InfoFieldSelect";
import MobileHeader from "mobile/layout/header/MobileHeader";
import { HEADER_TYPE } from "mobile/util/mobileConstant";
import CustomBreadcrumb from "../../common/breadcrumb/CustomBreadcrumb";
import { PARENTS_MANAGEMENT, routes } from "util/routes";
import IsMobile from "hook/isMobile";

const columns = [
  { field: "stt", headerName: "STT", width: 90 },
  { field: "MaHs", headerName: "MaHs", width: 90 },
  {
    field: "HoTen",
    headerName: "Họ và tên",
    width: 150,
    editable: true,
  },
  {
    field: "mon",
    headerName: "Môn học",
    width: 150,
    editable: true,
  },
  {
    field: "diem",
    headerName: "Điểm miệng",
    type: "number",
    width: 110,
    editable: true,
  },
  {
    field: "diem15p",
    headerName: "Điểm 15p",
    type: "number",
    width: 110,
    editable: true,
  },
  {
    field: "diem1t",
    headerName: "Điểm 1 tiết",
    type: "number",
    width: 110,
    editable: true,
  },
  {
    field: "diemhk",
    headerName: "Điểm học kỳ",
    type: "number",
    width: 110,
    editable: true,
  },
  {
    field: "diemtbm",
    headerName: "Điểm trung bình",
    type: "number",
    width: 110,
    editable: true,
  },
  {
    field: "age",
    headerName: "Tuổi",
    type: "number",
    width: 110,
    editable: true,
  },
];

const rows = [
  { id: 1, lastName: "Snow", firstName: "Jon", age: 35 },
  { id: 2, lastName: "Lannister", firstName: "Cersei", age: 42 },
  { id: 3, lastName: "Lannister", firstName: "Jaime", age: 45 },
  { id: 4, lastName: "Stark", firstName: "Arya", age: 16 },
  { id: 5, lastName: "Targaryen", firstName: "Daenerys", age: null },
  { id: 6, lastName: "Melisandre", firstName: null, age: 150 },
  { id: 7, lastName: "Clifford", firstName: "Ferrara", age: 44 },
  { id: 8, lastName: "Frances", firstName: "Rossini", age: 36 },
  { id: 9, lastName: "Roxie", firstName: "Harvey", age: 65 },
];

export default function ContactBook() {
  const isUsingMobile = IsMobile();

  return (
    <div>
      {isUsingMobile ? (
        <MobileHeader
          type={HEADER_TYPE.middleTitle}
          title="Sổ liên lạc"
          haveNotiButton={false}
        />
      ) : (
        <CustomBreadcrumb crumbs={[PARENTS_MANAGEMENT, routes.CONTACT_BOOK]} />
      )}
      <div className="p-4">
        <InfoFieldSelect
          label="Môn"
          // data={subjectList}
          // value={subjectTeacher}
          // setValue={setSubjectId}
          direction="vertical"
          className="flex-1 "
          valueTextName="name"
        />
        {/* <InfoFieldSelect
      label="Khối"
      data={gradeList}
      value={grade}
      setValue={setGrade}
      direction="vertical"
      className="flex-1"
    /> */}
        <InfoFieldSelect
          label="Lớp"
          // data={classList}
          // value={classId}
          // setValue={setClassId}
          direction="vertical"
          className="flex-1 pb-8"
        />
        <Box sx={{ height: 400, width: "100%" }}>
          <DataGrid
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            pageSizeOptions={[5]}
            checkboxSelection
            disableRowSelectionOnClick
          />
        </Box>
      </div>
    </div>
  );
}
