import store from "redux/store";
import { ROLE_KEY_LIST } from "util/constant";

export const isPermitted = (object, action) => {
  const userInfo = JSON.parse(localStorage.getItem("user_info")) || [];
  const school = store?.getState?.()?.school?.selectedSchool;

  // Cổng thông tin chỉ dành cho học sinh
  if (object === "info_gate") {
    if (userInfo?.student_info?.id) return true;
    return false;
  }

  // Nếu là trang "năng lực, phẩm chất" thì chỉ dành cho trường tiểu học
  // Trường hiện tại là trường tiểu học thì mới check tiếp permission
  if (
    object === "capacity_quality" &&
    !school?.level?.includes("primary_school")
  ) {
    return false;
  }

  // Nếu role là admin thì không cần check quyền
  const checkRole = userInfo?.user?.roles.some(item => item.type === ROLE_KEY_LIST.admin);
  if (checkRole) {
    return true;
  }

  const permissionInfo = userInfo?.user?.tabs || [];
  const objectIndex = permissionInfo?.findIndex((i) => i?.key === object);
  if (objectIndex > -1) {
    const actionIndex = permissionInfo[
      objectIndex
    ]?.permissions?.findIndex((i) => i === action);
    if (actionIndex > -1) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};
