import { Button } from "antd";
import { uploadIcon } from "asset/img/Images";
import Img from "common/image/Img";
import React from "react";
import styles from "./styles.module.css";

export default function ImportButton({
  text = "Nhập dữ liệu",
  onClick,
  className = "",
  textClassName = "",
  ...props
}) {
  // #region    VARIABLES //////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion VARIABLES //////////////////////////

  // #region    useEffect //////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion useEffect //////////////////////////

  // #region    FUNCTIONS //////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion FUNCTIONS //////////////////////////

  // #region    VIEWS //////////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion VIEWS //////////////////////////////
  return (
    <Button
      onClick={onClick}
      className={`flex justify-center items-center ${styles.searchBtn} ${
        props?.disabled && styles.disabledBtn
      } ${className}`}
      {...props}
    >
      <div className={styles.downloadIcon}>
        <Img src={uploadIcon} alt="Import" />
      </div>
      <span className={`${textClassName} ${styles.searchBtnText}`}>{text}</span>
    </Button>
  );
}
