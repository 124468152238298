export const SET_REPORT_CARD_PAGE_FILTER_GRADE =
  "SET_REPORT_CARD_PAGE_FILTER_GRADE";
export const SET_REPORT_CARD_PAGE_FILTER_CLASS =
  "SET_REPORT_CARD_PAGE_FILTER_CLASS";
export const SET_REPORT_CARD_PAGE_CURRENT_PAGE =
  "SET_REPORT_CARD_PAGE_CURRENT_PAGE";

export function setReportCardPageFilterGradeAction(data) {
  return {
    type: SET_REPORT_CARD_PAGE_FILTER_GRADE,
    payload: data,
  };
}

export function setReportCardPageFilterClassAction(data) {
  return {
    type: SET_REPORT_CARD_PAGE_FILTER_CLASS,
    payload: data,
  };
}

export function setReportCardPageCurrentPageAction(data) {
  return {
    type: SET_REPORT_CARD_PAGE_CURRENT_PAGE,
    payload: data,
  };
}
