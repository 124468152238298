import React from "react";
import BackButton from "../BackButton/BackButton";
import NotiButton from "../NotiButton/NotiButton";
import styles from "./styles.module.css";

export default function MiddleTitleHeader({
  title = "",
  haveNotiButton = true,
  haveBackButton = true,
}) {
  // #region    VARIABLES //////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion VARIABLES //////////////////////////

  // #region    useEffect //////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion useEffect //////////////////////////

  // #region    FUNCTIONS //////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion FUNCTIONS //////////////////////////

  // #region    VIEWS //////////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion VIEWS //////////////////////////////
  return (
    <div className={`flex justify-start items-center gap-2 ${styles.root}`}>
      <BackButton className={haveBackButton ? "" : "hidden"} />
      <div className={styles.title}>{title}</div>
      <NotiButton className={haveNotiButton ? "" : "hidden"} />
    </div>
    // <div className={`flex justify-between items-center ${styles.root}`}>
    //   <BackButton className={haveBackButton ? "" : "invisible"} />
    //   <span className={styles.title}>{title}</span>
    //   <NotiButton className={haveNotiButton ? "" : "invisible"} />
    // </div>
  );
}
