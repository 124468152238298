import { DatePicker } from "antd";
import { datePickerCalendarIcon } from "asset/img/Images";
import Img from "common/image/Img";
import moment from "moment";
import InfoField from "common/CusInfoField/infoField/InfoField";
import React from "react";
import { DATE_FORMAT } from "util/constant";
import styles from "./styles.module.css";

export default function InfoFieldDatePicker({
  label,
  isRequired = false,
  value,
  setValue = (_value) => { },
  placeholder = "",
  direction = "horizontal",
  className = "",
  disabled = false,
  picker,
  format = DATE_FORMAT,
  labelClassName = "",
  labelWrapperClassName = "",
  inputClassName = "",
  allowClear = false,
  noLabel = false,
  disabledDate,
}) {
  // #region    VARIABLES //////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion VARIABLES //////////////////////////

  // #region    useEffect //////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion useEffect //////////////////////////

  // #region    FUNCTIONS //////////////////////////
  //////////////////////////////////////////////////
  const handleChange = (date) => {
    if (date !== null) setValue(date.format());
    else setValue(date);
  };

  const getValue = () => {
    let temp = value;
    temp = moment(temp);
    if (temp.isValid()) return temp;
    else return null;
  };
  //////////////////////////////////////////////////
  // #endregion FUNCTIONS //////////////////////////

  // #region    VIEWS //////////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion VIEWS //////////////////////////////
  return (
    <InfoField
      label={label}
      isRequired={isRequired}
      input={
        <DatePicker
          onChange={handleChange}
          className={`w-full styled-input ${styles.datePicker} ${inputClassName}`}
          placeholder={placeholder}
          format={format}
          value={getValue()}
          suffixIcon={<Img src={datePickerCalendarIcon} alt="Calendar" />}
          disabled={disabled}
          picker={picker}
          allowClear={allowClear}
          disabledDate={disabledDate}
        />
      }
      className={className}
      labelWrapperClassName={labelWrapperClassName}
      labelClassName={labelClassName}
      direction={direction}
      noLabel={noLabel}
    />
  );
}
