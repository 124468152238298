export const SET_SUMMARY_PAGE_FILTER_GRADE = "SET_SUMMARY_PAGE_FILTER_GRADE";
export const SET_SUMMARY_PAGE_FILTER_SEMESTER =
  "SET_SUMMARY_PAGE_FILTER_SEMESTER";

export function setSummaryPageFilterGradeAction(data) {
  return {
    type: SET_SUMMARY_PAGE_FILTER_GRADE,
    payload: data,
  };
}

export function setSummaryPageFilterSemesterAction(data) {
  return {
    type: SET_SUMMARY_PAGE_FILTER_SEMESTER,
    payload: data,
  };
}
