// @ts-nocheck
import { Button, Space } from "antd";
import { newEditIcon } from "asset/img/Images";
import CusTable from "common/CusTable";
import { Notification } from "common/Notification";
import React, { useCallback, useMemo, useState, useRef } from "react";
import { useSelector } from "react-redux";
import {
  updateAttendanceOneStudent,
  updateAttendanceStatusByDate,
  getAttendanceEvent,
} from "util/APIs";
import { ATTENDANCE_STATUS, STATUS_ATTENDANCE } from "util/constant";
import styles from "../styles.module.css";
import style from "./styles.module.css";
import IsMobile from "hook/isMobile";
import FileList, { FileItem } from "common/FilePreviewer/FileList";
import IconFile from "asset/icons/IconFile";

import UploadField from "./UploadAttendance";
import moment from "moment";

function DateAttendance({
  data,
  classId,
  selectedDate,
  section,
  currentLesson,
  tableLoading,
  currentPage,
  setCurrentPage,
  pageSize,
  setPageSize,
  totalItems,
  setIsRefresh,
  scroll,
}) {
  const isUsingMobile = IsMobile();
  const userInfo = useSelector((state) => state?.app?.userInfo);
  const accountId = userInfo?.account?.id;
  const [editingKey, setEditingKey] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [isSubmittedAll, setIsSubmittedAll] = useState(true);

  const isAllowToUpdate = true;

  const isEditing = useCallback(
    (record) => {
      return record?.id === editingKey;
    },
    [editingKey]
  );

  const edit = useCallback(
    (record) => {
      if (editingKey === record?.id) {
        setEditingKey("");
      } else {
        setEditingKey(record?.id);
      }
    },
    [editingKey]
  );
  const handleAttendanceCheck = useCallback(
    async (isChecked, data, attendance_status) => {
      const date = {
        "filters[sessionId]": data.sessionId,
        "filters[date][$eq]": moment(selectedDate).format("YYYY-MM-DD"),
      };
      const attendance_event = await getAttendanceEvent(accountId, date);
      if (attendance_event.data?.data[0]?.attendance === false) {
        const item = {
          date: moment(selectedDate).format("YYYY-MM-DD"),
          studentId: data?.attendance_patrol?.[0].studentId,
          classId: data?.attendance_patrol?.[0].classId,
          sessionId: data?.attendance_patrol?.[0].sessionId,
          lessonId: data?.attendance_patrol?.[0].lessonId,
          workflow_state: isChecked
            ? attendance_status
            : STATUS_ATTENDANCE.unmarked,
        };
        const payload = {
          data: item,
        };
        const res = await updateAttendanceStatusByDate(
          accountId,
          data?.attendance_patrol?.[0].id,
          payload
        );
        console.log(data);
        if (res?.status === 200) {
          Notification.sendSuccess("Điểm danh thành công");
          setIsRefresh(true);
        }
      } else {
        const item = {
          date: selectedDate,
          students: data?.students?.[0].id,
          sessions: data?.sessions?.[0].id,
          lessons: data?.lessons?.[0].id,
          workflow_state: isChecked
            ? attendance_status
            : STATUS_ATTENDANCE.unmarked,
          staff: [userInfo?.user?.id],
        };
        const payload = {
          data: item,
        };
        const res = await updateAttendanceOneStudent(
          accountId,
          data.id,
          payload
        );
        if (res?.status === 200) {
          Notification.sendSuccess("Điểm danh thành công");
          setIsRefresh(true);
        }
      }
    },
    [accountId, selectedDate, setIsRefresh]
  );

  const viewFile = (values) => {
    const lst = values ? JSON.parse(values) : [];
    if (lst?.length) {
      const formattedFiles = lst?.map?.((item) => ({
        name: item.title,
        url: item.url,
        type: item.mimetype,
      }));

      return (
        <FileList attachments={formattedFiles}>
          <FileItem {...formattedFiles[0]}>
            {({ onOpen }) => {
              return (
                <div onClick={onOpen}>
                  <div className={`${style.viewFile}`}>
                    <IconFile />
                  </div>
                </div>
              );
            }}
          </FileItem>
        </FileList>
      );
    }
  };

  const columns = useMemo(() => {
    let columnLst = [
      {
        title: "STT",
        align: "center",
        key: "STT",
        render: (value, record, index) =>
          (currentPage - 1) * pageSize.value + index + 1,
        width: "5%",
      },
      {
        title: "Họ Tên",
        align: "left",
        key: `students`,
        dataIndex: "full_name",
        render: (text, record) => <div>{text}</div>,
        width: "10%",
        className: styles.fixedCol,
      },
      {
        title: "Điểm danh",
        key: "workflow_state",
        align: "center",
        width: "20%",
        render: (value, record) => {
          const editable = isEditing(record);
          // let tempItem = listAttendanceClass.find(
          //   (x) => x.student_id === record?.id
          // );

          let isLate =
              value?.workflow_state === STATUS_ATTENDANCE.late ? true : false,
            isAnualLeave =
              value?.workflow_state === STATUS_ATTENDANCE.author_absent
                ? true
                : false,
            isPresent =
              value?.workflow_state === STATUS_ATTENDANCE.present
                ? true
                : false,
            isNoAuthLeave =
              value?.workflow_state === STATUS_ATTENDANCE.unauthor_absent
                ? true
                : false;
          return (
            <Space size={"middle"}>
              <Button
                shape="circle"
                className={`late border-0 ${
                  isPresent
                    ? styles.presentActive
                    : styles.attendanceButtonDefault
                }`}
                // disabled={(!editable && isSubmittedAll) || !isAllowToUpdate}
                // disabled={!editable || !isAllowToUpdate}
                onClick={() =>
                  handleAttendanceCheck(
                    !isPresent,
                    record,
                    ATTENDANCE_STATUS[0].key
                  )
                }
              >
                C
              </Button>
              <Button
                shape="circle"
                className={`late border-0 ${
                  isLate ? styles.lateActive : styles.attendanceButtonDefault
                }`}
                // disabled={(!editable && isSubmittedAll) || !isAllowToUpdate}
                // disabled={!editable || !isAllowToUpdate}
                onClick={() =>
                  handleAttendanceCheck(
                    !isLate,
                    record,
                    ATTENDANCE_STATUS[1].key
                  )
                }
              >
                T
              </Button>
              <Button
                shape="circle"
                className={`anualLeave border-0 ${
                  isAnualLeave
                    ? styles.anualLeaveActive
                    : styles.attendanceButtonDefault
                }`}
                // disabled={(!editable && isSubmittedAll) || !isAllowToUpdate}
                // disabled={!editable || !isAllowToUpdate}
                onClick={() =>
                  handleAttendanceCheck(
                    !isAnualLeave,
                    record,
                    ATTENDANCE_STATUS[2].key
                  )
                }
              >
                P
              </Button>
              <Button
                shape="circle"
                className={`noAuthLeave border-0 ${
                  isNoAuthLeave
                    ? styles.noAuthLeaveActive
                    : styles.attendanceButtonDefault
                }`}
                // disabled={(!editable && isSubmittedAll) || !isAllowToUpdate}
                // disabled={!editable || !isAllowToUpdate}
                onClick={() =>
                  handleAttendanceCheck(
                    !isNoAuthLeave,
                    record,
                    ATTENDANCE_STATUS[3].key
                  )
                }
              >
                K
              </Button>
              {/* <Button
                type="link"
                disabled={!isSubmittedAll || !isAllowToUpdate}
                onClick={() => edit(record)}
              >
                <img
                  src={newEditIcon}
                  alt="Edit"
                  style={{ minWidth: "24px" }}
                />
              </Button> */}
            </Space>
          );
        },
      },
      // {
      //   title: "Files",
      //   align: "center",
      //   width: "5%",
      //   dataIndex: "files",
      //   render: (text, record) => viewFile(text),
      // },
      // {
      //   title: "",
      //   align: "center",
      //   width: "20%",
      //   dataIndex: "thumuc",
      //   render: (text, record, values) => {
      //     const editable = isEditing(record);

      //     return (
      //       <UploadField
      //         disabled={!editable || !isAllowToUpdate}
      //         record={record}
      //         accountId={accountId}
      //         setIsRefresh={setIsRefresh}
      //       />
      //     );
      //   },
      // },
      // {
      //   title: "",
      //   dataIndex: "operation",
      //   align: "center",
      //   width: "5%",
      //   render: (_, record) => (
      //     <Button
      //       type="link"
      //       disabled={!isSubmittedAll || !isAllowToUpdate}
      //       onClick={() => edit(record)}
      //     >
      //       <img src={newEditIcon} alt="Edit" />
      //     </Button>
      //   ),
      // },
    ];
    if (isUsingMobile) {
      columnLst = columnLst.filter((item) => item.dataIndex !== "dob");
    }
    return columnLst;
  }, [
    currentPage,
    edit,
    handleAttendanceCheck,
    isAllowToUpdate,
    isEditing,
    isSubmittedAll,
    isUsingMobile,
    pageSize.value,
  ]);

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record) ? 1 : 0,
      }),
    };
  });

  return (
    <>
      <CusTable
        rowKey="id"
        data={data}
        // columns={columns}
        columns={columns}
        loading={tableLoading}
        havePagination={totalItems > pageSize.value}
        rowClassName="bg-white attendance-row"
        page={currentPage}
        setPage={setCurrentPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        totalItems={totalItems}
        scroll={{ x: "500px", y: 480 }}
        // scroll={scroll}
      />
    </>
  );
}

export default DateAttendance;
