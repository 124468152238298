import React from "react";
import styles from "./styles.module.css";
import { Modal } from "antd";
import { tickCircleGreenIcon } from "asset/img/Images";
import MoonLoader from "react-spinners/MoonLoader";

export default function InProgressModal({ data, onClose }) {
  // #region    VARIABLES //////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion VARIABLES //////////////////////////

  // #region    useEffect //////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion useEffect //////////////////////////

  // #region    FUNCTIONS //////////////////////////
  //////////////////////////////////////////////////
  const renderImg = (item) => {
    if (item?.push_progress >= item?.total_data_to_push) {
      return <img src={tickCircleGreenIcon} className={styles.icon} />;
    } else if (
      typeof item?.push_progress === "number" &&
      typeof item?.total_data_to_push === "number"
    ) {
      return (
        <MoonLoader
          loading={true}
          speedMultiplier={0.4}
          size="21px"
          color="black"
        />
      );
    }
    return null;
  };

  const renderItem = (item, index) => (
    <div
      className={`flex items-center ${styles.itemMinHeight} ${
        index !== 0 ? "mTop8" : ""
      }`}
      key={`${item?.id}_${index}`}
    >
      <p className="mRight8">{`${item?.description}: ${item?.push_progress}/${item?.total_data_to_push}`}</p>
      {renderImg(item)}
    </div>
  );
  //////////////////////////////////////////////////
  // #endregion FUNCTIONS //////////////////////////

  // #region    VIEWS //////////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion VIEWS //////////////////////////////
  return (
    <Modal
      open={data?.length > 0}
      onOk={onClose}
      onCancel={onClose}
      title="Tiến trình đồng bộ dữ liệu"
      cancelButtonProps={{ hidden: true }}
    >
      <p>{data?.message || ""}</p>
      {data?.map(renderItem)}
    </Modal>
  );
}
