// @ts-nocheck
import { Button, Form, Input, Modal } from "antd";
import InfoFieldSelect from "common/CusInfoField/infoFieldSelect/InfoFieldSelect";
import CusTable from "common/CusTable";
import { Notification } from "common/Notification";
import styles from "../styles.module.css";
import IsMobile from "hook/isMobile";
import moment from "moment";
import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { createAttendanceOneStudent } from "util/APIs";
import { formatDateValue } from "util/common";
import { getGenderText } from "util/helper";

function LunchAttendance({
  data,
  boarding,
  classId,
  selectedDate,
  tableLoading,
  currentPage,
  pageSize,
  totalItems,
  setCurrentPage,
  setPageSize,
  setIsRefresh,
}) {
  const userInfo = useSelector((state) => state?.app?.userInfo);
  const accountId = userInfo?.account?.id;
  const [isOpen, setIsOpen] = useState(false);
  const isUsingMobile = IsMobile();

  const columns = useMemo(() => {
    let columnLst = [
      {
        title: "STT",
        key: "STT",
        width: 50,
        align: "center",
        render: (value, record, index) =>
          (currentPage - 1) * pageSize.value + index + 1,
      },
      {
        title: "Họ",
        align: "center",
        width: 200,
        key: `last_name`,
        dataIndex: "last_name",
        // fixed: "left",
        render: (text, record) => <span>{text}</span>,
        // render: (text, record) => <span>{record?.first_name}</span>,
      },
      {
        title: "Tên",
        align: "center",
        width: 100,
        key: `first_name`,
        // fixed: 'left',
        dataIndex: "first_name",
        render: (text, record) => <span>{text}</span>,
        className: styles.fixedCol,
        // render: (text, record) => <span>{record?.last_name}</span>,
      },
      {
        title: "Mã học sinh",
        // dataIndex: "code",
        width: 150,
        align: "center",
        // render: (text, record) => <span>{record?.code}</span>,
        dataIndex: "moeat_id",
        render: (text, record) => <span>{text}</span>,
      },
      // {
      //   title: "Mã attendance",
      //   dataIndex: "id",
      //   fixed: "left",
      //   width: 150,
      //   editable: false,
      //   align: "left",
      //   render: (text, record) => <span>{text}</span>,
      // },
      {
        title: "Ngày sinh",
        dataIndex: "dob",
        width: 150,
        align: "center",
        render: (value) => <span>{formatDateValue(value)}</span>,
      },
      {
        title: "Giới tính",
        dataIndex: "gender",
        width: 150,
        align: "center",
        render: (text, record) => <span>{getGenderText(text)}</span>,
      },
      {
        title: "Ăn trưa",
        dataIndex: "attendances",
        width: 150,
        align: "center",
        render: (text, record) => {
          const foundItem = text?.find?.(
            (item) =>
              item?.date === moment(selectedDate).format("YYYY-MM-DD") &&
              item?.has_lunch &&
              item?.lunch_attendance
          );
          if (foundItem) {
            return "Có";
          } else {
            return "Không";
          }
        },
      },
    ];
    if (isUsingMobile) {
      columnLst = columnLst.filter((item) => item.dataIndex !== "dob");
    }
    return columnLst;
  }, [currentPage, isUsingMobile, pageSize.value, selectedDate]);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const handleFinish = async (values) => {
    const payload = {
      data: {
        students: [values.students],
        note: values.note,
        date: selectedDate,
        workflow_state: "present",
        has_lunch: true,
        lunch_attendance: true,
        classes: [classId],
        type: "class",
      },
    };
    const res = await createAttendanceOneStudent(accountId, payload);
    if (res?.status === 200) {
      toggle();
      Notification.sendSuccess("Điểm danh ăn trưa thành công");
      setIsRefresh(true);
      return;
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const filterData = useMemo(() => {
    if (isOpen) {
      // giám thị chi điểm danh ăn trưa cho ~ học sinh ko đăng ký bán trú
      const initialData = data.filter((item) => !item.ban_tru);
      return initialData;
    }
    return {};
  }, [isOpen, data]);

  return (
    <>
      <Button className="rounded-lg mb-4" onClick={toggle}>
        Điểm danh
      </Button>
      <CusTable
        rowKey="id"
        data={data}
        columns={columns}
        loading={tableLoading}
        havePagination={totalItems > pageSize.value}
        rowClassName="bg-white"
        page={currentPage}
        setPage={setCurrentPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        totalItems={totalItems}
        scroll={{ x: "1000px", y: 480 }}
      />
      <Modal
        title="Điểm danh"
        open={isOpen}
        onCancel={toggle}
        footer={null}
        destroyOnClose={true}
        width={500}
        style={{ fontWeight: "bold" }}
      >
        <Form
          name="basic"
          labelCol={{
            span: 5,
          }}
          labelAlign="left"
          // initialValues={initialvalues}
          onFinish={handleFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          style={{ marginLeft: "2em", fontWeight: "bold" }}
        >
          <Form.Item
            label="Học sinh"
            name="students"
            rules={[
              {
                required: true,
                message: "Vui lòng chọn học sinh!",
              },
            ]}
          >
            <InfoFieldSelect
              label=""
              data={filterData}
              direction="vertical"
              valueTextName="full_name"
            />
          </Form.Item>
          <Form.Item label="Ghi chú" name="note">
            <Input.TextArea className="rounded-lg h-10" />
          </Form.Item>
          <Form.Item>
            <div key="button-row" className="flex items-center justify-center">
              <Button
                size="large"
                key="cancel"
                className="bg-gray-300 rounded-sm mRight12"
                onClick={toggle}
              >
                Huỷ bỏ
              </Button>
              <Button
                size="large"
                htmlType="submit"
                className="bg-blue-500 text-white rounded-sm"
              >
                Xác nhận
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default LunchAttendance;
