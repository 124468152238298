// @ts-nocheck
import { Button, Input, message, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { createStaff, deleteStaff, getStaffList } from "util/APIs";
import {
    DATE_FORMAT,
    PERMISSION_OBJECT,
    PERMISSION_TYPE,
    SEARCH_DELAY_TIME,
    SETTING_GROUP_KEYS,
    TABLE_PAGE_SIZES,
    UNIVERSITY,
} from "util/constant";
import { getGenderText } from "util/helper";
import styles from "./styles.module.css";
import { formatDateValue } from "util/common";
import { newEditIcon, tickGreen, xRed } from 'asset/img/Images';
import { isPermitted } from 'authen/authenHelper';
import InfoFieldCheckbox from 'common/CusInfoField/infoFieldCheckbox/InfoFieldCheckbox';
import InfoFieldDatePicker from 'common/CusInfoField/infoFieldDatePicker/InfoFieldDatePicker';
import InfoFieldNumber from 'common/CusInfoField/infoFieldNumber';
import InfoFieldSelect from 'common/CusInfoField/infoFieldSelect/InfoFieldSelect';
import InfoFieldSpan from 'common/CusInfoField/infoFieldSpan/InfoFieldSpan';
import InfoFieldText from 'common/CusInfoField/infoFieldText/InfoFieldText';
import InfoSection from 'common/CusInfoField/infoSection/InfoSection';
import RectangleTabs from 'common/RectangleTabs';
import CustomBreadcrumb from 'common/breadcrumb/CustomBreadcrumb';
import StyledButton from 'common/button/StyledButton';
import Img from 'common/image/Img';
import ImagePicker from 'common/imagePicker/ImagePicker';
import moment from 'moment';
import { getSetting } from 'util/APIHelper';
import {
    filterSubjectManagement,
    getCountryList,
    getDistrictByProvince,
    getProvinceByCountry,
    getSubDistrictByDistrict,
    updateStaff,
    updateUser,
    getEthnics,
    getReligions,
    getTeacherData,
} from 'util/APIs';
import {
    GENDER,
    OBJECT_TYPE_BE,
} from 'util/constant';
import {
    disableFutureDate,
    findEthnicAndReligion,
    sanitize,
} from 'util/helper';
import {
    teacherArrayFields,
    teacherStringFields,
    teacherZeroFields,
    validateTeacher,
} from 'util/validate/teacher';
import { ID_ISSUED_PLACES } from 'util/constant';


export default function AddTeacherModal() {

    const [isModalOpen, setIsModalOpen] = useState(false);

    const history = useHistory();

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [dob, setDob] = useState(undefined);
    const [gender, setGender] = useState('');
    const [ethnic, setEthnic] = useState('');
    const [ethnicList, setEthnicList] = useState([]);
    const [religion, setReligion] = useState('');
    const [religionList, setReligionList] = useState([]);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [idNo, setIdNo] = useState('');
    const [idIssueDate, setIdIssueDate] = useState(undefined);
    const [idIssuePlace, setIdIssuePlace] = useState(undefined);
    const [boGdId, setBoGdId] = useState('');
    const [soHcmId, setSoHcmId] = useState('');
    const [taxId, setTaxId] = useState('');
    const [bankId, setBankId] = useState('');
    const [bankName, setBankName] = useState('');
    const [bankBranch, setBankBranch] = useState('');
    const isAllowToUpdate = isPermitted(
        PERMISSION_OBJECT.staffs_management,
        PERMISSION_TYPE.update
    );
    ////////////////////////////////////////////////////////////////////////////////////////////////////

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    // Contact fields - 1
    const [placeOfPermanentProvinceList, setPlaceOfPermanentProvinceList] =
        useState([]);
    const [placeOfPermanentProvince, setPlaceOfPermanentProvince] =
        useState(undefined);
    const [placeOfPermanentDistrictList, setPlaceOfPermanentDistrictList] =
        useState([]);
    const [placeOfPermanentDistrict, setPlaceOfPermanentDistrict] =
        useState(undefined);
    const [placeOfPermanentSubDistrictList, setPlaceOfPermanentSubDistrictList] =
        useState([]);
    const [placeOfPermanentSubDistrict, setPlaceOfPermanentSubDistrict] =
        useState(undefined);
    const [placeOfPermanentQuarterHamlet, setPlaceOfPermanentQuarterHamlet] =
        useState('');
    const [placeOfPermanentDescription, setPlaceOfPermanentDescription] =
        useState('');
    //////////////////////////////////////////////////
    // #endregion VARIABLES //////////////////////////

    // #region    useEffect //////////////////////////
    //////////////////////////////////////////////////


    //////////////////////////////////////////////////
    // #endregion useEffect //////////////////////////

    // #region    FUNCTIONS //////////////////////////
    //////////////////////////////////////////////////

    const showAddModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        if (firstName === '' || lastName === '') {
            message.warning('Xin hãy điền Họ và Tên');
            // setIsModalOpen(false);
        }
        else {
            const params = {
                data: {
                    first_name: firstName,
                    last_name: lastName,
                    dob: dob,
                    gender: gender,
                    ethnic: findEthnicAndReligion(ethnicList, ethnic),
                    religion: findEthnicAndReligion(religionList, religion),
                    phone_number: phoneNumber,
                    email: email,
                    citizen_identify: {
                        id_no: idNo,
                        issued_on: idIssueDate,
                        place_of_issue: idIssuePlace,
                    },
                    permanent_address: {
                        country: 'Việt Nam',
                        province_city: placeOfPermanentProvince,
                        district: placeOfPermanentDistrict,
                        sub_district: placeOfPermanentSubDistrict,
                        quarter_hamlet: placeOfPermanentQuarterHamlet,
                        description: placeOfPermanentDescription,
                    },

                    tax_id: taxId,
                    bank_information: {
                        bank_id: bankId,
                        bank_name: bankName,
                        bank_branch: bankBranch,
                    },
                    digital_signature: null,
                    bo_gd_id: boGdId,
                    so_hcm_id: soHcmId,
                },
            };
            const accountId = JSON.parse(localStorage.getItem("user_info"))?.account?.id;
            createStaff(accountId, params).then((res) => {
                if (res?.statusText === 'OK') {
                    message.success('Cập nhật thành công!');
                }
            });
            setIsModalOpen(false);
        }
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const personalFields = [
        {
            title: '',
            fields: [
                <InfoFieldText
                    label="Họ"
                    isRequired={true}
                    value={firstName}
                    setValue={setFirstName}
                    placeholder="Nhập họ"
                    direction="vertical"

                />,
                <InfoFieldText
                    label="Tên"
                    isRequired={true}
                    value={lastName}
                    setValue={setLastName}
                    placeholder="Nhập tên"
                    direction="vertical"

                />,
                <InfoFieldDatePicker
                    label="Ngày sinh"
                    value={dob}
                    setValue={setDob}
                    placeholder="Ngày/tháng/năm"
                    direction="vertical"

                />,
                <InfoFieldSelect
                    label="Giới tính"
                    data={GENDER}
                    value={gender}
                    setValue={setGender}
                    placeholder="Chọn giới tính"
                    direction="vertical"

                    valueTextName="name"
                    valueFieldName="value"
                />,
                <InfoFieldSelect
                    label="Dân tộc"
                    data={ethnicList}
                    value={ethnic}
                    setValue={setEthnic}
                    placeholder="Chọn dân tộc"
                    direction="vertical"

                    valueTextName="name"
                    valueFieldName="id"
                />,
                <InfoFieldSelect
                    label="Tôn giáo"
                    data={religionList}
                    value={religion}
                    setValue={setReligion}
                    placeholder="Chọn tôn giáo"
                    direction="vertical"

                    valueTextName="name"
                    valueFieldName="id"
                />,
                <InfoFieldText
                    label="Số điện thoại"
                    value={phoneNumber}
                    setValue={setPhoneNumber}
                    placeholder="Nhập số điện thoại"
                    direction="vertical"

                />,
                <InfoFieldText
                    label="Email"
                    value={email}
                    setValue={setEmail}
                    placeholder="Nhập Email"
                    direction="vertical"
                />,
                <InfoFieldText
                    label="CMND / CCCD"
                    value={idNo}
                    setValue={setIdNo}
                    placeholder="Nhập số CMND / CCCD"
                    direction="vertical"

                />,
                <InfoFieldDatePicker
                    label="Ngày cấp"
                    value={idIssueDate}
                    setValue={setIdIssueDate}
                    placeholder="Ngày/tháng/năm"
                    direction="vertical"

                />,
                <InfoFieldSelect
                    label="Nơi cấp"
                    data={ID_ISSUED_PLACES}
                    value={idIssuePlace}
                    setValue={setIdIssuePlace}
                    placeholder="Chọn nơi cấp CMND / CCCD"
                    direction="vertical"

                    valueTextName="name"
                    valueFieldName="id"
                />,
            ],
        },
    ];
    const contactFields = [
        {
            title: 'Địa chỉ thường trú',
            fields: [
                <InfoFieldText
                    label="Quốc gia"
                    placeholder="Việt Nam"
                    direction="vertical"
                    disabled={true}
                />,
                <InfoFieldSelect
                    label="Tỉnh / TP"
                    isRequired={true}
                    data={placeOfPermanentProvinceList}
                    value={placeOfPermanentProvince}
                    setValue={setPlaceOfPermanentProvince}
                    placeholder="Chọn tỉnh / thành phố"
                    direction="vertical"

                    valueTextName="name"
                    valueFieldName="code"
                />,
                <InfoFieldSelect
                    label="Quận / Huyện"
                    isRequired={true}
                    data={placeOfPermanentDistrictList}
                    value={placeOfPermanentDistrict}
                    setValue={setPlaceOfPermanentDistrict}
                    placeholder="Chọn quận / huyện"
                    direction="vertical"
                    disabled={!placeOfPermanentProvince}
                    valueTextName="name"
                    valueFieldName="code"
                />,
                <InfoFieldSelect
                    label="Phường / Xã"
                    isRequired={true}
                    data={placeOfPermanentSubDistrictList}
                    value={placeOfPermanentSubDistrict}
                    setValue={setPlaceOfPermanentSubDistrict}
                    placeholder="Chọn phường / xã"
                    direction="vertical"
                    disabled={
                        !placeOfPermanentProvince ||
                        !placeOfPermanentDistrict
                    }
                    valueTextName="name"
                    valueFieldName="code"
                />,
                <InfoFieldText
                    label="Khu phố / Thôn"
                    value={placeOfPermanentQuarterHamlet}
                    setValue={setPlaceOfPermanentQuarterHamlet}
                    placeholder="Nhập khu phố / thôn"
                    direction="vertical"

                />,
                <InfoFieldText
                    label="Số nhà"
                    value={placeOfPermanentDescription}
                    setValue={setPlaceOfPermanentDescription}
                    placeholder="Nhập số nhà"
                    direction="vertical"

                />,
            ],
        },
    ];
    const teacherFields = [
        {
            title: '',
            fields: [
                <InfoFieldText
                    label="Mã số thuế"
                    value={taxId}
                    setValue={setTaxId}
                    placeholder="Nhập mã số thuế"
                    direction="vertical"

                />,
                <InfoFieldText
                    label="Mã sở"
                    value={soHcmId}
                    setValue={setSoHcmId}
                    placeholder="Nhập mã sở"
                    direction="vertical"
                    disabled={!isAllowToUpdate}
                />,
                <InfoFieldText
                    label="Mã bộ"
                    value={boGdId}
                    setValue={setBoGdId}
                    placeholder="Nhập mã bộ"
                    direction="vertical"
                    disabled={!isAllowToUpdate}
                />,
                <InfoFieldText
                    label="Số tài khoản ngân hàng"
                    value={bankId}
                    setValue={setBankId}
                    placeholder="Nhập số tài khoản ngân hàng"
                    direction="vertical"

                />,
                <InfoFieldText
                    label="Tên ngân hàng"
                    value={bankName}
                    setValue={setBankName}
                    placeholder="Nhập tên ngân hàng"
                    direction="vertical"

                />,
                <InfoFieldText
                    label="Tên chi nhánh ngân hàng"
                    value={bankBranch}
                    setValue={setBankBranch}
                    placeholder="Nhập tên chi nhánh ngân hàng"
                    direction="vertical"

                />,
            ],
        },
    ];

    useEffect(() => {
        pourData();
    }, []);

    useEffect(() => {
        if (placeOfPermanentProvince !== undefined) {
            getDistrictByProvince(placeOfPermanentProvince, 'vn').then((res) => {
                if (res?.statusText === 'OK') {
                    setPlaceOfPermanentDistrictList(res?.data);
                }
            });
        }
    }, [placeOfPermanentProvince]);

    useEffect(() => {
        if (placeOfPermanentDistrict !== undefined) {
            getSubDistrictByDistrict(
                placeOfPermanentDistrict,
                placeOfPermanentProvince,
                'vn'
            ).then((res) => {
                if (res?.statusText === 'OK') {
                    setPlaceOfPermanentSubDistrictList(res?.data);
                }
            });
        }
    }, [placeOfPermanentDistrict]);

    const pourData = (data) => {
        ////////////////////////////////////////////////////////////////////////////////////////////////////

        getEthnics().then((res) => {
            if (res?.statusText === 'OK') {
                setEthnicList(res?.data);
            }
        });
        getReligions().then((res) => {
            if (res?.statusText === 'OK') {
                setReligionList(res?.data?.data);
            }
        });
        ////////////////////////////////////////////////////////////////////////////////////////////////////
        ////////////////////////////////////////////////////////////////////////////////////////////////////
        getProvinceByCountry('vn').then((res) => {
            if (res?.statusText === 'OK') {
                setPlaceOfPermanentProvinceList(res?.data);
            }
        });
        ////////////////////////////////////////////////////////////////////////////////////////////////////
    };
    //////////////////////////////////////////////////
    // #endregion VIEWS //////////////////////////////
    return (
        <>
            <Button
                onClick={showAddModal}
                // disabled={true}
                className={`flex justify-center items-center ${styles.searchBtn}`}
            >
                <span className={`${styles.searchBtnText}`}>Thêm nhân sự</span>
            </Button>
            <Modal style={{ marginTop: "-70px" }} width={1000} title="Thêm nhân sự" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <InfoSection
                    title="THÔNG TIN CÁ NHÂN"
                    data={personalFields}
                    style={styles.mBottom24}
                />
                <InfoSection title="THÔNG TIN LIÊN HỆ" data={contactFields} />
                <InfoSection
                    title="THÔNG TIN GIÁO VIÊN"
                    data={teacherFields}
                    style={styles.mBottom24}
                />
            </Modal>
        </>
    );
}
