import IconCheck from "asset/icons/IconCheck";
import { reloadIcon, uploadIcon, radioCheckedIcon } from "asset/img/Images";
import { Notification } from "common/Notification";
import Avatar from "common/avatar/Avatar";
import Select from "page/teachingManagement/component/Select";
import React, { useRef, useState } from "react";
import { updateStudentViolateRules } from "util/APIs";
import styles from "../styles.module.css";

function EvaluationField({
  accountId,
  values,
  options,
  disabled,
  record,

  setIsRefresh,
}) {
  const [data, setData] = useState([]);
  const [files, setFiles] = useState([]);
  const hiddenFileInput = useRef(null);

  const handleUpload = (e) => {
    setFiles(e.target.files);
  };

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const handlechangefile = async (e) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      setFiles((prevFiles) => [...prevFiles, ...files]);
    }
  };



  return (
    <div>
      <Select
        className="w-72"
        value={values}
        mode="multiple"
        options={options}
        onChange={(v) => setData(v)}
        disabled={disabled}
      // maxTagCount="responsive"
      />
    </div>
  );
}

export default EvaluationField;
