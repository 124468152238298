import Img from "common/image/Img";
import React from "react";
import styles from "./styles.module.css";

const TableHeader = ({
  checkAll = false,
  setCheckAll = (_value) => {},
  selectedRowKeys = [],
  leftButtons = [],
  rightButtons = [],
}) => {
  // #region    VARIABLE ////////////////////////////////////////////////////////////////////////////////////////////////////

  // #endregion VARIABLE ////////////////////////////////////////////////////////////////////////////////////////////////////

  // #region      HOOK   ////////////////////////////////////////////////////////////////////////////////////////////////////

  // #endregion   HOOK   ////////////////////////////////////////////////////////////////////////////////////////////////////

  // #region    FUNCTION ////////////////////////////////////////////////////////////////////////////////////////////////////
  const toggleTickAll = () => {
    setCheckAll(!checkAll);
  };

  const renderLeftButton = (button, index) => {
    const isLast = index === leftButtons?.length - 1;
    return (
      <button
        onClick={() => button?.onClick?.(selectedRowKeys)}
        disabled={!!button?.disabled}
        key={`table_header_btn_left_${index}`}
        className={`${styles.leftBtn} ${isLast ? "" : "mRight12"} ${
          button?.className ? button?.className : ""
        }`}
      >
        {!!button?.icon && <Img src={button.icon} />}
      </button>
    );
  };

  const renderRightButton = (button, index) => {
    const isLast = index === rightButtons?.length - 1;
    return (
      <button
        onClick={() => button?.onClick?.(selectedRowKeys)}
        disabled={!!button?.disabled}
        key={`table_header_btn_right_${index}`}
        className={`${styles.rightBtn} ${isLast ? "" : "mRight12"} ${
          button?.className ? button?.className : ""
        }`}
      >
        {!!button?.text && (
          <span className={styles.rightBtnText}>{button.text}</span>
        )}
      </button>
    );
  };
  // #endregion FUNCTION ////////////////////////////////////////////////////////////////////////////////////////////////////

  // #region      VIEW   ////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <div
      className={`flex justify-start align-center pVertical12 pLeft8 mBottom10 ${styles.root}`}
    >
      <input
        type="checkbox"
        checked={checkAll}
        onChange={toggleTickAll}
        className={styles.checkbox}
      />
      {leftButtons?.length > 0 ? (
        <div className={`mLeft16 mRight8 ${styles.separator}`} />
      ) : null}
      <div className="flex flex-1 justify-between items-center">
        <div className="flex">{leftButtons.map(renderLeftButton)}</div>
        <div className="flex">{rightButtons.map(renderRightButton)}</div>
      </div>
    </div>
  );
  // #endregion   VIEW   ////////////////////////////////////////////////////////////////////////////////////////////////////
};

export default TableHeader;
