import {
  educentric,
  mobileBackground,
  mobileLogo,
  thuThiemLogo,
} from "asset/img/Images";
import Img from "common/image/Img";
import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { mrHeight, mrWidth } from "util/helper";
import styles from "./styles.module.css";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { rHeight, rWidth } from "util/helper";
import { redirectAuth2 } from "util/APIs";

export default function MobileLoginPage() {
  // #region    VARIABLES //////////////////////////
  //////////////////////////////////////////////////
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  //////////////////////////////////////////////////
  // #endregion VARIABLES //////////////////////////

  // #region    useEffect //////////////////////////
  //////////////////////////////////////////////////
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) history.push("/user/home");
  }, []);
  //////////////////////////////////////////////////
  // #endregion useEffect //////////////////////////

  // #region    FUNCTIONS //////////////////////////
  //////////////////////////////////////////////////
  const handleContinue = async () => {
    setLoading(true);
    window.location.replace(redirectAuth2);
    setLoading(false);
  };
  //////////////////////////////////////////////////
  // #endregion FUNCTIONS //////////////////////////

  // #region    VIEWS //////////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion VIEWS //////////////////////////////
  return (
    <div
      style={{
        height: "100vh",
        backgroundImage: `url(${mobileBackground})`,
        backgroundSize: "cover",
        // backgroundPosition: `right bottom`,
        backgroundRepeat: "no-repeat",
      }}
      className={`flex flex-col items-center ${styles.root}`}
    >
      <div style={{ width: mrWidth(77), height: mrHeight(77) }}>
        <Img src={thuThiemLogo} alt="Logo" />
      </div>
      <div
        style={{ width: mrWidth(233), height: mrHeight(17) }}
        className={"mTop20"}
      >
        <Img src={educentric} alt="Brandname" />
      </div>
      <span className={styles.greetText}>Chào mừng đến với Educentric</span>
      <span className={styles.title}>ĐĂNG NHẬP</span>
      <div
        className={`text-white flex flex-col ${styles.formWrapper}`}
        style={{ width: rWidth(1000) }}
      >
        <button
          style={{ height: rHeight(59), marginTop: rHeight(24) }}
          className={`flex justify-center items-center bg-blueDefault ${styles.loginButton}`}
          onClick={handleContinue}
          disabled={loading}
        >
          {loading && <FontAwesomeIcon icon={faCircleNotch} spin />}
          {!loading && (
            <span className={styles.continue}>Đăng nhập Google</span>
          )}
        </button>
        <Link
          to="/local/login"
          style={{ height: rHeight(59), marginTop: rHeight(24) }}
          className={`flex justify-center items-center bg-blueDefault ${styles.loginButton}`}
        >
          <button disabled={loading}>
            {loading && <FontAwesomeIcon icon={faCircleNotch} spin />}
            {!loading && (
              <span className={styles.continue}>Đăng nhập với tài khoản</span>
            )}
          </button>
        </Link>
      </div>
    </div>
  );
}
