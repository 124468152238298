export const SET_SETTING_PAGE_CLASS_LIST_FILTER_MAIN_SESSION =
  "SET_SETTING_PAGE_CLASS_LIST_FILTER_MAIN_SESSION";
export const SET_SETTING_PAGE_CLASS_LIST_FILTER_TYPE =
  "SET_SETTING_PAGE_CLASS_LIST_FILTER_TYPE";
export const SET_SETTING_PAGE_CLASS_LIST_FILTER_GRADE =
  "SET_SETTING_PAGE_CLASS_LIST_FILTER_GRADE";
export const SET_SETTING_PAGE_CLASS_LIST_CURRENT_PAGE =
  "SET_SETTING_PAGE_CLASS_LIST_CURRENT_PAGE";

export function setSettingPageClassListFilterMainSessionAction(data) {
  return {
    type: SET_SETTING_PAGE_CLASS_LIST_FILTER_MAIN_SESSION,
    payload: data,
  };
}

export function setSettingPageClassListFilterTypeAction(data) {
  return {
    type: SET_SETTING_PAGE_CLASS_LIST_FILTER_TYPE,
    payload: data,
  };
}

export function setSettingPageClassListFilterGradeAction(data) {
  return {
    type: SET_SETTING_PAGE_CLASS_LIST_FILTER_GRADE,
    payload: data,
  };
}

export function setSettingPageClassListCurrentPageAction(data) {
  return {
    type: SET_SETTING_PAGE_CLASS_LIST_CURRENT_PAGE,
    payload: data,
  };
}
