import CustomBreadcrumb from "common/breadcrumb/CustomBreadcrumb";
import InfoFieldSelect from "common/CusInfoField/infoFieldSelect/InfoFieldSelect";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import IsMobile from "hook/isMobile";
import MobileHeader from "mobile/layout/header/MobileHeader";
import { HEADER_TYPE } from "mobile/util/mobileConstant";
import {
  getClassListByGrade,
  getGradeList,
  getParentCheckAttendance,
  createParentCheckAttendance,
  getAttendanceByDay,
} from "util/APIs";
import { is1920OrMore, TABLE_PAGE_SIZES } from "util/constant";
import { routes, STUDENT_MANAGEMENT } from "util/routes";
import styles from "./styles.module.css";
import { Button, DatePicker, Form, Input, Modal, Select, Space } from "antd";
import CusTable from "common/CusTable";
import FilePicker from "common/filePicker/FilePicker";
import FileShower from "common/filePicker/component/FileShower";
import FileList, { FileItem } from "common/FilePreviewer/FileList";
import IconFile from "asset/icons/IconFile";
import { Notification } from "common/Notification";
import InfoFieldDatePicker from "common/CusInfoField/infoFieldDatePicker/InfoFieldDatePicker";

export default function ParentCheckAttendance() {
  const [form] = Form.useForm();
  const isUsingMobile = IsMobile();
  const userInfo = useSelector((state) => state?.app?.userInfo);
  const accountId = userInfo?.account?.id;

  const responsive = {
    setResponsiveCol: () => {
      if (!is1920OrMore) {
        // columns[1].width = 120;
        // columns[2].width = 120;
        // columns[3].width = 120;
      }
    },
    nodeWrapperWidth: () => {
      if (is1920OrMore) return "560px";
      if (!is1920OrMore) return "560px";
    },
    tableScroll: () => {
      if (is1920OrMore) return {};
      else
        return {
          x: 1000,
          y: 490,
        };
    },
  };

  const schoolYear = useSelector((state) => state);

  const [grade, setGrade] = useState(null);
  const [classId, setClassId] = useState(null);
  const [pageSize, setPageSize] = useState(TABLE_PAGE_SIZES[1]);
  const [gradeList, setGradeList] = useState([]);
  const [classList, setClassList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [files, setFiles] = useState(null);
  const [data, setData] = useState(null);
  const [parentName, setParentName] = useState("");
  const [today, setToday] = useState(moment().format("YYYY-MM-DD"));
  const [dayOff, setDayOff] = useState(moment().format("YYYY-MM-DD"));
  const [reason, setReason] = useState("");
  const [note, setNote] = useState("");
  const [studentId, setStudentId] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [studentData, setStudentData] = useState([]);
  const [loadData, setLoadData] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [selectedDate, setSelectedDate] = useState(moment().format());

  responsive.setResponsiveCol();

  const getGrade = useCallback(async () => {
    const params = {
      orderBy: "name",
    };
    const res = await getGradeList(accountId, params);
    if (res?.status === 200) {
      const data = res?.data?.data;
      data.pop()
      setGradeList(data);
      if (data?.length > 0) {
        setGrade(data[0]?.id);
      }
    }
  }, [accountId]);

  const getClass = useCallback(
    async (gradeId) => {
      const params = {
        orderBy: "name",
      };
      const res = await getClassListByGrade(accountId, gradeId, params);
      if (res?.status === 200) {
        const data = res?.data?.data;
        setClassList(data);
        if (data?.length > 0) {
          setClassId(data[0]?.id);
        }
      }
    },
    [accountId]
  );

  const getDataClassList = useCallback(async () => {
    const params = {
      "filters[date][$eq]": moment().format("YYYY-MM-DD"),
      "filters[sessionId]": 1,
      "filters[lessonId]": 1,
    };
    if (classId) {
      const res = await getAttendanceByDay(accountId, classId, params);
      if (res?.status === 200) {
        setStudentData(res?.data.data);
      }
    }
  }, [accountId, classId]);

  const getData = useCallback(
    async (page = null) => {
      if (page === null) page = currentPage;
      if (classId) {
        const params = {
          "pagination[pageSize]": pageSize.value,
          "pagination[page]": page,
          "filters[classId]": classId,
        };
        const res = await getParentCheckAttendance(accountId, params);
        if (res?.status === 200) {
          setData(res?.data.data);
          setTotalItems(res?.data.pagination?.total);
          setCurrentPage(res?.data.pagination?.page);
        }
      }
    },
    [accountId, classId, currentPage, pageSize.value]
  );

  const handleSetSelectedDate = (value) => {
    const temp = moment(value).format();
    setSelectedDate(temp);
  };

  useEffect(() => {
    getData();
  }, [getData, loadData]);

  const showAddModal = () => {
    setShowModal(true);
  };

  const handleChangeStudent = (selectedStudentId) => {
    setStudentId(selectedStudentId);
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        if (values) {
          // setParentName(values.parentName);
          const off = form.getFieldValue("dayoff");
          setDayOff(moment(off).format("YYYY-MM-DD"));
          setReason(values.reason);
          setNote(values.note);
        }
        setShowConfirmModal(true);
      })
      .catch((errorInfo) => {
        console.log("Validation failed:", errorInfo);
      });
  };

  const handleCreateAnnouncement = async () => {
    try {
      setLoading(true);
      if (classId) {
        const payload = {
          date: today,
          date_off: dayOff,
          // parentName: parentName.trim(),
          classId: classId,
          studentId: studentId,
          state: "approve",
          reason: reason.trim(),
          note: note.trim(),
        };
        const formData = new FormData();
        formData.append("data", JSON.stringify(payload));
        if (files) {
          for (let f of files) {
            formData.append("file", f);
          }
        }
        const res = await createParentCheckAttendance(accountId, formData);
        if (res?.status === 200) {
          Notification.sendSuccess("Gửi xin phép thành công.");
          setParentName("");
          setDayOff(moment().format("YYYY-MM-DD"));
          setReason("");
          setNote("");
          setShowConfirmModal(false);
          setShowModal(false);
          setLoading(false);
          setLoadData((prev) => !prev);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const viewFile = (values) => {
    const lst = JSON.parse(values);
    if (lst?.length) {
      const formattedFiles = lst?.map?.((item) => ({
        name: lst?.[0]?.title,
        url: lst?.[0]?.url,
        type: lst?.[0]?.mimetype,
      }));

      return (
        <FileList attachments={formattedFiles}>
          <FileItem {...formattedFiles[0]}>
            {({ onOpen }) => {
              return (
                <div onClick={onOpen}>
                  <div className="view-file">
                    <IconFile />
                  </div>
                </div>
              );
            }}
          </FileItem>
        </FileList>
      );
    }
  };

  const handleAddAttachments = (values) => {
    const temp = [...values];
    setFiles(temp);
  };

  const handleRemoveAttachment = (_value, index) => {
    let temp = [...files];
    temp.splice(index, 1);
    setFiles(temp);
  };

  const handleCloseModal = () => {
    setShowConfirmModal(false);
  };

  const columns = useMemo(() => {
    let columnLst = [
      {
        title: "STT",
        key: "STT",
        width: 50,
        align: "center",
        render: (value, record, index) =>
          (currentPage - 1) * pageSize.value + index + 1,
      },
      {
        title: "Họ và tên",
        key: "full_name",
        dataIndex: "students",
        width: 100,
        render: (text, record) => <span>{text.full_name}</span>,
      },
      {
        title: "Mã HS",
        key: "student_id",
        width: 50,
        align: "center",
        dataIndex: "students",
        render: (text, record) => <span>{text.id}</span>,
      },
      {
        title: "Ngày xin phép",
        key: "date",
        width: 100,
        align: "center",
        dataIndex: "date",
        render: (text, record) => (
          <span>{moment(text).format("DD-MM-YYYY")}</span>
        ),
      },
      {
        title: "Ngày nghỉ",
        key: "date_off",
        width: 100,
        align: "center",
        dataIndex: "date_off",
        render: (text, record) => (
          <span>{moment(text).format("DD-MM-YYYY")}</span>
        ),
      },
      {
        title: "Lý do",
        key: "reason",
        width: 100,
        align: "center",
        dataIndex: "reason",
        render: (text, record) => <span>{text}</span>,
      },
      {
        title: "Đơn xin phép",
        key: "files",
        width: 50,
        align: "center",
        dataIndex: "files",
        render: (text, record) => viewFile(text),
      },
      {
        title: "Ghi chú",
        key: "note",
        width: 100,
        align: "center",
        dataIndex: "note",
        render: (text, record) => <span>{text}</span>,
      },
    ];
    return columnLst;
  }, [currentPage, pageSize.value]);

  useEffect(() => {
    if (schoolYear && schoolYear !== null) getGrade();
  }, [schoolYear, getGrade]);

  useEffect(() => {
    if (grade && grade !== null) {
      getClass(grade);
    }
  }, [grade, getClass]);

  useEffect(() => {
    getDataClassList();
  }, [getDataClassList]);

  return (
    <div className={`flex flex-col h-full`}>
      {isUsingMobile ? (
        <MobileHeader
          type={HEADER_TYPE.middleTitle}
          title="Phụ huynh xin phép"
          haveNotiButton={false}
        />
      ) : (
        <CustomBreadcrumb
          crumbs={[STUDENT_MANAGEMENT, routes.PARENT_CHECK_ATTENDANCE]}
          style={styles.breadcrumb}
        />
      )}
      <div className={`py-4 section-shadow bg-white`}>
        <div className={`pt-2`}>
          <div className={`${styles.wrapperHeader}`}>
            <div className={`${styles.wrapperFilter}`}>
              <InfoFieldSelect
                label="Khối"
                data={gradeList}
                value={grade}
                setValue={setGrade}
                direction="vertical"
              />

              <InfoFieldSelect
                label="Lớp"
                data={classList}
                value={classId}
                setValue={setClassId}
                direction="vertical"
              />

              <InfoFieldDatePicker
                label={"Ngày nghỉ"}
                value={selectedDate}
                setValue={handleSetSelectedDate}
                placeholder="Chọn thời gian"
                direction="vertical"
                className="flex-1"
                picker={"date"}
              />
            </div>
            <div className="pr-5">
              <Button
                type="primary"
                onClick={showAddModal}
                className={`${styles.customButton}`}
              >
                Thêm điểm danh
              </Button>
            </div>
          </div>
          <div className={`mx-6 mt-5`}>
            <CusTable
              rowKey="id"
              data={data}
              havePagination={totalItems > pageSize.value}
              columns={columns}
              rowClassName="editable-row"
              page={currentPage}
              setPage={setCurrentPage}
              pageSize={pageSize}
              setPageSize={setPageSize}
              totalItems={totalItems}
              scroll={responsive.tableScroll()}
            />
          </div>
          <Modal
            style={{ marginTop: "-70px" }}
            width={800}
            title="Phụ huynh xin phép"
            open={showModal}
            onOk={handleOk}
            onCancel={handleCancel}
          >
            <Form form={form} name="parentPermissionForm">
              <Form.Item
                name="studentName"
                label="Tên học sinh"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  placeholder="Chọn học sinh cần xin phép"
                  onChange={handleChangeStudent}
                  allowClear
                  className={`${styles.customSelect}`}
                >
                  {studentData &&
                    studentData.map((student) => (
                      <Select.Option key={student.id} value={student.id}>
                        {student.full_name}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
              {/* <Form.Item
                name="parentName"
                label="Tên Phụ Huynh"
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập tên Phụ Huynh!",
                  },
                  {
                    max: 125,
                    message: "Giới hạn 125 ký tự",
                  },
                  {
                    pattern: /^(?!\s+$).+$/,
                    message: "Lỗi: Tên không phù hợp",
                  },
                ]}
                className={`${styles.customInput}`}
              >
                <Input />
              </Form.Item> */}
              <Form.Item label="Ngày xin phép" name="dayoff">
                <DatePicker className={`${styles.dateCustom}`} />
              </Form.Item>
              <Form.Item
                name="reason"
                label="Lý do"
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập Lý do!",
                  },
                  {
                    max: 256,
                    message: "Giới hạn 256 ký tự",
                  },
                  {
                    pattern: /^(?!\s+$).+$/,
                    message: "Lỗi: Lý do không phù hợp",
                  },
                ]}
                className={`${styles.customInput}`}
              >
                <Input />
              </Form.Item>
              <div className="flex items-start my-4">
                <div className="mRight12">
                  <FilePicker setFiles={handleAddAttachments} />
                </div>
                <FileShower
                  files={files}
                  removeFile={handleRemoveAttachment}
                  fileOnClick={() => { }}
                />
              </div>
              <Form.Item
                name="note"
                label="Ghi chú"
                className={`${styles.customInput}`}
                rules={[
                  {
                    max: 256,
                    message: "Giới hạn 256 ký tự",
                  },
                  {
                    pattern: /^(?!\s+$).+$/,
                    message: "Lỗi: Ghi chú không phù hợp",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Form>
          </Modal>
          <Modal
            title="Xác nhận"
            centered
            visible={showConfirmModal}
            footer={[
              <Button key="cancel" onClick={handleCloseModal}>
                Hủy
              </Button>,
              <Button
                key="send"
                type="primary"
                onClick={handleCreateAnnouncement}
                disabled={loading}
              >
                Gửi
              </Button>,
            ]}
          >
            <p>Xác nhận gửi đơn xin phép? </p>
          </Modal>
        </div>
      </div>
    </div>
  );
}
