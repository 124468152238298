import React from "react";
import styles from "./styles.module.css";

export default function NumberButton({
  number,
  onClick = () => {},
  currentPage,
  lastPage,
  disabled = false,
  ...props
}) {
  // #region    VARIABLES //////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion VARIABLES //////////////////////////

  // #region    useEffect //////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion useEffect //////////////////////////

  // #region    FUNCTIONS //////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion FUNCTIONS //////////////////////////

  // #region    VIEWS //////////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion VIEWS //////////////////////////////
  if (number > lastPage) return null;
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={`flex justify-center items-center mRight4 ${styles.root} ${
        currentPage === number ? styles.current : ""
      }`}
      {...props}
    >
      <span
        className={`${styles.text} ${
          currentPage === number ? styles.currentText : ""
        }`}
      >
        {number}
      </span>
    </button>
  );
}
