const color = {
  black: "#000000",
  white: "#ffffff",
  primary: "#1a77ff",
  blackText1: "#212b36",
  blackText2: "#637381",
  blackText3: "#ababab",
  blackText4: "#333333",
  blackText5: "#4b4b4b",
  blackText6: "#0f172a",
  blueText1: "#274c77",
  greyText1: "#797979",
  greyText2: "#64748B",
  greyBackground: "#edf1f7",
  greyBackground2: "#dfe3e8",
  greyBackground3: "#fafafa",
  mainBackground: "#f4f6f8",
  green: "#03d8Bc",
};
export default color;
