import { filterSchoolYear } from "util/APIs";

export const SET_SCHOOL_YEAR_LIST = "SET_SCHOOL_YEAR_LIST";
export const SET_SELECTED_SCHOOL_YEAR = "SET_SELECTED_SCHOOL_YEAR";

export function setSchoolYearListAction(data) {
  return {
    type: SET_SCHOOL_YEAR_LIST,
    payload: data,
  };
}

export function setSelectedSchoolYearAction(data) {
  return {
    type: SET_SELECTED_SCHOOL_YEAR,
    payload: data,
  };
}

export const reloadSchoolYearList = (schoolId) => async (dispatch) => {
  const params = {
    sort: "created_at desc",
    page: 1,
    size: 999,
    is_active: 1,
    school_id: schoolId,
  };

  const res = await filterSchoolYear(params);

  if (res?.data?.code === "success") {
    dispatch(setSchoolYearListAction(res?.data?.data));
    dispatch(setSelectedSchoolYearAction(res?.data?.data?.[0] || {}));
  }
};
