import { thuThiemLogo } from "asset/img/Images";
import React from "react";
import { mrHeight, mrWidth } from "util/helper";
import NotiButton from "../NotiButton/NotiButton";
import styles from "./styles.module.css";

export default function BrandnameHeader() {
  // #region    VARIABLES //////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion VARIABLES //////////////////////////

  // #region    useEffect //////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion useEffect //////////////////////////

  // #region    FUNCTIONS //////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion FUNCTIONS //////////////////////////

  // #region    VIEWS //////////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion VIEWS //////////////////////////////
  return (
    <div className={`flex justify-between ${styles.root}`}>
      <div className="flex items-center">
        <img
          src={thuThiemLogo}
          alt=""
          style={{ width: mrWidth(40), height: mrHeight(40) }}
        />
        {/* <img
          src={educentric}
          alt=""
          style={{ width: mrWidth(150), height: mrHeight(11) }}
        /> */}
      </div>
      <NotiButton />
    </div>
  );
}
