import React, { useCallback, useEffect, useState, useMemo } from "react";
import { DataGrid } from "@mui/x-data-grid";
import styles from "./styles.module.css";
import InfoFieldDatePicker from "common/CusInfoField/infoFieldDatePicker/InfoFieldDatePicker";
import moment from "moment";
import { Button, Form, Input, Modal } from "antd";
import { HEADER_TYPE } from "mobile/util/mobileConstant";
import { routes } from "util/routes";
import Box from "@mui/material/Box";
import IsMobile from "hook/isMobile";
import MobileHeader from "mobile/layout/header/MobileHeader";
import CustomBreadcrumb from "common/breadcrumb/CustomBreadcrumb";
import InfoFieldSelect from "../../common/CusInfoField/infoFieldSelect/InfoFieldSelect";
import {
  getGroupSms,
  createMenu,
  getMenu,
  updateMenu,
  createAnnouncement,
} from "util/APIs";
import { useSelector } from "react-redux";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { CropLandscapeOutlined } from "@mui/icons-material";
import { Notification } from "common/Notification";

export default function CanteenWeekPage() {
  const [selectedDate, setSelectedDate] = useState(moment().format());
  const [selectedWeek, setSelectedWeek] = useState(moment().format("wo"));
  const [showModalAdd, setShowModalAdd] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [value, setValue] = useState("staff");
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [type, setType] = useState("ke_hoach");
  const [files, setFiles] = useState(null);
  const [smsPending, setSmsPending] = useState(false);
  const [groupId, setGroupId] = useState([]);
  const [group, setGroup] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [rows, setRows] = useState([]);
  const [newSTT, setNewSTT] = useState([]);
  const [quanlityWeek, setQuanlityWeek] = useState("1");

  const [startWeek, setStartWeek] = useState(moment().startOf("week"));
  const [endWeek, setEndWeek] = useState(moment());
  const userInfo = useSelector((state) => state?.app?.userInfo);
  const accountId = userInfo?.account?.id;

  const [form] = Form.useForm();

  useEffect(() => {
    const formattedDate = startWeek.format("YYYY-MM-DD");

    if (!newSTT.some((entry) => entry.week === selectedWeek)) {
      setNewSTT((prevSTT) => {
        const newSortedSTT = [
          ...prevSTT,
          { date: formattedDate, week: selectedWeek },
        ].sort((a, b) => {
          const aNumber = parseInt(a.date.replace(/\D/g, ""));
          const bNumber = parseInt(b.date.replace(/\D/g, ""));
          return aNumber - bNumber;
        });

        return newSortedSTT;
      });
    }
  }, [selectedWeek, startWeek, setNewSTT, newSTT]);

  const isUsingMobile = IsMobile();
  const handleChange = (event) => {
    setQuanlityWeek(event.target.value);
  };
  const handleSetSelectedDate = (value) => {
    const temp = moment(value).format();
    setSelectedDate(temp);
    const temp2 = moment(value).format("wo");
    setSelectedWeek(temp2);
    const selectedDate = moment(value);

    const startOfWeek = selectedDate.clone().startOf("week");
    const endOfWeek = selectedDate.clone().endOf("week");
    setSelectedDate(selectedDate.format());
    setSelectedWeek(selectedDate.format("wo"));
    setStartWeek(startOfWeek);
    setEndWeek(endOfWeek);
  };

  const onChangeCheckbox = (e) => {
    setSmsPending(e.target.checked);
  };

  const handleCreateAnnouncementGroup = async () => {
    const payload = {
      title: title.trim(),
      body: body.trim(),
      roles: value,
      type,
      sms: smsPending,
      //   group: groupId,
    };
    const formData = new FormData();
    formData.append("data", JSON.stringify(payload));
    if (files) {
      for (let f of files) {
        formData.append("file", f);
      }
    }
    // const res = await createAnnouncement(accountId, formData);
    // if (res?.status === 200) {
    //   Notification.sendSuccess("Gửi thông báo thành công.");
    //   setFiles(null);
    //   setTitle("");
    //   setBody("");
    setShowConfirmModal(false);
  };

  const getGroups = useCallback(async () => {
    const params = {
      "filters[name]": "nha_an",
    };
    const res = await getGroupSms(accountId, params);
    if (res?.status === 200) {
      const data = res?.data.data;
      setGroup(data);
      if (data?.length > 0) {
        setGroupId(data[0].id);
      }
    }
  }, [accountId]);

  useEffect(() => {
    getGroups();
  }, [getGroups]);
  const countListWeek = Array.from({ length: 10 }, (_, index) =>
    (index + 1).toString()
  );
  const [currentValueWeek, setCurrentValueWeek] = useState("1");

  const daysOfWeek = [
    "Chủ nhật",
    "Thứ 2",
    "Thứ 3",
    "Thứ 4",
    "Thứ 5",
    "Thứ 6",
    "Thứ 7",
  ];
  const fieldMapDay = ["CN", "T2", "T3", "T4", "T5", "T6", "T7"];
  const mon_Day = fieldMapDay.map((day, index) => {
    return {
      mon_1: {
        field: `${day}_mon_1`,
        headerName: "Món 1",
        width: 150,
        editable: true,
      },
      mon_2: {
        field: `${day}_mon_2`,
        headerName: "Món 2",
        width: 150,
        editable: true,
      },
    };
  });

  const columns = [
    { field: "STT", headerName: "STT", width: 100, editable: true },
    ...mon_Day.flatMap((day) => [day.mon_1, day.mon_2]),
    {
      field: "submitbutton",
      headerName: "", // You can customize the header text
      width: 100,
      renderCell: (params) => {
        // Customize the button appearance or behavior as needed
        return (
          <Button
            onClick={() => handleButtonClick(params.row.STT)} // You can pass the row ID or any other data to the button click handler
            disabled={params.row.isButtonDisabled} // Optionally disable the button based on some condition
          >
            Gửi duyệt
          </Button>
        );
      },
    },
    {
      field: "closebutton",
      headerName: "",
      width: 100,
      renderCell: (params) => (
        <Button onClick={() => handleRemoveButtonClick(params.row.STT)}>
          X
        </Button>
      ),
    },
  ];

  const groupDayOfWeek = daysOfWeek.flatMap((day, index) => {
    const dayFields = mon_Day[index];
    const dayChildren = Object.values(dayFields);

    return {
      groupId: day,
      children: dayChildren,
    };
  });

  // Generate rows whenever newSTT or selectedWeek changes

  const fetchData = async () => {
    const generatedRows = [];

    for (const week of newSTT) {
      const newRow = {
        id: generatedRows.length + 1,
        STT: week.week,
        startweek: week.date,
      };

      // const weekNumber = parseInt(week.match(/\d+/)[0]);
      const startOfWeek = moment(week.date);
      let rowStatus = false;
      for (let dayIndex = 0; dayIndex < fieldMapDay.length; dayIndex++) {
        const currentDay = startOfWeek.clone().add(dayIndex, "days");
        const formattedDate = currentDay.format("YYYY-MM-DD");

        const params = {
          "filters[date]": formattedDate,
        };
        const res = await getMenu(accountId, params);

        if (res?.status === 200 && res.data.data.length > 0) {
          const dishes =
            res.data.data.find((entry) => entry.daily_id === "1")?.dishes || "";
          newRow[`${fieldMapDay[dayIndex]}_mon_1`] = dishes;

          const secondDishes =
            res.data.data.find((entry) => entry.daily_id === "2")?.dishes || "";
          newRow[`${fieldMapDay[dayIndex]}_mon_2`] = secondDishes;
        } else {
          newRow[`${fieldMapDay[dayIndex]}_mon_1`] = "";
          newRow[`${fieldMapDay[dayIndex]}_mon_2`] = "";
        }
        if (
          res.data.data.length !== 0 &&
          res.data?.data?.[0]?.granted === true
        ) {
          rowStatus = true;
        }
      }

      newRow.isButtonDisabled = rowStatus;
      generatedRows.push(newRow);
    }

    return generatedRows;
  };

  // Handle button click
  const handleButtonClick = async (rowSTT) => {
    // Handle button click for the specific row
    const body = {
      data: {
        title: "Gửi duyệt thực đơn",
        body: `Gửi duyệt thực đơn tuần thứ ${rowSTT}`,
        roles: "group",
        type: "ke_hoach",
        sms: true,
        group: [groupId],
      },
    };
    const res = await createAnnouncement(accountId, body);
    if (res.status === 200) {
      Notification.sendSuccess("Gửi duyệt thành công!");
    }
  };
  // Handle remove button click
  const handleRemoveButtonClick = (rowId) => {
    // Handle remove button click for the specific row
    setNewSTT((prevSTT) => prevSTT.filter((id) => id !== rowId));
  };

  useEffect(() => {
    const fetchDataAndSetRows = async () => {
      const data = await fetchData();
      setRows(data);
    };

    fetchDataAndSetRows();
  }, [newSTT, selectedWeek]);

  const columnGroupingModel = [
    {
      groupId: "Tuần",
      children: [{ field: "STT" }],
    },
    ...groupDayOfWeek,
    {
      groupId: "Gửi duyệt",
      children: [{ field: "submitbutton" }],
    },
  ];

  //Addmenu
  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  function findDifferentPairs(dict1, dict2) {
    let differentPairs = {};

    for (const key in dict1) {
      if (dict1.hasOwnProperty(key) && dict2.hasOwnProperty(key)) {
        if (dict1[key] !== dict2[key]) {
          differentPairs = { key, value: dict1[key] };
        }
      } else {
        // Handle keys that exist in one dictionary but not the other
        differentPairs = { key, value: dict1[key] };
      }
    }
    return differentPairs;
  }

  const extractDayInfo = (startweek, dayIndex, keyValuePair) => {
    const startOfWeek = moment(startweek);
    const selectedDay = startOfWeek.clone().add(dayIndex, "days");

    const daily_id = parseInt(keyValuePair.key.split("_")[2]);

    return {
      dishes: keyValuePair.value,
      date: selectedDay.format("YYYY-MM-DD"),
      daily_id: daily_id,
    };
  };

  const processRowUpdate = async (newRow) => {
    const updatedRow = { ...newRow };
    const updatedRows = rows.map((row) =>
      row.id === updatedRow.id ? updatedRow : row
    );
    const oldRow = rows.filter((e) => e.id === newRow.id)[0];
    // let data = {
    //     date:moment(selectedDate).format("YYYY-MM-DD"),
    //     dishes:
    // }
    const diff = findDifferentPairs(newRow, oldRow);
    const dayofweek = { CN: 0, T2: 1, T3: 2, T4: 3, T5: 4, T6: 5, T7: 6 };
    let dayIndex;
    // Check if the key starts with any key in dayofweek
    Object.keys(dayofweek).forEach((dayKey) => {
      if (diff.key.startsWith(dayKey)) {
        dayIndex = dayofweek[dayKey];
      }
    });
    if (!isNaN(dayIndex) && dayIndex >= 0 && dayIndex < 7) {
      const result = extractDayInfo(newRow.startweek, dayIndex, diff);
      const filters = {
        "filters[date][$eq]": result.date,
        "filters[daily_id]": result.daily_id,
        "filters[workflow_state]": "active",
      };
      const menu_dishes = await getMenu(accountId, filters);
      if (menu_dishes.data?.data?.length === 0) {
        const params = {
          data: {
            ...result,
            workflow_state: "active",
          },
        };
        const res = await createMenu(accountId, params);
        if (res?.status === 200) {
          Notification.sendSuccess("Thêm thành công!");
        }
      } else {
        if (menu_dishes.data?.data?.[0]?.granted === true) {
          Notification.sendError("Không thể chỉnh sửa món ăn đã duyệt");
        } else {
          const params = {
            data: {
              ...result,
              workflow_state: "active",
            },
          };
          const res = await updateMenu(
            accountId,
            menu_dishes.data?.data?.[0]?.id,
            params
          );
          if (res?.status === 200) {
            Notification.sendSuccess("Chỉnh sửa thành công!");
          }
        }
      }
    } else {
      console.log("Invalid dayIndex");
    }

    const data = await fetchData();
    setRows(data);
  };

  const handleProcessRowUpdateError = (error: Error) => {
    console.log(error);
  };

  return (
    <div className={`flex flex-col h-full`}>
      {isUsingMobile ? (
        <MobileHeader
          type={HEADER_TYPE.middleTitle}
          title="Thực đơn nhà ăn"
          haveNotiButton={false}
        />
      ) : (
        <CustomBreadcrumb crumbs={[routes.CANTEEN_WEEK]} />
      )}
      <div className={`py-4 section-shadow bg-white`}>
        <div className={`${styles.wrapper}`}>
          {isUsingMobile ? (
            ""
          ) : (
            <h2 className={`${styles.header}`}>Thực đơn nhà ăn </h2>
          )}
          <div className={`${styles.wrapperFilters}`}>
            {/* <Button
              type="primary"
              className={`${styles.customButton}`}
              onClick={() => setShowModalAdd(true)}
            >
              Thêm món mới
            </Button> */}

            <InfoFieldDatePicker
              label={"Tuần"}
              value={selectedDate}
              setValue={handleSetSelectedDate}
              placeholder="Chọn thời gian"
              direction="vertical"
              className="flex-1"
              format={"wo"}
              picker={"week"}
            />
            {/* <FormControl sx={{ minWidth: 120 }} size="small">
              <InputLabel id="demo-simple-select-label">Số tuần</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={quanlityWeek}
                label="Chọn số tuần"
                onChange={handleChange}
              >
                {[...Array(10).keys()].map((value) => (
                  <MenuItem key={value} value={value + 1}>
                    {value + 1}
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}
          </div>
        </div>
        <div className={`mx-6 mt-5`}>
          <Box sx={{ height: 400, width: "100%" }}>
            <DataGrid
              rows={rows}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 5,
                  },
                },
              }}
              processRowUpdate={processRowUpdate}
              pageSizeOptions={[5]}
              onRowModesModelChange={handleRowModesModelChange}
              experimentalFeatures={{ columnGrouping: true }}
              columnGroupingModel={columnGroupingModel}
              onProcessRowUpdateError={handleProcessRowUpdateError}
            />
          </Box>
          {/* <Button
            type="primary"
            className={`${styles.customButton} mt-5`}
            onClick={() => setShowModalAdd(true)}
          >
            Gửi duyệt
          </Button> */}
        </div>
        {/* <Modal
          style={{ marginTop: "-70px" }}
          width={600}
          title="Thêm món ăn mới"
          open={showModalAdd}
          // onOk={handleOk}
          onCancel={() => setShowModalAdd(false)}
          footer={[
            <Button key="cancel" onClick={() => setShowModalAdd(false)}>
              Hủy
            </Button>,
            <Button key="send" type="primary">
              Xác nhận
            </Button>,
          ]}
        >
          <Form form={form} name="dishesAddForm">
            <Form.Item
              name="newFood"
              label="Tên món ăn"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập tên Món ăn!",
                },
                {
                  max: 125,
                  message: "Giới hạn 125 ký tự",
                },
                {
                  pattern: /^(?!\s+$).+$/,
                  message: "Lỗi: Tên không phù hợp",
                },
              ]}
              className={`${styles.customInput}`}
            >
              <Input />
            </Form.Item>
          </Form>
        </Modal> */}
      </div>
    </div>
  );
}
