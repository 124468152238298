import InfoFieldSelect from "common/CusInfoField/infoFieldSelect/InfoFieldSelect";
import Img from "common/image/Img";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedSchoolAction } from "redux/action/school";
import {
  reloadSchoolYearList,
  setSelectedSchoolYearAction,
} from "redux/action/schoolYear";
import {
  cameraWhiteOutlined,
  demoAvatar
} from "asset/img/Images";
import { DEFAULT_AVATAR, SIDEBAR_EXPAND_WIDTH } from "util/constant";
import SidebarBody from "./component/SidebarBody";
import styles from "./styles.module.css";
import React, { useEffect, useRef, useState } from "react";
import {
  updateUser,
  getTeacherData
} from "util/APIs";
import Avatar from "common/avatar/Avatar";



export default function Sidebar() {
  // #region    VARIABLES //////////////////////////
  //////////////////////////////////////////////////
  const collapse = useSelector((state) => state.app.sidebarCollapse);
  const selectedSchoolRedux = useSelector(
    (state) => state.school.selectedSchool
  );
  const schoolYearRedux = useSelector((state) => state.schoolYear);

  const schoolRedux = useSelector((state) => state.school);
  const dispatch = useDispatch();


  const [userInfo, setUserInfo] = useState(
    JSON.parse(localStorage.getItem("user_info"))
  );
  const [imageUrls, setImageUrls] = useState(null);

  //////////////////////////////////////////////////
  // #endregion VARIABLES //////////////////////////

  // #region    useEffect //////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion useEffect //////////////////////////

  // #region    FUNCTIONS //////////////////////////
  //////////////////////////////////////////////////
  const handleSetSchoolYear = (value) => {
    const data = schoolYearRedux.schoolYearList?.find((i) => i.id === value);
    dispatch(setSelectedSchoolYearAction(data));
  };

  const handleSetSchool = (value) => {
    const data = schoolRedux?.schoolList?.find((x) => x.id === value);
    dispatch(setSelectedSchoolAction(data));
    dispatch(reloadSchoolYearList(value));
  };
  //////////////////////////////////////////////////
  // #endregion FUNCTIONS //////////////////////////

  // #region    VIEWS //////////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion VIEWS //////////////////////////////
  useEffect(() => {
    if (localStorage.getItem("user_info")) {
      getTeacherData().then((result) => {
        if (result.data?.user?.files != null) {
          result.data.user.files = JSON.parse(result.data?.user?.files)
          setImageUrls(result?.data?.user?.files[0]?.url)
        }
      })
    }
  }, []);
  const handlechangefile = async (e) => {
    const file = e.target.files
    const formData = new FormData();

    formData.append("file", file[0]);
    if (file) {
      const res = await updateUser(userInfo?.account?.id, userInfo?.user?.id, formData);
      if (res.status === 200) {
        setImageUrls(JSON.parse(res.data.files)[0].url)
      }
    }
  }
  // Create a reference to the hidden file input element
  const hiddenFileInput = useRef(null);

  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = event => {
    hiddenFileInput.current.click();
  };
  return (
    <div
      style={{
        width: !collapse ? SIDEBAR_EXPAND_WIDTH : "unset",
        minWidth: !collapse ? SIDEBAR_EXPAND_WIDTH : "unset",
      }}
      className={styles.root}
    >
      <div
        className={`pHorizontal16 flex flex-col items-center ${styles.topInfoWrapper}`}
      >
        <div className={`mTop16 ${styles.logo}`}>
          <Avatar
            src={imageUrls || demoAvatar}
            size={100}
            // className={styles?.avatar}
            imgClassName="w-full h-full"
          />
          <Avatar
            src={cameraWhiteOutlined}
            size={34}
            className={styles.changeAvatarBtn}
            imgClassName="w-full h-full"
            imgIsRound={false}
            onClick={handleClick}
          />
          <input
            type="file"
            onChange={handlechangefile}
            ref={hiddenFileInput}
            style={{ display: 'none' }} // Make the file input element invisible
          />
        </div>
        {/* <span className={`mTop4 ${styles.name}`}>
          {selectedSchoolRedux?.name}
        </span> */}
        {/* <InfoFieldSelect
          noLabel
          data={schoolRedux.schoolList}
          value={schoolRedux.selectedSchool.id}
          setValue={handleSetSchool}
          labelWrapperClassName={styles.label}
          className={`mTop24 ${styles.select}`}
          inputWrapperClassName={styles.select2}
          valueTextName="name"
          valueFieldName="id"
        />
        <InfoFieldSelect
          noLabel
          data={schoolYearRedux.schoolYearList}
          value={schoolYearRedux.selectedSchoolYear.id}
          setValue={handleSetSchoolYear}
          labelWrapperClassName={styles.label}
          className={`mTop24 ${styles.select}`}
          inputWrapperClassName={styles.select2}
        /> */}
      </div>
      <div className={`mTop16 ${styles.scrolling}`}>
        <SidebarBody />
      </div>
    </div>
  );
}
