import { Select } from "antd";
import { chevronDownIcon } from "asset/img/Images";
import Img from "common/image/Img";
import React from "react";
import {
  createAttendanceOneStudent,
  // updateAttendanceByDate,
  updateAttendanceOneStudent,
} from "util/APIs";
import { ATTENDANCE_STATUS } from "util/constant";

import {
  anualLeaveActive,
  lateActive,
  noAuthLeaveActive,
  presentActive,
} from "asset/img/Images";
import { Notification } from "common/Notification";
import moment from "moment";
import { useSelector } from "react-redux";
import styles from "./styles.module.css";

export default function SelectAttendance({
  classId,
  studentId,
  currentLesson,
  date,
  attendanceId,
  attendanceDate,
  attendanceStatus,
  setIsRefresh,
  placeholder = "",
  disabled,
  // schoolYear,
  section,
}) {
  // #region    VARIABLES //////////////////////////
  //////////////////////////////////////////////////
  const { Option } = Select;
  //////////////////////////////////////////////////
  // #endregion VARIABLES //////////////////////////

  // #region    useEffect //////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion useEffect //////////////////////////

  // #region    FUNCTIONS //////////////////////////
  //////////////////////////////////////////////////
  const userInfo = useSelector((state) => state?.app?.userInfo);
  const accountId = userInfo?.account?.id;

  const handleOnChange = async (status) => {
    const item = {
      workflow_state: status,
      students: [studentId],
      date: moment(date).format("YYYY-MM-DD"),
    };
    if (attendanceId) {
      const payload = {
        data: item,
      };
      const res = await updateAttendanceOneStudent(
        accountId,
        attendanceId,
        payload
      );
      if (res?.status === 200) {
        Notification.sendSuccess("Điểm danh thành công");
        setIsRefresh(true);
        return;
      }
    } else {
      const payload = {
        data: {
          ...item,
          sessions: [section],
          classes: [classId],
          lessons: [currentLesson],
          type: "class",
        },
      };
      const res = await createAttendanceOneStudent(accountId, payload);
      if (res?.status === 200) {
        Notification.sendSuccess("Điểm danh thành công");
        setIsRefresh(true);
        return;
      }
    }

    // const params = {
    //   class_id: classId,
    //   student_id: studentId,
    //   date: date,
    //   value: status,
    //   school_year_id: schoolYear?.id,
    //   section,
    // };
    // updateAttendanceByDate(params).then((res) => {
    //   if (res?.data?.code === "success") {
    //     message.success("Chỉnh sửa thành công!");
    //     setIsRefresh(true);
    //     return;
    //   }
    // });
  };
  //////////////////////////////////////////////////
  // #endregion FUNCTIONS //////////////////////////

  // #region    VIEWS //////////////////////////////
  //////////////////////////////////////////////////
  const renderOption = (item) => {
    let img = null;
    if (item.shortName === "U") img = <span></span>;
    if (item.shortName === "C")
      img = <img src={presentActive} alt="Có mặt" width={25} />;
    if (item.shortName === "T")
      img = <img src={lateActive} alt="Trễ" width={25} />;
    if (item.shortName === "P")
      img = <img src={anualLeaveActive} alt="Nghỉ có phép" width={25} />;
    if (item.shortName === "K")
      img = <img src={noAuthLeaveActive} alt="Nghỉ không phép" width={25} />;

    return (
      <Option value={item.key} key={item.key} className={styles.option}>
        {img}
      </Option>
    );
  };
  //////////////////////////////////////////////////
  // #endregion VIEWS //////////////////////////////
  return (
    <Select
      value={attendanceStatus}
      onChange={handleOnChange}
      bordered={false}
      showArrow={false}
      suffixIcon={<Img src={chevronDownIcon} alt="Select" />}
      placeholder={placeholder}
      disabled={disabled}
      className={`w-full text-center attendance-select ${styles.select}`}
    >
      {ATTENDANCE_STATUS.map(renderOption)}
    </Select>
  );
}
