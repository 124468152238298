import React from "react";
import moment from "moment";
import { DATE_FORMAT, ROLE_KEY_LIST } from "./constant";
import IconMinus from "asset/icons/IconMinus";

export const formatDateValue = (value) => {
  if (!value) return "--";
  return moment(value).format(DATE_FORMAT);
};

export const formatFiieldValue = (value) => {
  if (!value) return "--";
  return value;
};

export const formatCellValue = (value) => {
  if (!value) {
    return (
      <div>
        <IconMinus />
      </div>
    );
  }
  return value;
};

export const messageTypeList = [
  {
    id: "ke_hoach",
    name: "Kế hoạch",
  },
  {
    id: "hoc_tap",
    name: "Học tập",
  },
];

export const messageWorkFlowStateList = [
  {
    id: "active",
    name: "Hoạt động",
  },
  {
    id: "deleted",
    name: "Đã xoá",
  },
  {
    id: "read",
    name: "Đã đọc",
  },
  {
    id: "error",
    name: "Bị lỗi",
  },
  {
    id: "sent",
    name: "Đã gửi",
  },
  {
    id: "waiting",
    name: "Đang chờ",
  },
];

export const formatMessageType = (value) => {
  const item = messageTypeList.find((t) => t.id === value);
  return item?.name || "--";
};

export const formatMessageWorkflowState = (value) => {
  const item = messageWorkFlowStateList.find((w) => w.id === value);
  return item?.name || "--";
};

export const checkIsRoleAdmin = (roleList) => {
  if (roleList.find((role) => role.type === ROLE_KEY_LIST.admin) !== -1) {
    return true;
  }
  return false;
};
