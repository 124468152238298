import React, { PureComponent } from "react";

class ELoading extends PureComponent {
  state = {
    loading: false,
  };

  componentDidMount() {
    this.setLoading(this.props.isLoading);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isLoading !== this.props.isLoading) {
      this.setLoading(this.props.isLoading);
    }
  }

  setLoading = (isLoading) => {
    if (isLoading) {
      clearTimeout(this.timerLoading);
      this.setState({
        isLoading: true,
      });
    } else {
      this.timerLoading = setTimeout(() => {
        this.setState({
          isLoading: false,
        });
      }, 100);
    }
  };

  render() {
    if (this.state.isLoading) {
      return (
        <div className="e-loading">
          <div className={`circle-loading`}>Loading...</div>
        </div>
      );
    }
    return null;
  }
}

export default ELoading;
