import React, { memo, useEffect } from "react";
// import Loadable from 'react-loadable';
import { FileType } from "../../enums/FileType";
import { getFileTypeFromMimeType } from "../fileUtils";
import ImagePreview from "./ImagePreview";
import NoPreview from "./NoPreview";
import PdfPreview from "./PdfPreview";

const FileContentViewer = memo(
  ({ previewUrl, name, contentType, onDownload, onLoaded }) => {
    const fileType = getFileTypeFromMimeType(contentType);

    const ContentComponent = {
      [`${FileType.IMAGE}`]: ImagePreview,
      [`${FileType.PDF}`]: PdfPreview,
    }[fileType];

    useEffect(() => {
      if (onLoaded) {
        onLoaded(fileType);
      }
    }, [fileType, onLoaded]);

    if (ContentComponent) {
      return (
        <ContentComponent
          src={previewUrl}
          name={name}
          contentType={contentType}
          onDownload={onDownload}
        />
      );
    }
    return <NoPreview onDownload={onDownload} />;
  }
);

FileContentViewer.displayName = "FileContentViewer";
export default FileContentViewer;
