export const TEACHING_MANAGEMENT = "/teaching-management";
export const STUDENT_MANAGEMENT = "/student-management";
export const PARENTS_MANAGEMENT = "/parents-management";

export const routes = {
  HOME: "/user/home",
  PROFILE: "/user/profile",
  TIMETABLE: `${TEACHING_MANAGEMENT}/timetable/list`,
  REGISTER_NOTEBOOKS: `${TEACHING_MANAGEMENT}/register-notebooks`,
  TEACHING_DISTRIBUTION: `${TEACHING_MANAGEMENT}/teaching-distribution`,
  GRADER_SUPPORT: `${TEACHING_MANAGEMENT}/grader-support`,
  GRADEBOOK: `${STUDENT_MANAGEMENT}/gradebook`,
  STUDENT_INFO: `${STUDENT_MANAGEMENT}/info`,
  ATTENDANCE: `${STUDENT_MANAGEMENT}/attendance`,
  TRANSCRIPT: `${STUDENT_MANAGEMENT}/transcript`,
  PARENT_CHECK_ATTENDANCE: `${STUDENT_MANAGEMENT}/parent-check-attendance`,
  EVALUATION: `${STUDENT_MANAGEMENT}/evaluation`,
  VIOLATE: `${STUDENT_MANAGEMENT}/violate`,
  MESSAGE: "/message-management",
  MESSAGE_DETAIL: "/message-management/:messageId",
  CANTEENT_DETAIL: "/canteen-management",
  ADMIN_CANTEEN: "/admin/canteen-management-week",
  LUNCH_REGISTRY: "/lunch-register",
  CONTACT_BOOK: `${PARENTS_MANAGEMENT}/contact-book`,
  CANTEEN_WEEK: "/canteen-management-week",
  ADMIN_NOTIFICATION: "/admin/sms",
  ADMIN_STAFF_MANAGEMENT: "/admin/staff-management",
  ADMIN_STUDENT_MANAGEMENT_DETAIL: "/admin/student-management/:studentId",
};
