import { logo } from "asset/img/Images";

export const BASE_URL =
  process.env.REACT_APP_BE_URL || "https://sis.cohota.dev";
export const BASE_URL_TEST =
  process.env.REACT_APP_BE_URL || "http://localhost:13307";
export const MEDIA_BASE_URL =
  process.env.REACT_APP_MEDIA_BE_URL || `https://media.educentric.vn`;
export const MASS_UPLOAD_URL = `${BASE_URL}/ms-mass-upload/api`;
export const API_URL = `${BASE_URL}/api/v1`;
export const API_URL_TEST = `${BASE_URL_TEST}/api/v1`;
export const IMAGE_BASE_URL = `${MEDIA_BASE_URL}/media`;
export const IMAGE_BASE_URL_USER = `${IMAGE_BASE_URL}/user`;
export const DEFAULT_AVATAR = `${IMAGE_BASE_URL}/student/default.jpg`;
export const is1920x1080 = window.innerWidth === 1920;
export const is1920OrMore = window.innerWidth >= 1920;
export const is1440x900 = window.innerWidth === 1440;
export const is1366x768 = window.innerWidth === 1366;
export const SCREEN_WIDTH = window.innerWidth;
export const SCREEN_HEIGHT = window.innerHeight;

export const OBJECT_TYPE_BE = {
  student: "student",
  user: "user",
  staff: "staff",
  homework_attachment: "homework_attachment",
  homework_detail_attachment: "homework_detail_attachment",
  school: "school",
  school_cover: "school_cover",
  personal: "personal",
  personal_cover: "personal_cover",
  out_of_school: "out_of_school",
  suspended_from_school: "suspended_from_school",
};

export const GENDER = [
  { value: "male", name: "Nam", symbol: logo, prefixName: "Thầy." },
  { value: "female", name: "Nữ", symbol: logo, prefixName: "Cô." },
];

export const CRUD_MODE = {
  create: "create",
  update: "update",
};

export const ZERO_ID = "00000000-0000-0000-0000-000000000000";

export const DATE_FORMAT = "DD/MM/YYYY";

export const TRANSCRIPT_POINTS_COLUMNS = [
  "fifteen_minutes_first",
  "fifteen_minutes_second",
  "fifteen_minutes_third",
  "fifteen_minutes_fourth",
  "forty_five",
  "exam",
];

export const SEMESTERS = [
  { key: "semester_first", value: "Học kỳ I", color: "blue", shortValue: "I" },
  {
    key: "semester_second",
    value: "Học kỳ II",
    color: "geekblue",
    shortValue: "II",
  },
  { key: "whole_year", value: "Cả năm", color: "purple" },
];

export const IMAGE_TYPE = ["image/jpeg", "image/jpg", "image/png"];

export const PERIOD = {
  morning: { code: "morning", name: "Buổi sáng" },
  afternoon: { code: "afternoon", name: "Buổi chiều" },
  night: { code: "night", name: "Buổi tối" },
};

export const GENERAL_SECTION = [
  {
    key: "primary_section",
    value: "Chính khóa",
  },
  {
    key: "secondary_section",
    value: "Trái buổi",
  },
  {
    key: "whole_day",
    value: "Cả ngày",
  },
];
const SCHEDULE_LIST_EMPTY_TEMPLATE = {
  monday: null,
  tuesday: null,
  wednesday: null,
  thursday: null,
  friday: null,
  saturday: null,
  sunday: null,
};
export const SCHEDULE_LIST_EMPTY = [
  {
    lesson: "Tiết 1",
    period: PERIOD.morning.code,
    ...SCHEDULE_LIST_EMPTY_TEMPLATE,
  },
  {
    lesson: "Tiết 2",
    period: PERIOD.morning.code,
    ...SCHEDULE_LIST_EMPTY_TEMPLATE,
  },
  {
    lesson: "Tiết 3",
    period: PERIOD.morning.code,
    ...SCHEDULE_LIST_EMPTY_TEMPLATE,
  },
  {
    lesson: "Tiết 4",
    period: PERIOD.morning.code,
    ...SCHEDULE_LIST_EMPTY_TEMPLATE,
  },
  {
    lesson: "Tiết 5",
    period: PERIOD.morning.code,
    ...SCHEDULE_LIST_EMPTY_TEMPLATE,
  },
  {
    lesson: "Tiết 1",
    period: PERIOD.afternoon.code,
    ...SCHEDULE_LIST_EMPTY_TEMPLATE,
  },
  {
    lesson: "Tiết 2",
    period: PERIOD.afternoon.code,
    ...SCHEDULE_LIST_EMPTY_TEMPLATE,
  },
  {
    lesson: "Tiết 3",
    period: PERIOD.afternoon.code,
    ...SCHEDULE_LIST_EMPTY_TEMPLATE,
  },
  {
    lesson: "Tiết 4",
    period: PERIOD.afternoon.code,
    ...SCHEDULE_LIST_EMPTY_TEMPLATE,
  },
  {
    lesson: "Tiết 5",
    period: PERIOD.afternoon.code,
    ...SCHEDULE_LIST_EMPTY_TEMPLATE,
  },
  {
    lesson: "Tiết 1",
    period: PERIOD.night.code,
    ...SCHEDULE_LIST_EMPTY_TEMPLATE,
  },
  {
    lesson: "Tiết 2",
    period: PERIOD.night.code,
    ...SCHEDULE_LIST_EMPTY_TEMPLATE,
  },
  {
    lesson: "Tiết 3",
    period: PERIOD.night.code,
    ...SCHEDULE_LIST_EMPTY_TEMPLATE,
  },
  {
    lesson: "Tiết 4",
    period: PERIOD.night.code,
    ...SCHEDULE_LIST_EMPTY_TEMPLATE,
  },
  {
    lesson: "Tiết 5",
    period: PERIOD.night.code,
    ...SCHEDULE_LIST_EMPTY_TEMPLATE,
  },
];

export const DAY_OF_WEEK = [
  { key: "lesson", name: "", weekday: null },
  { key: "monday", name: "Thứ Hai", weekday: 1 },
  { key: "tuesday", name: "Thứ Ba", weekday: 2 },
  { key: "wednesday", name: "Thứ Tư", weekday: 3 },
  { key: "thursday", name: "Thứ Năm", weekday: 4 },
  { key: "friday", name: "Thứ Sáu", weekday: 5 },
  { key: "saturday", name: "Thứ Bảy", weekday: 6 },
  { key: "sunday", name: "Chủ Nhật", weekday: 7 },
];

export const DAY_OF_WEEK_2 = [
  { key: "monday", name: "Thứ Hai", weekday: 1 },
  { key: "tuesday", name: "Thứ Ba", weekday: 2 },
  { key: "wednesday", name: "Thứ Tư", weekday: 3 },
  { key: "thursday", name: "Thứ Năm", weekday: 4 },
  { key: "friday", name: "Thứ Sáu", weekday: 5 },
  { key: "saturday", name: "Thứ Bảy", weekday: 6 },
  { key: "sunday", name: "Chủ Nhật", weekday: 7 },
];

export const SUBJECT_ID_OBJECT_EMPTY = {
  ml1d2: null,
  ml1d3: null,
  ml1d4: null,
  ml1d5: null,
  ml1d6: null,
  ml1d7: null,
  ml1d8: null,
  ml2d2: null,
  ml2d3: null,
  ml2d4: null,
  ml2d5: null,
  ml2d6: null,
  ml2d7: null,
  ml2d8: null,
  ml3d2: null,
  ml3d3: null,
  ml3d4: null,
  ml3d5: null,
  ml3d6: null,
  ml3d7: null,
  ml3d8: null,
  ml4d2: null,
  ml4d3: null,
  ml4d4: null,
  ml4d5: null,
  ml4d6: null,
  ml4d7: null,
  ml4d8: null,
  ml5d2: null,
  ml5d3: null,
  ml5d4: null,
  ml5d5: null,
  ml5d6: null,
  ml5d7: null,
  ml5d8: null,
  al1d2: null,
  al1d3: null,
  al1d4: null,
  al1d5: null,
  al1d6: null,
  al1d7: null,
  al1d8: null,
  al2d2: null,
  al2d3: null,
  al2d4: null,
  al2d5: null,
  al2d6: null,
  al2d7: null,
  al2d8: null,
  al3d2: null,
  al3d3: null,
  al3d4: null,
  al3d5: null,
  al3d6: null,
  al3d7: null,
  al3d8: null,
  al4d2: null,
  al4d3: null,
  al4d4: null,
  al4d5: null,
  al4d6: null,
  al4d7: null,
  al4d8: null,
  al5d2: null,
  al5d3: null,
  al5d4: null,
  al5d5: null,
  al5d6: null,
  al5d7: null,
  al5d8: null,
  nl1d2: null,
  nl1d3: null,
  nl1d4: null,
  nl1d5: null,
  nl1d6: null,
  nl1d7: null,
  nl1d8: null,
  nl2d2: null,
  nl2d3: null,
  nl2d4: null,
  nl2d5: null,
  nl2d6: null,
  nl2d7: null,
  nl2d8: null,
  nl3d2: null,
  nl3d3: null,
  nl3d4: null,
  nl3d5: null,
  nl3d6: null,
  nl3d7: null,
  nl3d8: null,
  nl4d2: null,
  nl4d3: null,
  nl4d4: null,
  nl4d5: null,
  nl4d6: null,
  nl4d7: null,
  nl4d8: null,
  nl5d2: null,
  nl5d3: null,
  nl5d4: null,
  nl5d5: null,
  nl5d6: null,
  nl5d7: null,
  nl5d8: null,
};

export const STATUS_ATTENDANCE = {
  present: "present", // có mặt
  author_absent: "author_absent", // vắng có phép
  late: "late", // trễ
  unauthor_absent: "unauthor_absent", // vắng không phép
  unmarked: "unmarked", // chưa điểm danh
};

export const ATTENDANCE_STATUS = [
  { key: "present", shortName: "C", fullName: "Có mặt" },
  { key: "late", shortName: "T", fullName: "Trễ" },
  { key: "author_absent", shortName: "P", fullName: "Nghỉ có phép" },
  { key: "unauthor_absent", shortName: "K", fullName: "Nghỉ không phép" },
  { key: "unmarked", shortName: "U", fullName: "Chưa điểm danh" },
];
export const STATUS_ATTENDANCE_DAY_SCHOOL = {
  present: "present", // có mặt

  unauthor_absent: "unauthor_absent", // vắng không phép
};

export const ATTENDANCE_DAY_SCHOOL_STATUS = [
  { key: "present", shortName: "N", fullName: "Ngủ" },
  { key: "unauthor_absent", shortName: "K", fullName: "Không ngủ" },
];

export const LESSON_TIME = [
  {
    period: PERIOD.morning.code,
    lesson: "Tiết 1",
    time: "07:00 - 07:45",
  },
  {
    period: PERIOD.morning.code,
    lesson: "Tiết 2",
    time: "07:55 - 08:40",
  },
  {
    period: PERIOD.morning.code,
    lesson: "Tiết 3",
    time: "08:50 - 09:35",
  },
  {
    period: PERIOD.morning.code,
    lesson: "Tiết 4",
    time: "09:40 - 10:25",
  },
  {
    period: PERIOD.morning.code,
    lesson: "Tiết 5",
    time: "10:30 - 11:15",
  },
  {
    period: PERIOD.afternoon.code,
    lesson: "Tiết 1",
    time: "13:00 - 13:45",
  },
  {
    period: PERIOD.afternoon.code,
    lesson: "Tiết 2",
    time: "13:55 - 14:40",
  },
  {
    period: PERIOD.afternoon.code,
    lesson: "Tiết 3",
    time: "14:50 - 15:35",
  },
  {
    period: PERIOD.afternoon.code,
    lesson: "Tiết 4",
    time: "15:40 - 16:25",
  },
  {
    period: PERIOD.afternoon.code,
    lesson: "Tiết 5",
    time: "16:30 - 17:15",
  },
];

export const RANKED_ACADEMIC = [
  { key: "excellent", valueVN: "Xuất sắc", valueEN: "High distinction" },
  { key: "very_good", valueVN: "Giỏi", valueEN: "Distinction" },
  { key: "good", valueVN: "Khá", valueEN: "Credit" },
  { key: "average", valueVN: "Trung bình", valueEN: "Pass" },
  { key: "weak", valueVN: "Yếu", valueEN: "Fail" },
  { key: "poor", valueVN: "Kém", valueEN: "Poor" },
];

export const CONDUCT = [
  { key: "very_good", valueVN: "Tốt", valueEN: "Distinction" },
  { key: "good", valueVN: "Khá", valueEN: "Credit" },
  { key: "weak", valueVN: "Yếu", valueEN: "Fail" },
];

export const SUMMARY_TITLE = [
  { key: "excellent", valueVN: "HS Xuất sắc", valueEN: "High distinction" },
  { key: "very_good", valueVN: "HS Giỏi", valueEN: "Distinction" },
  { key: "good", valueVN: "HS Khá", valueEN: "Credit" },
  { key: "average", valueVN: "HS TB", valueEN: "Pass" },
  { key: "weak", valueVN: "HS Yếu", valueEN: "Fail" },
  { key: "poor", valueVN: "HS Kém", valueEN: "Poor" },
];

export const SUMMARY_STATUS = [
  { key: "pass", valueVN: "Lên lớp", valueEN: "Pass" },
  { key: "fail", valueVN: "Ở lại lớp", valueEN: "Fail" },
  { key: "retest", valueVN: "Thi lại", valueEN: "Retest" },
];

export const OAUTH2_URL_FE = "/oauth2/auth";
export const LOGIN_URL_FE = "/login";

// Danh sách link không được hiện sidebar và header
export const NO_LAYOUT_LINKS = [
  LOGIN_URL_FE,
  OAUTH2_URL_FE,
  "/local/login",
  "/app-privacy-policy",
  "/login-sso-callback",
];

export const SEARCH_DELAY_TIME = 500;

export const ID_ISSUED_PLACES = [
  {
    id: "1",
    name: "Cục Cảnh sát ĐKQL cư trú và DLQG về dân cư",
  },
  {
    id: "2",
    name: "Cục Cảnh sát quản lý hành chính về trật tự xã hội",
  },
];

export const HOMEWORK_TYPE = [
  {
    id: "elective",
    name: "Tự chọn",
  },
  {
    id: "evaluate",
    name: "Đánh giá",
  },
];

export const HOMEWORK_SCOPE = [
  {
    id: "class",
    name: "Cả lớp",
  },
  {
    id: "personal",
    name: "Cá nhân",
  },
];

export const HOMEWORK_STATUS = [
  {
    id: "not_yet_hand_in",
    name: "Chưa nộp",
  },
  {
    id: "hand_in",
    name: "Đã nộp",
  },
  {
    id: "hand_in_late",
    name: "Nộp trễ",
  },
];

export const SCORE_INFO_TYPE = {
  number: "number",
  score: "score",
  text: "text",
  comment: "comment",
};

export const SIDEBAR_EXPAND_WIDTH = "280px";
export const SIDEBAR_COLLAPSE_WIDTH = "82px";

export const STUDY_FORM = [
  {
    id: "offline",
    name: "Học tại trường",
  },
  {
    id: "online",
    name: "Học online",
  },
];

export const REVENUE_SCOPE = [
  {
    id: "grade",
    name: "Khối",
  },
  {
    id: "class",
    name: "Lớp",
  },
  {
    id: "personal",
    name: "Cá nhân",
  },
];

export const REVENUE_RECEIPT_TYPE = [
  {
    id: "",
    name: "Tất cả",
  },
  {
    id: "required",
    name: "Bắt buộc",
  },
  {
    id: "voluntary",
    name: "Tự nguyện",
  },
];

export const REVENUE_STATUS = [
  {
    id: "",
    name: "Tất cả",
  },
  {
    id: "uncompleted",
    name: "Chưa hoàn thành",
  },
  {
    id: "completed",
    name: "Đã hoàn thành",
  },
];

export const COLLECT_REVENUE_STATUS = [
  {
    id: "",
    name: "Tất cả",
  },
  {
    id: "not_yet_collected",
    name: "Chưa thu",
  },
  {
    id: "paid_up",
    name: "Đã thu",
  },
];

export const TIMETABLE_CELL_MODE = {
  selectSubject: "selectSubject",
  studentViewSubject: "studentViewSubject",
  teacherViewSubject: "teacherViewSubject",
};

export const PERMISSION_OBJECT = {
  change_place: "change_place",
  user_management: "user_management",
  learning_information: "learning_information",
  student_information: "student_information",
  learning_outcomes: "learning_outcomes",
  overview: "overview",
  timetable_setting: "timetable_setting",
  timetable_auto_gen: "timetable_auto_gen",
  certificate_of_merit: "certificate_of_merit",
  area_structure: "area_structure",
  disciplinary_reason: "disciplinary_reason",
  subject_structure: "subject_structure",
  score_group: "score_group",
  type_staff: "type_staff",
  staff_position: "staff_position",
  evaluate_method: "evaluate_method",
  area_management: "area_management",
  floor_management: "floor_management",
  area_function: "area_function",
  area_condition: "area_condition",
  area_status: "area_status",
  medical_disease_list: "medical_disease_list",
  medical_solution: "medical_solution",
  medical_vaccine: "medical_vaccine",
  subject: "subject",
  search_school_record: "search_school_record",
  receipt_management: "receipt_management",
  school_year: "school_year",
  grade: "grade",
  class: "class",
  school_info: "school_info",
  student_timetable: "student_timetable",
  receipt_item: "receipt_item",
  category: "category",
  teaching_group: "teaching_group",
  staff_management: "staff_management",
  student_management: "student_management",
  attendance: "attendance",
  transcript: "transcript",
  study_summary: "study_summary",
  homework: "homework",
  teaching_distribution: "teaching_distribution",
  curriculum_distribution: "curriculum_distribution",
  curriculum: "curriculum",
  teaching_schedule: "teaching_schedule",
  timetable_information: "timetable_information",
  capacity_quality: "capacity_quality",
  comment: "comment",
  school_manager: "school_manager",
  role: "role",
};

export const PERMISSION_TYPE = {
  view: "view",
  create: "create",
  update: "update",
  delete: "delete",
};

export const ISO_WEEKDAY_STRING = [
  "",
  "monday",
  "tuesday",
  "wednesday",
  "thursay",
  "friday",
  "saturday",
  "sunday",
];

// export const TEACHER_DEFAULT_LINK = "/school";

export const TEACHER_DEFAULT_LINK = "/staff-management";

export const STUDENT_DEFAULT_LINK = "/learning-information";

export const PIE_CHART_COLORS = [
  "#1a77ff33",
  "#1a77ff80",
  "#1a77ffcc",
  "#0B6DEF",
  "#002C60",
  "#01B2B2",
  "#6DF6DD",
  "#61CAFF",
  "#A2E0FF",
  "#165DDA",
  "#1A77FF",
];

export const ROLE_KEY_LIST = {
  admin: "quan_tri_vien",
  teacher: "teacher",
  student: "student",
};

export const SETTING_GROUP_KEYS = {
  school_level: "school_level",
  evaluate_method: "evaluate_method",
  evaluate_type: "evaluate_type",
  semester_certificate_of_merit: "semester_certificate_of_merit",
  title_certificate_of_merit: "title_certificate_of_merit",
  main_section: "main_section",
  type_class: "type_class",
  student_status: "student_status",
  reason_object_out_of_school: "reason_object_out_of_school",
  area_function: "area_function",
  area_condition: "area_condition",
  area_status: "area_status",
  value_score_type_text: "value_score_type_text",
  department_of_education_status: "department_of_education_status",
  reason_leaving_school: "reason_leaving_school",
  student_area: "student_area",
  policy_target: "policy_target",
  vocational_training: "vocational_training",
  eye_disease: "eye_disease",
  disability_type: "disability_type",
  met_category: "met_category", // Loại hình trường: Công lập - Tư thục
  met_type: "met_type", // Loại trường: Trường phổ thông - Trường chuyên biệt
  school_area: "school_area",
  school_area_policy: "school_area_policy",
  gov_standard_lvl: "gov_standard_lvl",
  pass_inspection: "pass_inspection",
  employee_status: "employee_status",
  working_position: "working_position",
  foreign_language_program: "foreign_language_program",
  foreign_language_lesson_per_week: "foreign_language_lesson_per_week",
  position: "position",
  labor_contract: "labor_contract",
  concurrent_position: "concurrent_position",
  rank: "rank",
  salary_scale: "salary_scale",
  regular_training_result: "regular_training_result",
  professional_qualification: "professional_qualification",
  political_theory_degree: "political_theory_degree",
  educational_management_degree: "educational_management_degree",
  participate_training_01: "participate_training_01",
  participate_training_02: "participate_training_02",
  participate_new_textbook_training: "participate_new_textbook_training",
  foreign_language: "foreign_language",
  foreign_language_training: "foreign_language_training",
  foreign_language_certificate_group: "foreign_language_certificate_group",
  foreign_language_certificate_type: "foreign_language_certificate_type",
  foreign_language_level: "foreign_language_level",
  it_level: "it_level",
  major: "major",
  employee_level: "employee_level",
  minority_language: "minority_language",
  evaluate_result: "evaluate_result",
  good_teacher: "good_teacher",
  section_per_week: "section_per_week",
  textbook: "textbook",
  semi_boarding: "semi_boarding",
  attribute_subject: "attribute_subject",
  ethnic: "ethnic",
  religion: "religion",
  has_internet: "has_internet",
  internet_connection_type: "internet_connection_type",
  internet_provider: "internet_provider",
  online_teaching_program: "online_teaching_program",
  lesson_per_week: "lesson_per_week",
  nationality: "nationality",
  class_department: "class_department",
  comment_type: "comment_type",
  ability_grade: "ability_grade",
  section: "section",
  profession_assessment_criteria: "profession_assessment_criteria",
  template_import: "template_import",
  ranked_academic_: "ranked_academic_",
  conduct_: "conduct_",
  student_title_: "student_title_",
};

export const SCHOOL_TYPES = [
  {
    key: "outpatient",
    value: "Ngoại trú",
  },
  {
    key: "inpatient",
    value: "Nội trú",
  },
  {
    key: "daySchool",
    value: "Bán trú",
  },
];

export const TABLE_PAGE_SIZES = [
  { id: 50, name: "50", value: 50 },
  { id: 10, name: "10", value: 10 },
  { id: 20, name: "20", value: 20 },
  { id: 30, name: "30", value: 30 },
];

// DÙng để tham chiếu key, nếu cần data phải gọi api, không dùng biến này
export const STUDENT_STATUS = [
  {
    id: "studying",
    name: "Đang học",
  },
  {
    id: "amission",
    name: "Nhập học",
  },
  {
    id: "graduated",
    name: "Đã tốt nghiệp",
  },
  {
    id: "change_school",
    name: "Thuyên chuyển",
  },
  {
    id: "suspended_from_school",
    name: "Đình chỉ học",
  },
  {
    id: "out_of_school",
    name: "Thôi học",
  },
];

export const FILTER_ALL_OBJECT_ID_NAME = {
  id: "",
  name: "Tất cả",
};

export const FILTER_ALL_OBJECT_KEY_VALUE = {
  key: null,
  value: "Tất cả",
};

export const SESSION_KEY_BE = {
  morning: "0_morning",
  afternoon: "1_afternoon",
  night: "2_night",
};

export const OTHER_SUBJECTS = [
  {
    id: "",
    subject_info: {
      name: "",
    },
  },
  {
    id: "empty",
    subject_info: {
      name: "Tiết trống",
    },
  },
  {
    id: "salute_the_flag",
    subject_info: {
      name: "Chào cờ",
    },
  },
  {
    id: "homeroom_activities",
    subject_info: {
      name: "Sinh hoạt lớp",
    },
  },
];

export const EMPTY_CELL_GENERATE_TIMETABLE = {
  section: "", // SESSION_KEY_BE
  teacher_name: "",
  subject_name: "",
  is_locked: false,
};

export const EMPTY_CELL_SETTING_TIMETABLE = {
  section: "", // SESSION_KEY_BE
  type: "", // CELL_TYPE_SETTING_TIMETABLE
  class_has_subject_id: "",
  day_of_week: -1, // 0 -> 6
  lesson: -1, // 0 -> 4
};

export const EMPTY_CELL_STAFF_SCHEDULE = ""; // <-- string này là subject_name

export const SCRIPT_TYPE = [{ key: "circulars", value: "Thông tư" }];

export const DISPATCH_TYPE = ["change_school", "change_class"];

export const AREA_TYPE = {
  building: "building",
  area: "area",
  floor: "floor",
  classroom: "classroom",
  functional_classroom: "functional_classroom",
};

export const TMP_DISEASES = [
  { key: "fever", value: "Sốt" },
  { key: "cough", value: "Ho" },
  { key: "dizziness", value: "Chóng mặt" },
  { key: "stomach_pain", value: "Đau dạ dày" },
  { key: "toothache", value: "Nhức răng" },
];

export const TMP_SOLUTION = [
  { key: "take_medicine", value: "Cho uống thuốc" },
  { key: "go_home", value: "Cho về nhà" },
  { key: "take_a_rest", value: "Cho nghỉ tại trường" },
  { key: "hospitalize", value: "Nhập viện" },
  { key: "isolation", value: "Cách ly" },
];

export const VIP_MEMBER_TYPE = [
  {
    id: null,
    name: "Không",
  },
  {
    id: "is_pioneer",
    name: "Đội viên",
  },
  {
    id: "is_youth_union_member",
    name: "Đoàn viên",
  },
];

export const COLOR_RGB = {
  black: { r: 0, g: 0, b: 0 },
  grey50: { r: 127, g: 127, b: 127 },
  darkRed: { r: 136, g: 0, b: 21 },
  red: { r: 237, g: 28, b: 36 },
  orange: { r: 255, g: 127, b: 39 },
  yellow: { r: 255, g: 242, b: 0 },
  green: { r: 34, g: 177, b: 76 },
  turquoise: { r: 0, g: 162, b: 232 },
  indigo: { r: 63, g: 72, b: 204 },
  purple: { r: 163, g: 73, b: 164 },
  white: { r: 255, g: 255, b: 255 },
  grey25: { r: 195, g: 195, b: 195 },
  brown: { r: 185, g: 122, b: 87 },
  rose: { r: 255, g: 174, b: 201 },
  gold: { r: 255, g: 201, b: 14 },
  lightYellow: { r: 239, g: 228, b: 176 },
  lime: { r: 181, g: 230, b: 29 },
  lightTurquoise: { r: 153, g: 217, b: 234 },
  blueGray: { r: 112, g: 146, b: 190 },
  lavender: { r: 200, g: 191, b: 231 },
};

export const ADMISSIONS_STATUS = [
  {
    key: "right_route",
    value: "Đúng tuyến",
  },
  {
    key: "cross_route",
    value: "Trái tuyến",
  },
];

export const PRIMARY_SCHOOL = "primary_school";
export const UNIVERSITY = "university";

export const PROGRESS_STATUS = {
  success: "success",
  failed: "failed",
  in_progress: "in_progress",
};
