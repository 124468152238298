import React, { memo } from "react";
import { usePreviewContext } from "../../context/PreviewContext";
import NoPreview from "./NoPreview";
import PDFViewer from "./PDFViewer/PDFViewer";
import "../../styles/index.scss";

const PdfPreview = memo(({ src, onDownload }) => {
  const { translate } = usePreviewContext();

  return (
    <PDFViewer
      className="pdf-preview"
      src={src}
      error={<NoPreview onDownload={onDownload} />}
      loading={
        <div className="pdf-preview-loading">
          <span>{translate("learner.assessment.loading_content")}...</span>
        </div>
      }
    />
  );
});

PdfPreview.displayName = "PdfPreview";
export default PdfPreview;
