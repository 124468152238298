import { Checkbox } from "antd";
import React from "react";
import styles from "./styles.module.css";

export default function InfoFieldCheckbox({
  label = "Checkbox",
  value = false,
  setValue,
  className = "",
  disabled = false,
  defaultChecked = false,
  labelClassName = "",
}) {
  // #region    VARIABLES //////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion VARIABLES //////////////////////////

  // #region    useEffect //////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion useEffect //////////////////////////

  // #region    FUNCTIONS //////////////////////////
  //////////////////////////////////////////////////
  const handleChange = (event) => {
    setValue(event?.target?.checked);
  };
  //////////////////////////////////////////////////
  // #endregion FUNCTIONS //////////////////////////

  // #region    VIEWS //////////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion VIEWS //////////////////////////////
  return (
    <Checkbox
      onChange={handleChange}
      checked={value}
      className={`ml-0 ${className}`}
      disabled={disabled}
      defaultChecked={defaultChecked}
    >
      <span className={`${styles.label} ${labelClassName}`}>{label}</span>
    </Checkbox>
  );
}
