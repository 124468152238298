import _toNumber from "lodash/toNumber";
import React, { memo, useEffect, useState } from "react";
import { usePreviewContext } from "../../../context/PreviewContext";
import IconArrow from "../../../icons/IconArrow";
import "./../../../styles/index.scss";

const NavigationTool = memo(({ currentPage, totalPages, onChange }) => {
  const { translate } = usePreviewContext();
  const [pageValue, setPageValue] = useState("");

  useEffect(() => {
    setPageValue(`${currentPage}`);
  }, [currentPage]);

  const changePage = (page) => {
    let pageNo = _toNumber(page);
    if (pageNo !== currentPage) {
      if (pageNo > 0 && pageNo <= totalPages) {
        onChange(pageNo);
      } else {
        setPageValue(currentPage);
      }
    }
  };

  const handleKeyDown = (e) => {
    e.stopPropagation();
    if (e.keyCode === 13) {
      changePage(pageValue);
    }
  };

  return (
    <div className="pdf-viewer__navigation">
      <button
        className="square-button"
        disabled={currentPage === 1}
        onClick={() => changePage(currentPage - 1)}
      >
        <IconArrow />
      </button>
      <button
        className="square-button next"
        disabled={currentPage === totalPages}
        onClick={() => changePage(currentPage + 1)}
      >
        <IconArrow />
      </button>
      <input
        value={pageValue}
        onChange={(e) => setPageValue(e.target.value)}
        onBlur={() => changePage(pageValue)}
        onFocus={(e) => {
          e.target.select();
        }}
        onKeyDown={handleKeyDown}
      />
      <span>
        {translate("common_text.of")} {totalPages}
      </span>
    </div>
  );
});

NavigationTool.displayName = "NavigationTool";
export default NavigationTool;
