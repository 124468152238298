import "antd/dist/antd.css";
import React from "react";
import ReactDOM from "react-dom";
import "util/common.css";
import "util/fontStyle.css";
import "util/globalStyle.css";
import "util/spacing/margin.css";
import "util/spacing/padding.css";
import App from "./App";
import "./index.css";

import { pdfjs } from "react-pdf/dist/esm/entry.webpack";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;
// import { pdfjs } from 'react-pdf';
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/2.12.313/pdf.worker.js`;

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
