// @ts-nocheck
import IconFile from "asset/icons/IconFile";
import InfoFieldDatePicker from "common/CusInfoField/infoFieldDatePicker/InfoFieldDatePicker";
import InfoFieldSelect from "common/CusInfoField/infoFieldSelect/InfoFieldSelect";
import CusTable from "common/CusTable";
import FileList, { FileItem } from "common/FilePreviewer/FileList";
import "common/FilePreviewer/styles/index.scss";
import CustomBreadcrumb from "common/breadcrumb/CustomBreadcrumb";
import IsMobile from "hook/isMobile";
import MobileHeader from "mobile/layout/header/MobileHeader";
import { HEADER_TYPE } from "mobile/util/mobileConstant";
import { useSelector } from "react-redux";
import violateStyles from "page/violateManagementPage/styles.module.css";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { getGradeList, getSubjectList, makeGrade, importGrade } from "util/APIs";
import {
    formatDateValue,
    formatMessageType,
    formatMessageWorkflowState,
    messageTypeList,
    messageWorkFlowStateList,
} from "util/common";
import { TABLE_PAGE_SIZES } from "util/constant";
import { routes } from "util/routes";
import FilePicker from "common/filePicker/FilePicker";
import { Notification } from "common/Notification";



function MakeGrade() {
    const isUsingMobile = IsMobile();
    const userInfo = useSelector((state) => state?.app?.userInfo);
    const accountId = userInfo?.account?.id;
    const schoolYear = useSelector(
        // (state) => state.schoolYear.selectedSchoolYear
        (state) => state
    );

    const [tableLoading, setTableLoading] = useState(false);
    const [grade, setGrade] = useState(null);
    const [gradeList, setGradeList] = useState([]);
    const [subject, setSubject] = useState(null);
    const [subjectList, setSubjectList] = useState([]);
    const [files, setFiles] = useState(null);



    const getGrade = useCallback(async () => {
        const params = {
            orderBy: "name",
        };
        const res = await getGradeList(accountId, params);
        if (res?.status === 200) {
            const data = res?.data?.data;
            data.pop()
            setGradeList(data);
            if (data?.length > 0) {
                setGrade(data[0]?.id);
            }
        }
    }, [accountId])
    const getSubject = useCallback(async () => {
        const res = await getSubjectList(accountId);
        if (res?.status === 200) {
            const data = res?.data?.data;
            setSubjectList(data);
            if (data?.length > 0) {
                setSubject(data[0]?.id);
            }
        }
    }, [accountId])
    const handleAddAttachments = async (values) => {
        const temp = [...values];
        setFiles(temp);
        const payload = { "subjects": [subject], "grades": [grade] };
        const formData = new FormData();
        formData.append("data", JSON.stringify(payload));
        for (let f of temp) {
            formData.append("files", f);
        }
        const res = await makeGrade(accountId, formData);
        if (res?.status === 200) {
            setFiles(null);
            console.log(res.data)
            if (res.data.data.startsWith("http")) {
                console.log(res.data)
                window.open(res.data.data, "_blank");
            }
            else {
                console.log(res.data)
                Notification.sendError(res.data.data);
            }
        }
    };



    useEffect(() => {
        if (schoolYear && schoolYear !== null) getGrade();
    }, [schoolYear, getGrade]);

    useEffect(() => {
        getSubject();
    }, [getSubject]);

    return (
        <div >
            <div className={`${violateStyles.wrapperFilters}`}>
                <InfoFieldSelect
                    label="Khối"
                    data={gradeList}
                    value={grade}
                    setValue={setGrade}
                    direction="vertical"
                // className={`flex-1 mr-4 max-[912px]:w-1/2`}
                />
                <InfoFieldSelect
                    label="Môn học"
                    data={subjectList}
                    value={subject}
                    setValue={setSubject}
                    direction="vertical"
                // className={`flex-1 mr-4 max-[912px]:w-1/2`}
                />
                <div className="mLeft24 mTop24" >
                    <FilePicker setFiles={handleAddAttachments} />
                </div>
            </div>
            <div className="flex items-start mt-6">

            </div>
        </div>
    );
}

export default MakeGrade;
