import React, { memo, useCallback, useMemo, useState } from "react";
import FilePreviewer from "./Preview/FilePreviewer";
import {
  PreviewContextProvider,
  usePreviewContext,
} from "./context/PreviewContext";
import useBlob from "./hooks/useBlob";

const FileList = memo(({ attachments, children }) => {
  const [previewState, setPreviewState] = useState({
    isOpen: false,
    currentFileIndex: 0,
  });

  const openPreview = useCallback(
    (name, url) => {
      setPreviewState({
        isOpen: true,
        currentFileIndex: attachments.findIndex(
          (i) => i.name === name && i.url === url
        ),
      });
    },
    [attachments]
  );

  const closePreview = useCallback(() => {
    setPreviewState({
      isOpen: false,
    });
  }, []);

  return (
    <PreviewContextProvider
      value={{
        openPreview,
      }}
    >
      <div className="attachment-list">{children}</div>
      <FilePreviewer
        fileList={attachments}
        isOpen={previewState.isOpen}
        currentFileIndex={previewState.currentFileIndex}
        onClose={closePreview}
      />
    </PreviewContextProvider>
  );
});
FileList.displayName = "FileList";

export const FileItem = memo(({ children, ...props }) => {
  const { contentType, name, url } = props;
  const { download } = useBlob({ name, url, contentType });
  const { openPreview } = usePreviewContext();

  const open = useCallback(() => {
    openPreview(name, url);
  }, [openPreview, name, url]);

  const extendedProps = useMemo(
    () => ({
      ...props,
      onOpen: open,
      onDownload: download,
    }),
    [props, open, download]
  );

  return children(extendedProps);
});

FileItem.displayName = "FileItem";

export default FileList;
