const MimeType = {
  // document
  PDF: "application/pdf",
  TEXT: "text/plain",
  JSON: "application/json",
};

const PDF_MIMETYPES = [MimeType.PDF];
const SUPPORTED_IMAGE_TYPES = [
  "image/gif",
  "image/jpeg",
  "image/png",
  "image/svg+xml",
  "image/webp",
  "image/bmp",
];

export const constant = {
  SUPPORTED_IMAGE_TYPES,
  PDF_MIMETYPES,
};
