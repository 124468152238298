// @ts-nocheck
import IconFile from "asset/icons/IconFile";
import InfoFieldDatePicker from "common/CusInfoField/infoFieldDatePicker/InfoFieldDatePicker";
import InfoFieldSelect from "common/CusInfoField/infoFieldSelect/InfoFieldSelect";
import CusTable from "common/CusTable";
import FileList, { FileItem } from "common/FilePreviewer/FileList";
import "common/FilePreviewer/styles/index.scss";
import CustomBreadcrumb from "common/breadcrumb/CustomBreadcrumb";
import IsMobile from "hook/isMobile";
import MobileHeader from "mobile/layout/header/MobileHeader";
import { HEADER_TYPE } from "mobile/util/mobileConstant";
import { useSelector } from "react-redux";
import violateStyles from "page/violateManagementPage/styles.module.css";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { getGradeList, getSubjectList, importGrade, getTerms, getAcademicColumns } from "util/APIs";
import {
    formatDateValue,
    formatMessageType,
    formatMessageWorkflowState,
    messageTypeList,
    messageWorkFlowStateList,
} from "util/common";
import { TABLE_PAGE_SIZES } from "util/constant";
import { routes } from "util/routes";
import FilePicker from "common/filePicker/FilePicker";
import { Notification } from "common/Notification";



function ImportGrade() {
    const isUsingMobile = IsMobile();
    const userInfo = useSelector((state) => state?.app?.userInfo);
    const accountId = userInfo?.account?.id;
    const schoolYear = useSelector(
        // (state) => state.schoolYear.selectedSchoolYear
        (state) => state
    );

    const [tableLoading, setTableLoading] = useState(false);
    const [term, setTerm] = useState(null);
    const [termList, setTermList] = useState([]);
    const [subject, setSubject] = useState(null);
    const [subjectList, setSubjectList] = useState([]);
    const [programColumns, setPogramColumns] = useState(null);
    const [programColumnsList, setPogramColumnsList] = useState([]);
    const [files, setFiles] = useState(null);



    const getTerm = useCallback(async () => {
        const params = {
            orderBy: "id",
        };
        const res = await getTerms(accountId, params);
        if (res?.status === 200) {
            const data = res?.data?.data;
            setTermList(data);
            if (data?.length > 0) {
                setTerm(data[0]?.id);
            }
        }
    }, [accountId])
    const getSubject = useCallback(async () => {
        const res = await getSubjectList(accountId);
        if (res?.status === 200) {
            const data = res?.data?.data;
            setSubjectList(data);
            if (data?.length > 0) {
                setSubject(data[0]?.id);
            }
        }
    }, [accountId])
    const getAcademicColumn = useCallback(async () => {
        const params = {
            orderBy: "name",
        };
        const res = await getAcademicColumns(accountId, params);
        if (res?.status === 200) {
            const data = res?.data?.data;
            setPogramColumnsList(data);
            if (data?.length > 0) {
                setPogramColumns(data[0]?.id);
            }
        }
    }, [accountId])
    const handleAddAttachments = async (values) => {
        const temp = [...values];
        setFiles(temp);
        const payload = {
            "subjects": [subject],
            "terms": [term],
            "graderId": userInfo?.user?.id,
            "academic_programs_columns": [programColumns]
        };
        const formData = new FormData();
        formData.append("data", JSON.stringify(payload));
        for (let f of temp) {
            formData.append("files", f);
        }
        const res = await importGrade(accountId, formData);
        setTableLoading(true)
        if (res?.status === 200) {
            setTableLoading(false)
            setFiles(null);
            console.log(res.data)
            Notification.sendSuccess("Đã nhập điểm thành công");
        }
    };



    useEffect(() => {
        if (schoolYear && schoolYear !== null) getTerm();
    }, [schoolYear, getTerm]);

    useEffect(() => {
        getSubject();
        getAcademicColumn();
    }, [getSubject, getAcademicColumn]);

    return (
        <div >
            <div className={`${violateStyles.wrapperFilters}`}>
                <InfoFieldSelect
                    label="Học kì"
                    data={termList}
                    value={term}
                    setValue={setTerm}
                    direction="vertical"
                // className={`flex-1 mr-4 max-[912px]:w-1/2`}
                />
                <InfoFieldSelect
                    label="Môn học"
                    data={subjectList}
                    value={subject}
                    setValue={setSubject}
                    direction="vertical"
                // className={`flex-1 mr-4 max-[912px]:w-1/2`}
                />
                <InfoFieldSelect
                    label="Cột điểm"
                    data={programColumnsList}
                    value={programColumns}
                    setValue={setPogramColumns}
                    direction="vertical"
                // className={`flex-1 mr-4 max-[912px]:w-1/2`}
                />
                <div className="mLeft24 mTop24" >
                    <FilePicker setFiles={handleAddAttachments} />
                </div>
            </div>
            <div className="flex items-start mt-6">

            </div>
        </div>
    );
}

export default ImportGrade;
