const bodyModalClassname = 'modal-open';
const removeModalClass = () => {
    if (document.body.classList.contains(bodyModalClassname)) {
        const pattern = new RegExp(
            '(?:^|\\s)' + bodyModalClassname + '(?:\\s|$)',
            'g'
        );
        document.body.className = document.body.className.replace(pattern, '');
    }
};

/**
 * Replace an item in array
 * @param {Array} baseList
 * @returns {Array}
 */
export const replaceItemInArray = baseList => (conditionReplace, newData) => {
    let newBaseList = [...baseList];
    let currentItemIndex = newBaseList.findIndex(conditionReplace);
    if (~currentItemIndex) {
        let clonedItem = { ...newBaseList[currentItemIndex] };
        let replacedData = null;
        if (typeof newData === 'object') {
            replacedData = newData;
        } else if (typeof newData === 'function') {
            replacedData = newData(clonedItem);
        }
        newBaseList.splice(currentItemIndex, 1, {
            ...clonedItem,
            ...(replacedData || {}),
        });
    }
    return newBaseList;
};

export const common = {
    removeModalClass,
    replaceItemInArray,
};
