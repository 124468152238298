import { message } from "antd";

export const validateSchool = (data) => {
  if (!data?.address?.province_city) {
    message.error("Vui lòng nhập Tỉnh/TP");
    return false;
  }
  if (!data?.address?.district) {
    message.error("Vui lòng nhập Quận/Huyện");
    return false;
  }
  if (!data?.address?.sub_district) {
    message.error("Vui lòng nhập Phường/Xã");
    return false;
  }
  if (!data?.address?.description) {
    message.error("Vui lòng nhập địa chỉ chi tiết");
    return false;
  }
  // TODO: add internet fields checking
  if (data?.has_internet && data?.has_internet === true) {
    // if (
    //   !data?.internet_connection_type ||
    //   data?.internet_connection_type === null
    // ) {
    //   message.error("Vui lòng nhập loại kết nối internet");
    //   return false;
    // }
    if (!data?.internet_speed || data?.internet_speed === 0) {
      message.error("Vui lòng nhập tốc độ internet");
      return false;
    }
    if (!data?.upload_speed || data?.upload_speed === 0) {
      message.error("Vui lòng nhập chất lượng tải lên");
      return false;
    }
    if (!data?.download_speed || data?.download_speed === 0) {
      message.error("Vui lòng nhập chất lượng tải xuống");
      return false;
    }
    if (!data?.ping || data?.ping === 0) {
      message.error("Vui lòng nhập độ trễ - ping");
      return false;
    }
    if (!data?.total_modems || data?.total_modems === 0) {
      message.error("Vui lòng nhập số lượng modem");
      return false;
    }
    if (!data?.total_routers || data?.total_routers === 0) {
      message.error("Vui lòng nhập số lượng router");
      return false;
    }
    if (!data?.internet_provider || data?.internet_provider === null) {
      message.error("Vui lòng nhập nhà cung cấp internet");
      return false;
    }
  }
  return true;
};

export const schoolStringFields = [
  "province_city",
  "district",
  "sub_district",
  "level",
  "school_type",
  "met_category",
  "met_type",
  "school_area",
  "school_area_policy",
  "gov_standard_lvl",
  "propound",
  "pass_inspection",
  "has_internet",
  "internet_connection_type",
];

export const schoolArrayFields = [
  "internet_provider",
  "online_teaching_program_name",
];

export const schoolZeroFields = [
  "internet_speed",
  "upload_speed",
  "download_speed",
  "ping",
  "total_modems",
  "total_routers",
  "total_assistive_devices_for_disabled_student",
  "total_disabled_teachers",
  "total_proficient_in_technology_teachers",
  "total_computer_rooms",
  "total_computers_for_manager",
  "total_laptops_for_manager",
  "total_tablets_for_manager",
  "total_cellphones_for_manager",
  "total_computers_for_teaching",
  "total_laptops_for_teaching",
  "total_tablets_for_teaching",
  "total_cellphones_for_teaching",
  "total_computers_for_learning",
  "total_laptops_for_learning",
  "total_tablets_for_learning",
  "total_cellphones_for_learning",
  "total_it_employees",
  "total_it_teachers",
];
