// @ts-nocheck
import IconFile from "asset/icons/IconFile";
import InfoFieldDatePicker from "common/CusInfoField/infoFieldDatePicker/InfoFieldDatePicker";
import InfoFieldSelect from "common/CusInfoField/infoFieldSelect/InfoFieldSelect";
import CusTable from "common/CusTable";
import FileList, { FileItem } from "common/FilePreviewer/FileList";
import "common/FilePreviewer/styles/index.scss";
import CustomBreadcrumb from "common/breadcrumb/CustomBreadcrumb";
import IsMobile from "hook/isMobile";
import MobileHeader from "mobile/layout/header/MobileHeader";
import { HEADER_TYPE } from "mobile/util/mobileConstant";
import moment from "moment";
import violateStyles from "page/violateManagementPage/styles.module.css";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { getMessageList } from "util/APIs";
import {
  formatDateValue,
  formatMessageType,
  formatMessageWorkflowState,
  messageTypeList,
  messageWorkFlowStateList,
} from "util/common";
import { TABLE_PAGE_SIZES } from "util/constant";
import { routes } from "util/routes";

function MessagePage() {
  const isUsingMobile = IsMobile();
  const [tableLoading, setTableLoading] = useState(false);
  const [messageList, setMessageList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(TABLE_PAGE_SIZES[0]);
  const [totalItems, setTotalItems] = useState(0);
  const [type, setType] = useState(null);
  const [workflowState, setWorkflowState] = useState(null);
  const [selectedDate, setSelectedDate] = useState(moment().format());

  const handleSetSelectedDate = (value) => {
    const temp = moment(value).format();
    setSelectedDate(temp);
  };

  const columns = useMemo(() => {
    const viewFile = (values) => {
      const lst = JSON.parse(values);
      if (lst?.length) {
        const formattedFiles = lst?.map?.((item) => ({
          name: lst?.[0]?.title,
          url: lst?.[0]?.url,
          type: lst?.[0]?.mimetype,
        }));

        return (
          <FileList attachments={formattedFiles}>
            <FileItem {...formattedFiles[0]}>
              {({ onOpen }) => {
                return (
                  <div onClick={onOpen}>
                    <div className="view-file">
                      <IconFile />
                    </div>
                  </div>
                );
              }}
            </FileItem>
          </FileList>
        );
      }
    };
    return [
      {
        title: "STT",
        align: "left",
        key: "stt",
        width: "50px",
        fixed: "left",
        render: (value, record, index) =>
          (currentPage - 1) * pageSize.value + index + 1,
      },
      {
        title: "Tiêu đề",
        // align: "",
        key: "title",
        dataIndex: "title",
        // width: "",
      },
      {
        title: "Nội dung",
        // align: "",
        key: "body",
        dataIndex: "body",
        // width: "",
      },
      {
        title: "Loại",
        // align: "",
        key: "type",
        dataIndex: "type",
        width: "10%",
        render: (text, record) => formatMessageType(text),
      },
      {
        title: "Trạng thái",
        // align: "",
        key: "workflow_state",
        dataIndex: "workflow_state",
        width: "10%",
        render: (text, record) => formatMessageWorkflowState(text),
      },
      {
        title: "Ngày tạo",
        // align: "",
        key: "created_at",
        dataIndex: "created_at",
        width: "10%",
        render: (text, record) => formatDateValue(text),
      },
      {
        title: "Files",
        // align: "",
        dataIndex: "files",
        render: (text, record) => viewFile(text),
        // render: (text, record) => {
        //   const lst = JSON.parse(text);
        //   if (lst?.length) {
        //     return lst.map((item) => (
        //       <a
        //         href={item.url}
        //         target="_blank"
        //         rel="noreferrer"
        //       >
        //         {item?.title}
        //       </a>
        //     ));
        //   } else {
        //     return "";
        //   }
        // },
        // width: "",
      },
    ];
  }, [currentPage, pageSize.value]);

  const getData = useCallback(
    async (page = null) => {
      const date = moment(selectedDate).format("YYYY-MM-DD");
      const params = {
        "filters[created_at][$gte]": `${date}T00:00:00Z`,
        "filters[created_at][$lte]": `${date}T23:59:59Z`,
        "filters[type]": type,
        "filters[workflow_state]": workflowState,
        "pagination[page]": currentPage,
        "pagination[pageSize]": pageSize.value,
      };
      try {
        if (page === null) page = currentPage;
        setTableLoading(true);
        const res = await getMessageList(params);
        if (res?.status === 200) {
          const { data, pagination } = res?.data;
          setMessageList(data);
          setCurrentPage(pagination?.page);
          setTotalItems(pagination?.total);
        }
      } catch (err) {
      } finally {
        setTableLoading(false);
      }
    },
    [currentPage, pageSize.value, selectedDate, type, workflowState]
  );

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <div className="flex flex-col h-full">
      {isUsingMobile ? (
        <MobileHeader
          type={HEADER_TYPE.middleTitle}
          title="Thông báo"
          haveNotiButton={false}
        />
      ) : (
        <CustomBreadcrumb crumbs={[routes.MESSAGE]} />
      )}
      <div className={`py-4 section-shadow bg-white`}>
        <div className={`${violateStyles.wrapperFilters}`}>
          <InfoFieldDatePicker
            label="Ngày tạo"
            value={selectedDate}
            setValue={handleSetSelectedDate}
            direction="vertical"
            placeholder="Chọn thời gian"
          />
          <InfoFieldSelect
            label="Loại"
            data={messageTypeList}
            value={type}
            setValue={setType}
            direction="vertical"
          />
          <InfoFieldSelect
            label="Trạng thái"
            data={messageWorkFlowStateList}
            value={workflowState}
            setValue={setWorkflowState}
            direction="vertical"
          />
        </div>
        <div className={`mx-6 mt-5`}>
          <CusTable
            rowKey="id"
            data={messageList}
            columns={columns}
            loading={tableLoading}
            havePagination={totalItems > pageSize.value}
            page={currentPage}
            setPage={setCurrentPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            totalItems={totalItems}
            scroll={{ x: "1000px", y: 480 }}
          />
        </div>
      </div>
    </div>
  );
}

export default MessagePage;
