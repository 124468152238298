import React from "react";

export default function Img({ style = {}, className = "", src, alt = "" }) {
  return (
    <img
      style={style}
      className={`w-full h-full ${className}`}
      src={src}
      alt={alt}
    />
  );
}
