import { message, Modal } from "antd";
import ConfirmCloseComponent from "common/ConfirmCloseComponent";
import FileShower from "common/filePicker/component/FileShower";
import FilePicker from "common/filePicker/FilePicker";
import ResultModal from "common/ResultModal";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { massUploadTeachingDistribution } from "util/APIs";
import { PROGRESS_STATUS } from "util/constant";
import { logMessage, logMessageDirect } from "util/helper";

export default function ImportTeachingDistributionModal({
  title = "Nhập liệu từ file Excel",
  visible,
  onClose = () => {},
  callback,
}) {
  // #region    VARIABLES //////////////////////////
  //////////////////////////////////////////////////
  const schoolYear = useSelector(
    (state) => state.schoolYear.selectedSchoolYear
  );
  const [importResult, setImportResult] = useState(PROGRESS_STATUS.success);
  const [resultVisible, setResultVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState(null);
  const [log, setLog] = useState(null);
  //////////////////////////////////////////////////
  // #endregion VARIABLES //////////////////////////

  // #region    useEffect //////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion useEffect //////////////////////////

  // #region    FUNCTIONS //////////////////////////
  //////////////////////////////////////////////////
  const handleAddAttachments = (values) => {
    const temp = [...values];
    setFiles(temp);
  };

  const handleRemoveAttachment = (_value, index) => {
    let temp = [...files];
    temp.splice(index, 1);
    setFiles(temp);
  };

  const validation = () => {
    if (!files?.length) {
      message.error("Vui lòng chọn file nhập liệu");
      return false;
    }
    if (!schoolYear?.id) {
      message.error("Vui lòng chọn niên khóa hiện hành");
      return false;
    }
    return true;
  };

  const handleConfirm = async () => {
    try {
      if (!validation()) return;
      const params = {
        file: files?.[0],
        school_year_id: schoolYear?.id,
      };
      setLoading(true);
      const res = await massUploadTeachingDistribution(params);
      if (res?.data?.code === "success") {
        onClose();
        setImportResult(PROGRESS_STATUS.success);
        if (res?.data?.data) {
          setLog(logMessageDirect(res?.data?.data, false));
        }
      } else {
        setImportResult(PROGRESS_STATUS.failed);
        setLog(logMessage(res, false));
      }
    } catch (error) {
      setImportResult(PROGRESS_STATUS.failed);
    } finally {
      setLoading(false);
      if (validation()) showResult();
      callback && callback();
    }
  };

  const showResult = () => setResultVisible(true);

  const hideResult = () => setResultVisible(false);
  //////////////////////////////////////////////////
  // #endregion FUNCTIONS //////////////////////////

  // #region    VIEWS //////////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion VIEWS //////////////////////////////
  return (
    <>
      <Modal
        title={<span className={`font-bold text-lg`}>{title}</span>}
        open={visible}
        onCancel={loading ? () => {} : onClose}
        onOk={handleConfirm}
        cancelText="Hủy"
        okText="Nhập"
        confirmLoading={loading}
        zIndex={100}
      >
        <div className="flex items-start">
          <div className="mRight12">
            <FilePicker setFiles={handleAddAttachments} />
          </div>
          <FileShower
            files={files}
            removeFile={handleRemoveAttachment}
            fileOnClick={() => {}}
          />
        </div>
        <ConfirmCloseComponent isActive={loading} />
      </Modal>
      <ResultModal
        visible={resultVisible}
        result={importResult}
        onClose={hideResult}
        log={log}
      />
    </>
  );
}
