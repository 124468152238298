import { message } from "antd";
import ConfirmCloseComponent from "common/ConfirmCloseComponent";
import InProgressModal from "common/InProgressModal";
import ResultModal from "common/ResultModal";
import StyledButton from "common/button/StyledButton";
import React, { useState } from "react";
import { pullScoreCohotaAPI } from "util/APIs";
import { PROGRESS_STATUS } from "util/constant";
import { logMessage } from "util/helper";

export default function PullScoreCohotaButton({
  schoolYearId,
  semester,
  classId,
  subject,
}) {
  // #region    VARIABLES //////////////////////////
  //////////////////////////////////////////////////
  const [importResult, setImportResult] = useState(PROGRESS_STATUS.success);
  const [inProgressData, setInProgressData] = useState(null);
  const [resultVisible, setResultVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [log, setLog] = useState(null);
  //////////////////////////////////////////////////
  // #endregion VARIABLES //////////////////////////

  // #region    useEffect //////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion useEffect //////////////////////////

  // #region    FUNCTIONS //////////////////////////
  //////////////////////////////////////////////////
  const validation = () => {
    if (!schoolYearId) {
      message.error("Vui lòng chọn niên khóa");
      return false;
    }
    if (!classId) {
      message.error("Vui lòng chọn lớp");
      return false;
    }
    return true;
  };

  const pullScoreCohota = async () => {
    try {
      if (!validation()) return;
      const params = {
        school_year_id: schoolYearId,
        semester,
        class_id: classId,
        subject_id: subject,
      };
      setLoading(true);
      const res = await pullScoreCohotaAPI(params);
      if (!!res?.data?.data) {
        // show progress modal
        setInProgressData(res?.data?.data);
      } else if (res?.data?.code === "success") {
        setImportResult(PROGRESS_STATUS.in_progress);
      } else {
        setImportResult(PROGRESS_STATUS.failed);
        setLog(logMessage(res, false));
      }
    } catch (error) {
      setImportResult(PROGRESS_STATUS.failed);
    } finally {
      setLoading(false);
      if (validation()) showResult();
    }
  };

  const showResult = () => setResultVisible(true);

  const hideResult = () => setResultVisible(false);

  const closeInProgressModal = () => setInProgressData(null);
  //////////////////////////////////////////////////
  // #endregion FUNCTIONS //////////////////////////

  // #region    VIEWS //////////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion VIEWS //////////////////////////////
  return (
    <>
      <StyledButton
        onClick={pullScoreCohota}
        loading={loading}
        theme="blue"
        className="mRight12"
      >
        Đồng bộ điểm LMS
      </StyledButton>
      <ResultModal
        visible={resultVisible}
        result={importResult}
        onClose={hideResult}
        successMsg="Đồng bộ thành công"
        inProgressMsg="Quá trình đồng bộ đang diễn ra"
        failMsg="Lỗi! Đồng bộ thất bại"
        log={log}
      />
      <InProgressModal data={inProgressData} onClose={closeInProgressModal} />
      <ConfirmCloseComponent isActive={loading} />
    </>
  );
}
