import { Button } from "antd";
import React from "react";
import styles from "./styles.module.css";

export default function StyledButton({
  children = null,
  onClick = () => {},
  className = "",
  type = null,
  disabled = false,
  loading = false,
  theme = "normal",
  ...props
}) {
  // #region    VARIABLES //////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion VARIABLES //////////////////////////

  // #region    useEffect //////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion useEffect //////////////////////////

  // #region    FUNCTIONS //////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion FUNCTIONS //////////////////////////

  // #region    VIEWS //////////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion VIEWS //////////////////////////////
  return (
    <Button
      onClick={onClick}
      className={`text-base font-semibold ${styles.root} ${
        theme === "blue" && styles.blueBtn
      } ${disabled && styles.disabled} ${className}`}
      type={type}
      disabled={disabled}
      loading={loading}
      {...props}
    >
      {children}
    </Button>
  );
}
