import React, { useCallback, useEffect } from "react";
import styles from "./styles.module.css";
import InfoFieldDatePicker from "common/CusInfoField/infoFieldDatePicker/InfoFieldDatePicker";
import moment from "moment";
import { useState } from "react";
import { useMemo } from "react";
import { TABLE_PAGE_SIZES, is1920OrMore } from "util/constant";
import { useSelector } from "react-redux";
import CustomBreadcrumb from "common/breadcrumb/CustomBreadcrumb";
import {
  createMenu,
  deleteMenu,
  getMenu,
  updateMenu,
  getLunchRegistry,
  updateLunchRegistry,
  createLunchRegistry,
} from "util/APIs";
import CusTable from "common/CusTable";
import { Button, Form, Input, Modal, Space, Select } from "antd";

import { closeIconThin, newEditIcon } from "asset/img/Images";
import { Notification } from "common/Notification";
import IsMobile from "hook/isMobile";
import MobileHeader from "mobile/layout/header/MobileHeader";
import { HEADER_TYPE } from "mobile/util/mobileConstant";
import { routes } from "util/routes";

export default function LunchRegisterPage() {
  const [form] = Form.useForm();
  const [data, setData] = useState(null);
  const [selectedDate, setSelectedDate] = useState(moment().format());
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(TABLE_PAGE_SIZES[1]);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showModalAdd, setShowModalAdd] = useState(false);
  const [recordId, setRecordId] = useState(null);
  const [deleteItem, setDeleteItem] = useState(null);
  const [haveEdit, setHaveEdit] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loadingEdit, setLoadingEdit] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [registerStatus, setRegisterStatus] = useState(null);
  const isUsingMobile = IsMobile();

  const userInfo = useSelector((state) => state?.app?.userInfo);
  const accountId = userInfo?.account?.id;

  const handleSetSelectedDate = (value) => {
    const temp = moment(value).format();
    setSelectedDate(temp);
  };

  const handleCloseModalEdit = () => {
    setShowModalEdit(false);
    form.resetFields();
  };

  const handleRegsiter = async () => {
    console.log(recordId);
    const filters = {
      "filters[date][$eq]": moment(selectedDate).format("YYYY-MM-DD"),
      "filters[studentId]": userInfo?.student?.id,
    };
    const params = {
      data: {
        dishesId: recordId,
        date: moment(selectedDate).format("YYYY-MM-DD"),
        studentId: userInfo?.student?.id,
      },
    };

    const res = await getLunchRegistry(accountId, filters);
    if (res?.status === 200) {
      console.log(res);
      if (res?.data?.data?.length !== 0) {
        const update = await updateLunchRegistry(
          accountId,
          res?.data?.data[0]?.id,
          params
        );
        if (update?.status !== 200) {
          Notification.sendError("Cập nhật thất bại.");
        } else {
          setShowModalEdit(false);
          Notification.sendSuccess("Cập nhật thành công.");
        }
        console.log("update", update);
      } else {
        const create = await createLunchRegistry(accountId, params);
        console.log("create", create);
        if (
          create?.data?.message ===
          "Học sinh không được phép đăng ký ăn trưa sau 08h30"
        ) {
          setShowModalEdit(false);
          Notification.sendError("Đăng ký thất bại.");
        } else {
          setShowModalEdit(false);
          Notification.sendSuccess("Đăng ký thành công.");
        }
        console.log("create", create);
      }
    }
  };

  const responsive = {
    setResponsiveCol: () => {
      if (!is1920OrMore) {
        // columns[1].width = 120;
        // columns[2].width = 120;
        // columns[3].width = 120;
      }
    },
    nodeWrapperWidth: () => {
      if (is1920OrMore) return "560px";
      if (!is1920OrMore) return "560px";
    },
    tableScroll: () => {
      if (is1920OrMore) return {};
      else
        return {
          x: 1000,
          y: 490,
        };
    },
  };

  const columns = useMemo(() => {
    let columnLst = [
      {
        title: "STT",
        key: "STT",
        width: 50,
        align: "center",
        render: (value, record, index) =>
          (currentPage - 1) * pageSize.value + index + 1,
      },
      {
        title: "Tên món ăn",
        key: "foods",
        align: "center",
        dataIndex: "dishes",
        width: 100,
        render: (text, record) => <span>{text}</span>,
      },
      {
        title: "Trạng thái",
        key: "status",
        align: "center",
        dataIndex: "workflow_state",
        width: 100,
        render: (text, record) => {
          if (text === "active") {
            return <span>Khả dụng</span>;
          } else {
            return <span>Không khả dụng</span>;
          }
        },
      },
      {
        title: "",
        dataIndex: "id",
        align: "center",
        width: 30,
        render: (_, record) => {
          const handleShowModalEdit = () => {
            setShowModalEdit(true);
            setRecordId(record.id);
            form.setFieldsValue({ dishesName: record.dishes });
          };
          return (
            <Space size={2}>
              {registerStatus === record.id ? (
                "Đã đăng ký"
              ) : (
                <button
                  className={`flex justify-center items-center bg-blueDefault ${styles.customButton}`}
                  onClick={handleShowModalEdit}
                >
                  <span>Đăng ký</span>
                </button>
              )}
            </Space>
          );
        },
      },
    ];
    return columnLst;
  }, [currentPage, form, pageSize.value]);

  const getMenuData = useCallback(
    async (page = null) => {
      if (page === null) page = currentPage;
      const params = {
        "pagination[pageSize]": pageSize.value,
        "pagination[page]": page,
        "filters[date]": moment(selectedDate).format("YYYY-MM-DD"),
      };
      const res = await getMenu(accountId, params);
      if (res?.status === 200) {
        setData(res?.data.data);
        setTotalItems(res?.data.pagination?.total);
        setCurrentPage(res?.data.pagination?.page);
      }
    },
    [accountId, currentPage, pageSize.value, selectedDate]
  );

  const getRegisterStatus = useCallback(async () => {
    const filters = {
      "filters[date][$eq]": moment(selectedDate).format("YYYY-MM-DD"),
      "filters[studentId]": userInfo?.student?.id,
    };
    const res = await getLunchRegistry(accountId, filters);
    if (res?.status === 200) {
      if (res?.data?.data?.length !== 0) {
        console.log(res?.data?.data?.[0]?.dishesId);
        setRegisterStatus(parseInt(res?.data?.data?.[0]?.dishesId));
      }
    }
  }, [accountId, getLunchRegistry]);

  useEffect(() => {
    getMenuData();
  }, [getMenuData, haveEdit]);

  useEffect(() => {
    getRegisterStatus();
  }, [getRegisterStatus]);

  useEffect(() => {
    const fetchData = async () => {
      const params = {
        "filters[date]": moment().format("YYYY-MM-DD"),
        "filters[studentId]": userInfo?.user?.id,
      };
      console.log(userInfo?.user?.id);
    };
    // call the function
    fetchData()
      // make sure to catch any error
      .catch(console.error);
  }, []);

  return (
    <div className={`flex flex-col h-full`}>
      {isUsingMobile ? (
        <MobileHeader
          type={HEADER_TYPE.middleTitle}
          title="Đăng ký ăn trưa"
          haveNotiButton={false}
        />
      ) : (
        <CustomBreadcrumb crumbs={[routes.ADMIN_NOTIFICATION]} />
      )}
      <div className={`py-4 section-shadow bg-white`}>
        <div className={`${styles.wrapper}`}>
          {isUsingMobile ? (
            ""
          ) : (
            <h2 className={`${styles.header}`}>Đăng ký ăn trưa </h2>
          )}
        </div>
        <div className={`mx-6 mt-5`}>
          <CusTable
            rowKey="id"
            data={data}
            havePagination={totalItems > pageSize.value}
            columns={columns}
            rowClassName="editable-row"
            page={currentPage}
            setPage={setCurrentPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            totalItems={totalItems}
            scroll={responsive.tableScroll()}
          />
          <Modal
            title="Xác nhận"
            centered
            open={showModalEdit}
            onCancel={() => setShowModalEdit(false)}
            footer={[
              <Button key="cancel" onClick={() => setShowModalEdit(false)}>
                Hủy
              </Button>,
              <Button
                key="send"
                type="primary"
                onClick={handleRegsiter}
                disabled={loadingDelete}
              >
                Xác nhận
              </Button>,
            ]}
          >
            <p>Xác nhận đăng ký?</p>
          </Modal>
        </div>
      </div>
    </div>
  );
}
