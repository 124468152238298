import Loading from "common/loader/Loading";
import React from "react";
import { useSelector } from "react-redux";
import styles from "./styles.module.css";

export default function GlobalLoading() {
  // #region    VARIABLES //////////////////////////
  //////////////////////////////////////////////////
  const visible = useSelector((state) => state.app.globalLoading);
  //////////////////////////////////////////////////
  // #endregion VARIABLES //////////////////////////

  // #region    useEffect //////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion useEffect //////////////////////////

  // #region    FUNCTIONS //////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion FUNCTIONS //////////////////////////

  // #region    VIEWS //////////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion VIEWS //////////////////////////////
  if (visible) return <Loading color="#ffffff" className={styles.root} />;
  else return null;
}
