export const SET_STUDENT_LIST_PAGE_FILTER_GRADE =
  "SET_STUDENT_LIST_PAGE_FILTER_GRADE";
export const SET_STUDENT_LIST_PAGE_FILTER_CLASS =
  "SET_STUDENT_LIST_PAGE_FILTER_CLASS";
export const SET_STUDENT_LIST_PAGE_CURRENT_PAGE =
  "SET_STUDENT_LIST_PAGE_CURRENT_PAGE";

export function setStudentListPageFilterGradeAction(data) {
  return {
    type: SET_STUDENT_LIST_PAGE_FILTER_GRADE,
    payload: data,
  };
}

export function setStudentListPageFilterClassAction(data) {
  return {
    type: SET_STUDENT_LIST_PAGE_FILTER_CLASS,
    payload: data,
  };
}

export function setStudentListPageCurrentPageAction(data) {
  return {
    type: SET_STUDENT_LIST_PAGE_CURRENT_PAGE,
    payload: data,
  };
}
