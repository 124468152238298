export const renderLessonTitle = {
  tiet1_s: "Tiết 1",
  tiet2_s: "Tiết 2",
  tiet3_s: "Tiết 3",
  tiet4_s: "Tiết 4",
  tiet5_s: "Tiết 5",
  tiet1_c: "Tiết 1",
  tiet2_c: "Tiết 2",
  tiet3_c: "Tiết 3",
  tiet4_c: "Tiết 4",
  tiet5_c: "Tiết 5",
};

export const morningLessons = {
  tiet1_s: "tiet1_s",
  tiet2_s: "tiet2_s",
  tiet3_s: "tiet3_s",
  tiet4_s: "tiet4_s",
  tiet5_s: "tiet5_s",
};

export const afternoonLessons = {
  tiet1_c: "tiet1_c",
  tiet2_c: "tiet2_c",
  tiet3_c: "tiet3_c",
  tiet4_c: "tiet4_c",
  tiet5_c: "tiet5_c",
};
