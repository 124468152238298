// @ts-nocheck
import CusTable from "common/CusTable";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import styles from "../styles.module.css";
import { formatDateValue } from "util/common";
import SelectAttendance from "./SelectAttendance";
import IsMobile from "hook/isMobile";

const dayOfWeeks = [
  "Chủ nhật",
  "Thứ hai",
  "Thứ ba",
  "Thứ tư",
  "Thứ năm",
  "Thứ sáu",
  "Thứ bảy",
];

function WeekAttendance({
  data,
  classId,
  section,
  currentLesson,
  selectedDate,
  tableLoading,
  currentPage,
  setCurrentPage,
  pageSize,
  setPageSize,
  totalItems,
  setIsRefresh,
}) {
  const [weekColumns, setWeekColumns] = useState([]);
  const isUsingMobile = IsMobile();

  const isAllowToUpdate = true;

  const columns = useMemo(() => {
    let columnLst = [
      {
        title: "STT",
        key: "STT",
        width: 50,
        align: "center",
        render: (value, record, index) =>
          (currentPage - 1) * pageSize.value + index + 1,
      },
      {
        title: "Họ",
        align: "center",
        width: 200,
        key: `last_name`,
        dataIndex: "last_name",
        // fixed: "center",
        render: (text, record) => <span>{text}</span>,
      },
      {
        title: "Tên",
        align: "center",
        width: 100,
        key: `first_name`,
        dataIndex: "first_name",
        className: styles.fixedCol,
        // fixed: "center",
        render: (text, record) => <span>{text}</span>,
      },
      {
        title: "Mã học sinh",
        width: 150,
        align: "center",
        dataIndex: "moeat_id",
        render: (text, record) => <span>{text}</span>,
      },
      {
        title: "Ngày sinh",
        dataIndex: "dob",
        width: 150,
        align: "center",
        render: (value) => <span>{formatDateValue(value)}</span>,
      },
    ];
    if (isUsingMobile) {
      columnLst = columnLst.filter((item) => item.dataIndex !== "dob");
    }
    return columnLst;
  }, [currentPage, isUsingMobile, pageSize.value]);

  const getDaysInCurrentWeek = (date) => {
    const currentDate = new Date(date);
    const currentDay = currentDate.getDay();
    const days = [];

    const startDate = new Date(currentDate);
    startDate.setDate(currentDate.getDate() - currentDay);

    for (let i = 0; i < 7; i++) {
      const _date = new Date(startDate);
      _date.setDate(startDate.getDate() + i);
      days.push({
        date: moment(_date).format(),
        dayOfMonth: _date.getDate(),
        dayOfWeek: _date.getDay(),
      });
    }

    return days;
  };

  const setupWeekColumns = useCallback(() => {
    var dt = new Date();
    let daysInWeek = getDaysInCurrentWeek(dt);
    const children = daysInWeek.map((val) => {
      const dayOfWeekName = dayOfWeeks[val.dayOfWeek];
      const format = moment(val.date).format("YYYY-MM-DD");
      return {
        title: <span>{dayOfWeekName}</span>,
        key: `date${val.dayOfWeek}`,
        dataIndex: "attendances",
        editable: true,
        align: "center",
        width: 90,
        render: (value, record) => {
          const foundItem = value?.find?.(
            (item) =>
              item?.date === format &&
              item?.lessons?.[0]?.id === currentLesson &&
              item?.sessions?.[0]?.id === section
          );
          return {
            props: {
              style: {
                background: val.dayOfWeek === 0 ? "#F7EAEA" : "inherit",
              },
            },
            children: (
              <div className="flex justify-center items-center">
                <SelectAttendance
                  classId={classId}
                  attendanceId={foundItem?.id ?? null}
                  studentId={record?.id}
                  date={val.date}
                  currentLesson={currentLesson}
                  attendanceDate={foundItem?.date}
                  attendanceStatus={foundItem?.workflow_state ?? null}
                  setIsRefresh={setIsRefresh}
                  disabled={!isAllowToUpdate}
                  // schoolYear={schoolYear}
                  section={section}
                />
              </div>
            ),
          };
        },
      };
    });
    setWeekColumns([...columns, ...children]);
  }, [classId, columns, currentLesson, isAllowToUpdate, section, setIsRefresh]);

  useEffect(() => {
    if (classId && classId !== null && selectedDate) {
      setupWeekColumns();
      // checkIsAttendanceAll();
    }
  }, [classId, selectedDate, section, setupWeekColumns]);

  return (
    <>
      <CusTable
        rowKey="id"
        data={data}
        columns={weekColumns}
        loading={tableLoading}
        havePagination={totalItems > pageSize.value}
        page={currentPage}
        setPage={setCurrentPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        totalItems={totalItems}
        scroll={{ x: "1000px", y: 480 }}
        rowClassName="bg-white attendance-row"
      />
    </>
  );
}

export default WeekAttendance;
