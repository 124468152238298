import { message } from "antd";
import InProgressModal from "common/InProgressModal";
import StyledButton from "common/button/StyledButton";
import React, { useState } from "react";
import { checkPullScoreCohotaAPI } from "util/APIs";

export default function CheckPullScoreCohotaButton({
  schoolYearId,
  semester,
  classId,
  subject,
}) {
  // #region    VARIABLES //////////////////////////
  //////////////////////////////////////////////////
  const [inProgressData, setInProgressData] = useState(null);
  //////////////////////////////////////////////////
  // #endregion VARIABLES //////////////////////////

  // #region    useEffect //////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion useEffect //////////////////////////

  // #region    FUNCTIONS //////////////////////////
  //////////////////////////////////////////////////
  const checkIntegrate = async () => {
    try {
      const params = {
        school_year_id: schoolYearId,
        semester,
        class_id: classId,
        subject_id: subject,
      };
      const res = await checkPullScoreCohotaAPI(params);
      if (!!res?.data?.data) {
        setInProgressData(res?.data?.data);
      } else if (res?.data?.code === "success") {
        message.success("Đã đồng bộ thành công");
      }
    } catch (error) {}
  };

  const closeInProgressModal = () => setInProgressData(null);
  //////////////////////////////////////////////////
  // #endregion FUNCTIONS //////////////////////////

  // #region    VIEWS //////////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion VIEWS //////////////////////////////
  return (
    <>
      <StyledButton onClick={checkIntegrate} theme="blue" className="mRight12">
        Tiến trình đồng bộ
      </StyledButton>
      <InProgressModal data={inProgressData} onClose={closeInProgressModal} />
    </>
  );
}
