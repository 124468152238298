import { Font } from "@react-pdf/renderer";
import BeVNPro_Bold from "asset/fonts/Be_Vietnam_Pro/BeVietnamPro-Bold.ttf";
import BeVNPro_BoldItalic from "asset/fonts/Be_Vietnam_Pro/BeVietnamPro-BoldItalic.ttf";
import BeVNPro_Italic from "asset/fonts/Be_Vietnam_Pro/BeVietnamPro-Italic.ttf";
import BeVNPro_Medium from "asset/fonts/Be_Vietnam_Pro/BeVietnamPro-Medium.ttf";
import BeVNPro_MediumItalic from "asset/fonts/Be_Vietnam_Pro/BeVietnamPro-MediumItalic.ttf";
import BeVNPro_Regular from "asset/fonts/Be_Vietnam_Pro/BeVietnamPro-Regular.ttf";
import BeVNPro_SemiBold from "asset/fonts/Be_Vietnam_Pro/BeVietnamPro-SemiBold.ttf";
import BeVNPro_SemiBoldItalic from "asset/fonts/Be_Vietnam_Pro/BeVietnamPro-SemiBoldItalic.ttf";
import Loading from "common/loader/Loading";
import React from "react";
import { Provider } from "react-redux";
import persistStore from "redux-persist/es/persistStore";
import { PersistGate } from "redux-persist/integration/react";
import store from "redux/store";
import RouterProvider from "./router/RouterProvider";

export default function App() {
  const persistor = persistStore(store);
  Font.register({
    family: "Be Vietnam Pro",
    fonts: [
      {
        src: BeVNPro_Regular,
        fontWeight: 400,
        fontStyle: "normal",
      },
      {
        src: BeVNPro_Italic,
        fontWeight: 400,
        fontStyle: "italic",
      },
      {
        src: BeVNPro_Medium,
        fontWeight: 500,
        fontStyle: "normal",
      },
      {
        src: BeVNPro_MediumItalic,
        fontWeight: 500,
        fontStyle: "italic",
      },
      {
        src: BeVNPro_SemiBold,
        fontWeight: 600,
        fontStyle: "normal",
      },
      {
        src: BeVNPro_SemiBoldItalic,
        fontWeight: 600,
        fontStyle: "italic",
      },
      {
        src: BeVNPro_Bold,
        fontWeight: 700,
        fontStyle: "normal",
      },
      {
        src: BeVNPro_BoldItalic,
        fontWeight: 700,
        fontStyle: "italic",
      },
    ],
  });

  return (
    <Provider store={store}>
      <PersistGate loading={<Loading />} persistor={persistor}>
        <RouterProvider />
      </PersistGate>
    </Provider>
  );
}
