export const SET_TRANSCRIPT_PAGE_FILTER_GRADE =
  "SET_TRANSCRIPT_PAGE_FILTER_GRADE";
export const SET_TRANSCRIPT_PAGE_FILTER_SEMESTER =
  "SET_TRANSCRIPT_PAGE_FILTER_SEMESTER";
export const SET_TRANSCRIPT_PAGE_FILTER_SUBJECT =
  "SET_TRANSCRIPT_PAGE_FILTER_SUBJECT";

export function setTranscriptPageFilterGradeAction(data) {
  return {
    type: SET_TRANSCRIPT_PAGE_FILTER_GRADE,
    payload: data,
  };
}

export function setTranscriptPageFilterSemesterAction(data) {
  return {
    type: SET_TRANSCRIPT_PAGE_FILTER_SEMESTER,
    payload: data,
  };
}

export function setTranscriptPageFilterSubjectAction(data) {
  return {
    type: SET_TRANSCRIPT_PAGE_FILTER_SUBJECT,
    payload: data,
  };
}
