import { Button, Input, Modal } from "antd";
import { eyeIcon, searchIcon, whitePlusIcon } from "asset/img/Images";
import { isPermitted } from "authen/authenHelper";
import CustomBreadcrumb from "common/breadcrumb/CustomBreadcrumb";
import StyledButton from "common/button/StyledButton";
import CusTable from "common/CusTable";
import Img from "common/image/Img";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  PERMISSION_OBJECT,
  PERMISSION_TYPE,
  TABLE_PAGE_SIZES,
} from "util/constant";
import styles from "./styles.module.css";

export default function TimetableListPage() {
  // #region    VARIABLES //////////////////////////
  //////////////////////////////////////////////////
  const isAllowToCreate = isPermitted(
    PERMISSION_OBJECT.school_year,
    PERMISSION_TYPE.create
  );

  const history = useHistory();

  const [search, setSearch] = useState("");

  const [dataList, setDataList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(TABLE_PAGE_SIZES[0]);
  const [totalItems, setTotalItems] = useState(0);
  const [tableLoading, setTableLoading] = useState(false);

  const columns = [
    {
      title: "Tên thời khóa biểu",
      key: "name",
      width: 400,
      render: (text, record) => <span>{record?.name}</span>,
    },
    {
      title: "Thời gian áp dụng",
      key: "from_year",
      align: "center",
      render: (text, record) => <span> - </span>,
    },
    {
      title: "Người tạo",
      key: "creator_name",
      align: "center",
      dataIndex: "creator_name",
    },
    {
      title: "",
      key: "action",
      align: "center",
      render: (text, record) => {
        return (
          <Button type="link" onClick={() => {}}>
            <Img src={eyeIcon} />
          </Button>
        );
      },
    },
  ];
  //////////////////////////////////////////////////
  // #endregion VARIABLES //////////////////////////

  // #region    useEffect //////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion useEffect //////////////////////////

  // #region    FUNCTIONS //////////////////////////
  //////////////////////////////////////////////////
  const handleSetSearch = (e) => setSearch(e.target.value);

  const goToCreateTimetablePage = () => history.push("/timetable-auto-gen");
  //////////////////////////////////////////////////
  // #endregion FUNCTIONS //////////////////////////

  // #region    VIEWS //////////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion VIEWS //////////////////////////////
  return (
    <div className={`flex-col ${styles.root}`}>
      <CustomBreadcrumb crumbs={["/home", "/timetable-information"]} />

      <div className={`bg-white section-shadow ${styles.sectionShadow}`}>
        <div className="flex flex-col flex-1 px-8 pb-4">
          <div className="flex mb-4">
            <Input
              value={search}
              onChange={handleSetSearch}
              className={`styled-input mr-4`}
              prefix={
                <div className="mRight8">
                  <Img src={searchIcon} alt="search" />
                </div>
              }
              placeholder="Tìm kiếm"
            />
            <div className="flex justify-end">
              <StyledButton
                onClick={goToCreateTimetablePage}
                disabled={!isAllowToCreate}
                className="bg-blueDefault flex justify-center items-center px-8 py-4"
              >
                <img src={whitePlusIcon} className="mr-2" />
                <span className="text-base font-semibold text-white">
                  Thêm mới
                </span>
              </StyledButton>
            </div>
          </div>

          <CusTable
            rowKey="id"
            data={dataList}
            columns={columns}
            page={currentPage}
            setPage={setCurrentPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            totalItems={totalItems}
            tableLoading={tableLoading}
          />
        </div>
      </div>
    </div>
  );
}
