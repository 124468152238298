import { SET_SCHOOL_LIST, SET_SELECTED_SCHOOL } from "redux/action/school";
import { deepClone } from "util/helper";

export default function schoolReducer(
  state = {
    schoolList: [],
    selectedSchool: {},
  },
  action
) {
  switch (action.type) {
    case SET_SCHOOL_LIST:
      return {
        ...state,
        schoolList: deepClone(action.payload),
      };
    case SET_SELECTED_SCHOOL:
      return {
        ...state,
        selectedSchool: deepClone(action.payload),
      };
    default:
      return state;
  }
}
