import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { getOauthJWT } from 'util/APIs';
import useHandleLogin from 'hook/handleLoginSuccess';
import IsMobile from 'hook/isMobile';
import { useDispatch } from "react-redux";
import { setSidebarActiveKey } from "redux/action/app";

function CustomRedirect() {
  const isUsingMobile = IsMobile();
  const dispatch = useDispatch();
  const history = useHistory();
  const currentPath = history.location;
  const [handleLoginSuccess] = useHandleLogin();
  useEffect(() => {
    async function fetchData() {
      try {
        const res = await getOauthJWT(currentPath.search);
        await handleLoginSuccess(res?.data);
        if (isUsingMobile) history.push('/user/home');
        else {
          dispatch(setSidebarActiveKey('personal_details'));
          history.push('/user');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    }
    fetchData();
  }, []);

  return <div></div>;
}

export default CustomRedirect;
