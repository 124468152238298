import TextArea from "antd/lib/input/TextArea";
import InfoField from "common/CusInfoField/infoField/InfoField";
import React from "react";

export default function InfoFieldTextArea({
  label = "",
  isRequired = false,
  value,
  setValue = (_value) => {},
  placeholder = "",
  disabled = false,
  className = "",
  labelClassName = "",
  inputClassName = "",
  labelWrapperClassName = "",
  direction = "horizontal",
  labelStyle = {},
  rows = 4,
  noLabel = false,
  ...props
}) {
  // #region    VARIABLES //////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion VARIABLES //////////////////////////

  // #region    useEffect //////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion useEffect //////////////////////////

  // #region    FUNCTIONS //////////////////////////
  //////////////////////////////////////////////////
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  //////////////////////////////////////////////////
  // #endregion FUNCTIONS //////////////////////////

  // #region    VIEWS //////////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion VIEWS //////////////////////////////
  return (
    <InfoField
      label={label}
      isRequired={isRequired}
      input={
        <TextArea
          value={value}
          onChange={handleChange}
          placeholder={placeholder}
          disabled={disabled}
          rows={rows}
          className={`styled-input ${inputClassName}`}
          {...props}
        />
      }
      className={className}
      labelClassName={labelClassName}
      labelStyle={labelStyle}
      labelWrapperClassName={labelWrapperClassName}
      direction={direction}
      isTextArea={true}
      noLabel={noLabel}
    />
  );
}
