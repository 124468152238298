import {
  SET_MARK_PAGE_FILTER_GRADE,
  SET_MARK_PAGE_FILTER_SEMESTER,
  SET_MARK_PAGE_FILTER_SUBJECT,
} from "redux/action/markPageAction";
import { SEMESTERS } from "util/constant";
import { deepClone } from "util/helper";

export default function markPageReducer(
  state = {
    filterGrade: null,
    filterSemester: SEMESTERS[0],
    filterSubject: null,
  },
  action
) {
  switch (action.type) {
    case SET_MARK_PAGE_FILTER_GRADE:
      return {
        ...state,
        filterGrade: deepClone(action.payload),
      };
    case SET_MARK_PAGE_FILTER_SEMESTER:
      return {
        ...state,
        filterSemester: deepClone(action.payload),
      };
    case SET_MARK_PAGE_FILTER_SUBJECT:
      return {
        ...state,
        filterSubject: deepClone(action.payload),
      };
    default:
      return state;
  }
}
