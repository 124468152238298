import { message } from "antd";
import {
  cameraWhiteOutlined,
  demoAvatar,
  editPic,
  pencilWhite,
} from "asset/img/Images";
import Avatar from "common/avatar/Avatar";
import InfoFieldDatePicker from "common/CusInfoField/infoFieldDatePicker/InfoFieldDatePicker";
import InfoFieldSelect from "common/CusInfoField/infoFieldSelect/InfoFieldSelect";
import InfoFieldText from "common/CusInfoField/infoFieldText/InfoFieldText";
import ImagePicker from "common/imagePicker/ImagePicker";
import CustomModal from "mobile/component/customModal/CustomModal";
import MobileHeader from "mobile/layout/header/MobileHeader";
import { HEADER_TYPE } from "mobile/util/mobileConstant";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  getCountryList,
  getDistrictByProvince,
  getProvinceByCountry,
  getSubDistrictByDistrict,
  updateStudent,
  getTeacherData,
  updateUser,
  getEthnics,
  getReligions,
  getTeacherDetailsById,
} from "util/APIs";
import color from "util/color";
import { GENDER, OBJECT_TYPE_BE } from "util/constant";
import {
  disableFutureDate,
  findEthnicAndReligion,
  textStyle,
} from "util/helper";
import InfoItem from "./component/infoItem/InfoItem";
import styles from "./styles.module.css";
import { ID_ISSUED_PLACES } from "util/constant";
import { useCallback } from "react";

export default function ProfilePage() {
  // #region    VARIABLES //////////////////////////
  //////////////////////////////////////////////////
  const dispatch = useDispatch();
  const history = useHistory();
  const INFO_TABS = [
    {
      key: "general",
      name: "Thông tin cá nhân",
    },
    {
      key: "teacher",
      name: "Thông tin giáo viên",
    },
  ];
  const [selectedTab, setSelectedTab] = useState(INFO_TABS[0]);
  const [imageUrls, setImageUrls] = useState(null);
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const [userInfo, setUserInfo] = useState(
    history?.location?.state?.data
      ? history?.location?.state?.data
      : JSON.parse(localStorage.getItem("user_info"))
  );
  const [isSelfUpdate, setIsSelfUpdate] = useState(
    history?.location?.state?.data === undefined
  );
  const avatarPickerRef = useRef(null);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const [expandPersonal, setExpandPersonal] = useState(false);
  const [isEdittingPersonal, setIsEdittingPersonal] = useState(false);
  const [expandContact0, setExpandContact0] = useState(false);
  const [isEdittingContact0, setIsEdittingContact0] = useState(false);
  const [expandTeacher, setExpandTeacher] = useState(false);
  const [isEdittingTeacher, setIsEdittingTeacher] = useState(false);
  const isShowUpdateBtn =
    isEdittingPersonal || isEdittingContact0 || isEdittingTeacher;
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // Personal fields - 1
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dob, setDob] = useState("");
  const [gender, setGender] = useState("");
  const [ethnic, setEthnic] = useState("");
  const [ethnicList, setEthnicList] = useState([]);
  const [religion, setReligion] = useState("");
  const [religionList, setReligionList] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [idNo, setIdNo] = useState("");
  const [idIssueDate, setIdIssueDate] = useState(undefined);
  const [idIssuePlace, setIdIssuePlace] = useState(undefined);
  const [boGdId, setBoGdId] = useState("");
  const [soHcmId, setSoHcmId] = useState("");
  const [taxId, setTaxId] = useState("");
  const [bankId, setBankId] = useState("");
  const [bankName, setBankName] = useState("");
  const [bankBranch, setBankBranch] = useState("");
  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // Contact fields - 1
  const [placeOfPermanentProvinceList, setPlaceOfPermanentProvinceList] =
    useState([]);
  const [placeOfPermanentProvince, setPlaceOfPermanentProvince] =
    useState(undefined);
  const [placeOfPermanentDistrictList, setPlaceOfPermanentDistrictList] =
    useState([]);
  const [placeOfPermanentDistrict, setPlaceOfPermanentDistrict] =
    useState(undefined);
  const [placeOfPermanentSubDistrictList, setPlaceOfPermanentSubDistrictList] =
    useState([]);
  const [placeOfPermanentSubDistrict, setPlaceOfPermanentSubDistrict] =
    useState(undefined);
  const [placeOfPermanentQuarterHamlet, setPlaceOfPermanentQuarterHamlet] =
    useState("");
  const [placeOfPermanentDescription, setPlaceOfPermanentDescription] =
    useState("");
  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const personalFields = [
    {
      title: "Thông tin cá nhân",
      fields: [
        <InfoFieldText
          label="Họ"
          isRequired={true}
          value={firstName}
          setValue={setFirstName}
          placeholder="Nhập họ"
          direction="vertical"
          disabled={!isEdittingPersonal}
        />,
        <InfoFieldText
          label="Tên"
          isRequired={true}
          value={lastName}
          setValue={setLastName}
          placeholder="Nhập tên"
          direction="vertical"
          disabled={!isEdittingPersonal}
        />,
        <InfoFieldDatePicker
          label="Ngày sinh"
          value={dob}
          setValue={setDob}
          placeholder="Ngày/tháng/năm"
          direction="vertical"
          disabled={!isEdittingPersonal}
        />,
        <InfoFieldSelect
          label="Giới tính"
          data={GENDER}
          value={gender}
          setValue={setGender}
          placeholder="Chọn giới tính"
          direction="vertical"
          disabled={!isEdittingPersonal}
          valueTextName="name"
          valueFieldName="value"
        />,
        <InfoFieldSelect
          label="Dân tộc"
          data={ethnicList}
          value={ethnic}
          setValue={setEthnic}
          placeholder="Chọn dân tộc"
          direction="vertical"
          disabled={!isEdittingPersonal}
          valueTextName="name"
          valueFieldName="id"
        />,
        <InfoFieldSelect
          label="Tôn giáo"
          data={religionList}
          value={religion}
          setValue={setReligion}
          placeholder="Chọn tôn giáo"
          direction="vertical"
          disabled={!isEdittingPersonal}
          valueTextName="name"
          valueFieldName="id"
        />,
        <InfoFieldText
          label="Số điện thoại"
          value={phoneNumber}
          setValue={setPhoneNumber}
          placeholder="Nhập số điện thoại"
          direction="vertical"
          disabled={!isEdittingPersonal}
        />,
        <InfoFieldText
          label="Email"
          value={email}
          setValue={setEmail}
          placeholder="Nhập Email"
          direction="vertical"
          disabled={!isEdittingPersonal}
        />,
        <InfoFieldText
          label="CMND / CCCD"
          value={idNo}
          setValue={setIdNo}
          placeholder="Nhập số CMND / CCCD"
          direction="vertical"
          disabled={!isEdittingPersonal}
        />,
        <InfoFieldDatePicker
          label="Ngày cấp"
          value={idIssueDate}
          setValue={setIdIssueDate}
          placeholder="Ngày/tháng/năm"
          direction="vertical"
          disabled={!isEdittingPersonal}
        />,
        <InfoFieldSelect
          label="Nơi cấp"
          data={ID_ISSUED_PLACES}
          value={idIssuePlace}
          setValue={setIdIssuePlace}
          placeholder="Chọn nơi cấp CMND / CCCD"
          direction="vertical"
          disabled={!isEdittingPersonal}
          valueTextName="name"
          valueFieldName="id"
        />,
      ],
    },
  ];
  const contactFields = [
    {
      title: "Địa chỉ thường trú",
      fields: [
        <InfoFieldText
          label="Quốc gia"
          placeholder="Việt Nam"
          direction="vertical"
          disabled={true}
        />,
        <InfoFieldSelect
          label="Tỉnh / TP"
          isRequired={true}
          data={placeOfPermanentProvinceList}
          value={placeOfPermanentProvince}
          setValue={setPlaceOfPermanentProvince}
          placeholder="Chọn tỉnh / thành phố"
          direction="vertical"
          disabled={!isEdittingContact0}
          valueTextName="name"
          valueFieldName="code"
        />,
        <InfoFieldSelect
          label="Quận / Huyện"
          isRequired={true}
          data={placeOfPermanentDistrictList}
          value={placeOfPermanentDistrict}
          setValue={setPlaceOfPermanentDistrict}
          placeholder="Chọn quận / huyện"
          direction="vertical"
          disabled={!isEdittingContact0 || !placeOfPermanentProvince}
          valueTextName="name"
          valueFieldName="code"
        />,
        <InfoFieldSelect
          label="Phường / Xã"
          isRequired={true}
          data={placeOfPermanentSubDistrictList}
          value={placeOfPermanentSubDistrict}
          setValue={setPlaceOfPermanentSubDistrict}
          placeholder="Chọn phường / xã"
          direction="vertical"
          disabled={
            !isEdittingContact0 ||
            !placeOfPermanentProvince ||
            !placeOfPermanentDistrict
          }
          valueTextName="name"
          valueFieldName="code"
        />,
        <InfoFieldText
          label="Khu phố / Thôn"
          value={placeOfPermanentQuarterHamlet}
          setValue={setPlaceOfPermanentQuarterHamlet}
          placeholder="Nhập khu phố / thôn"
          direction="vertical"
          disabled={!isEdittingContact0}
        />,
        <InfoFieldText
          label="Số nhà"
          value={placeOfPermanentDescription}
          setValue={setPlaceOfPermanentDescription}
          placeholder="Nhập số nhà"
          direction="vertical"
          disabled={!isEdittingContact0}
        />,
      ],
    },
  ];
  const teacherFields = [
    {
      title: "Thông tin giáo viên",
      fields: [
        <InfoFieldText
          label="Mã số thuế"
          value={taxId}
          setValue={setTaxId}
          placeholder="Nhập mã số thuế"
          direction="vertical"
          disabled={!isEdittingTeacher}
        />,
        <InfoFieldText
          label="Mã sở"
          value={soHcmId}
          setValue={setSoHcmId}
          placeholder="Nhập mã sở"
          direction="vertical"
          disabled={!isEdittingTeacher}
        />,
        <InfoFieldText
          label="Mã bộ"
          value={boGdId}
          setValue={setBoGdId}
          placeholder="Nhập mã bộ"
          direction="vertical"
          disabled={!isEdittingTeacher}
        />,
        <InfoFieldText
          label="Số tài khoản ngân hàng"
          value={bankId}
          setValue={setBankId}
          placeholder="Nhập số tài khoản ngân hàng"
          direction="vertical"
          disabled={!isEdittingTeacher}
        />,
        <InfoFieldText
          label="Tên ngân hàng"
          value={bankName}
          setValue={setBankName}
          placeholder="Nhập tên ngân hàng"
          direction="vertical"
          disabled={!isEdittingTeacher}
        />,
        <InfoFieldText
          label="Tên chi nhánh ngân hàng"
          value={bankBranch}
          setValue={setBankBranch}
          placeholder="Nhập tên chi nhánh ngân hàng"
          direction="vertical"
          disabled={!isEdittingTeacher}
        />,
      ],
    },
  ];
  //////////////////////////////////////////////////
  // #endregion VARIABLES //////////////////////////

  // #region    useEffect //////////////////////////
  //////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (placeOfPermanentProvince !== undefined) {
      getDistrictByProvince(placeOfPermanentProvince, "vn").then((res) => {
        if (res?.statusText === "OK") {
          setPlaceOfPermanentDistrictList(res?.data);
        }
      });
    }
  }, [placeOfPermanentProvince]);

  useEffect(() => {
    if (placeOfPermanentDistrict !== undefined) {
      getSubDistrictByDistrict(
        placeOfPermanentDistrict,
        placeOfPermanentProvince,
        "vn"
      ).then((res) => {
        if (res?.statusText === "OK") {
          setPlaceOfPermanentSubDistrictList(res?.data);
        }
      });
    }
  }, [placeOfPermanentDistrict]);
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion useEffect //////////////////////////

  // #region    FUNCTIONS //////////////////////////
  //////////////////////////////////////////////////
  const openAvatarPicker = () => {
    avatarPickerRef?.current?.upload?.uploader?.onClick();
  };

  const handleDataUser = useCallback(async () => {
    const accountId = userInfo?.account?.id;
    const userId = userInfo?.user?.id;
    const res = await getTeacherDetailsById(accountId, userId);
    if (res?.status === 200) {
      console.log("ressss", res);
      res.data.citizen_identify =
        res.data.citizen_identify !== null
          ? typeof res?.data?.citizen_identify === "string"
            ? JSON.parse(res?.data?.citizen_identify)
            : res?.data?.citizen_identify
          : {};
      res.data.permanent_address =
        res.data.permanent_address !== null
          ? typeof res?.data?.permanent_address === "string"
            ? JSON.parse(res?.data?.permanent_address)
            : res?.data?.permanent_address
          : {};
      res.data.ethnic =
        res.data.ethnic !== null
          ? typeof res?.data?.ethnic === "string"
            ? JSON.parse(res?.data?.ethnic)
            : res?.data?.ethnic
          : {};
      res.data.religion =
        res.data.religion !== null
          ? typeof res?.data?.religion === "string"
            ? JSON.parse(res?.data?.religion)
            : res?.data?.religion
          : {};
      res.data.bank_information =
        res.data.bank_information !== null
          ? typeof res?.data?.bank_information === "string"
            ? JSON.parse(res?.data?.bank_information)
            : res?.data?.bank_information
          : {};
      ////////////////////////////////////////////////////////////////////////////////////////////////////
      setFirstName(res?.data.first_name);
      setLastName(res?.data.last_name);
      setDob(res?.data.dob);
      setGender(res?.data.gender);
      getEthnics().then((res) => {
        if (res?.statusText === "OK") {
          setEthnicList(res?.data);
        }
      });
      setEthnic(res?.data.ethnic?.id);
      getReligions().then((res) => {
        if (res?.statusText === "OK") {
          setReligionList(res?.data?.data);
        }
      });
      setReligion(res?.data?.religion?.id);
      setPhoneNumber(res?.data?.phone_number);
      setEmail(res?.data?.email);
      setIdNo(res?.data?.citizen_identify?.id_no);
      setIdIssueDate(res?.data?.citizen_identify?.issued_on);
      setIdIssuePlace(res?.data?.citizen_identify?.place_of_issue);
      setTaxId(res?.data?.tax_id);
      setSoHcmId(res?.data?.so_hcm_id);
      setBoGdId(res?.data?.bo_gd_id);
      setBankId(res?.data?.bank_information?.bank_id);
      setBankName(res?.data?.bank_information?.bank_name);
      setBankBranch(res?.data?.bank_information?.bank_branch);
      ////////////////////////////////////////////////////////////////////////////////////////////////////

      ////////////////////////////////////////////////////////////////////////////////////////////////////
      getProvinceByCountry("vn").then((res) => {
        if (res?.statusText === "OK") {
          setPlaceOfPermanentProvinceList(res?.data);
        }
      });
      setPlaceOfPermanentProvince(res?.data?.permanent_address?.province_city);
      if (res?.data?.permanent_address?.province_city) {
        getDistrictByProvince(
          res?.data?.permanent_address?.province_city,
          "vn"
        ).then((res) => {
          if (res?.statusText === "OK") {
            setPlaceOfPermanentDistrictList(res?.data);
          }
        });
      }
      setPlaceOfPermanentDistrict(
        res?.data?.permanent_address?.district || null
      );
      if (res?.data?.permanent_address?.district) {
        getSubDistrictByDistrict(
          res?.data?.permanent_address?.district,
          res?.data?.permanent_address?.province_city,
          "vn"
        ).then((res) => {
          if (res?.statusText === "OK") {
            setPlaceOfPermanentSubDistrictList(res?.data);
          }
        });
      }
      setPlaceOfPermanentSubDistrict(
        res?.data?.permanent_address?.sub_district
      );
      setPlaceOfPermanentQuarterHamlet(
        res?.data?.permanent_address?.quarter_hamlet
      );
      setPlaceOfPermanentDescription(res?.data?.permanent_address?.description);
    }
  }, [userInfo?.account?.id, userInfo?.user?.id]);

  useEffect(() => {
    handleDataUser();
  }, [handleDataUser]);

  const handleSetImageUrl = (imageUrl) => {
    setImageUrls([imageUrl]);
  };

  const showConfirmModal = () => {
    setConfirmModalVisible(true);
  };

  const hideConfirmModal = () => {
    setConfirmModalVisible(false);
  };

  const handleUpdateStudent = () => {
    const params = {
      data: {
        id: userInfo?.user?.id,
        first_name: firstName,
        last_name: lastName,
        dob: dob,
        gender: gender,
        ethnic: findEthnicAndReligion(ethnicList, ethnic),
        religion: findEthnicAndReligion(religionList, religion),
        phone_number: phoneNumber,
        email: email,
        citizen_identify: {
          id_no: idNo,
          issued_on: idIssueDate,
          place_of_issue: idIssuePlace,
        },
        permanent_address: {
          country: "Việt Nam",
          province_city: placeOfPermanentProvince,
          district: placeOfPermanentDistrict,
          sub_district: placeOfPermanentSubDistrict,
          quarter_hamlet: placeOfPermanentQuarterHamlet,
          description: placeOfPermanentDescription,
        },

        tax_id: taxId,
        bank_information: {
          bank_id: bankId,
          bank_name: bankName,
          bank_branch: bankBranch,
        },
        digital_signature: null,
        bo_gd_id: boGdId,
        so_hcm_id: soHcmId,
      },
    };
    const accountId = userInfo?.account?.id;
    updateUser(accountId, userInfo?.user?.id, params).then((res) => {
      if (res?.statusText === "OK") {
        message.success("Cập nhật thành công!");

        userInfo["role"] = userInfo?.user?.roles;
        userInfo["tabs"] = userInfo?.user?.tabs;
        if (isSelfUpdate) {
          setUserInfo({ ...userInfo });
          localStorage.setItem("user_info", JSON.stringify(userInfo));
        } else {
          goBack();
        }
        clearData();
      }
    });
  };

  const goBack = () => history.push("/user/home");

  const clearData = () => {};
  //////////////////////////////////////////////////
  // #endregion FUNCTIONS //////////////////////////

  // #region    VIEWS //////////////////////////////
  //////////////////////////////////////////////////
  const renderTab = (item) => {
    const isSelected = item?.key === selectedTab?.key;
    return (
      <button
        onClick={() => setSelectedTab(item)}
        className={`flex-1 ${styles.semester} ${
          isSelected ? styles.choosingSemester : ""
        }`}
        key={item?.key}
      >
        <span style={textStyle(14, 700, isSelected ? color.white : "#313437")}>
          {item?.name}
        </span>
      </button>
    );
  };
  //////////////////////////////////////////////////
  // #endregion VIEWS //////////////////////////////
  const handlechangefile = async (e) => {
    const file = e.target.files;
    const formData = new FormData();

    formData.append("file", file[0]);
    if (file) {
      const res = await updateUser(
        userInfo?.account?.id,
        userInfo?.user?.id,
        formData
      );
      if (res.status === 200) {
        setImageUrls(JSON.parse(res.data.files)[0].url);
      }
    }
  };
  // Create a reference to the hidden file input element
  const hiddenFileInput = useRef(null);

  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  return (
    <div className={`flex flex-col ${styles.root}`}>
      <MobileHeader
        type={HEADER_TYPE.middleTitle}
        title="Hồ sơ"
        haveNotiButton={false}
      />
      <div className={`flex ${styles.semesterWrapper}`}>
        {INFO_TABS.map(renderTab)}
      </div>
      <div
        className={`flex flex-col ${styles.infoWrapper} ${
          isShowUpdateBtn ? styles.mBottom90 : ""
        }`}
      >
        <div className={styles.avatarWrapper}>
          <Avatar
            src={imageUrls || demoAvatar}
            size={80}
            className={styles?.avatar}
            imgClassName="w-full h-full"
          />

          <Avatar
            src={cameraWhiteOutlined}
            size={34}
            className={styles.changeAvatarBtn}
            imgClassName="w-full h-full"
            imgIsRound={false}
            onClick={handleClick}
          />
          <input
            type="file"
            onChange={handlechangefile}
            ref={hiddenFileInput}
            style={{ display: "none" }} // Make the file input element invisible
          />
        </div>

        <div
          className={`mTop16 ${
            selectedTab.key === INFO_TABS[0].key ? "" : "hidden"
          }`}
        >
          <InfoItem
            data={personalFields[0]}
            expanded={expandPersonal}
            setExpanded={setExpandPersonal}
            isEditting={isEdittingPersonal}
            setEditting={setIsEdittingPersonal}
          />
          <InfoItem
            data={contactFields[0]}
            expanded={expandContact0}
            setExpanded={setExpandContact0}
            isEditting={isEdittingContact0}
            setEditting={setIsEdittingContact0}
            className="mTop16"
          />
        </div>

        <div
          className={`mTop16 ${
            selectedTab.key === INFO_TABS[1].key ? "" : "hidden"
          }`}
        >
          <InfoItem
            data={teacherFields[0]}
            expanded={expandTeacher}
            setExpanded={setExpandTeacher}
            isEditting={isEdittingTeacher}
            setEditting={setIsEdittingTeacher}
          />
        </div>
      </div>

      <div
        className={`${styles.updateBtnWrapper} ${
          isShowUpdateBtn ? "" : "hidden"
        }`}
      >
        <button
          className={`w-full ${styles.updateBtn}`}
          onClick={showConfirmModal}
        >
          <span className={styles.updateText}>Cập nhật</span>
        </button>
      </div>

      <CustomModal
        className={styles.confirmModal}
        visible={confirmModalVisible}
        setVisible={setConfirmModalVisible}
      >
        <div className={`flex flex-col flex-1 items-center ${styles.content}`}>
          <div className="relative">
            <img src={editPic} alt="Confirm edit" />
            <img src={pencilWhite} alt="Pen" className={styles.pen} />
          </div>
          <div className="flex flex-col items-center mTop24">
            <span className={styles.confirmText2}>Xác nhận</span>
            <span className={styles.confirmText}>
              Bạn có chắc muốn cập nhật thông tin?
            </span>
          </div>
          <div className="flex w-full">
            <button
              className={`${styles.confirmBtn} ${styles.cancelBtn} mRight10`}
              onClick={hideConfirmModal}
            >
              <span className={styles.cancelText}>Hủy bỏ</span>
            </button>
            <button className={styles.confirmBtn} onClick={handleUpdateStudent}>
              <span className={styles.confirmText3}>Xác nhận</span>
            </button>
          </div>
        </div>
      </CustomModal>

      <ImagePicker
        imageUrl={imageUrls}
        setImageUrl={handleSetImageUrl}
        alt=""
        objectType={OBJECT_TYPE_BE.student}
        ref={avatarPickerRef}
        className="hidden"
      />
    </div>
  );
}
