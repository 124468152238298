import {
  SET_SCHOOL_YEAR_LIST,
  SET_SELECTED_SCHOOL_YEAR,
} from "redux/action/schoolYear";
import { deepClone } from "util/helper";

export default function schoolYearReducer(
  state = {
    schoolYearList: [],
    selectedSchoolYear: {},
  },
  action
) {
  switch (action.type) {
    case SET_SCHOOL_YEAR_LIST:
      return {
        ...state,
        schoolYearList: deepClone(action.payload),
      };
    case SET_SELECTED_SCHOOL_YEAR:
      return {
        ...state,
        selectedSchoolYear: deepClone(action.payload),
      };
    default:
      return state;
  }
}
