import { Input, Popover } from "antd";
import {
  chevronDownLightIcon,
  demoAvatar,
  searchIcon,
  settingIcon,
} from "asset/img/Images";
import Img from "common/image/Img";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { logout } from "util/APICaller";
import NotiButton from "./component/NotiButton/NotiButton";
import styles from "./styles.module.css";

export default function Header() {
  // #region    VARIABLES //////////////////////////
  //////////////////////////////////////////////////
  const userInfo = useSelector((state) => state?.app?.userInfo);
  //////////////////////////////////////////////////
  // #endregion VARIABLES //////////////////////////

  // #region    useEffect //////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion useEffect //////////////////////////

  // #region    FUNCTIONS //////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion FUNCTIONS //////////////////////////

  // #region    VIEWS //////////////////////////////
  //////////////////////////////////////////////////
  const renderPopoverContent = () => (
    <div onClick={logout} className={styles.btn}>
      <span>Đăng xuất</span>
    </div>
  );
  //////////////////////////////////////////////////
  // #endregion VIEWS //////////////////////////////
  return (
    <div className={`flex justify-between items-center ${styles.root}`}>
      <Input
        className={`styled-input ${styles.searchInput}`}
        prefix={
          <div className={`mRight8`}>
            <Img src={searchIcon} alt="search" />
          </div>
        }
        placeholder="Tìm kiếm"
      />
      <div className={`flex items-center`}>
        <NotiButton />
        <Popover
          placement="bottomRight"
          content={renderPopoverContent}
          trigger="click"
        >
          <div className={`${styles.settingBtn}`}>
            <Img src={settingIcon} alt="Setting" />
          </div>
        </Popover>
        <div className="flex items-center mLeft16">
          <div className={styles.avatar}>
            <Link to={"/staff-management"}>
              <Img
                src={userInfo?.avatar || demoAvatar}
                alt="Avatar"
                className={styles.avatarImg}
              />
            </Link>
          </div>
          <div className="mLeft16">
            <img src={chevronDownLightIcon} alt="expand" />
          </div>
        </div>
      </div>
    </div>
  );
}
