import React, { memo, useCallback, useRef, useState } from "react";
import { Document } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import NavigationTool from "./NavigationTool";
// import PageScaleTool from "./PageScaleTool";
import PdfPage from "./PdfPage";
import useScaleHandler from "./useScaleHandler";
import "./../../../styles/index.scss";

const PDFViewer = memo(({ src, error, loading, className }) => {
  const [totalPages, setTotalPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  const documentRef = useRef(null);

  const { scaleValue, scaleInfo, changeScaleValue, getScaleOptions } =
    useScaleHandler();

  const onDocumentLoadSuccess = useCallback(({ numPages }) => {
    setTotalPages(numPages);
    // setCurrentPage(3);
    setCurrentPage(1);
  }, []);

  const changeCurrentPage = useCallback(
    (pageNo) => {
      if (currentPage !== pageNo) {
        setCurrentPage(pageNo);
      }
    },
    [currentPage]
  );

  const changePageNumber = (pageNo) => {
    let targetPage = [
      ...documentRef.current.querySelectorAll(`[data-page-number="${pageNo}"]`),
    ]?.[0];
    if (targetPage) {
      setCurrentPage(pageNo);
      targetPage.scrollIntoView({
        behavior: "auto",
        block: "start",
        inline: "start",
      });
    }
  };

  const getDocumentRef = useCallback(() => {
    return documentRef.current;
  }, []);

  return (
    <div className={`pdf-viewer ${className || ""}`} ref={documentRef}>
      <div className="pdf-viewer__container" tabIndex="-1">
        <Document
          // options={{
          //    cMapUrl: `//cdn.jsdelivr.net/npm/pdfjs-dist@2.9.359/cmaps/`,
          //    cMapPacked: true,
          //  }}
          // options={{
          //   cMapUrl: "cmaps/",
          //   cMapPacked: true,
          // }}
          options={{
            workerSrc: `//cdnjs.cloudflare.com/ajax/libs/pdf.js/2.12.313/pdf.worker.js`,
          }}
          className="pdf-viewer__document"
          file={src}
          error={error}
          loading={loading}
          externalLinkTarget="_blank"
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={(e) => {
            console.log(e);
          }}
        >
          {Array.from(new Array(totalPages), (_, index) => (
            <PdfPage
              key={`page_${index + 1}`}
              pageNo={index + 1}
              width={scaleInfo?.width}
              height={scaleInfo?.height}
              scale={scaleInfo?.scale}
              // customTextRenderer={textRenderer}
              getDocumentRef={getDocumentRef}
              onAccess={changeCurrentPage}
            />
          ))}
        </Document>
      </div>
      {totalPages && (
        <div className="pdf-viewer__tools">
          <div className="pdf-viewer__tools--container">
            <div className="pdf-viewer__tool-bar">
              <NavigationTool
                currentPage={currentPage}
                totalPages={totalPages}
                onChange={changePageNumber}
              />
              {/* <PageScaleTool
                scaleValue={scaleValue}
                changeScaleValue={changeScaleValue}
                getScaleOptions={getScaleOptions}
              /> */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
});

PDFViewer.displayName = "PDFViewer";
export default PDFViewer;
