import { chevronDown, chevronUp, pencilWhite } from "asset/img/Images";
import React from "react";
import styles from "./styles.module.css";

export default function InfoItem({
  data = {},
  className = "",
  expanded,
  setExpanded = (_expanded) => {},
  isEditting,
  setEditting = (_isEdit) => {},
}) {
  // #region    VARIABLES //////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion VARIABLES //////////////////////////

  // #region    useEffect //////////////////////////
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  // #endregion useEffect //////////////////////////

  // #region    FUNCTIONS //////////////////////////
  //////////////////////////////////////////////////
  const toggleExpand = () => setExpanded(!expanded);

  const toggleEditting = () => {
    setEditting(!isEditting);
    // Mở edit thì mở luôn expand
    if (!isEditting && !expanded) toggleExpand();
  };

  const onPressExpandBtn = (e) => {
    e.stopPropagation();
    toggleExpand();
  };
  //////////////////////////////////////////////////
  // #endregion FUNCTIONS //////////////////////////

  // #region    VIEWS //////////////////////////////
  //////////////////////////////////////////////////
  const renderField = (item, index, title) => {
    return (
      <div
        className={`flex-1 flex justify-between items-start ${
          styles.fieldWrapper
        } ${index !== 0 ? "mTop10" : ""}`}
        key={`${title}_${index}`}
      >
        {item}
      </div>
    );
  };
  //////////////////////////////////////////////////
  // #endregion VIEWS //////////////////////////////
  return (
    <div className={`${styles.root} ${className}`}>
      <div
        className={`w-full flex justify-between items-center ${styles.itemTop}`}
      >
        <div className="flex flex-col">
          <span className={styles.title}>{data?.title}</span>
        </div>
        <div className="flex items-center mLeft10">
          <button
            className={`${styles.editBtn} ${
              isEditting ? styles.editOn : styles.editOff
            }`}
            onClick={toggleEditting}
          >
            <img src={pencilWhite} alt="Edit" />
          </button>
          <button
            className={`${styles.expandBtn} mLeft20`}
            onClick={onPressExpandBtn}
          >
            <img src={expanded ? chevronUp : chevronDown} alt="Expand" />
          </button>
        </div>
      </div>
      {expanded ? (
        <div className={`flex flex-col ${styles.detailWrapper}`}>
          {data?.fields?.map((item, index) =>
            renderField(item, index, data?.title)
          )}
        </div>
      ) : null}
    </div>
  );
}
