// @ts-nocheck
import { MinusCircleOutlined } from "@ant-design/icons";
import { Button, Form, Input, InputNumber, Modal } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  getLessonEvaluationList,
  getLessonList,
  getRegisterNotebookRuleList,
  getStudentListByClass,
  getSubjectList,
  getSignatures,
  getAttendanceListByClass,
} from "util/APIs";
import styles from "../styles.module.css";
import Select from "./Select";
import _ from "lodash";
const { Option } = Select;

function EditLessonModal({
  isOpen,
  toggle,
  onFinish,
  initialData,
  onChangeValues,
}) {
  const [form] = Form.useForm();
  const userInfo = useSelector((state) => state?.app?.userInfo);
  const accountId = userInfo?.account?.id;
  const staffInfo = userInfo?.staff?.id;

  const [subjectList, setSubjectList] = useState([]);
  const [studentList, setStudentList] = useState([]);
  const [absentStudentList, setAbsentStudentList] = useState([]);
  const [ruleList, setRuleList] = useState([]);
  const [lessonEvaluationsList, setLessonEvaluationsList] = useState([]);
  const [lessonId, setLessonId] = useState(null);
  const [sign, setSign] = useState(null);
  const [staff, setStaff] = useState(null);

  const handleFinish = (values) => {
    const data = {
      ...values,
      lesson_id: lessonId,
      signed: sign,
      signature: staff
    };
    onFinish(data);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleSign = async () => {
    setSign(true);
    const filters = {
      "filters[staff.id]": userInfo?.staff?.id
    }
    const res = await getSignatures(accountId, filters);
    if (res.status === 200) {
      setStaff(res.data?.data?.[0]?.id)
    }
  }

  const initialvalues = useMemo(() => {
    if (isOpen) {
      initialData.subject = initialData?.subjects?.[0]?.id;
      const absent_students = initialData?.students?.map((item) => item?.id);
      initialData.absent_students = absent_students;
      initialData.lesson_evaluation = initialData?.lesson_evaluations?.[0]?.id;
      const oralTests = initialData?.oral_tests?.map((item) => {
        return {
          id: item?.students?.[0]?.id,
          score: item?.score,
        };
      });

      const violate = initialData?.students_violate_rules?.map((item) => {
        const studentId = item?.students?.[0]?.id;
        let rules = [];
        item.register_notebook_rules.forEach((item) => {
          rules.push(item?.id);
        });
        return {
          id: studentId,
          rules,
        };
      });
      if (violate?.length) {
        initialData.students_violate_rules_data = violate;
      } else {
        initialData.students_violate_rules_data = [{ id: "", rules: [] }];
      }
      if (oralTests?.length) {
        initialData.oral_tests_data = oralTests;
      } else {
        initialData.oral_tests_data = [{ id: "", score: "" }];
      }

      return initialData;
    }
    return {};
  }, [isOpen, initialData]);

  const fetchClassList = useCallback(async () => {
    try {
      const response = await getSubjectList(accountId);
      if (response?.status === 200) {
        const formatData = response?.data?.data?.map((item) => ({
          label: item?.name,
          value: item?.id,
        }));
        setSubjectList(formatData);
      }
    } catch (e) { }
  }, [accountId]);

  const fetchLessonEvaluationList = useCallback(async () => {
    try {
      const response = await getLessonEvaluationList(accountId);
      if (response?.status === 200) {
        const formatData = response?.data?.data?.map((item) => ({
          label: item?.name,
          value: item?.id,
        }));
        setLessonEvaluationsList(formatData);
      }
    } catch (e) { }
  }, [accountId]);

  const fetchRuleList = useCallback(async () => {
    try {
      const response = await getRegisterNotebookRuleList(accountId);
      if (response?.status === 200) {
        const data = response?.data?.data;
        const options = data?.map((item) => ({
          // cateogry: item?.Category,
          label: item?.name,
          value: item?.id,
        }));
        setRuleList(options);
      }
    } catch (e) { }
  }, [accountId]);

  const fetchStudentListByClass = useCallback(async () => {
    try {
      const classId = initialData?.classId;
      const params = {
        "orderBy": "stt"
      }
      const response = await getStudentListByClass(accountId, classId, params);
      if (response?.status === 200) {
        const formatData = response?.data?.data?.map((item) => ({
          label: item?.full_name,
          value: item?.id,
        }));
        setStudentList(formatData);
      }
    } catch (e) { }
  }, [accountId, initialData]);

  const fetchAbsentStudent = useCallback(async () => {
    try {
      const classId = initialData?.classId;
      const params = {
        "filters[workflow_state][$contains]": "absent",
        "filters[date]": initialData.date,
        "filters[sessions.id]": initialData.sessions
      }
      const response = await getAttendanceListByClass(accountId, classId, params);
      if (response?.status === 200) {
        const formatData = response?.data?.data?.map((item) => ({
          label: item?.students?.[0]?.full_name,
          value: item?.students?.[0]?.id,
        }));
        setAbsentStudentList(formatData);
      }
    } catch (e) { }
  }, [accountId, initialData]);

  const fetchLessonList = useCallback(async () => {
    try {
      const response = await getLessonList(accountId);
      if (response?.status === 200) {
        const item = response?.data?.data?.find(
          (i) => i.lesson_code === initialData.lesson_code
        );
        setLessonId(item?.id);
      }
    } catch (e) { }
  }, [accountId, initialData]);

  useEffect(() => {
    if (isOpen) {
      setSign(initialData.signed)
      fetchClassList();
      fetchRuleList();
      fetchStudentListByClass();
      fetchLessonEvaluationList();
      fetchLessonList();
      fetchAbsentStudent();
    }
  }, [
    isOpen,
    fetchClassList,
    fetchStudentListByClass,
    fetchRuleList,
    fetchLessonEvaluationList,
    fetchLessonList,
    fetchAbsentStudent,
  ]);

  return (
    <Modal
      title="Chỉnh sửa"
      open={isOpen}
      onCancel={toggle}
      footer={null}
      destroyOnClose={true}
      width={900}
      style={{ fontWeight: "bold" }}
    >
      <Form
        name="basic"
        labelCol={{
          span: 5,
        }}
        labelAlign="left"
        initialValues={initialvalues}
        className={styles.footerModal}
        onFinish={handleFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        style={{ marginLeft: "2em", fontWeight: "bold" }}
      >
        <Form.Item
          label="Môn học"
          name="subject"
          rules={[
            {
              required: true,
              message: "Vui lòng chọn môn học!",
            },
          ]}
        >
          <Select options={subjectList} />
        </Form.Item>
        <Form.Item
          label="Tên bài học"
          name="name"
          rules={[
            {
              required: true,
              message: "Vui lòng nhập tên bài học!",
            },
          ]}
        >
          <Input className="rounded-lg h-10" />
        </Form.Item>
        {sign ?
          <Form.Item label="Học sinh vắng" name="absent_students">
            <Select options={studentList} mode="multiple" />
          </Form.Item> :
          <Form.Item initialValue={absentStudentList} label="Học sinh vắng" name="absent_students">
            <Select options={studentList} mode="multiple" />
          </Form.Item>}
        <Form.Item
          labelCol={{ span: 24 }}
          label="Kiểm tra đầu giờ"
          name="oral_tests"
        >
          <Form.List
            name="oral_tests_data"
            initialValue={[{ id: "", score: "" }]}
          >
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <div
                    key={key}
                    align="baseline"
                    className="w-full bg-gray-100 p-4 rounded-xl mb-3 flex flex-col"
                  >
                    <div className={`${styles.wrapperForm}`}>
                      <Form.Item
                        {...restField}
                        label="Họ và tên"
                        name={[name, "id"]}
                        className={styles.fieldItem}
                      >
                        <Select
                          className={`${styles.inputWrapper}`}
                          options={studentList}
                        >
                          {[].map((item) => (
                            <Option key={item} value={item}>
                              {item}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        // className="flex-1"
                        className={styles.fieldItem}
                        {...restField}
                        label="Điểm"
                        name={[name, "score"]}
                      >
                        <InputNumber
                          min={1}
                          max={10}
                          className={`${styles.inputWrapper}`}
                        />
                      </Form.Item>
                    </div>
                    {fields.length > 1 && (
                      <Button
                        className={`${styles.buttonDelete}`}
                        onClick={() => remove(name)}
                        block
                      >
                        Xoá
                      </Button>
                    )}
                    {/* <MinusCircleOutlined
                      className="flex-1"
                      onClick={() => remove(field.name)}
                    /> */}
                  </div>
                ))}

                <Form.Item>
                  <Button
                    className="bg-blue-500 text-white rounded-md w-36"
                    onClick={() => add()}
                    block
                  >
                    Thêm mới
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form.Item>

        <Form.Item
          labelCol={{ span: 24 }}
          label="Học sinh vi phạm"
          name="students_violate_rules_data"
        >
          <Form.List
            name="students_violate_rules_data"
            initialValue={[{ id: "", rules: [] }]}
          >
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <div
                    key={key}
                    align="baseline"
                    className="w-full bg-gray-100 p-4 rounded-xl mb-3 flex flex-col"
                  >
                    <div className={`${styles.wrapperForm}`}>
                      <Form.Item
                        {...restField}
                        label="Họ và tên"
                        name={[name, "id"]}
                        className={styles.fieldItem}
                      // className="max-w-screen-md:w-full"
                      >
                        <Select
                          className={`${styles.inputWrapper}`}
                          options={studentList}
                        >
                          {[].map((item) => (
                            <Option key={item} value={item}>
                              {item}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        label="Lỗi vi phạm"
                        name={[name, "rules"]}
                        className={styles.fieldItem}
                      >
                        <Select
                          options={ruleList}
                          mode="multiple"
                          className={`${styles.inputWrapper}`}
                        >
                          {[].map((item) => (
                            <Option key={item} value={item}>
                              {item}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                    {fields.length > 1 && (
                      <Button
                        className={`${styles.buttonDelete}`}
                        onClick={() => remove(name)}
                        block
                      >
                        Xoá
                      </Button>
                    )}
                    {/* <MinusCircleOutlined
                      className={styles.removeIcon}
                      onClick={() => remove(field.name)}
                    /> */}
                  </div>
                ))}

                <Form.Item>
                  <Button
                    className="bg-blue-500 text-white rounded-md w-36"
                    onClick={() => add()}
                    block
                  >
                    Thêm mới
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form.Item>
        <Form.Item label="Nhận xét chung" name="lesson_evaluation">
          <Select options={lessonEvaluationsList} />
        </Form.Item>
        <Form.Item label="Nhận xét thêm" name="comment">
          <Input className="rounded-lg h-10" />
        </Form.Item>
        <Form.Item label="Chữ ký" name="signatures">
          <div key="button-row" className="">
            {sign ? (<Button
              onClick={() => handleSign()}
              className="bg-gray-500 text-white rounded-md w-36"
            >
              Đã ký sổ
            </Button>) : <Button
              onClick={() => handleSign()}
              className="bg-blue-500 text-white rounded-md w-36"
            >
              Ký sổ
            </Button>}
          </div>
        </Form.Item>
        <Form.Item>
          <div key="button-row" className="flex items-center justify-center">
            <Button
              size="large"
              key="cancel"
              className="bg-gray-300 rounded-sm mRight12"
              onClick={toggle}
            >
              Huỷ bỏ
            </Button>
            <Button
              size="large"
              htmlType="submit"
              className="bg-blue-500 text-white rounded-sm"
            >
              Cập nhật
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default EditLessonModal;
